import type { Action } from '@/features/actions/types'
import type { User } from '@/features/user/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { Topic } from '@/features/topics/types'
import type { Location } from '@/features/locations/types'

export enum NotificationType {
  INCIDENT = 'incident',
  DANGEROUS_SITUATION = 'situation',
  OPPORTUNITY = 'opportunity'
}

export interface Notification {
  id: Guid
  notificationType: NotificationType
  name: string
  isInjured?: boolean | null
  description?: string | null
  parentsAlerted?: boolean | null
  topic?: Topic | null
  location: Location
  datetime: string
  createdAt: string
  createdBy: User
  preventiveSuggestion?: string | null
  workersInvolved?: Array<User> | []
  mediaUrls: string[] | null
  actions: Array<Action> | null
}

export type NotificationFormData = {
  id?: Guid | null
  notificationType?: NotificationType | null
  name?: string | null
  description?: string | null
  datetime: string | null | undefined
  createdAt: string | null
  createdBy: User | null
  topicId: Guid | null | undefined
  removeTopic?: boolean | null
  locationId: Guid | null | undefined
  isInjured: boolean | null | undefined
  parentsAlerted: boolean | null | undefined
  workersInvolvedIds: Guid[] | null | undefined
  preventiveSuggestion: string | null
  mediaUrls: string[]
}

export interface NotificationsListFilter {
  filterNotificationType?: NotificationType | null
  filterTopicId?: Guid | null
  filterStartDatetime?: string
  filterEndDatetime?: string
  filterOnlyOrganisation?: boolean
}

export interface NotificationsFilterOptions {
  types: Array<{ value: string; label: string }>
  topics: Array<{ value: string; label: string }>
}
