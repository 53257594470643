<template>
  <modal-advanced
    :id="modalConfig.id"
    ref="modalAdvancedRef"
    v-model:title="model.name"
    :button="modalConfig.button"
    :editable-content-props="{ placeholder: modalConfig.titlePlaceholder, readonly }"
    :modal-props="modalProps"
    @confirm="onConfirm"
    @cancel="onClose"
    @close="onClose"
    @hidden="onHidden"
  >
    <template #type>
      <icon :name="modalTypeIcon" />
      {{ modalType }}
    </template>

    <template v-if="model.id" #sub-navigation>
      <tabs class="sub-navigation">
        <tab-item
          :title="t('notifications.dialogs.edit.documentation')"
          :active="currentTab === Tab.documentation"
          @click="currentTab = Tab.documentation"
        />
        <tab-item
          :title="t('notifications.dialogs.edit.followUpActions')"
          :active="currentTab === Tab.followUp"
          @click="currentTab = Tab.followUp"
        />
      </tabs>
    </template>

    <template #default>
      <template v-if="currentTab === Tab.documentation">
        <reactive-form-field-select
          v-if="model.notificationType === NotificationType.INCIDENT"
          v-model="model.isInjured"
          :field="fields.isInjured"
          :vuelidate="v$.isInjured"
        />

        <reactive-form-field-textarea v-model="model.description" :field="fields.description" />

        <reactive-form-field-toggle
          v-if="model.notificationType === NotificationType.INCIDENT"
          v-model="model.parentsAlerted"
          :field="fields.parentsAlerted"
          :vuelidate="v$.parentsAlerted"
        />

        <reactive-form-field-textarea
          v-if="showPreventiveSuggestion"
          v-model="model.preventiveSuggestion"
          :field="fields.preventiveSuggestion"
        />

        <div v-if="showWorkersInvolvedIds" class="employees-list-container">
          <list-label
            :label="t('notifications.dialogs.edit.involvedEmployees')"
            :hide-add-button="readonly"
            @click:add="onAddWorkers"
          />
          <list-view
            v-if="model.workersInvolvedIds?.length ?? 0 > 0"
            id="employees-list"
            :headers="[{ property: 'user' }]"
            :rows="listViewWorkersInvolved"
            :buttons="readonly ? [] : [ListViewButton.Delete]"
            hide-header
            @event="onRemoveWorker"
          />
          <empty-state
            v-else
            :title="t('notifications.dialogs.edit.noWorkersTitle')"
            :description="
              readonly ? undefined : t('notifications.dialogs.edit.noWorkersDescription')
            "
          />
        </div>

        <div class="image-grid-container">
          <list-label
            :label="t('notifications.dialogs.edit.images')"
            :hide-add-button="readonly"
            @click:add="onAddImages"
          />
          <image-grid
            v-if="combinedMediaList.length"
            id="image-grid"
            preview-mode="grid"
            :images="combinedMediaList"
            :enable-title="false"
            :delete-disabled="readonly"
            @click="onViewImage"
            @delete="onDeleteImage"
          />
          <empty-state
            v-else
            :title="t('notifications.dialogs.edit.noImagesTitle')"
            :description="
              readonly ? undefined : t('notifications.dialogs.edit.noImagesDescription')
            "
          />
        </div>
      </template>

      <template v-if="currentTab === Tab.followUp">
        <actions-overview
          :hide-filters="true"
          :context="actionsNotificationContext"
          :row-click="onRowClickHandler"
          :add-click="onAddActionClick"
        />
      </template>
    </template>

    <template #panel="{ confirm, cancel }">
      <div v-if="model.id" class="actions">
        <dropdown-more-options v-if="!readonly && moreOptions" :items="moreOptions" />
        <spinner v-if="saving" size="small" />
        <button-icon-floating v-else variant="on-white" icon-name="action-close" @click="cancel" />
      </div>
      <div v-else>
        <button class="button-cancel" @click="cancel">
          {{ $t('notifications.dialogs.edit.cancel') }}
        </button>
        <button v-if="!readonly" :disabled="v$.$invalid" class="button-primary" @click="confirm">
          {{ model.id ? modalConfig.button.update.text : modalConfig.button.add.text }}
        </button>
      </div>
      <reactive-form-field-date-picker
        v-model="model.datetime"
        :field="fields.datetime"
        :vuelidate="v$.datetime"
      />
      <reactive-form-field-select
        v-model="model.topicId"
        :field="fields.topicId"
        :vuelidate="v$.topicId"
      />
      <reactive-form-field-label field-id="locationId">
        {{ t('notifications.dialogs.edit.form.location') }}
      </reactive-form-field-label>
      <div>{{ locationName }}</div>
      <created-at
        v-if="notification?.createdAt"
        :created-at="notification.createdAt"
        :created-by="notification.createdBy"
      />
    </template>
  </modal-advanced>
</template>

<script setup lang="ts">
import '@/features/notifications/localization'
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import type {
  ReactiveFormFieldDatePicker,
  ReactiveFormFieldInputText,
  ReactiveFormFieldSelect,
  ReactiveFormFieldTextarea,
  ReactiveFormFieldToggle
} from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import { useVuelidate } from '@vuelidate/core'

import ActionsOverview from '@/features/actions/components/actionsOverview/index.vue'

import ModalAdvanced from '@/support/components/modal/advanced/index.vue'

import type { NotificationFormData, Notification } from '@/features/notifications/types'
import { NotificationType } from '@/features/notifications/types'
import { createGuid } from '@/support/helpers/guid'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import { currentLocationId, hasPermission, locations, userInfo } from '@/features/auth'
import { PermissionName } from '@/features/roles/types'
import { useTopicsStore } from '@/features/topics/store'
import { useUserStore } from '@/features/user/store'
import { useNotificationsStore } from '@/features/notifications/store'
import type { User } from '@/features/user/types'
import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'
import { notificationTypeIcon } from '@/features/notifications/helpers'
import TabItem from '@/support/components/tabs/components/tabItem.vue'
import type { Guid } from '@/support/types/Guid.dto'
import ImageGrid from '@/features/media/components/imageGrid.vue'
import type { DropdownItemProps } from '@/support/components/dropdownMenu/dtos'
import type { ExtendedFile, MediaListItem, NewFile } from '@/features/media/types'
import { required } from '@/support/helpers/i18n-validators'
import type { Topic } from '@/features/topics/types'
import type { ActionContext } from '@/features/actions/types'
import type { ListViewRecord } from '@/support/components/listView/types'

const { t } = useI18n()

type Event =
  | { type: 'add-workers' | 'add-images' }
  | { type: 'remove-worker'; id: Guid }
  | { type: 'view-image'; index: number; images: any[] }
  | { type: 'delete-image'; image: MediaListItem }
  | { type: 'create-action'; id: Guid }
  | { type: 'edit-action'; id: Guid }
  | { type: 'delete'; id: Guid }

const emit = defineEmits<{
  confirm: [data: NotificationFormData, files: File[]]
  close: []
  hidden: []
  event: [event: Event]
}>()

interface Props {
  modalProps: any
  form: NotificationFormData
  notification?: Notification
  saving?: boolean
  newFiles: File[]
}

const topicsStore = useTopicsStore()
const usersStore = useUserStore()
const notificationsStore = useNotificationsStore()

const { topics } = storeToRefs(topicsStore)
const { users } = storeToRefs(usersStore)

const props = withDefaults(defineProps<Props>(), {
  modalProps: () => ({})
})

const model = reactive(props.form)

if (!model.id && !model.locationId) {
  model.locationId = currentLocationId.value
}

const modalId = createGuid()
const modal = computed(() => ({
  id: modalId,
  titlePlaceholder: t('notifications.dialogs.edit.titlePlaceholder'),
  button: {
    cancel: {
      text: t('notifications.dialogs.edit.cancel')
    },
    add: {
      text: t('notifications.dialogs.edit.add')
    },
    update: {
      text: t('notifications.dialogs.edit.update')
    }
  }
}))

const readonly = computed<boolean>(() => {
  if (hasPermission(PermissionName.NotificationEdit)) {
    return false
  }
  if (
    hasPermission(PermissionName.NotificationCreateViewEditMine) &&
    (!model.id || props.notification?.createdBy.id === userInfo.value?.id)
  ) {
    return false
  }
  return true
})

const fields = computed(() => ({
  description: {
    id: 'description',
    type: FieldType.TEXTAREA,
    label: t('notifications.dialogs.edit.form.description'),
    placeholder: t('notifications.dialogs.edit.form.descriptionPlaceholder'),
    validationRules: {
      required: false
    },
    readonly: readonly.value
  } satisfies ReactiveFormFieldTextarea,
  datetime: {
    id: 'datetime',
    type: FieldType.DATE_PICKER,
    label: t('notifications.dialogs.edit.form.datetime'),
    placeholder: t('notifications.dialogs.edit.form.datetimePlaceholder'),
    enableTime: true,
    validationRules: {
      required: true
    },
    readonly: readonly.value
  } satisfies ReactiveFormFieldDatePicker,
  topicId: {
    id: 'topicId',
    type: FieldType.SELECT,
    label: t('notifications.dialogs.edit.form.topic'),
    placeholder: t('notifications.dialogs.edit.form.topicPlaceholder'),
    validationRules: {
      required: false
    },
    options: (topics.value || []).map((topic: Topic) => ({
      value: topic.id,
      label: topic.title
    })),
    readonly: readonly.value
  } satisfies ReactiveFormFieldSelect,
  isInjured: {
    id: 'isInjured',
    type: FieldType.SELECT,
    label: t('notifications.dialogs.edit.form.isInjured'),
    placeholder: t('notifications.dialogs.edit.form.isInjuredPlaceholder'),
    options: [
      { label: t('app.defaults.yes'), value: true },
      { label: t('app.defaults.no'), value: false }
    ],
    validationRules: {
      required: false
    },
    readonly: readonly.value
  } satisfies ReactiveFormFieldSelect,
  parentsAlerted: {
    id: 'parentsAlerted',
    type: FieldType.TOGGLE,
    size: 'small',
    hideLabel: true,
    options: [
      {
        label: t('notifications.dialogs.edit.form.parentsAlerted'),
        value: true
      }
    ],
    readonly: readonly.value
  } satisfies ReactiveFormFieldToggle,
  preventiveSuggestion: {
    id: 'preventiveSuggestion',
    type: FieldType.TEXTAREA,
    label: t('notifications.dialogs.edit.form.preventiveSuggestion'),
    placeholder: '',
    validationRules: {
      required: false
    },
    readonly: readonly.value
  } satisfies ReactiveFormFieldInputText
}))

const modalAdvancedRef = ref<InstanceType<typeof ModalAdvanced>>()

const formCached = ref<string>(JSON.stringify(model))
const v$ = useVuelidate(
  computed(() => ({
    datetime: { required },
    locationId: { required },
    isInjured: model.notificationType === NotificationType.INCIDENT ? { required } : {},
    parentsAlerted: model.notificationType === NotificationType.INCIDENT ? { required } : {},
    workersInvolvedIds: {},
    preventiveSuggestion: {}
  })),
  model
)
const modalConfig = ref(modal)

enum Tab {
  documentation,
  followUp
}
const currentTab = ref<Tab>(Tab.documentation)
const loading = ref(false)

const moreOptions = computed<DropdownItemProps[] | undefined>(() =>
  hasPermission(PermissionName.NotificationDelete)
    ? [
        {
          id: 1,
          name: t('app.button.delete'),
          iconName: 'action-delete',
          onClick: () => onDeleteClick()
        }
      ]
    : undefined
)

const locationName = computed<string | undefined | null>(
  () =>
    locations.value?.find((option) => option.id === (model.locationId || currentLocationId.value))
      ?.name
)

const modalTypeIcon = computed(() => {
  return notificationTypeIcon(model.notificationType)
})

const modalType = computed(() => {
  switch (model.notificationType) {
    case NotificationType.INCIDENT:
      return t('notifications.incident')
    case NotificationType.DANGEROUS_SITUATION:
      return t('notifications.dangerousSituation')
    case NotificationType.OPPORTUNITY:
      return t('notifications.type.opportunity')
    default:
      return t('notifications.incident')
  }
})

const showPreventiveSuggestion = computed(() => {
  switch (model.notificationType) {
    case NotificationType.INCIDENT:
    case NotificationType.DANGEROUS_SITUATION:
      return true
    default:
      return false
  }
})

const showWorkersInvolvedIds = computed(() => {
  switch (model.notificationType) {
    case NotificationType.INCIDENT:
    case NotificationType.DANGEROUS_SITUATION:
      return true
    default:
      return false
  }
})

const actionsNotificationContext = computed(() => {
  if (!model.id) {
    return
  }

  return {
    context: 'notification',
    id: model.id
  } satisfies ActionContext
})

const listViewWorkersInvolved = computed<ListViewRecord[]>(() =>
  (users.value || [])
    .filter((user: User) => model.workersInvolvedIds?.some((id) => id === user.id))
    .map((user) => ({ id: user.id, user }))
)

const combinedMediaList = computed<MediaListItem<string>[]>(() => [
  ...(model.mediaUrls?.map((data) => ({ type: 'extended', data }) satisfies ExtendedFile<string>) ||
    []),
  ...(props.newFiles?.map((data) => ({ type: 'new', data }) satisfies NewFile) || [])
])

const close = () => {
  modalAdvancedRef.value?.close()
}

const onAddActionClick = () => {
  emit('event', { type: 'create-action', id: model.id! })
}

const onRowClickHandler = ({ action, id }: { id: Guid; action: 'edit' | 'delete' }) => {
  if (action === 'edit') {
    emit('event', { type: 'edit-action', id })
  }
}

const onDeleteClick = () => {
  emit('event', { type: 'delete', id: model.id! })
}

const onConfirm = () => {
  emit('confirm', model, props.newFiles)
}

const onClose = () => {
  emit('close')
}

const onHidden = () => {
  emit('hidden')
}

const onAddWorkers = () => {
  emit('event', { type: 'add-workers' })
}

const onRemoveWorker = (event: any) => {
  const { action, id } = event
  if (action === ListViewButton.Delete && id) {
    emit('event', { type: 'remove-worker', id })
  }
}

const onViewImage = ({ index, images }: any) => {
  emit('event', { type: 'view-image', index, images })
}

const onDeleteImage = (image: MediaListItem) => {
  emit('event', { type: 'delete-image', image })
}

const onAddImages = () => {
  emit('event', { type: 'add-images' })
}

onMounted(() => {
  const promises = []

  if (!topics.value) {
    promises.push(topicsStore.getAllTopics())
  }
})

watch(currentTab, async () => {
  if (currentTab.value === Tab.followUp && model.id) {
    loading.value = true
    await notificationsStore.getNotification(model.id)
    loading.value = false
  }
})

watchDebounced(
  () => [model, props.newFiles],
  async () => {
    const formChanged = JSON.stringify(model) !== formCached.value || props.newFiles.length

    if (!model.id || !formChanged) {
      return
    }

    if (v$.value.$invalid) {
      v$.value.$touch()
      await nextTick()
      document.querySelector<HTMLDivElement>('div.reactive-form__errors')?.scrollIntoView()
      return
    }

    emit('confirm', model, props.newFiles)

    formCached.value = JSON.stringify(model)
  },
  { debounce: 500, deep: true }
)

defineExpose({
  close
})
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-self: end;
  align-items: center;
}
.sub-navigation {
  padding: 0 24px;
  background-color: map-get($theme-color-secondary, 'light-green-2');
}
.employees-list-container,
.image-grid-container {
  margin-bottom: 30px;
  padding-right: 30px;
}
:deep(.created-at) {
  padding: 20px 0;
  max-width: 263px;
}
:deep(.reactive-form__field) {
  &.is-injured-field {
    width: 167px;
  }
  &.is-injured-field,
  &.parents-alerted-field,
  &.preventive-suggestion-field {
    margin-bottom: 30px;
  }
}
:deep(.action-overview) {
  margin-right: 10px;

  .title {
    h4 {
      display: none;
    }
    button {
      margin-left: auto;
    }
  }
  .list-view.list-view-actions {
    .column.column-buttons {
      display: none;
    }
  }
}
:deep(.location-selector) {
  .dropdown-holder {
    display: block;

    button {
      display: block;
      width: 263px;
      padding: 10px 38px 10px 40px;
      min-height: 45px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;

      .icon.icon--left,
      .icon.icon--right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        margin: 0;
      }
      .icon.icon--left {
        left: 10px;
      }
      .icon.icon--right {
        right: 10px;
      }
    }
    .dropdown-container {
      width: 263px;
      height: 300px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}
</style>
