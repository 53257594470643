import { computed } from 'vue'
import { hasPermission, userInfo } from '@/features/auth'
import { PermissionName } from '@/features/roles/types'

export const useActionsPermissions = () => {
  const canCreateActionsPermission = computed(() => hasPermission(PermissionName.ActionsCreate))

  const canChangeAssignedActionsPermission = computed(() =>
    hasPermission(PermissionName.ActionsChangeMine)
  )

  const canDeleteActionsPermission = computed(() => hasPermission(PermissionName.ActionsDelete))

  const canChangeActionsLocationsPermission = computed(() =>
    hasPermission(PermissionName.ActionsChangeLocation)
  )

  // Computed method to check if the user is the assignee of the action
  const isAssignee = (action: any) =>
    computed(() => (action.manager?.id ?? action?.managerId) === userInfo.value?.id)

  // Computed method to check if the user is the owner of the action
  const isOwner = (action: any) => computed(() => action.createdBy?.id === userInfo.value?.id)

  // Computed method to check if the user can edit the action record
  const canEditActionRecord = (action: any) =>
    computed(() => {
      if (!action.id) {
        return true
      }

      const isActionAssignee = isAssignee(action).value
      const isActionOwner = isOwner(action).value

      // User can only change an actions if they have permission to change assigned actions
      if (isActionAssignee && canChangeAssignedActionsPermission.value) {
        return true
      }

      // User can only edit an actions if user is owner and has permission to create actions
      if (isActionOwner && canCreateActionsPermission.value) {
        return true
      }

      // User can only change actions locations if they have permission to change actions locations
      if (canChangeActionsLocationsPermission.value) {
        return true
      }

      return false
    })

  return {
    canCreateActionsPermission,
    canDeleteActionsPermission,
    canEditActionRecord
  }
}
