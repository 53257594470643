import { type Component, defineAsyncComponent } from 'vue'

import components from '@/support/components/components'

const allComponents: { [key: string]: Component } = {
  'container-fluid': defineAsyncComponent(() => import('@/app/layout/ContainerFluid.vue')),
  'container-one-column': defineAsyncComponent(() => import('@/app/layout/ContainerOneColumn.vue')),
  'container-two-column': defineAsyncComponent(() => import('@/app/layout/ContainerTwoColumn.vue')),
  'master-page': defineAsyncComponent(() => import('@/app/layout/MasterPage.vue')),
  'two-column': defineAsyncComponent(() => import('@/app/layout/TwoColumn.vue')),
  ...components
}
export default allComponents
