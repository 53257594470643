import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { ContactFormData, HelpItem } from '../types'
import type { AxiosResponse } from 'axios'

export const getHelpContent = async () => {
  return (await fetchWithAuth('/helps', { method: 'GET' })) as AxiosResponse<HelpItem[]>
}

export const submitContactForm = async (data: ContactFormData) => {
  return await fetchWithAuth('/helps/sendform', { method: 'POST', data })
}
