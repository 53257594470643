<template>
  <div :id="`accordion-${uniqueId}`" class="accordion">
    <slot :unique-id="uniqueId" :open="open" :on-update="onUpdate" />
  </div>
</template>

<script setup lang="ts">
import { createGuid } from '@/support/helpers/guid'

const open = defineModel<number>('open', { default: 0 })

const uniqueId = createGuid()

const onUpdate = (newOpen: number) => {
  open.value = newOpen
}
</script>
