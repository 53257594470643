import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { AxiosResponse } from 'axios'
import type {
  Notification,
  NotificationFormData,
  NotificationsListFilter
} from '@/features/notifications/types'
import type { Guid } from '@/support/types/Guid.dto'

export const getNotifications = async ({
  locationPath,
  pageSize = 10,
  ...params
}: NotificationsListFilter & { locationPath: string; pageIndex: number; pageSize?: number }) => {
  return (await fetchWithAuth(`/notifications/list/${locationPath}`, {
    method: 'GET',
    params: { ...params, pageSize }
  })) as AxiosResponse
}

export const getNotification = async (id: Guid) => {
  return (await fetchWithAuth(`/notifications/${id}`)) as AxiosResponse
}

export const createNotification = async (data: NotificationFormData) => {
  return (await fetchWithAuth('/notifications/create', {
    method: 'POST',
    data
  })) as AxiosResponse<Notification>
}

export const updateNotification = async (notification: Partial<NotificationFormData>) => {
  const { id, ...data } = notification
  if (!data.notificationType) {
    throw new Error('notificationType is required for the backend')
  }
  return (await fetchWithAuth(`/notifications/${id}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<Notification>
}

export const deleteNotification = async (id: Guid) => {
  return (await fetchWithAuth(`/notifications/${id}`, { method: 'DELETE' })) as AxiosResponse
}
