<template>
  <modal-default
    :id="modal.id"
    :title="modal.title"
    :button="modal.button"
    :hide-footer="role?.isLocked"
    :width="modal.width"
    height="90%"
    @confirm="$emit('confirm', form)"
    @cancel="$emit('close')"
    @close="$emit('close')"
    @hidden="$emit('hidden')"
  >
    <template #default>
      <div>
        <reactive-form v-model:form="form" :config="formConfig" class-name="secondary" />
        <template v-for="field in permissionsSelectedFields" :key="field.id">
          <reactive-form-field-toggle v-model="permissionsSelected" :field="field" />
        </template>
      </div>
    </template>
  </modal-default>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'
import { useI18n } from 'vue-i18n'
import { createGuid } from '@/support/helpers/guid'

import { useRolesStore } from '@/features/roles/store'

import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import type { Role, RoleFormData, PermissionName } from '@/features/roles/types'
import permissionCategories from '@/features/roles/components/roleDialog/permission-categories'
import type { ReactiveFormFieldToggle } from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import type { Guid } from '@/support/types/Guid.dto'

interface Props {
  role?: Role
}

const props = defineProps<Props>()
const form = defineModel<RoleFormData>({ required: true })
defineEmits<{ confirm: [form: RoleFormData]; close: []; hidden: [] }>()

const { t, te } = useI18n()

const formConfig = computed<ReactiveFormConfig>(() => ({
  legend: null,
  buttons: null,
  fields: [
    {
      id: 'name',
      type: FieldType.INPUT_TEXT,
      label: t('roles.modal.role.form.name'),
      validationRules: { required: true },
      readonly: props.role?.isLocked
    },
    {
      id: 'description',
      type: FieldType.INPUT_TEXT,
      label: t('roles.modal.role.form.description'),
      readonly: props.role?.isLocked
    }
  ]
}))

const modalId = createGuid()
const modal = computed(() => ({
  id: modalId,
  title: t('roles.modal.role.title'),
  height: 800,
  width: 960,
  button: !props.role?.isLocked
    ? {
        cancel: { text: t('app.button.cancel') },
        confirm: { text: t('app.button.save') }
      }
    : undefined
}))

const rolesStore = useRolesStore()
const { permissions } = storeToRefs(rolesStore)

const permissionId = computed<Map<PermissionName, Guid>>(() =>
  permissions.value.reduce<Map<PermissionName, Guid>>(
    (result, permission) => result.set(permission.name, permission.id),
    new Map()
  )
)

const permissionsSelected = defineModel<Guid[]>('permissionsSelected', {
  get: () => form.value.permissionIds,
  set: (value) => {
    if (!form.value.permissionIds) {
      form.value.permissionIds = []
    }
    form.value.permissionIds.splice(0, form.value.permissionIds.length)
    form.value.permissionIds.push(...value)
  }
})
const permissionsSelectedFields = computed<ReactiveFormFieldToggle[]>(() =>
  permissionCategories.map((category) => ({
    id: `permission-category-${category.name}`,
    type: FieldType.TOGGLE,
    size: 'small',
    label: t(`roles.permission.category.${category.name}`),
    labelTooltip: te(`roles.permission.categoryDescription.${category.name}`)
      ? t(`roles.permission.categoryDescription.${category.name}`)
      : undefined,
    readonly: props.role?.isLocked,
    options: category.permissions.map((permissionName) => ({
      value: permissionId.value.get(permissionName) || 'unknown-permission',
      label: t(`roles.permission.${permissionName.replace(/\./g, '')}`),
      className: permissionName.toLowerCase().replace(/[ .]/g, '-')
    }))
  }))
)
</script>

<style lang="scss" scoped>
:deep(.reactive-form.reactive-form--secondary) {
  margin-bottom: 30px;

  .reactive-form__field.description-field {
    margin-bottom: 0;
  }
}
:deep(.reactive-form__field[class*='permission-category-']) {
  margin-bottom: 40px;

  .field-label {
    font-family: $theme-brand-font-headings;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .custom-toggle {
    padding: 15px 15px 0;
    gap: 20px;

    &.policy-feedback,
    &.policy-edit,
    &.notification-edit,
    &.cyclus-activities-change-location,
    &.actions-change-location {
      padding-left: 75px;
    }
    &.policy-publish {
      padding-left: 125px;
    }
  }
}
</style>
