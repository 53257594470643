import type { Guid } from '@/support/types/Guid.dto'

export type Organisation = {
  id: Guid
  name: string | null
  isActive: boolean | null
  emailAdress: string | null
  postalPostCode?: string | null
  postalAddress?: string | null
  postalCity?: string | null
  contactPersonInvoiceTelephone: string | null
  contactPersonInvoiceFirstName: string | null
  contactPersonInvoiceLastName: string | null
  contactPersonInvoiceNamePrefix: string | null
  invoiceEmail: string | null
  invoiceAddress: string | null
  invoiceCity: string | null
  invoicePostcode: string | null
  countLocations: number
  paymentDetails?: string | null
}

export type UpdateOrganisation = Omit<Organisation, 'isActive' | 'countLocations' | 'id'>

export const DEFAULT_ORGANISATION: UpdateOrganisation = {
  name: null,
  emailAdress: null,
  postalPostCode: null,
  postalAddress: null,
  postalCity: null,
  contactPersonInvoiceTelephone: null,
  contactPersonInvoiceFirstName: null,
  contactPersonInvoiceLastName: null,
  contactPersonInvoiceNamePrefix: null,
  invoiceEmail: null,
  invoiceAddress: null,
  invoiceCity: null,
  invoicePostcode: null,
  paymentDetails: null
}
