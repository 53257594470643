<template>
  <div>
    <h2 class="mb-4">
      {{ t('user.overview.title') }}
      <template v-if="isOnboarding">
        <button-icon
          variant="secondary"
          :icon-props="{ name: `action-add`, states: ['disabled'] }"
          icon-position="left"
          @click="showModal('create')"
        >
          {{ t('app.button.new') }}
        </button-icon>
      </template>
    </h2>

    <list-view-filters v-if="!isOnboarding">
      <reactive-form-field-select v-model="filters.roleId" :field="filterFieldRoleId" />
      <reactive-form-field-select v-model="filters.locationId" :field="filterFieldLocationId" />
      <template #right>
        <button-icon
          variant="secondary"
          :icon-props="{ name: `action-add`, states: ['disabled'] }"
          icon-position="left"
          @click="showModal('create')"
        >
          {{ t('app.button.new') }}
        </button-icon>
      </template>
    </list-view-filters>

    <strong v-if="listViewRecords.length" class="d-block font-size-s mb-3">{{
      t('user.overview.listView.count', { count: listViewRecords.length })
    }}</strong>

    <skeleton v-if="loading && !listViewRecords.length" />
    <spinner-context>
      <spinner v-if="(loading || saving) && listViewRecords.length" />
      <template #context>
        <list-view
          v-if="listViewRecords.length"
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewButtons"
          @event="handleRowClick"
        >
          <template #name="{ item }">
            <badge v-if="item.inactive" :type="Status.neutral">{{
              t('user.overview.listView.tag.inviteSend')
            }}</badge>
            <div>{{ item.name }}</div>
          </template>
          <template #role="{ item }">
            <badge :type="Status.mintgreen" rounded>
              {{ item.role }}
            </badge>
          </template>
          <template #footer>
            <pagination :page-index="pageIndex" :total-pages="totalPages" @change="onPageChange" />
          </template>
        </list-view>
        <empty-state
          v-if="!loading && !filteredUsers.length"
          :title="$t('user.overview.listView.emptyState.title')"
          :description="$t('user.overview.listView.emptyState.description')"
        />
      </template>
    </spinner-context>

    <user-dialog
      v-if="modalRecordOpen"
      v-model:form="reactiveUser"
      :user="selectedUser"
      @confirm="onConfirmModalRecord"
      @hidden="modalRecordOpen = false"
    />

    <modal-delete
      v-if="modalDeleteRecordOpen"
      :title="t('user.overview.modal.deleteUser.title', { name: selectedUser?.email })"
      :message="t('user.overview.modal.deleteUser.message')"
      :confirm-button-text="t('user.overview.modal.deleteUser.confirmButton')"
      @confirm="onConfirmModalDeleteRecord"
      @hidden="onHiddenModalDeleteRecord"
    />

    <modal-alert
      v-if="modalCannotDeleteRecordOpen && cannotDeleteMessage"
      :title="cannotDeleteMessage"
      @hidden="modalCannotDeleteRecordOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import Console from '@/support/core/console'

import UserDialog, { type UserForm } from '@/features/user/components/userDialog/index.vue'
import ModalDelete from '@/features/modal/modalDelete/index.vue'
import ModalAlert from '@/features/modal/modalAlert/index.vue'

import useOnboarding from '@/features/onboarding/compositions/useOnboarding'

import { useLocationsStore } from '@/features/locations/store'
import { useRolesStore } from '@/features/roles/store'
import { useUserStore } from '@/features/user/store'

import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'
import { getFullNameOrEmail } from '@/features/user/helpers'
import { useAlertStore } from '@/support/components/alert/store'

import { Status } from '@/support/enums/Status'
import type { UpdateUser, User, UsersListFilter } from '@/features/user/types'
import type { Alert } from '@/support/components/alert/types'
import type { ListviewHeader } from '@/support/components/listView/types'
import type { AxiosResponse } from 'axios'
import { userInfo } from '@/features/auth'
import type { ReactiveFormFieldSelect } from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'

const { t } = useI18n()
const { isOnboarding } = useOnboarding()

const userRecordDefaults: UserForm = {
  type: 'invite',
  email: '',
  hasAccessToOrganisation: false,
  locationIds: null,
  roleId: null
}

const filterFieldRoleId = computed<ReactiveFormFieldSelect>(() => ({
  id: 'roleId',
  type: FieldType.SELECT,
  label: t('user.overview.modal.user.form.role'),
  placeholder: t('app.filterOn', { entity: t('user.overview.modal.user.form.role').toLowerCase() }),
  hideLabel: true,
  options: filterOptions.value.roles,
  disabled: loading.value
}))

const filterFieldLocationId = computed<ReactiveFormFieldSelect>(() => ({
  id: 'locationId',
  type: FieldType.SELECT,
  label: t('user.overview.modal.user.form.location'),
  placeholder: t('app.filterOn', {
    entity: t('user.overview.modal.user.form.location').toLowerCase()
  }),
  hideLabel: true,
  options: filterOptions.value.locations,
  disabled: loading.value
}))

const modalRecordOpen = ref(false)
const modalDeleteRecordOpen = ref(false)
const modalCannotDeleteRecordOpen = ref(false)

const alertStore = useAlertStore()
const locationsStore = useLocationsStore()
const rolesStore = useRolesStore()
const userStore = useUserStore()

const { locations } = storeToRefs(locationsStore)
const { roles } = storeToRefs(rolesStore)
const { filterOptions } = storeToRefs(userStore)

const users = computed<User[]>(() => (userStore.users || []).filter((user) => !user.isDeleted))

const filteredUsers = computed<User[]>(() =>
  users.value.filter((user) => {
    if (
      filters.locationId &&
      !user.hasAccessToOrganisation &&
      !user.locations?.some((location) => location.id === filters.locationId)
    ) {
      return false
    }
    if (filters.roleId && user.role?.id !== filters.roleId) {
      return false
    }
    return true
  })
)

const PAGE_LENGTH = 12
const pageIndex = ref(1)
const totalPages = computed<number>(() => Math.ceil(filteredUsers.value.length / PAGE_LENGTH))
const usersPage = computed<User[]>(() =>
  [...filteredUsers.value].slice((pageIndex.value - 1) * PAGE_LENGTH, pageIndex.value * PAGE_LENGTH)
)
const onPageChange = (index: number) => {
  pageIndex.value = index
}

const loading = ref(false)
const saving = ref(false)

const reactiveUser = reactive<UserForm>({ ...userRecordDefaults })
const selectedUser = ref<User>()
const filters = reactive<UsersListFilter>({ roleId: null, locationId: null })

const selectedSelf = computed<boolean>(
  () => (selectedUser.value && selectedUser.value.id === userInfo.value?.id) || false
)
const cannotDeleteMessage = computed<string | undefined>(() => {
  if (selectedSelf.value) {
    return t('user.overview.modal.cannotDelete.self')
  }
  if (selectedUser.value?.isSuperUser) {
    return t('user.overview.modal.cannotDelete.onboardingUser')
  }
  if (selectedUser.value?.role?.mustBeAtLeastOne) {
    return t('user.overview.modal.cannotDelete.lastOfRole', { role: selectedUser.value.role.name })
  }
  return ''
})

const listViewHeader = computed<ListviewHeader[]>(() => [
  { value: t('user.overview.listView.header.name'), property: 'name', wrap: true },
  {
    value: t('user.overview.listView.header.role'),
    property: 'role',
    align: 'right',
    wrap: true
  },
  {
    value: t('user.overview.listView.header.locations'),
    property: 'locations',
    align: 'right',
    wrap: true
  }
])

const listViewButtons: Array<ListViewButton> = [ListViewButton.Edit, ListViewButton.Delete]

type ListViewItem = {
  id: string
  inactive?: boolean
  name: string
  role?: string
  locations: string
  disableButtons?: ListViewButton[]
}

const listViewRecords = computed<ListViewItem[]>(() =>
  usersPage.value.map((user) => {
    const userLocations = user.locations?.map((location) => location.name).join(', ') || ''

    const userLocationsValue = user.hasAccessToOrganisation
      ? t('user.hasAccessToOrganisationLabel')
      : user.locations?.length
        ? `(${user.locations.length}) ${userLocations}`
        : t('user.overview.listView.noLocationsSelected')

    return {
      id: user.id,
      inactive: !user.hasSsoId || user.isDeleted || false,
      name: getFullNameOrEmail(user),
      role: user.role?.name,
      locations: userLocationsValue
    } satisfies ListViewItem
  })
)

const showModal = (action: string, id?: string) => {
  selectedUser.value = id ? users.value.find((user: User) => user.id === id) : undefined

  const recordForm: UserForm = !selectedUser.value
    ? { ...userRecordDefaults }
    : {
        type: 'update',
        id: selectedUser.value.id,
        email: selectedUser.value.email,
        firstName: selectedUser.value.firstName,
        namePrefix: selectedUser.value.namePrefix,
        lastName: selectedUser.value.lastName,
        hasAccessToOrganisation: selectedUser.value.hasAccessToOrganisation,
        locationIds: selectedUser.value.locations?.map((location) => location.id) || [],
        roleId: selectedUser.value.role?.id ?? null
      }

  Console.log('showModal', recordForm)

  Object.assign(reactiveUser, recordForm)

  if (['edit', 'create'].includes(action)) {
    modalRecordOpen.value = true
    return
  }

  if (action === 'delete' && selectedUser.value) {
    if (
      selectedSelf.value ||
      (selectedUser.value.isSuperUser && !userInfo.value?.isOnboardingComplete)
    ) {
      modalCannotDeleteRecordOpen.value = true
      return
    }

    const currentRole = roles.value.find((role) => role.id === selectedUser.value!.role?.id)

    if (currentRole?.mustBeAtLeastOne) {
      const usersWithSameRole = users.value.filter(
        (user) =>
          user.id !== selectedUser.value!.id &&
          !user.isDeleted &&
          user.hasSsoId &&
          user.role?.id === currentRole!.id
      )
      if (usersWithSameRole.length > 0) {
        modalDeleteRecordOpen.value = true
        return
      }
    }

    modalDeleteRecordOpen.value = true
  }
}

const onHiddenModalDeleteRecord = () => {
  modalDeleteRecordOpen.value = false
}

const onConfirmModalRecord = async (user: UserForm) => {
  Console.log('onConfirmModalRecord', location)

  let response: AxiosResponse<User> | undefined
  if (user.type === 'update' && selectedUser.value) {
    const mutations: Partial<UpdateUser> = {}

    if (
      JSON.stringify([...(user.locationIds || [])].sort()) !==
      JSON.stringify([...(selectedUser.value?.locations?.map((location) => location.id) || [])])
    ) {
      mutations.locationIds = user.locationIds
    }
    if (user.roleId !== selectedUser.value.role?.id) {
      mutations.roleId = user.roleId
    }
    if (user.roleId !== selectedUser.value?.role?.id) {
      mutations.roleId = user.roleId
    }
    if (user.hasAccessToOrganisation !== selectedUser.value?.hasAccessToOrganisation) {
      mutations.hasAccessToOrganisation = user.hasAccessToOrganisation
    }

    if (!Object.keys(mutations).length) {
      return
    }

    saving.value = true
    response = await userStore.updateUser(selectedUser.value.id, { ...mutations })
  }

  if (user.type === 'invite') {
    const { email, hasAccessToOrganisation, roleId, locationIds } = user
    saving.value = true
    response = await userStore.inviteUser({ email, hasAccessToOrganisation, roleId, locationIds })
  }
  saving.value = false

  const alert: Alert =
    response?.status !== 200
      ? {
          message:
            response?.status === 409
              ? t('alert.users.conflict', { contactEmail: 'risicomonitor@veiligheid.nl' })
              : t(`app.xhr.POST.error`),
          type: Status.error
        }
      : {
          message: user.type === 'invite' ? t('alert.users.created') : t('alert.users.updated'),
          type: Status.success
        }

  if (response?.status === 200) {
    loading.value = true
    await userStore.reloadAllUsers()
    loading.value = false
  }

  alertStore.setMessage(alert)
}

const onConfirmModalDeleteRecord = async () => {
  Console.log('onConfirmModalDeleteRecord', selectedUser.value?.id)

  if (!selectedUser.value) {
    return
  }

  saving.value = true
  const response = await userStore.deleteUser(selectedUser.value.id)
  saving.value = false
  const alert: Alert =
    response?.status !== 200
      ? { message: t('app.xhr.delete.error'), type: Status.error }
      : { message: t('alert.users.deleted'), type: Status.success }

  if (response?.status === 200) {
    loading.value = true
    pageIndex.value = 1
    await userStore.reloadAllUsers()
    loading.value = false
  }

  alertStore.setMessage(alert)
}

const handleRowClick = (event: any) => {
  if (loading.value) {
    return
  }

  Console.log('onRowClickHandler', event)

  const { action, id } = event

  switch (action) {
    case 'edit':
      showModal(action, id)
      break
    case 'delete':
      showModal(action, id)
      break
  }
}

onMounted(async () => {
  loading.value = true

  if (!locations.value.length) {
    await locationsStore.getAllLocations()
  }

  if (!roles.value.length) {
    await rolesStore.getAllRoles()
  }

  if (!filterOptions.value.locations.length && !filterOptions.value.roles.length) {
    await userStore.getUserFilters()
  }

  await userStore.getAllUsers()

  loading.value = false
})
</script>

<style lang="scss" scoped>
h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep(.list-view-filters) {
  input:disabled,
  select:disabled {
    opacity: 0.5;
  }
}
</style>
