<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <div ref="container" class="flatpickr">
      <div v-show="field.readonly" class="flatpickr-input field-readonly">
        {{ readOnlyValue || '-' }}
      </div>
      <input
        v-show="!field.readonly"
        ref="datepickerInput"
        type="text"
        :name="field.id"
        :disabled="field.disabled"
        :placeholder="field.placeholder ?? ''"
        data-lpignore="true"
        data-input
        autocomplete="off"
        @click="onClickInput"
        @keydown.enter.prevent="datepickerInput?.blur()"
      />
      <icon ref="datepickerToggle" name="action-date" data-toggle />
    </div>

    <reactive-form-errors :errors="errors" />
  </div>
</template>

<script setup lang="ts">
import { watch, ref, onMounted, onBeforeUnmount, computed } from 'vue'

import { useFormField } from '../composables/useFormField'
import { datePickerDefaults } from '../constants/datePickerDefaults'
import type { ReactiveFormFieldDatePicker } from '../types/ReactiveFormField.dto'
import type { BaseOptions } from 'flatpickr/dist/types/options'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'

import ArrowLeft from '@/app/img/icon/action-chevron-left.svg?raw'
import ArrowRight from '@/app/img/icon/action-chevron-right.svg?raw'
import Icon from '@/support/components/icon/index.vue'

import Flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import { DatePickerMode } from '../types/DatePickerConfig.dto'
import type { Validation } from '@vuelidate/core'
import { DateTime } from 'luxon'
import i18n from '@/support/core/localization'

type Props = {
  field: ReactiveFormFieldDatePicker
  vuelidate?: Validation | null
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null
})

const { d } = i18n.global

const container = ref<HTMLDivElement>()
const flatpickr = ref<Flatpickr.Instance>()
const datepickerInput = ref<HTMLInputElement>()
const datepickerToggle = ref<InstanceType<typeof Icon>>()

const { errors, className, isRequired, modelSetter } = useFormField(props)

const model = defineModel<string | string[] | undefined | null>({ default: '', set: modelSetter })

const ISOtoDateList = (input: string | string[]): Date[] => {
  if (Array.isArray(input)) {
    return input.map((date) => DateTime.fromISO(date).toJSDate())
  }
  return [DateTime.fromISO(input).toJSDate()]
}

const readOnlyValue = computed<string | undefined>(() => {
  if (!model.value || !model.value.length) {
    return props.field.placeholder
  }
  return ISOtoDateList(model.value)
    .map((date) => d(date, 'dateShortMonthAsText'))
    .join(' - ')
})
const mode = computed<DatePickerMode>(() => props.field.mode || DatePickerMode.SINGLE)
const allowInput = computed<boolean>(() => mode.value === DatePickerMode.SINGLE)
const onClickInput = () => {
  if (!allowInput.value) {
    return
  }
  datepickerInput.value?.select()
}

watch(model, () => {
  if (!flatpickr.value) {
    return
  }

  flatpickr.value.setDate(model.value ? ISOtoDateList(model.value) : [])
})

const flatpickrSelectedDates = ref<Date[]>()

const getFlatpickrDate = (
  input?: Date | string | number | null | undefined
): Date | 'today' | undefined => {
  if (!input) {
    return undefined
  }
  if (input instanceof Date) {
    return input
  }
  if (input === 'today') {
    return 'today'
  }
  if (typeof input === 'number') {
    return DateTime.fromMillis(input).toJSDate()
  }
  return DateTime.fromISO(input).toJSDate()
}

// input is normalized to change any character that is not part of the datetime into one space and to remove all zero padding
// so "21-03-2025" or "21/03/2025" is normalized to "21 3 2025"
// and "03.06.2025 00:05" is normalized to "3 6 2025 0:5"
const normalizeDateInput = (input: string): string =>
  input
    .replace(/[^\da-zA-Z:]+/g, ' ')
    .replace(/([^\d]|^)0+([1-9]\d*)/g, '$1$2')
    .replace(/([^\d]|^)0+([^\d])/g, (match) => `${match[1]}0${match[2]}`)
const ACCEPTED_FORMATS = [
  'd M yyyy', // 21 3 2025
  'd M yyyy H:m', // 21 3 2025 13:5
  'd M yy', // 21 3 25
  'd M yy H:m', // 21 3 25 9:12
  'd MMM yyyy', // 21 Mar 2025
  'd MMM yyyy H:m' // 21 Mar 2025 13:5
]

const flatpickrOptions = computed<Partial<BaseOptions>>(
  () =>
    ({
      mode: mode.value,
      enableTime: props.field.enableTime ?? false,
      ...props.field.config,
      minDate: getFlatpickrDate(props.field.config?.minDate),
      maxDate: getFlatpickrDate(props.field.config?.maxDate),
      monthSelectorType: 'dropdown',
      allowInput: allowInput.value,
      position: 'below left',
      ...(allowInput.value
        ? ({
            parseDate: (input) => {
              const normalisedInput = normalizeDateInput(input)
              const format = ACCEPTED_FORMATS.find(
                (f) => DateTime.fromFormat(normalisedInput, f).isValid
              )

              if (format) {
                return DateTime.fromFormat(normalisedInput, format).toJSDate()
              }

              if (model.value && !Array.isArray(model.value)) {
                return DateTime.fromISO(model.value).toJSDate()
              }
              return undefined
            },
            formatDate(input) {
              const date = DateTime.fromJSDate(input)
              return date.toFormat(props.field.enableTime ? 'd MMM yyyy H:mm' : 'd MMM yyyy')
            }
          } satisfies Partial<BaseOptions>)
        : ({
            dateFormat: props.field.enableTime === true ? 'd M Y H:i' : 'd M Y'
          } satisfies Partial<BaseOptions>))
    }) as Partial<BaseOptions>
)

onMounted(() => {
  let defaultDate: Date | Date[] | undefined = undefined
  if (Array.isArray(model.value)) {
    defaultDate = model.value.map((date) => DateTime.fromISO(date).toJSDate())
  }
  if (model.value && typeof model.value === 'string') {
    defaultDate = DateTime.fromISO(model.value).toJSDate()
  }

  const defaults: Partial<BaseOptions> = Object.assign(datePickerDefaults, {
    defaultDate,
    prevArrow: ArrowLeft,
    nextArrow: ArrowRight,
    ...flatpickrOptions.value,
    onClose: () => {
      props.vuelidate?.$touch()
    },
    onChange: (input: any) => {
      flatpickrSelectedDates.value = (input ? [...input] : undefined) as Date[] | undefined
      props.vuelidate?.$touch()
    }
  })

  flatpickr.value = Flatpickr(container.value!, defaults)

  watch(flatpickrOptions, (options) => {
    ;(Object.keys(options) as (keyof BaseOptions)[]).forEach((key) => {
      if (flatpickr.value!.config[key] === options[key]) {
        return
      }
      flatpickr.value!.set(key, options[key])
    })
  })
})

watch(flatpickrSelectedDates, (v) => {
  if (!v) {
    model.value = undefined
    return
  }
  if (props.field.mode === DatePickerMode.RANGE && v.length === 1) {
    return
  }

  const isoDates = v
    .map((date) => DateTime.fromJSDate(date))
    .filter((date) => date.isValid)
    .map((date) => (props.field.enableTime ? date.toISO() : date.toISODate())) as string[]

  if (JSON.stringify(model.value) === JSON.stringify(isoDates)) {
    return
  }
  if (props.field.mode === DatePickerMode.RANGE) {
    model.value = isoDates.filter(Boolean) as string[]
    return
  }
  model.value = isoDates[0] || ''
})

onBeforeUnmount(() => {
  datepickerToggle.value?.$el.remove()
  flatpickr.value?.destroy()
})
</script>

<style lang="scss" scoped>
.reactive-form__field {
  .field-readonly {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    height: 45px;
    align-items: center;

    &.placeholder {
      opacity: 0.3;
    }
  }

  &.failed {
    .field-label {
      color: map-get($theme-color-status, 'error');
    }
    input[type='text'] {
      &,
      &:focus,
      &:focus:active {
        border-color: map-get($theme-color-status, 'error');
      }
    }
  }

  .flatpickr {
    position: relative;

    .flatpickr-input {
      padding-left: 40px;

      & + .icon-action-date {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
  }

  input[type='text'] {
    padding: 10px;
    border-radius: 4px;
    width: 100%;

    font-size: map-get($form-field-font, 'size');
    color: map-get($form-field-font, 'color');

    background-color: map-get($form-field-background-color, 'idle');
    border: 1px solid map-get($form-field-border-color, 'idle');

    &:hover {
      background-color: map-get($form-field-background-color, 'hover');
      border-color: map-get($form-field-border-color, 'hover');
    }

    &:focus,
    &:focus:active {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');

      box-shadow: 0 0 0 5px $form-field-outline-color;
      border-radius: 2px;
    }

    &:disabled {
      background-color: map-get($form-field-background-color, 'disabled');
      border-color: map-get($form-field-border-color, 'disabled');
      color: $form-field-font-color-disabled;
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $form-field-placeholder-color;
    }
  }
}
</style>

<style lang="scss">
$icon-size: 50px;
$day-width: 50px;
$week-width: $day-width * 7;

.flatpickr-disabled {
  color: #c4bfba !important;
  cursor: not-allowed;
}

.flatpickr-calendar {
  max-width: none;
  width: auto;
  padding: 20px;

  .flatpickr-current-month {
    top: 5px;
    width: auto;
    left: 0;
    right: 0;

    .flatpickr-monthDropdown-months {
      width: auto;
      color: black;
      font-weight: 400;
      font-size: 16px;
      padding-right: 5px;
    }

    .numInputWrapper {
      width: 65px;

      input {
        width: 100%;
        text-align: left;
        color: black;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .flatpickr-months {
    .flatpickr-prev-month:hover svg,
    .flatpickr-next-month:hover svg {
      fill: none;
    }
    .flatpickr-prev-month,
    .flatpickr-next-month {
      all: unset;
      padding: 0;
      height: 24px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }
    .flatpickr-prev-month {
      margin-left: 5px;
    }
    .flatpickr-next-month {
      margin-right: 5px;
    }
    .flatpickr-month {
      .flatpickr-current-month {
        all: unset;

        .numInputWrapper {
          &:hover {
            background-color: white;
          }
          .cur-year {
            font-family: $theme-brand-font-headings;
            font-weight: 700;
            font-size: $theme-font-size-m;
            margin: 0;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }
          }
        }

        .flatpickr-monthDropdown-months {
          font-family: $theme-brand-font-headings;
          font-weight: 700;
          font-size: $theme-font-size-m;
          appearance: none;
        }

        span.cur-month {
          margin: 0 10px 0 0;
          font-family: $theme-brand-font-headings;
          font-weight: 700;
          font-size: $theme-font-size-m;
          text-transform: capitalize;

          &:hover {
            background: white;
          }
        }
      }
    }
  }

  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    span.flatpickr-weekday {
      width: $day-width;
      height: $day-width;
      max-width: $day-width;
      line-height: $day-width;
      flex-basis: auto;
      font-weight: 400;
      color: #c4bfba;
    }
  }

  .flatpickr-days {
    width: $week-width;

    .dayContainer {
      min-width: $week-width;
      max-width: $week-width;
      width: $week-width;

      .flatpickr-day {
        width: $day-width;
        height: $day-width;
        max-width: $day-width;
        line-height: $day-width;
        flex-basis: auto;
        font-weight: 400;
        color: black;

        &.prevMonthDay {
          color: #c4bfba;
        }

        &.selected {
          background-color: #f9e0b8;
          border-color: #eb9713;
        }

        &.startRange,
        &.endRange {
          background-color: #f9e0b8;
          border-color: #eb9713;

          &:hover {
            background-color: #f9e0b8;
            border-color: #eb9713;
          }
        }

        &.today {
          background-color: white;
          border-color: #c4bfba;

          &:hover {
            background-color: #f0f0f0;
            border-color: #c4bfba;
          }
        }

        &.inRange {
          background-color: rgb(240, 240, 240);
          border-color: rgb(240, 240, 240);
          box-shadow: 0 0 0;
        }
      }
    }
  }

  .flatpickr-weekdays {
    height: auto;

    .flatpickr-weekdaycontainer {
      .flatpickr-weekday {
        font-size: 100%;
        font-weight: normal;
        width: 34px;
        height: 34px;
        line-height: 34px;
        color: #c4bfba;
      }
    }
  }
}
</style>
