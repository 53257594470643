export enum FieldValidationType {
  REQUIRED = 'required',
  SAME_AS = 'sameAs',
  MAX_LENGTH = 'maxLength',
  EMAIL = 'email',
  ALPHA_SPACES = 'alphaSpaces',
  PHONE = 'phone',
  IBAN = 'iban',
  ZIP_CODE = 'zipCode',
  URL = 'url',
  REGEX = 'regex',
  CUSTOM = 'custom'
}

export interface ValidationRules {
  [FieldValidationType.REQUIRED]?: boolean
  [FieldValidationType.SAME_AS]?: any
  [FieldValidationType.MAX_LENGTH]?: number
  [FieldValidationType.EMAIL]?: boolean
  [FieldValidationType.ALPHA_SPACES]?: boolean
  [FieldValidationType.PHONE]?: boolean
  [FieldValidationType.IBAN]?: boolean
  [FieldValidationType.ZIP_CODE]?: boolean
  [FieldValidationType.URL]?: boolean
  [FieldValidationType.REGEX]?: RegExp
  [FieldValidationType.CUSTOM]?: (value: any) => boolean
}

export interface ValidationMessages {
  [FieldValidationType.REQUIRED]?: string
  [FieldValidationType.SAME_AS]?: string
  [FieldValidationType.MAX_LENGTH]?: string
  [FieldValidationType.EMAIL]?: string
  [FieldValidationType.ALPHA_SPACES]?: string
  [FieldValidationType.PHONE]?: string
  [FieldValidationType.IBAN]?: string
  [FieldValidationType.ZIP_CODE]?: string
  [FieldValidationType.URL]?: string
  [FieldValidationType.REGEX]?: string
  [FieldValidationType.CUSTOM]?: string
}
