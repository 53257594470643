import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import {
  type DatePickerConfig,
  DatePickerMode,
  MonthSelectorType
} from '../types/DatePickerConfig.dto'

export const datePickerDefaults: DatePickerConfig = {
  allowInput: false,
  locale: Dutch,
  disableMobile: true,
  wrap: true,
  dateFormat: 'd-m-Y',
  mode: DatePickerMode.SINGLE,
  enableTime: false,
  enableSeconds: false,
  monthSelectorType: MonthSelectorType.STATIC
}
