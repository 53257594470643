import Banner from './default/index.vue'
import BannerSticky from './sticky/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    Banner: typeof Banner
    BannerSticky: typeof BannerSticky
  }
}

export default {
  Banner,
  BannerSticky
}
