import * as validators from '@vuelidate/validators'
import i18n from '@/support/core/localization'

const { createI18nMessage } = validators

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

export const required = withI18nMessage(validators.required)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
export const email = withI18nMessage(validators.email, { withArguments: true })
export const numeric = withI18nMessage(validators.numeric, { withArguments: true })
export const url = withI18nMessage(validators.url, { withArguments: true })
export const alpha = withI18nMessage(validators.alpha, { withArguments: true })
