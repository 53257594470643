import i18n from '@/support/core/localization.js'
import Login from '@/app/page/Login.vue'
import LoginCallback from '@/app/page/LoginCallback.vue'
import AuthPage from '@/app/page/AuthPage.vue'
import type { RouteRecordRaw } from 'vue-router'
import { computed } from 'vue'

const { t } = i18n.global

export default [
  {
    path: '/auth/login',
    alias: '/login',
    name: 'login.index',
    component: Login,
    meta: {
      requiresAuth: false,
      title: computed<string>(() => t('auth.login.title'))
    }
  },
  {
    path: '/auth/invite',
    name: 'login.invite',
    component: Login,
    meta: {
      requiresAuth: false,
      title: computed<string>(() => t('auth.login.title'))
    }
  },
  {
    path: '/auth/callback',
    name: 'login.callback',
    component: LoginCallback,
    meta: {
      requiresAuth: false,
      title: computed<string>(() => t('auth.login.title'))
    }
  },
  {
    path: '/auth/account-heeft-geen-organisatie',
    name: 'auth.accountHasNoOrganisation',
    component: AuthPage,
    meta: {
      title: computed<string>(() => t('auth.accountHasNoOrganisation.title'))
    }
  },
  {
    path: '/auth/organisatie-configuratie-niet-voltooid',
    name: 'auth.organisationConfigurationNotCompleted',
    component: AuthPage,
    meta: {
      title: computed<string>(() => t('auth.organisationConfigurationNotCompleted.title'))
    }
  },
  {
    path: '/auth/geen-toegang',
    component: AuthPage,
    name: 'auth.unauthorized',
    meta: {
      title: computed<string>(() => t('auth.unauthorized.title'))
    }
  },
  {
    path: '/auth/geen-locaties',
    component: AuthPage,
    name: 'auth.no-context',
    meta: {
      title: computed<string>(() => t('auth.noContext.title'))
    }
  }
] satisfies RouteRecordRaw[]
