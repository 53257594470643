<template>
  <modal-default
    :id="modalConfig.id"
    :sub-title="modalConfig.subTitle"
    :title="modalConfig.title"
    :button="modalConfig.button"
    :width="800"
    @confirm="onConfirm"
    @cancel="onClose"
    @close="onClose"
    @hidden="onHidden"
  >
    <template #default>
      <div class="modal-body__inner">
        <div class="row justify-content-center text-center mt-5">
          <div v-for="item in options" :key="item.value" class="col p-0">
            <card
              :title="item.title"
              type=""
              :img="item.image"
              :details="item.details"
              :active="item.value === form.notificationType"
              @click="() => onSelectType(item.value)"
            />
          </div>
        </div>
        <reactive-form v-model:form="form" :config="formConfig" :hidden="true" />
      </div>
    </template>
  </modal-default>
</template>

<script setup lang="ts">
import '@/features/notifications/localization'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { createGuid } from '@/support/helpers/guid'
import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'

import type { NotificationFormData } from '@/features/notifications/types'
import { NotificationType } from '@/features/notifications/types'

import incidentIcon from '@/app/img/icon/card-incident.svg'
import dangerousSituationIcon from '@/app/img/icon/card-warning.svg'
import opportunityIcon from '@/app/img/icon/card-opportunity.svg'

const { t } = useI18n()
const emit = defineEmits(['confirm', 'close', 'hidden'])

interface Props {
  form: NotificationFormData
}

const props = defineProps<Props>()
const form = reactive(props.form)

const modalId = createGuid()
const modalConfig = computed(() => ({
  id: modalId,
  subTitle: t('notifications.dialogs.selectType.subTitle'),
  title: t('notifications.dialogs.selectType.title'),
  button: {
    cancel: {
      text: t('notifications.dialogs.selectType.cancel')
    },
    confirm: {
      text: t('notifications.dialogs.selectType.confirm')
    }
  }
}))

const formConfig = computed<ReactiveFormConfig>(() => ({
  legend: null,
  buttons: null,
  fields: [
    {
      id: 'notificationType',
      type: FieldType.INPUT_TEXT,
      hidden: false,
      validationRules: {
        required: true
      }
    }
  ]
}))

const options = [
  {
    value: NotificationType.INCIDENT,
    title: t('notifications.incident'),
    details: [t('notifications.dialogs.selectType.incidentDescription')],
    image: incidentIcon
  },
  {
    value: NotificationType.DANGEROUS_SITUATION,
    title: t('notifications.dangerousSituation'),
    details: [t('notifications.dialogs.selectType.dangerousSituationDescription')],
    image: dangerousSituationIcon
  },
  {
    value: NotificationType.OPPORTUNITY,
    title: t('notifications.opportunity'),
    details: [t('notifications.dialogs.selectType.oppurtunityDescription')],
    image: opportunityIcon
  }
]

const onSelectType = (selectedType: NotificationType) => {
  form.notificationType = selectedType
}

const onConfirm = () => {
  emit('confirm', form)
}

const onClose = () => {
  emit('close')
}

const onHidden = () => {
  emit('hidden')
}
</script>

<style scoped></style>
