<template>
  <page-header :title="$t('dashboard.index.title', { name })" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storedOIDCUser, userInfo } from '@/features/auth'
import { getFirstNameOrEmail } from '@/features/user/helpers'

const name = computed<string | undefined>(
  () => getFirstNameOrEmail(userInfo.value) || storedOIDCUser.value?.profile.name
)
</script>

<style scoped></style>
