<template>
  <div>
    <h2 class="mb-4">
      {{ t('locations.title') }}
      <button-icon
        variant="secondary"
        :disabled="locations.length >= (maxLocationCount || 0)"
        :icon-props="{ name: `action-add`, states: ['disabled'] }"
        icon-position="left"
        @click="showModal('create')"
      >
        {{ t('app.button.new') }}
      </button-icon>
    </h2>

    <div v-if="locations.length" class="font-size-s mb-3">
      <strong>{{
        t('locations.listView.count', {
          count: locations.length,
          max: maxLocationCount || '[maxLocationCount not found]'
        })
      }}</strong>
    </div>

    <notification-primary
      v-if="locations.length >= (maxLocationCount || 0)"
      class="mb-2"
      :icon-circle="false"
    >
      <span class="me-1">{{ t('locations.listView.notification.text') }}</span>
      <strong v-safe-html="t('locations.listView.notification.upsell')" />
    </notification-primary>

    <spinner-context>
      <spinner v-if="loading" />
      <template #context>
        <empty-state
          v-if="!loading && !listViewRecords.length"
          class="mb-4"
          :title="t('locations.listView.emptyState.title')"
          :description="t('locations.listView.emptyState.description')"
        />
        <list-view
          v-else
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewButtons"
          @event="handleRowClick"
        >
          <template #footer>
            <pagination
              :page-index="paginatedLocations.pageIndex"
              :total-pages="paginatedLocations.totalPages"
              @change="onPageChange"
            />
          </template>
        </list-view>
      </template>
    </spinner-context>

    <location-dialog
      v-if="modalRecordOpen"
      v-model:form="locationRecord"
      @confirm="onConfirmModalRecord"
      @hidden="onHiddenModalCreateRecord"
    />

    <modal-delete
      v-if="modalDeleteRecordOpen"
      :title="t('locations.modal.deleteLocation.title', { name: locationRecord.name })"
      :message="t('locations.modal.deleteLocation.message')"
      :confirm-button-text="t('locations.modal.deleteLocation.confirmButton')"
      @confirm="onConfirmModalDeleteRecord"
      @hidden="onHiddenModalDeleteRecord"
    />

    <modal-alert
      v-if="modalDenyDeleteLastLocation"
      :title="$t('locations.modal.denyDeleteLastLocation')"
      @hidden="modalDenyDeleteLastLocation = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, type ComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import Console from '@/support/core/console'

import LocationDialog from '@/features/locations/components/locationDialog/index.vue'
import ModalDelete from '@/features/modal/modalDelete/index.vue'
import ModalAlert from '@/features/modal/modalAlert/index.vue'

import useOnboarding from '@/features/onboarding/compositions/useOnboarding'

import { useLocationsStore } from '@/features/locations/store'
import { maxLocationCount, reloadUserInfo, userInfo } from '@/features/auth'
import { useAlertStore } from '@/support/components/alert/store'
import { useUserStore } from '@/features/user/store'

import { delay } from '@/support/helpers/delay'
import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'

import { Status } from '@/support/enums/Status'
import type { UpdateCreateLocation, Location, LocationManager } from '@/features/locations/types'
import type { User } from '@/features/user/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { Alert } from '@/support/components/alert/types'
import type { ListviewHeader } from '@/support/components/listView/types'

const modalRecordOpen = ref<boolean>(false)
const modalDeleteRecordOpen = ref<boolean>(false)
const modalDenyDeleteLastLocation = ref(false)

const locationRecordDefaults: UpdateCreateLocation = {
  id: '',
  name: null,
  managerId: null,
  typeId: undefined
}

const { t } = useI18n()
const alertStore = useAlertStore()
const locationsStore = useLocationsStore()
const userStore = useUserStore()

const { isOnboarding } = useOnboarding()
const { locations, locationTypes, paginatedLocations } = storeToRefs(locationsStore)

const loading = ref<boolean | number>(1)

const locationRecord = reactive<UpdateCreateLocation>({ ...locationRecordDefaults })

const pageRecords: ComputedRef<Location[]> = computed(() => paginatedLocations.value.items)

const listViewHeader = ref<ListviewHeader<keyof Location>[]>([
  { value: t('locations.listView.header.name'), property: 'name' },
  { value: t('locations.listView.header.type'), property: 'type', width: 140, align: 'right' },
  ...(isOnboarding.value
    ? []
    : ([
        {
          value: t('locations.listView.header.locationManager'),
          property: 'manager',
          width: 210,
          align: 'right'
        }
      ] satisfies ListviewHeader<keyof Location>[]))
])

const listViewButtons: Array<ListViewButton> = [ListViewButton.Edit, ListViewButton.Delete]

type ListViewRecord = {
  id: string
  name: string
  type?: string
  disableButtons?: ListViewButton[]
  manager?: LocationManager | User
  managerId?: Guid
}

const listViewRecords = computed<ListViewRecord[]>(() => {
  if (loading.value === 1) {
    const { pageSize = 5 } = paginatedLocations.value

    return Array.from({ length: pageSize }, (_, index) => ({
      id: String(index),
      name: t('app.loading.records', { entity: t('locations.listView.title') }),
      disableButtons: [ListViewButton.Edit, ListViewButton.Delete]
    }))
  }

  return pageRecords.value.map(({ type, id, name, manager }: Location) => {
    const user = userStore.users?.find((user) => user.id === manager.id)
    const userForLocation = user && {
      ...user,
      isDeleted:
        user.isDeleted ||
        (!user.hasAccessToOrganisation && !user.locations?.some((location) => location.id === id))
    }

    return {
      id,
      name: name!,
      type: type.name,
      manager: userForLocation || manager,
      managerId: manager.id
    } satisfies ListViewRecord
  })
})

const showModal = (action: string, id?: Guid) => {
  const record: Location | null =
    pageRecords.value.find((location: Location) => location.id === id) ?? null
  const recordForm: UpdateCreateLocation = !record
    ? { ...locationRecordDefaults }
    : {
        id: record.id,
        name: record.name,
        typeId: record.type.id,
        managerId: record.manager.id
      }

  Console.log('showModal', recordForm)

  Object.assign(locationRecord, recordForm)

  if (['edit', 'create'].includes(action)) {
    modalRecordOpen.value = true
  }
  if (action === 'delete') {
    if (!record || !locations.value) {
      return
    }
    if (locations.value.length <= 1) {
      modalDenyDeleteLastLocation.value = true
      return
    }
    modalDeleteRecordOpen.value = true
  }
}

const onHiddenModalCreateRecord = () => {
  modalRecordOpen.value = false
}

const onHiddenModalDeleteRecord = () => {
  modalDeleteRecordOpen.value = false
}

const onConfirmModalRecord = async (location: UpdateCreateLocation) => {
  Console.log('onConfirmModalRecord', location)

  const method = location.id ? 'put' : 'post'

  // For onboarding, set the managerId to the current user
  if (isOnboarding.value) {
    location.managerId = userInfo.value?.id || null
  }

  loading.value = true

  const response =
    method === 'put'
      ? await locationsStore.updateLocation(location)
      : await locationsStore.createLocation(location)

  const alert: Alert =
    response?.status !== 200
      ? { message: t(`app.xhr.${method}.error`), type: Status.error }
      : {
          message: method === 'put' ? t('alert.locations.updated') : t('alert.locations.created'),
          type: Status.success
        }

  if (response?.status === 200) {
    await reloadUserInfo()
    await locationsStore.getPaginatedLocations()
    await locationsStore.getAllLocations()
    await userStore.reloadAllUsers()
  }

  loading.value = false

  alertStore.setMessage(alert)
}

const onConfirmModalDeleteRecord = async () => {
  Console.log('onConfirmModalDeleteRecord', locationRecord.id)

  if (!locationRecord.id) return

  loading.value = true
  const response = await locationsStore.deleteLocation(locationRecord.id)
  const alert: Alert =
    response?.status !== 200
      ? { message: t('app.xhr.delete.error'), type: Status.error }
      : { message: t('alert.locations.deleted'), type: Status.success }

  if (response?.status === 200) {
    await reloadUserInfo()
    locationsStore.setPaging(1)
    await locationsStore.getPaginatedLocations()
    await locationsStore.getAllLocations()
    await userStore.reloadAllUsers()
  }

  loading.value = false

  alertStore.setMessage(alert)
}

const handleRowClick = (event: any) => {
  if (loading.value === 1) return

  Console.log('onRowClickHandler', event)

  const { action, id } = event

  switch (action) {
    case 'edit':
      showModal(action, id)
      break
    case 'delete':
      showModal(action, id)
      break
  }
}

const onPageChange = (pageIndex: number) => {
  loading.value = true
  locationsStore.setPaging(pageIndex)
  locationsStore.getPaginatedLocations().finally(() => {
    loading.value = false
  })
}

onMounted(async () => {
  const promises = []

  if (!locations.value.length) {
    promises.push(locationsStore.getAllLocations())
  }

  if (!locationTypes.value.length) {
    promises.push(locationsStore.getLocationTypes())
  }

  if (!pageRecords.value.length) {
    promises.push(locationsStore.getPaginatedLocations())
  }

  await delay(400)

  Promise.all(promises).finally(() => {
    loading.value = false
  })
})
</script>

<style lang="scss" scoped>
h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
