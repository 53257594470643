import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalizedLoaded,
  type RouteLocationNormalized,
  type RouteRecordRaw,
  type RouterScrollBehavior
} from 'vue-router'

import base from './routes/base'
import auth from './routes/auth'
import dashboard from './routes/dashboard'
import designSystem from './routes/designSystem'
import newCycle from './routes/newCycle'
import currentCycle from './routes/currentCycle'
import editCurrentCycle from './routes/editCurrentCycle'
import notifications from './routes/notifications'
import onboarding from './routes/onboarding'
import policy from './routes/policy'
import settings from './routes/settings'

import { authRouteGuard } from './hooks/authRouteGuard'
import { setPageTitleRouteHook } from './hooks/setPageTitleRouteHook'
import type { PermissionName } from '@/features/roles/types'
import { computed, type ComputedRef, ref } from 'vue'

export type RequiredPermissions =
  | PermissionName
  | PermissionName[]
  | ((permissions: PermissionName[]) => boolean)

declare module 'vue-router'
declare module 'vue-router' {
  interface RouteMeta {
    title?: string | ComputedRef<string>
    intro?: string | ComputedRef<string>
    content?: string | ComputedRef<string>
    requiresAuth?: boolean
    requiredPermissions?: RequiredPermissions
    requiresOrganisationAccess?: boolean
    needsContext?: boolean
    print?: {
      hideTitle?: boolean
    }
  }
}
const routes: RouteRecordRaw[] = [
  ...(base satisfies RouteRecordRaw[]),
  ...(auth satisfies RouteRecordRaw[]),
  ...(designSystem satisfies RouteRecordRaw[]),
  ...(newCycle satisfies RouteRecordRaw[]),
  ...(currentCycle satisfies RouteRecordRaw[]),
  ...(editCurrentCycle satisfies RouteRecordRaw[]),
  ...(onboarding satisfies RouteRecordRaw[]),
  ...(dashboard satisfies RouteRecordRaw[]),
  ...(policy satisfies RouteRecordRaw[]),
  ...(notifications satisfies RouteRecordRaw[]),
  ...(settings satisfies RouteRecordRaw[])
]

const scrollBehavior: RouterScrollBehavior = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded
) => {
  if (to.name === from.name) return
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ left: 0, top: 0 })
    }, 100)
  })
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior
})

const localPreviousRoute = ref<RouteLocationNormalizedLoaded>()
export const previousRoute = computed<RouteLocationNormalizedLoaded | undefined>(
  () => localPreviousRoute.value
)
router.beforeEach((_, from, next) => {
  localPreviousRoute.value = from
  next()
})

// Before route hooks
router.beforeEach(authRouteGuard)

// After route hooks
router.afterEach(setPageTitleRouteHook)

export default router
