<template>
  <div>
    <div class="intro mb-5">{{ $t('notifications.overview.intro') }}</div>

    <list-view-filters>
      <reactive-form-field-select
        v-model="filters.filterNotificationType"
        :field="filterFieldType"
      />
      <reactive-form-field-select v-model="filters.filterTopicId" :field="filterFieldTopic" />
      <reactive-form-field-date-picker
        v-model="filters.datetimeRange"
        :disabled="loading"
        :field="filterFieldDatetimeRange"
      />
      <a v-if="filtersActive" role="button" @click="onRemoveFilters">
        <icon name="action-close" />
        {{ $t('app.button.removeFilters') }}
      </a>
      <template #right>
        <div class="buttons">
          <a
            role="button"
            :href="listViewRecords?.length ? downloadUrl : '#'"
            :target="listViewRecords?.length ? '_blank' : undefined"
          >
            <icon name="action-download" />
            {{ $t('app.button.export') }}
          </a>
          <button-icon
            v-if="
              hasPermission(PermissionName.NotificationCreateViewEditMine) &&
              currentContextType === 'location'
            "
            variant="secondary"
            :icon-props="{ name: `action-add`, states: ['disabled'] }"
            icon-position="left"
            :loading="saving"
            @click="onClickNewNotification"
          >
            {{ $t('app.button.new') }}
          </button-icon>
        </div>
      </template>
    </list-view-filters>

    <div v-if="paginatedNotifications?.totalCount" class="font-size-s mb-3">
      <strong>{{
        $t('notifications.overview.listView.count', { count: paginatedNotifications.totalCount })
      }}</strong>
    </div>

    <spinner-context>
      <spinner v-if="(saving || loading) && listViewRecords?.length" />
      <template #context>
        <skeleton v-if="(loading || loading) && !listViewRecords?.length" />
        <list-view
          v-if="listViewRecords?.length"
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewButtons"
          class="list-view-notifications mb-5"
          @event="onRowClickHandler"
        >
          <template #title="{ item }">
            <icon :name="item.title.icon.name" class="d-inline-block me-2" />
            {{ item.title.value }}
          </template>
          <template #footer>
            <pagination
              v-if="paginatedNotifications"
              :page-index="paginatedNotifications.pageIndex"
              :total-pages="paginatedNotifications.totalPages"
              @change="onPageChange"
            />
          </template>
        </list-view>
        <empty-state
          v-if="!loading && listViewRecords?.length === 0"
          :title="$t('notifications.overview.listView.empty.title')"
          :description="$t('notifications.overview.listView.empty.description')"
          :icon-props="{
            name: 'illustration-empty-state-actions',
            size: { width: 180, height: 180 }
          }"
        />
      </template>
    </spinner-context>

    <notification-detail ref="notificationDetailRef" v-model:saving="saving" @event="onEvent" />

    <action-detail ref="actionDetailRef" v-model:saving="saving" />
  </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, onMounted, reactive, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'
import type { ListviewHeader, ListViewRecord } from '@/support/components/listView/types'

import { notificationTypeIcon } from '@/features/notifications/helpers'

import ActionDetail from '@/features/actions/components/actionDetail/index.vue'
import NotificationDetail from '@/features/notifications/components/notificationDetail/index.vue'

import { useNotificationsStore } from '@/features/notifications/store'
import { useTopicsStore } from '@/features/topics/store'
import {
  currentContextApiPath,
  currentContextType,
  hasPermission,
  currentLocation,
  userInfo
} from '@/features/auth'
import { PermissionName } from '@/features/roles/types'

import type { Guid } from '@/support/types/Guid.dto'
import {
  type Notification,
  type NotificationsListFilter,
  NotificationType
} from '@/features/notifications/types'
import type {
  ReactiveFormFieldDatePicker,
  ReactiveFormFieldSelect
} from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import { DatePickerMode } from '@/support/components/reactiveForm/types/DatePickerConfig.dto'
import { useThisMinute } from '@/support/composition/use-this-minute'
import { usePageTitle } from '@/support/composition/use-page-title'

const { t } = useI18n()

const pageTitle = usePageTitle()

const notificationsStore = useNotificationsStore()
const topicsStore = useTopicsStore()

const { paginatedNotifications } = storeToRefs(notificationsStore)
const { topics } = storeToRefs(topicsStore)

const notificationDetailRef = ref<InstanceType<typeof NotificationDetail>>()
const actionDetailRef = ref<InstanceType<typeof ActionDetail>>()
const loading = ref<boolean>(false)
const saving = ref<boolean>(false)

type Filters = Omit<NotificationsListFilter, 'filterStartDatetime' | 'filterEndDatetime'> & {
  datetimeRange?: string[]
}

const thisMinute = useThisMinute()
const defaultFilters = computed<Filters>(() => ({
  filterTopicId: null,
  filterNotificationType: null,
  datetimeRange: [thisMinute.value.minus({ years: 1 }).toISODate(), thisMinute.value.toISODate()]
}))
const filters = reactive<Filters>({ ...defaultFilters.value })

const filterFieldType = computed<ReactiveFormFieldSelect>(() => ({
  id: 'type',
  type: FieldType.SELECT,
  label: t('notifications.overview.filter.type'),
  placeholder: t('app.filterOn', { entity: t('notifications.overview.filter.type').toLowerCase() }),
  hideLabel: true,
  options: [
    NotificationType.INCIDENT,
    NotificationType.DANGEROUS_SITUATION,
    NotificationType.OPPORTUNITY
  ].map((type) => ({ value: type, label: t(`notifications.type.${type}`) }))
}))

const filterFieldTopic = computed<ReactiveFormFieldSelect>(() => ({
  id: 'topic',
  type: FieldType.SELECT,
  label: t('notifications.overview.filter.topic'),
  placeholder: t('app.filterOn', {
    entity: t('notifications.overview.filter.topic').toLowerCase()
  }),
  hideLabel: true,
  options: topics.value?.map((topic) => ({ value: topic.id, label: topic.title })) || [],
  disabled: loading.value && !topics.value?.length
}))

const filterFieldDatetimeRange = computed<ReactiveFormFieldDatePicker>(() => ({
  id: 'datetimeRange',
  type: FieldType.DATE_PICKER,
  label: undefined,
  hideLabel: true,
  placeholder: t('notifications.overview.filter.datetimeRange'),
  mode: DatePickerMode.RANGE
}))

const filtersActive: ComputedRef<boolean> = computed(() =>
  Object.values(filters).some((value) => value && !(Array.isArray(value) && !value.length))
)

const listViewHeader: Array<ListviewHeader> = [
  { value: t('notifications.overview.listView.header.title'), property: 'title' },
  {
    value: t('notifications.overview.listView.header.location'),
    property: 'location',
    width: 140,
    align: 'right'
  },
  {
    value: t('notifications.overview.listView.header.date'),
    property: 'datetime',
    width: 140,
    align: 'right'
  },
  {
    value: t('notifications.overview.listView.header.actions'),
    property: 'actions',
    width: 100,
    align: 'right'
  }
]

const listViewButtons: Array<ListViewButton> = [
  ListViewButton.View,
  ListViewButton.Edit,
  ListViewButton.Delete
]

const isReadOnly = (notification: Notification): boolean => {
  if (hasPermission(PermissionName.NotificationEdit)) {
    return false
  }
  if (
    hasPermission(PermissionName.NotificationCreateViewEditMine) &&
    notification.createdBy.id === userInfo.value?.id
  ) {
    return false
  }
  return true
}

const listViewRecords = computed<ListViewRecord[] | undefined>(() =>
  paginatedNotifications.value?.items.map((item: Notification) => {
    const actionsLength = item.actions?.length ?? null
    const actionsLengthStr = actionsLength ? actionsLength.toString() : null
    const title = {
      value: item.name,
      icon: {
        name: notificationTypeIcon(item.notificationType)
      }
    }

    return {
      id: item.id,
      title,
      location: item.location.name,
      datetime: item.datetime,
      actions: actionsLengthStr,
      disableButtons: [
        ...(isReadOnly(item) ? [ListViewButton.Edit] : []),
        ...(!hasPermission(PermissionName.NotificationDelete) ? [ListViewButton.Delete] : [])
      ]
    }
  })
)

watch(saving, (newValue, oldValue) => {
  if (oldValue && !newValue) {
    notificationsStore.getPaginatedNotifications(currentContextApiPath.value)
  }
})

const onClickNewNotification = () => {
  notificationDetailRef.value?.openDialog('create', null)
}

const onRowClickHandler = ({ action, id }: { id: Guid; action: 'edit' | 'delete' | 'view' }) => {
  if (loading.value || !['edit', 'delete', 'view'].includes(action)) {
    return
  }

  notificationDetailRef.value?.openDialog(action, id)
}

const downloadUrl = computed<string>(() => {
  const filter: NotificationsListFilter = notificationsStore.filter
  const query: string = (Object.keys(filter) as (keyof NotificationsListFilter)[])
    .reduce<
      string[]
    >((result, key) => [...result, `${encodeURIComponent(key)}=${encodeURIComponent(filter[key] || '')}`], [])
    .join('&')
  return `/api/notifications/export/${currentContextApiPath.value}${query ? `?${query}` : ''}`
})

type Event =
  | { type: 'create-action'; id: Guid; locationId: Guid }
  | { type: 'edit-action'; id: Guid }

const onEvent = async (event: Event) => {
  const { type, id } = event

  const notification = paginatedNotifications.value?.items.find(
    (notification) => notification.id === id
  )

  if (type === 'create-action') {
    actionDetailRef.value?.openDialog(null, {
      context: 'notification',
      id,
      locationId: notification?.location.id
    })
    return
  }

  if (type === 'edit-action') {
    actionDetailRef.value?.openDialog(id)
    return
  }
}

const onRemoveFilters = () => {
  Object.assign(filters, defaultFilters.value, { datetimeRange: [] } satisfies Partial<Filters>)
}

const onPageChange = async (pageIndex: number) => {
  loading.value = true
  notificationsStore.setPaging(pageIndex)
  await notificationsStore.getPaginatedNotifications(currentContextApiPath.value)
  loading.value = false
}

watch(
  () => [filters, currentContextApiPath.value],
  async () => {
    loading.value = true

    notificationsStore.setPaging(1)

    const { datetimeRange = [], ...apiFilters } = filters
    const [filterStartDatetime, filterEndDatetime] = datetimeRange
    notificationsStore.setFilters({ ...apiFilters, filterStartDatetime, filterEndDatetime })

    await notificationsStore.getPaginatedNotifications(currentContextApiPath.value)
    loading.value = false
  },
  { deep: true, immediate: true }
)

watch(
  () => [currentContextType.value, currentLocation.value],
  () => {
    if (currentContextType.value === 'organisation') {
      pageTitle.value = t('notifications.pageTitle.organisation')
    }
    if (currentContextType.value === 'location' && currentLocation.value) {
      pageTitle.value = t('notifications.pageTitle.location', {
        location: currentLocation.value.name
      })
    }
  },
  { immediate: true }
)

onMounted(async () => {
  const promises = []

  if (!topics.value) {
    promises.push(topicsStore.getAllTopics())
  }

  await Promise.all(promises)
  loading.value = false
})
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;

  a[role='button'] {
    margin-right: 15px;
  }
}

:deep(.list-view-filters) {
  input:disabled,
  select:disabled {
    opacity: 0.5;
  }

  .reactive-form__field.type-field {
    width: 170px;
  }

  .reactive-form__field.topic-field {
    width: 165px;
  }

  .reactive-form__field.datetime-range-field {
    width: 269px;
  }
}

.list-view-notifications :deep(.column-title) {
  display: flex;
  align-items: center;
}
</style>
