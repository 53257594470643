<template>
  <legend class="reactive-form__legend" :class="className">
    {{ text }}
    <slot />
  </legend>
</template>

<script setup lang="ts">
import { toKebabCase } from '@/support/helpers/string'
import { computed } from 'vue'

interface Props {
  id: string
  text: string
}

const props = defineProps<Props>()

const className = computed(() => {
  return toKebabCase(props.id)
})
</script>

<style lang="scss" scoped>
.reactive-form__legend {
  display: flex;
  justify-content: space-between;
  font-size: $theme-font-size-l;
  font-family: $theme-brand-font-headings;
  font-weight: 700;
  margin-bottom: 15px;
}
</style>
