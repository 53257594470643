<template>
  <div class="created-at">
    <p>{{ text }}</p>
  </div>
</template>

<script setup lang="ts">
import './localization'

import { computed } from 'vue'
import { DateTime } from 'luxon'

import i18n from '@/support/core/localization'
import { getFullNameOrEmail } from '@/features/user/helpers'

import type { User } from '@/features/user/types'

const { t, d } = i18n.global

interface Props {
  createdAt: string
  createdBy?: User | null
}

const props = defineProps<Props>()

const text = computed(() => {
  return t('createdAt', {
    date: props.createdAt && d(DateTime.fromISO(props.createdAt).toJSDate(), 'dateShortMonthAsText'),
    time: props.createdAt && d(DateTime.fromISO(props.createdAt).toJSDate(), 'time'),
    user: getFullNameOrEmail(props.createdBy)
  })
})
</script>

<style lang="scss" scoped>
.created-at p {
  font-size: $theme-font-size-xs;
  color: map-get($theme-color-neutral, 'grey-2');
}
</style>
