<template>
  <modal-default
    :id="modalConfig.id"
    :title="modalConfig.title"
    :button="modalConfig.button"
    @confirm="onConfirm"
    @cancel="onClose"
    @close="onClose"
    @hidden="onHidden"
  >
    <template #default>
      <div>
        <reactive-form
          v-model:form="form"
          :config="formConfig"
          :class-name="ReactiveFormTypes.SECONDARY"
        />
        <div v-if="!loading && !isOnboarding" class="mb-4">
          <reactive-form-field-label field-id="search">
            {{ t('locations.modal.location.form.locationManager') }}
          </reactive-form-field-label>
          <search-input
            placeholder=""
            variant="tiny"
            :min-input-length="0"
            :clear-input="false"
            :default-item="form.managerId"
            :icon-props="{ name: 'action-user', position: 'left' }"
            :items="searchUserOptions"
            :item-projection="searchUserProjection"
            :item-is-disabled="searchUserIsDisabled"
            @select-item="onSelectUser"
          />
        </div>
      </div>
    </template>
  </modal-default>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { computedAsync } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import { createGuid } from '@/support/helpers/guid'

import useOnboarding from '@/features/onboarding/compositions/useOnboarding'

import { useUserStore } from '@/features/user/store'
import { useLocationsStore } from '@/features/locations/store'

import type { UpdateCreateLocation, LocationType } from '@/features/locations/types'
import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import { getFullNameOrEmail } from '@/features/user/helpers'
import { ReactiveFormTypes } from '@/support/components/reactiveForm/types/ReactiveFormTypes.dto'
import type { Guid } from '@/support/types/Guid.dto'
import type {
  ReactiveFormFieldInputText,
  ReactiveFormFieldSelect
} from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import { locations } from '@/features/auth'

interface Props {
  form: UpdateCreateLocation
}

const { t } = useI18n()
const emit = defineEmits(['confirm', 'close', 'hidden'])
const props = defineProps<Props>()
const form = reactive(props.form)
const loading = ref<boolean>(true)

const formConfig = computed<ReactiveFormConfig>(() => ({
  legend: null,
  buttons: null,
  fields: [
    {
      id: 'name',
      type: FieldType.INPUT_TEXT,
      label: t('locations.modal.location.form.name'),
      validationRules: {
        required: true,
        custom: (value: string | undefined) => {
          if (!value) {
            return true
          }
          const location = locations.value?.find(
            (location) => location.name?.trim().toLowerCase() === value.trim().toLowerCase()
          )
          return !location || location?.id === form.id
        }
      },
      validationMessages: {
        custom: t('locations.modal.location.form.existingLocationNameErrorMessage')
      }
    } satisfies ReactiveFormFieldInputText,
    {
      id: 'typeId',
      type: FieldType.SELECT,
      label: t('locations.modal.location.form.type'),
      options: locationTypes.value.map((item: LocationType) => ({
        value: item.id,
        label: item.name
      })),
      readonly: Boolean(form.id),
      validationRules: {
        required: true
      }
    } satisfies ReactiveFormFieldSelect,
    {
      id: 'managerId',
      type: FieldType.INPUT_TEXT,
      label: t('locations.modal.location.form.locationManager'),
      hidden: true,
      validationRules: {
        required: !isOnboarding.value
      }
    } satisfies ReactiveFormFieldInputText
  ]
}))

const modal = {
  id: createGuid(),
  title: t('locations.modal.location.title'),
  button: {
    cancel: {
      text: t('app.button.cancel')
    },
    confirm: {
      text: t('app.button.save')
    }
  }
}

const modalConfig = ref(modal)

const locationsStore = useLocationsStore()
const userStore = useUserStore()

const { isOnboarding } = useOnboarding()
const { locationTypes } = storeToRefs(locationsStore)

const onConfirm = () => {
  emit('confirm', form)
}

const onClose = () => {
  emit('close')
}

const onHidden = () => {
  emit('hidden')
}

const users = computedAsync(
  async () =>
    form.id
      ? await userStore.getUsersForLocation(form.id, form.managerId)
      : await userStore.getUsersForOrganisation(form.managerId),
  []
)

const searchUserOptions = computed<Guid[]>(() => users.value.map((user) => user.id))

const searchUserProjection = (id: Guid): string => {
  const user = users.value.find((user) => user.id === id)
  return user ? getFullNameOrEmail(user) : ''
}
const searchUserIsDisabled = (id: string): boolean => {
  const user = users.value.find((user) => user.id === id)
  if (!user) {
    return false
  }
  return (
    user.isDeleted ||
    !user.hasSsoId ||
    !(user.hasAccessToOrganisation || user.locations?.some((loc) => loc.id === form.id))
  )
}

const onSelectUser = (id: string) => {
  form.managerId = id
}

onMounted(() => {
  const promises = []

  if (!locationTypes.value.length) {
    promises.push(locationsStore.getLocationTypes())
  }

  Promise.all(promises).finally(() => {
    loading.value = false
  })
})
</script>

<style lang="scss" scoped>
:deep(.modal-body) {
  overflow: visible !important;
}
</style>
