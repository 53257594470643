<template>
  <Transition name="fade" mode="out-in">
    <component :is="AsyncDetailComponent" :key="AsyncDetailComponent" />
  </Transition>
</template>

<script setup lang="ts">
import { shallowRef, watch, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'

const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase())
}

const route = useRoute()
const AsyncDetailComponent = shallowRef()
const routeWatch = {
  getter: () => route.name,
  callback: (newRouteName: any) => {
    const componentName = getComponentName(newRouteName as string)
    AsyncDetailComponent.value = !componentName
      ? null
      : defineAsyncComponent(() => import(`./section/${componentName}.vue`))
  },
  options: {
    immediate: true,
    deep: true
  }
}

const getComponentName = (routeName: string | null) => {
  if (!routeName) return null
  const name = routeName === 'design-system' ? 'index' : routeName.replace('design-system.', '')
  return toCamelCase(name)
}

watch(routeWatch.getter, routeWatch.callback, routeWatch.options)
</script>
