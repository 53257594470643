<template>
  <div ref="container" class="modal modal-advanced fade" tabindex="-1">
    <div class="modal-dialog" :style="{ width: `${width}px` }">
      <div class="modal-content modal-content-left">
        <div class="modal-header">
          <div class="modal-header__type">
            <slot name="type" />
          </div>
          <editable-content
            v-model:title="title"
            :required="computedEditableContentProps.required"
            :readonly="computedEditableContentProps.readonly"
            :enable-enter="computedEditableContentProps.enableEnter"
            :class-names="computedEditableContentProps.classNames"
            :placeholder="computedEditableContentProps.placeholder"
          />
        </div>
        <slot name="sub-navigation" />
        <div class="modal-body">
          <slot name="default" />
        </div>
      </div>
      <div class="modal-content modal-content-right">
        <div class="modal-content__inner">
          <slot
            name="panel"
            :confirm="onConfirmClick"
            :close="onCloseClick"
            :cancel="onCancelClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './localization'

import { onMounted, onUnmounted, computed, ref } from 'vue'
import { Modal } from 'bootstrap'

import type { ModalAdvanced } from './types'

const props = withDefaults(defineProps<ModalAdvanced>(), {
  width: 1160,
  modalProps: {} as any
})

let modal: any = null

const emit = defineEmits(['confirm', 'cancel', 'close', 'hidden'])
const title = defineModel<string | undefined | null>('title', { default: '' })

const computedEditableContentProps = computed(() => {
  return {
    classNames: ['h3'],
    required: true,
    ...props.editableContentProps
  }
})

const close = () => {
  modal.toggle()
}

const onHideModal = () => {
  emit('hidden')
}

const onCloseClick = () => {
  emit('close')
  modal.toggle()
}

const onConfirmClick = () => {
  emit('confirm')
  modal.toggle()
}

const onCancelClick = () => {
  emit('cancel')
  modal.toggle()
}

const container = ref<HTMLDivElement>()

onMounted(() => {
  if (!container.value) {
    throw new Error('could not find container')
  }

  modal = new Modal(container.value, props.modalProps)
  container.value.addEventListener('hidden.bs.modal', onHideModal)
  container.value.addEventListener('shown.bs.modal', (event) => {
    event.preventDefault()

    const firstInput = container.value?.querySelector<
      HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
    >('input[type=default], div[contenteditable=true], textarea')
    firstInput?.focus()
  })

  modal.show()
})

onUnmounted(() => {
  modal.dispose()
  if (document.querySelector('.modal.show')) {
    return
  }
  document.querySelector('body.modal-open')?.classList.remove('modal-open')
  const bodyStyle = document.querySelector('body')?.style
  bodyStyle?.removeProperty('overflow')
  bodyStyle?.removeProperty('padding-right')
})

defineExpose({
  close
})
</script>

<style lang="scss" scoped>
.modal.modal-advanced {
  --bs-modal-margin: 6rem;

  .modal-dialog {
    display: flex;
    flex-direction: row;
    max-width: none;
    transition: all 0.3s ease-out !important;
    min-height: 700px;

    .modal-content,
    .modal-header,
    .modal-body,
    .modal-footer {
      border-radius: 0;
    }

    .modal-content {
      border: 0;
      width: auto;
      flex-grow: unset;
      max-height: 700px;
    }

    .modal-content-left {
      flex-grow: 1;

      .modal-header,
      .modal-body {
        padding: 24px;
      }
      .modal-header {
        background-color: map-get($theme-color-secondary, 'light-green-2');
        border-bottom: 0;
        flex-direction: column;
        align-items: stretch;

        &__type {
          margin: 0 0 5px 5px;
          display: flex;
          flex-direction: row;
        }

        h3 {
          margin: 0;
          font-size: 1.5rem;
          font-weight: 500;
          color: var(--bs-modal-header-color);
        }
      }
      .modal-body {
        overflow: hidden;
        overflow-y: auto;

        :deep(.field-label) {
          font-size: map-get($theme-heading-4, 'font-size');
          font-weight: map-get($theme-heading-4, 'font-weight');
          margin-bottom: 5px;
        }
      }
    }
    .modal-content-right {
      min-width: 300px;
      background-color: var(--bs-modal-bg);
      border-left: 1px solid map-get($theme-color-primary, 'light-blue-2');

      .modal-content__inner {
        padding: 45px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        & > :deep(div:first-child) {
          margin-bottom: 40px;
        }
      }

      :deep(button.button-cancel) {
        all: unset;
        cursor: pointer;
        font-weight: 700;
        font-family: $theme-brand-font-headings;
        margin-right: 20px;
      }
    }
  }
}
</style>
