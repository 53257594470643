import * as userApi from '@/features/user/api'
import * as helpContactApi from '@/features/helpAndContact/api'
import * as rolesApi from '@/features/roles/api'
import * as locationsApi from '@/features/locations/api'
import * as onboardingApi from '@/features/onboarding/api'
import * as organisationApi from '@/features/organisation/api'
import * as descriptivePageApi from '@/features/descriptivePage/api'
import * as topicsApi from '@/features/topics/api'
import * as notificationsApi from '@/features/notifications/api'
import * as actionsApi from '@/features/actions/api'
import * as policyApi from '@/features/policy/api'
import * as cycleApi from '@/features/cycle/api'
import * as mediaApi from '@/features/media/api'

export function useApi() {
  return {
    user: userApi,
    helpContact: helpContactApi,
    roles: rolesApi,
    locations: locationsApi,
    onboarding: onboardingApi,
    organisation: organisationApi,
    descriptivePage: descriptivePageApi,
    topics: topicsApi,
    notifications: notificationsApi,
    actions: actionsApi,
    policy: policyApi,
    cycle: cycleApi,
    media: mediaApi
  }
}
