<template>
  <div class="button-group">
    <dropdown-selector
      v-if="blockOptions && blockOptions.length > 1"
      v-model="blockType"
      :items="blockOptions"
    />
    <button
      v-if="allowedTags.includes('STRONG')"
      :disabled="!tiptap.can().chain().focus().toggleBold().run()"
      :class="{ active: tiptap.isActive('bold') }"
      @click="tiptap.chain().focus().toggleBold().run()"
    >
      <svg viewBox="0 0 18 18">
        <path
          class="stroke"
          d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
        ></path>
        <path
          class="stroke"
          d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
        ></path>
      </svg>
    </button>
    <button
      v-if="allowedTags.includes('EM')"
      :disabled="!tiptap.can().chain().focus().toggleItalic().run()"
      :class="{ active: tiptap.isActive('italic') }"
      @click="tiptap.chain().focus().toggleItalic().run()"
    >
      <svg viewBox="0 0 18 18">
        <line class="stroke" x1="7" x2="13" y1="4" y2="4"></line>
        <line class="stroke" x1="5" x2="11" y1="14" y2="14"></line>
        <line class="stroke" x1="8" x2="10" y1="14" y2="4"></line>
      </svg>
    </button>
    <button
      v-if="allowedTags.includes('U')"
      :disabled="!tiptap.can().chain().focus().toggleUnderline().run()"
      :class="{ active: tiptap.isActive('underline') }"
      @click="tiptap.chain().focus().toggleUnderline().run()"
    >
      <svg viewBox="0 0 18 18">
        <path class="stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path>
        <rect class="fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect>
      </svg>
    </button>
    <button
      v-if="allowedTags.some((tag) => ['STRONG', 'U', 'EM'].includes(tag))"
      @click="tiptap.chain().focus().unsetAllMarks().run()"
    >
      <svg class="" viewBox="0 0 18 18">
        <line class="stroke" x1="5" x2="13" y1="3" y2="3"></line>
        <line class="stroke" x1="6" x2="9.35" y1="12" y2="3"></line>
        <line class="stroke" x1="11" x2="15" y1="11" y2="15"></line>
        <line class="stroke" x1="15" x2="11" y1="11" y2="15"></line>
        <rect class="fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect>
      </svg>
    </button>
    <button
      v-if="allowedTags.includes('LI') && allowedTags.includes('OL')"
      :class="{ active: tiptap.isActive('orderedList') }"
      @click="tiptap.chain().focus().toggleOrderedList().run()"
    >
      <svg viewBox="0 0 18 18">
        <line class="stroke" x1="7" x2="15" y1="4" y2="4"></line>
        <line class="stroke" x1="7" x2="15" y1="9" y2="9"></line>
        <line class="stroke" x1="7" x2="15" y1="14" y2="14"></line>
        <line class="stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line>
        <path
          class="ql-fill"
          d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
        ></path>
        <path
          class="stroke ql-thin"
          d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
        ></path>
        <path
          class="stroke ql-thin"
          d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
        ></path>
      </svg>
    </button>
    <button
      v-if="allowedTags.includes('LI') && allowedTags.includes('UL')"
      :class="{ active: tiptap.isActive('bulletList') }"
      @click="tiptap.chain().focus().toggleBulletList().run()"
    >
      <svg viewBox="0 0 18 18">
        <line class="stroke" x1="6" x2="15" y1="4" y2="4"></line>
        <line class="stroke" x1="6" x2="15" y1="9" y2="9"></line>
        <line class="stroke" x1="6" x2="15" y1="14" y2="14"></line>
        <line class="stroke" x1="3" x2="3" y1="4" y2="4"></line>
        <line class="stroke" x1="3" x2="3" y1="9" y2="9"></line>
        <line class="stroke" x1="3" x2="3" y1="14" y2="14"></line>
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { Editor } from '@tiptap/core'
import { computed } from 'vue'
import i18n from '@/support/core/localization'
import type { AllowableTag } from '@/support/components/htmlEditor/index.vue'

const props = defineProps<{ tiptap: Editor; allowedTags: AllowableTag[] }>()

const { t } = i18n.global

type BlockType = 'h1' | 'h2' | 'h3' | 'p'
type BlockOption = { name: string; className: string; id: BlockType }
const blockOptions = computed<BlockOption[] | undefined>(() => {
  const options: BlockOption[] = []
  if (props.allowedTags.includes('H1')) {
    options.push({
      name: t('htmlEditor.heading', { level: 1 }),
      className: 'h1 text-nowrap',
      id: 'h1'
    })
  }
  if (props.allowedTags.includes('H2')) {
    options.push({
      name: t('htmlEditor.heading', { level: 2 }),
      className: 'h2 text-nowrap',
      id: 'h2'
    })
  }
  if (props.allowedTags.includes('H3')) {
    options.push({
      name: t('htmlEditor.heading', { level: 3 }),
      className: 'h3 text-nowrap',
      id: 'h3'
    })
  }
  if (props.allowedTags.includes('P')) {
    options.push({
      name: t('htmlEditor.paragraph'),
      className: 'p text-nowrap',
      id: 'p'
    })
  }

  return options.length ? options : undefined
})
const blockType = computed<BlockType>({
  get: () => {
    if (props.tiptap?.isActive('heading', { level: 1 })) {
      return 'h1'
    }
    if (props.tiptap?.isActive('heading', { level: 2 })) {
      return 'h2'
    }
    if (props.tiptap?.isActive('heading', { level: 3 })) {
      return 'h3'
    }
    return 'p'
  },
  set: (blockType: BlockType) => {
    if (blockType === 'h1') {
      props.tiptap?.chain().focus().setHeading({ level: 1 }).run()
      return
    }
    if (blockType === 'h2') {
      props.tiptap?.chain().focus().setHeading({ level: 2 }).run()
      return
    }
    if (blockType === 'h3') {
      props.tiptap?.chain().focus().setHeading({ level: 3 }).run()
      return
    }
    props.tiptap?.chain().focus().setParagraph().run()
  }
})
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
}

.dropdown {
  border: none;
  background-color: map-get($theme-color-primary, 'dark-blue');

  :deep(.button-secondary) {
    height: 18px;
    margin-right: 2px;

    padding: 0 20px 0 5px;
    background-color: transparent;
    color: map-get($theme-color-neutral, 'grey-3');
    border: none;

    &:focus {
      background-color: transparent;
      color: map-get($theme-color-neutral, 'grey-3');
    }

    .icon svg path {
      stroke: map-get($theme-color-neutral, 'grey-3');
    }
  }

  :deep(.dropdown-item) {
    padding: 0;
    margin: 0;

    .label {
      padding: 3px 12px;
      margin: 0;
    }
  }
}

button {
  background-color: transparent;
  border: none;
  color: map-get($theme-color-neutral, 'grey-3');
  margin-right: 2px;
  padding: 3px 5px;
  height: 24px;

  svg {
    float: left;
    height: 100%;
  }

  .stroke {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;

    color: map-get($theme-color-neutral, 'grey-3');
    stroke: map-get($theme-color-neutral, 'grey-3');
  }
  .fill {
    color: map-get($theme-color-neutral, 'grey-3');
    fill: map-get($theme-color-neutral, 'grey-3');
  }

  &.active {
    color: map-get($theme-color-neutral, 'white');
    .stroke {
      color: map-get($theme-color-neutral, 'white');
      stroke: map-get($theme-color-neutral, 'white');
    }
    .fill {
      color: map-get($theme-color-neutral, 'white');
      fill: map-get($theme-color-neutral, 'white');
    }
  }
}
</style>
