<template>
  <div class="editor-container">
    <div class="editor-buttons">
      <tiptap-buttons v-if="tiptap" :tiptap="tiptap" :allowed-tags="computedAllowedTags" />
    </div>
    <editor-content :editor="tiptap" class="editor" />
  </div>
</template>

<script lang="ts" setup>
import './localization'
import { computed, ref, watch } from 'vue'
import TiptapButtons from '@/support/components/htmlEditor/components/tiptap-buttons.vue'

import { EditorContent, type Extensions, useEditor } from '@tiptap/vue-3'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import HardBreak from '@tiptap/extension-hard-break'
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Document from '@tiptap/extension-document'
import DOMPurify from 'dompurify'
import { watchDebounced } from '@vueuse/core'

export type AllowableTag =
  | 'DIV'
  | 'P'
  | 'UL'
  | 'OL'
  | 'LI'
  | 'H1'
  | 'H2'
  | 'H3'
  | 'BR'
  | 'STRONG'
  | 'U'
  | 'EM'
const ALLOWED_ATTR: string[] = []

const modelValue = defineModel<string | null>()

const props = withDefaults(defineProps<{ allowedTags?: AllowableTag[] }>(), {
  allowedTags: () => ['UL', 'OL', 'LI', 'H1', 'H2', 'H3', 'STRONG', 'U', 'EM']
})
const computedAllowedTags = computed<AllowableTag[]>(() => [...props.allowedTags, 'DIV', 'P', 'BR'])
const emit = defineEmits<{ blur: [] }>()

const sanitizeHtml = (input: string) =>
  DOMPurify.sanitize(input, {
    ALLOWED_TAGS: [...computedAllowedTags.value],
    ALLOWED_ATTR: [...ALLOWED_ATTR]
  })
const tiptapValue = ref(sanitizeHtml(modelValue.value || ''))

const extensions: Extensions = [
  Document,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  Paragraph,
  Heading,
  Text,
  Bold,
  Italic,
  Underline
]

const textToHtml = (input: string) => input.replace(/\r+/g, '').replace(/\n/g, '<br>')

const tiptap = useEditor({
  extensions,
  autofocus: true,
  content: tiptapValue.value,
  onBlur: () => {
    emit('blur')
  },
  onUpdate({ editor }) {
    const html = editor.getHTML()
    if (tiptapValue.value === html) {
      return
    }
    tiptapValue.value = html
  },
  editorProps: {
    handlePaste(view, event) {
      event.preventDefault()

      const clipboardData = event.clipboardData
      if (!clipboardData) {
        return false
      }

      const data =
        clipboardData.getData('text/html') || textToHtml(clipboardData.getData('text/plain') || '')

      const html = sanitizeHtml(data)

      tiptap.value!.commands.insertContent(html)

      return true
    }
  }
})

const htmlIsEmpty = (input: string) => !input || input === '<p></p>' || input === '<div></div>'
watchDebounced(
  tiptapValue,
  () => {
    if (tiptapValue.value === modelValue.value) {
      return
    }
    modelValue.value = htmlIsEmpty(tiptapValue.value) ? '' : sanitizeHtml(tiptapValue.value)
  },
  { debounce: 300 }
)

watch(
  modelValue,
  () => {
    if (modelValue.value === tiptapValue.value) {
      return
    }
    tiptapValue.value = sanitizeHtml(modelValue.value || '')
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.editor-container {
  cursor: default;

  :deep(.editor) {
    padding: 10px;

    .tiptap {
      cursor: text;
      word-break: break-word;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .editor-buttons {
    background-color: map-get($theme-color-primary, 'dark-blue');
    padding: 8px;
  }
}
</style>
