<template>
  <li class="nested-doll-menu__item" @click="onItemClick">
    {{ item.title }}
    <icon name="action-chevron-right" />
  </li>
</template>

<script setup lang="ts">
interface Props {
  item: any
}

const props = defineProps<Props>()

const emit = defineEmits(['click'])

const onItemClick = () => {
  const item = props.item
  const action = item.contentTree && item.contentTree.length ? 'next' : 'open'
  emit('click', { action, item })
}
</script>

<style lang="scss" scoped>
li.nested-doll-menu__item {
  span {
    position: absolute;
    right: 10px;
  }
}
</style>
