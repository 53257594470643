export const ALPHA_SPACES = /^[a-zA-Z ]*$/

export const PHONE =
  /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/

export const IBAN = /^NL\d{2}\s?[A-Z]{4}\s?(\d{4}\s?){2}(\d){2}$/

export const ZIP_CODE = /^\s*(\d{4})(\s?)([a-z]{2})\s*$/i

export const URL =
  /^(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/
