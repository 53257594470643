<template>
  <div
    ref="container"
    class="modal modal-default fade"
    :class="{ 'modal-shadow': !modalProps?.backdrop }"
    tabindex="-1"
  >
    <div
      ref="dialog"
      class="modal-dialog"
      :class="{ 'fixed-height': height }"
      :style="modalDialogStyle"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header__title">
            <span v-if="subTitle">
              {{ subTitle }}
            </span>
            <h3>
              {{ title }}
            </h3>
          </div>
          <button @click="onCloseClick">
            <icon name="action-close" />
          </button>
        </div>
        <div class="modal-body">
          <modal-transition :modal="container || null" :width="width">
            <modal-default-body v-if="message?.length" :message="message" />
            <slot v-else name="default" :settings="settings" :message="message" />
          </modal-transition>
        </div>
        <div v-if="!hideFooter" class="modal-footer">
          <slot name="footer" :button="button" :cancel="onCancelClick" :confirm="onConfirmClick">
            <button
              v-if="buttonCancel"
              id="cancel-button"
              :class="buttonCancel.className"
              type="button"
              @click="onCancelClick"
            >
              {{ buttonCancel.text }}
            </button>
            <button-icon
              v-if="buttonConfirm"
              id="ok-button"
              :disabled="v$.$invalid"
              :class="buttonConfirm.className"
              variant="primary"
              :loading="loading"
              @click="onConfirmClick"
            >
              {{ buttonConfirm.text }}
            </button-icon>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './localization'
import { useI18n } from 'vue-i18n'

import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { onClickOutside } from '@vueuse/core'
import { Modal } from 'bootstrap'

import ModalTransition from './components/modalTransition.vue'
import ModalDefaultBody from './components/modalDefaultBody.vue'

import type { ModalDefault } from './types/ModalDefault.dto'
import type { ModalDefaultButtons } from '@/support/components/modal/default/types/ModalDefaultButtons.dto'

const { t } = useI18n()

const props = withDefaults(defineProps<ModalDefault>(), {
  title: '',
  message: null,
  width: 600,
  height: null,
  disableClose: null,
  modalProps: {} as any
})

defineSlots<{
  default(props: { message: string | null; settings: any }): void
  footer(props: { button?: ModalDefaultButtons; cancel: () => void; confirm: () => void }): void
}>()

const v$ = useVuelidate()
const dialog = ref<HTMLElement | null>(null)
const emit = defineEmits(['confirm', 'cancel', 'close', 'hidden'])

let modal: any = null

const onHideModal = () => {
  emit('hidden')
}

const onCloseClick = () => {
  emit('close')
  modal.toggle()
}

const onConfirmClick = () => {
  emit('confirm')

  if (props.disableClose && props.disableClose === 'confirm') return

  modal.toggle()
}

const onCancelClick = () => {
  emit('cancel')

  if (props.disableClose && props.disableClose === 'cancel') return

  modal.toggle()
}

const modalDialogStyle = computed(() => {
  const width = props.width && props.width + (typeof props.width === 'number' ? 'px' : '')
  const height = props.height && props.height + (typeof props.height === 'number' ? 'px' : '')
  const marginTop =
    props.marginTop && props.marginTop + (typeof props.marginTop === 'number' ? 'px' : '')
  return { width, height, marginTop }
})

const buttonCancel = computed(() => {
  if (!props.button?.cancel) return null

  const { cancel } = props.button

  const text = cancel.text ? cancel.text : t('modal.default.button.cancel')
  const className = cancel.className ? cancel.className : 'button-cancel'

  return {
    text,
    className
  }
})

const buttonConfirm = computed(() => {
  if (!props.button?.confirm) return null

  const { confirm } = props.button

  const text = confirm.text ? confirm.text : t('modal.default.button.confirm')

  return {
    text,
    className: confirm.className
  }
})

onClickOutside(dialog, () => {
  modal.toggle()
})

const container = ref<HTMLDivElement>()
onMounted(() => {
  if (!container.value) {
    throw new Error('could not find container')
  }

  modal = new Modal(container.value, props.modalProps)
  container.value.addEventListener('hidden.bs.modal', onHideModal)

  container.value.addEventListener('shown.bs.modal', (event) => {
    event.preventDefault()

    const firstInput = container.value?.querySelector<
      HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
    >('input[type=default], div[contenteditable=true], textarea')
    firstInput?.focus()
  })

  modal.show()
})

onUnmounted(() => {
  modal.dispose()
  if (document.querySelector('.modal.show')) {
    return
  }
  document.querySelector('body.modal-open')?.classList.remove('modal-open')
  const bodyStyle = document.querySelector('body')?.style
  bodyStyle?.removeProperty('overflow')
  bodyStyle?.removeProperty('padding-right')
})

const close = () => {
  modal.toggle()
}

defineExpose({
  close
})
</script>

<style lang="scss">
.modal-open-keep-scroll {
  overflow: auto !important;
  padding-right: 0 !important;
}
.modal.modal-default {
  --bs-modal-margin: 3rem;

  &.modal-shadow {
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.2);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: -1;
    }
    .modal-dialog {
      box-shadow:
        0px 8px 8px 0px rgba(38, 56, 75, 0.05),
        0px -4px 8px 0px rgba(38, 56, 75, 0.05);
    }
  }

  .modal-dialog {
    display: flex;
    max-width: none;
    transition: all 0.3s ease-out !important;

    .modal-content,
    .modal-header,
    .modal-body,
    .modal-footer {
      border-radius: 0;
    }

    .modal-content {
      border: 0;

      .modal-header {
        border-bottom: 0;
        border-top: 4px solid map-get($theme-color-primary, 'orange');
        padding: 40px;
        justify-content: space-between;

        .modal-header__title {
          margin-right: 40px;

          h3 {
            margin-bottom: 0;
          }

          span {
            color: map-get($theme-color-primary, 'light-blue-1');
          }
        }

        button {
          background: none;
          border: 0;
          cursor: pointer;
          padding: 0;
          margin-top: 2px;
          align-self: flex-start;
        }
      }
      .modal-body {
        overflow: hidden;
        flex-grow: 1;
        padding: 0 40px 40px 40px;

        &:empty {
          padding: 0;
        }
      }
      .modal-footer {
        border-top: 0;
        padding: 0 25px 40px 25px;
        justify-content: flex-start;
        margin-top: auto;

        button.button-cancel {
          all: unset;
          cursor: pointer;
          font-weight: 700;
          font-family: $theme-brand-font-headings;
          margin-right: 20px;
          margin-left: auto;
        }
      }
    }
    &.fixed-height {
      .modal-content {
        .modal-body {
          overflow: hidden;
          overflow-y: auto;
        }
        .modal-footer {
          padding: 40px 25px;
          position: relative;

          &:before {
            content: '';
            background: linear-gradient(to top, rgb(38, 56, 75, 6%), rgba(0, 0, 0, 0));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 10px 0 0 0;
            margin-top: -10px;
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
