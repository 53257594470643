import { requiredIf, helpers, sameAs, maxLength, email } from '@vuelidate/validators'

import { ALPHA_SPACES, PHONE, IBAN, ZIP_CODE, URL } from '../constants/validationRegex'

import { FieldValidationType } from '../types/FieldValidationType.dto'
import type { ReactiveFormField } from '../types/ReactiveFormField.dto'
import type { ReactiveFormLegend } from '../types/ReactiveFormLegend.dto'

import { isDatePickerDate } from './isDatePickerDate'
import { getLocaleMessage } from './getLocaleMessage'

export const getVuelidateRules = (fields: Array<ReactiveFormField | ReactiveFormLegend>) => {
  const form: any = {}

  fields.forEach((field: any) => {
    if (!field.validationRules) {
      form[field.id] = {}
    } else {
      form[field.id] = {
        required: helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.REQUIRED),
          requiredIf(field.validationRules.required)
        )
      }

      if (field.datePicker && field.validationRules.required) {
        delete form[field.id].required

        form[field.id].isDatePickerDate = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.REQUIRED),
          isDatePickerDate
        )
      }

      if (field.validationRules.sameAs) {
        form[field.id].sameAs = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.SAME_AS),
          sameAs(field.validationRules.sameAs)
        )
      }

      if (field.validationRules.maxLength) {
        form[field.id].maxLength = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.MAX_LENGTH),
          maxLength(field.validationRules.maxLength)
        )
      }

      if (field.validationRules.email) {
        form[field.id].email = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.EMAIL),
          email
        )
      }

      if (field.validationRules.alphaSpaces) {
        form[field.id].regex = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.ALPHA_SPACES),
          helpers.regex(ALPHA_SPACES)
        )
      }

      if (field.validationRules.phone) {
        form[field.id].regex = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.PHONE),
          helpers.regex(PHONE)
        )
      }

      if (field.validationRules.iban) {
        form[field.id].regex = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.IBAN),
          helpers.regex(IBAN)
        )
      }

      if (field.validationRules.zipCode) {
        form[field.id].regex = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.ZIP_CODE),
          helpers.regex(ZIP_CODE)
        )
      }

      if (field.validationRules.url) {
        form[field.id].regex = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.URL),
          helpers.regex(URL)
        )
      }

      if (field.validationRules.regex) {
        form[field.id].regex = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.REGEX),
          helpers.regex(new RegExp(field.validationRules.regex))
        )
      }

      if (field.validationRules.custom) {
        form[field.id].custom = helpers.withMessage(
          getLocaleMessage(field, FieldValidationType.CUSTOM),
          field.validationRules.custom
        )
      }
    }
  })

  return { form }
}
