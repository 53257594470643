<template>
  <div class="container mt-4">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.container {
  @media print {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
