import { type HelpItem } from '../types'

export const createNestedTree = (helpTopics: HelpItem[]): HelpItem[] => {
  const root: HelpItem[] = []

  const pathMap: { [path: string]: HelpItem } = {}

  for (const topic of helpTopics) {
    const segments = topic.path.split('/').filter(Boolean)
    if (segments.length === 1) {
      root.push(topic)
      pathMap[topic.path] = topic
    } else {
      const parentPath = segments.slice(0, -1).join('/')
      const parent = pathMap[parentPath]
      if (parent) {
        if (!parent.contentTree) {
          parent.contentTree = []
        }
        parent.contentTree.push(topic)
        pathMap[topic.path] = topic
      }
    }
  }
  return root
}

export const pathToHelpPath = (path: string): string[] => {
  const correctedPath = path.replace(/bewerk-huidige-cyclus/, 'nieuwe-cyclus-inrichten')
  return correctedPath.split('/').filter(Boolean)
}
