<template>
  <section class="gradient">
    <slot />
  </section>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
section.gradient {
  box-shadow:
    0px 8px 8px 0px rgba(38, 56, 75, 0.05),
    0px -4px 8px 0px rgba(38, 56, 75, 0.05);
  border-radius: 5px;
  padding: 40px;
}
</style>
