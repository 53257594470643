<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
      :description="field.labelTooltip"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <label
      v-for="(option, index) in field.options"
      :key="index"
      :class="[
        'custom-toggle',
        option.className ?? null,
        { small: field.size === 'small', readonly: field.readonly }
      ]"
      :for="`${field.id}-${index}`"
    >
      <input
        :id="`${field.id}-${index}`"
        v-model="computedModel"
        class="toggle-checkbox"
        type="checkbox"
        :name="field.id"
        :disabled="option.disabled || field.readonly"
        :value="option.value"
        :true-value="option.value"
        :false-value="optionValueFalse"
      />
      <div class="toggle-switch"></div>
      <span>
        <slot name="label" v-bind="option">
          {{ option.label }}
        </slot>
      </span>
    </label>

    <reactive-form-errors :errors="errors" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useFormField } from '../composables/useFormField'
import type {
  ReactiveFormFieldOption,
  ReactiveFormFieldToggle
} from '../types/ReactiveFormField.dto'
import type { ReactiveFormFieldValue } from '../types/ReactiveFormFieldValue.dto'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'
import type { Validation } from '@vuelidate/core'

interface Props {
  field: ReactiveFormFieldToggle
  vuelidate?: Validation | null
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null
})

defineSlots<{ label(props: ReactiveFormFieldOption): void }>()

const { errors, className, isRequired, modelSetter } = useFormField(props)

const model = defineModel<ReactiveFormFieldValue>({ default: '', set: modelSetter })

const computedModel = computed({
  get: () => {
    if (props.field.options.length === 1) {
      return model.value
    }

    return Array.isArray(model.value) ? model.value : []
  },
  set: (value: any) => {
    model.value = value
  }
})

const optionValueFalse = computed(() => {
  const isBoolean = typeof props.field.options[0].value === 'boolean'
  const isNumber = typeof props.field.options[0].value === 'number'

  return props.field.options.length === 1 ? (isBoolean ? false : isNumber ? 0 : null) : null
})
</script>

<style lang="scss" scoped>
.custom-toggle {
  display: flex;
  cursor: pointer;
  position: relative;
  gap: 10px;
  align-items: center;

  & + .custom-toggle {
    margin-top: 10px;
  }

  &.small {
    .toggle-switch {
      width: 32px;
      height: 18px;

      &:before {
        width: 14px;
        height: 14px;
      }
    }
    .toggle-checkbox:checked + .toggle-switch {
      &:before {
        left: 16px;
      }
    }
  }

  .toggle-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .toggle-switch {
    flex-shrink: 0;
    background: map-get($theme-color-neutral, 'grey-2');
    border-radius: 12px;
    width: 44px;
    height: 24px;
    position: relative;
    transition: background 0.25s;

    &:before,
    &:after {
      content: '';
    }

    &:before {
      display: block;
      background: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: left 0.25s;
    }
  }

  &.readonly {
    cursor: not-allowed;

    .toggle-switch:before {
      background: #fff;
      opacity: 0.5;
    }
  }

  .toggle-checkbox:disabled + .toggle-switch {
    background-color: map-get($form-field-background-color, 'disabled');
    cursor: not-allowed;
  }

  .toggle-checkbox:focus + .toggle-switch {
    box-shadow: 0 0 0 5px $form-field-outline-color;
    border-radius: 12px;
  }

  .toggle-checkbox:checked + .toggle-switch {
    background: #55d769;

    &:before {
      left: 22px;
    }
  }
}
</style>
