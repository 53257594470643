<template>
  <router-link class="parent-link" :to="to" @click="onClickBack">
    <icon class="icon" name="action-back" :states="['hover']" />
    <div class="text">
      <slot name="default">{{ title }}</slot>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { type RouteLocationRaw, useRouter } from 'vue-router'
import { previousRoute } from '@/app/http/router'

const router = useRouter()

const props = defineProps<{ title?: string; to: RouteLocationRaw }>()

const onClickBack = (e: MouseEvent) => {
  if (e.ctrlKey) {
    return
  }
  if (router.resolve(props.to).name === previousRoute.value?.name) {
    e.preventDefault()
    router.back()
    return
  }
}
</script>

<style lang="scss" scoped>
.parent-link {
  display: flex;

  .icon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 5px;
  }

  .text {
    color: map-get($theme-color-primary, 'light-blue-1');
  }

  &:hover {
    .text {
      color: map-get($theme-color-primary, 'dark-blue');
    }
  }

  :deep(.icon__svg) {
    opacity: 0;
    &.idle {
      opacity: 1;
    }
  }

  &:not([disabled]):has(.hover):hover :deep(.icon__svg) {
    &.idle,
    &.disabled {
      opacity: 0;
    }
    &.hover {
      opacity: 1;
    }
  }

  .text {
    flex-grow: 1;
  }
}
</style>
