<template>
  <div />
</template>

<script setup lang="ts">
import { h, watch } from 'vue'
import { toast } from 'vue3-toastify'
import { type KeyValue } from '@/support/types/KeyValue.dto'
import Icon from '@/support/components/icon/index.vue'
import { options as defaultOptions } from './constants/options'
import { Status } from '@/support/enums/Status'
import { useAlertStore } from './store/index'
import './localization'

const store = useAlertStore()
const watchOptions = { deep: true }
const instantiated: KeyValue = {}
const icons: KeyValue = {
  success: 'action-done',
  error: 'action-error',
  warning: 'action-warning',
  info: 'action-in-progress'
}

const getOptions = ({ type, guid, ...rest }: any) => {
  const data: object = { guid: guid }
  const onClose = ({ guid }: any) => {
    store.removeMessage(guid)
    delete instantiated[guid]
  }

  const options = {
    ...rest,
    type,
    data,
    onClose
  }

  if (type !== Status.neutral) {
    options.icon = h(Icon, { name: icons[type], size: { width: 24, height: 24 } })
  }

  return options
}

watch(
  () => store.messages,
  (messages) => {
    if (!messages.length) return

    const { message, guid, type } = messages[messages.length - 1]

    if (!guid || Object.prototype.hasOwnProperty.call(instantiated, guid)) return

    instantiated[guid] = message

    const options = getOptions({ type, guid, message, ...defaultOptions })

    toast(message, options)
  },
  watchOptions
)
</script>

<style lang="scss">
.Toastify__toast-container {
  width: max-content;

  .Toastify__toast {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0;
    padding: 16px;
    border-left: 4px solid map-get($theme-color-status, 'neutral');

    &.Toastify__toast--info {
      border-left-color: map-get($theme-color-status, 'info');
    }
    &.Toastify__toast--success {
      border-left-color: map-get($theme-color-status, 'success');
    }
    &.Toastify__toast--warning {
      border-left-color: map-get($theme-color-status, 'warning');
    }
    &.Toastify__toast--error {
      border-left-color: map-get($theme-color-status, 'error');
    }
  }
}
</style>
