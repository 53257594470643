<template>
  <span class="icon" :class="`icon-${iconName}`" :style="style">
    <component
      :is="library[icon.name]"
      v-for="(icon, index) in iconComponent"
      :key="index"
      class="icon__svg"
      :class="icon.state"
      role="img"
    />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import icons from '@/support/components/icon/constants/icons'
import type { Icon } from './types/Icon.dto'

const flattenObject = (obj: object) =>
  Object.values(obj).reduce((acc, cur) => ({ ...acc, ...cur }), {})

const props = withDefaults(defineProps<Icon>(), {
  size: () => ({ width: 24, height: 24 }),
  states: () => []
})

const library = computed(() => flattenObject(icons))

const style = computed(() => {
  return {
    width: `${props.size.width}px`,
    height: `${props.size.height}px`
  }
})

const iconName = computed(() => props.name)

const iconComponent = computed(() => {
  const states = ['idle', ...props.states]

  return states.map((state) => {
    const suffix = state !== 'idle' ? `-${state}` : ''

    return {
      state,
      name: `${props.name}${suffix}`
    }
  })
})
</script>

<style lang="scss" scoped>
.icon {
  display: block;
  position: relative;
  flex-shrink: 0;
  .icon__svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
