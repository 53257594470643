<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <div v-if="field.readonly" class="field-readonly" :class="{ 'no-value': !model }">
      {{ (model ? model : field.placeholder) || '-' }}
    </div>
    <reactive-form-field-save-wrapper
      v-else
      v-model="model"
      v-model:internal-model="internalModel"
      class="field-input"
      :field="field"
    >
      <textarea
        :id="field.id"
        v-model.trim="internalModel"
        :name="field.id"
        :placeholder="field.placeholder"
        :disabled="field.disabled"
        class="text"
        type="text"
        :rows="field.rows || 6"
        @blur="vuelidate?.$touch()"
      />
      <reactive-form-errors :errors="errors" />
    </reactive-form-field-save-wrapper>
  </div>
</template>

<script setup lang="ts">
import { useFormField } from '../composables/useFormField'
import type { ReactiveFormFieldTextarea } from '../types/ReactiveFormField.dto'
import type { ReactiveFormFieldValue } from '../types/ReactiveFormFieldValue.dto'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'
import type { Validation } from '@vuelidate/core'
import ReactiveFormFieldSaveWrapper from '@/support/components/reactiveForm/components/reactiveFormFieldSaveWrapper.vue'
import { ref } from 'vue'

interface Props {
  field: ReactiveFormFieldTextarea
  vuelidate?: Validation | null
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null
})

const { errors, className, isRequired, modelSetter } = useFormField(props)

const model = defineModel<ReactiveFormFieldValue>({ default: '', set: modelSetter })
const internalModel = ref<ReactiveFormFieldValue>()
</script>

<style lang="scss" scoped>
.field-readonly {
  &.no-value {
    opacity: 0.3;
  }
}

textarea {
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  color: #333;
  background-color: #fff;
  resize: vertical;

  &:hover {
    background-color: map-get($form-field-background-color, 'hover');
    border-color: map-get($form-field-border-color, 'hover');
  }

  &:focus,
  &:focus:active {
    background-color: map-get($form-field-background-color, 'focus');
    border-color: map-get($form-field-border-color, 'focus');

    box-shadow: 0 0 0 5px $form-field-outline-color;
    border-radius: 2px;
  }

  &:disabled {
    background-color: map-get($form-field-background-color, 'disabled');
    border-color: map-get($form-field-border-color, 'disabled');
    color: $form-field-font-color-disabled;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $form-field-placeholder-color;
  }
}
</style>
