import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { useApi } from '@/features/api/api'
import { createNestedTree } from '../helpers'
import type { HelpItem } from '../types'

export interface HelpContactStore {
  isOpen: Ref<boolean>
  item: Ref<HelpItem | null>
  path: Ref<Array<string>>
  contentTree: Ref<Array<HelpItem> | undefined>
  searchResult: Ref<HelpItem[] | undefined>
  searchQuery: Ref<string>
  openHelpContact: (helpPath: Array<string>) => void
  getContentTree: () => void
  searchContentTree: (query: string) => void
  closeHelpContact: () => void
  setHelpDetail: (selectedItem: HelpItem | null, cachedPath: Array<string>) => void
}

const sortByOrder = (a: { order: number }, b: { order: number }) => a.order - b.order

export const useHelpContactStore = defineStore('helpContact', () => {
  const Api = useApi()
  const isOpen = ref(false)
  const item = ref<HelpItem | null>(null)
  const path = ref<Array<string>>([])
  const flatContent = ref<HelpItem[]>([])
  const contentTree = ref<HelpItem[]>()
  const searchResult = ref<HelpItem[]>()
  const searchQuery = ref('')

  const openHelpContact = async (helpPath: Array<string>) => {
    isOpen.value = true
    path.value = helpPath
  }

  const closeHelpContact = () => {
    isOpen.value = false
  }

  const getContentTree = async () => {
    const items = (await Api.helpContact.getHelpContent())?.data || []
    flatContent.value = [...items]
      .map((item) => ({
        ...item,
        slug: item.path.split('/').filter(Boolean).pop() || '',
        searchString: `${item.title} ${item.content || ''}`.toLowerCase()
      }))
      .sort(sortByOrder)
    contentTree.value = createNestedTree(flatContent.value)
  }

  const searchContentTree = async (query: string) => {
    if (!query.length) {
      searchResult.value = undefined
      return
    }

    const words = query.toLowerCase().replace(/\s+/g, ' ').split(' ')
    searchResult.value = flatContent.value
      .reduce<HelpItem[]>((result, item) => {
        if (words.every((word) => item.searchString.includes(word))) {
          return [...result, item]
        }
        return result
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title))
  }

  const setHelpDetail = (selectedItem: HelpItem | null, cachedPath: Array<string>) => {
    item.value = selectedItem
    path.value = cachedPath
  }

  return {
    isOpen,
    item,
    path,
    contentTree,
    searchQuery,
    openHelpContact,
    getContentTree,
    searchResult,
    searchContentTree,
    closeHelpContact,
    setHelpDetail
  } satisfies HelpContactStore
})
