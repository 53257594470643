import { createGtm, type VueGtmPlugin } from '@gtm-support/vue-gtm'
import Console from '@/support/core/console'

export const createGtmInstance = (router: any): VueGtmPlugin => {
  const id: string = import.meta.env.VITE_APP_GTM_ID ?? 'GTM-XXXXXXX'

  Console.info(`GTM enabled, ID ${import.meta.env.VITE_APP_GTM_ID}`)

  return createGtm({
    id,
    debug: true,
    enabled: true,
    loadScript: true,
    vueRouter: router
  })
}
