<template>
  <modal-default
    :id="modalId"
    :title="t('notifications.dialogs.addWorkers.title')"
    :button="{ confirm: { text: t('notifications.dialogs.addWorkers.add') } }"
    @confirm="onConfirm"
    @cancel="onClose"
    @close="onClose"
    @hidden="onHidden"
  >
    <template #default>
      <div>
        <div class="mb-4">
          <div class="d-flex">
            <reactive-form-field-label field-id="search">
              <span v-if="usersCount" class="count ms-1">{{ usersCount }}</span>
            </reactive-form-field-label>
          </div>
          <search-input
            :items="userOptions"
            :min-input-length="0"
            :item-projection="searchMapFn"
            @select-item="onSelectUser"
          />
        </div>

        <list-view
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewActions"
          :hide-header="true"
          @event="onListViewRowClick"
        />
      </div>
    </template>
  </modal-default>
</template>
<script setup lang="ts">
import { computed, reactive } from 'vue'
import { computedAsync } from '@vueuse/core'
import { useUserStore } from '@/features/user/store'
import { createGuid } from '@/support/helpers/guid'
import type { Guid } from '@/support/types/Guid.dto'
import { getFullNameOrEmail } from '@/features/user/helpers'
import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'
import { useI18n } from 'vue-i18n'

type Props = {
  settings?: { users?: Guid[] | null; locationId?: Guid | null }
}

const props = defineProps<Props>()

const { t } = useI18n()

const emit = defineEmits(['confirm', 'close', 'hidden'])

const modalId = createGuid()
const userIds = reactive<Guid[]>([])

const userStore = useUserStore()

const searchMapFn = (id: Guid) => getFullNameOrEmail(users.value?.find((user) => user.id === id))

const onSelectUser = (userId: Guid) => {
  userIds.push(userId)
}

const usersCount = computed(() => {
  return userIds.length >= 1 ? `(${userIds.length})` : null
})

const users = computedAsync(
  async () =>
    props.settings?.locationId
      ? await userStore.getUsersForLocation(props.settings.locationId)
      : [],
  []
)

const userOptions = computed<Guid[]>(() =>
  users.value.filter((user) => !userIds.some((userId) => userId === user.id)).map((user) => user.id)
)

const listViewHeader = [{ title: t('users.listView.header.name'), property: 'user' }]

const listViewActions = [ListViewButton.Delete]

const listViewRecords = computed(() =>
  users.value
    .filter((user) => userIds.some((userId) => userId === user.id))
    .sort((a, b) => getFullNameOrEmail(a).localeCompare(getFullNameOrEmail(b)))
    .map((user) => ({ id: user!.id, user }))
)

const onListViewRowClick = (event: any) => {
  const { action, id } = event

  if (action === ListViewButton.Delete && id) {
    const index = listViewRecords.value.findIndex((userId) => userId === id)
    userIds.splice(index, 1)
  }
}

const onConfirm = () => {
  emit('confirm', userIds)
}

const onClose = () => {
  emit('close')
}

const onHidden = () => {
  emit('hidden')
}
</script>

<style lang="scss" scoped>
:deep(.modal-body) {
  overflow: visible !important; // fixes issue with typeahead dropdown overlay
}
:deep(.list-view) {
  margin-right: 45px;
}
</style>
