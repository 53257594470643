<template>
  <div class="files">
    <list-label
      :hide-add-button="!editable"
      :label="label || $t('actions.editAction.field.labelDocuments')"
      @click:add="$emit('click:add')"
    />
    <div v-if="items.length" class="list">
      <div v-for="(item, index) of sortedItems" :key="index" class="list-item">
        <component
          :is="item.type === 'new' ? 'div' : 'a'"
          class="label"
          :href="
            item.type === 'new'
              ? undefined
              : parseMediaUrl(typeof item.data === 'string' ? item.data : item.data.url)
          "
          target="_blank"
          @click="downloadNewItem($event, item)"
        >
          <icon name="action-download" />
          <div class="text">{{ removeDashes(getName(item)) }}</div>
        </component>
        <button v-if="editable" class="delete" @click="$emit('click:delete', item)">
          <icon name="action-delete" />
        </button>
      </div>
    </div>
    <div v-else>
      <empty-state
        :title="$t('media.mediaList.emptyState.title')"
        :description="editable ? $t('media.mediaList.emptyState.text') : undefined"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="ListItem extends MediaListItem<string> | MediaListItem<{ url: string; title: string }>"
>
import type { MediaListItem } from '@/features/media/types'
import { parseMediaUrl } from '@/features/media'
import { computed } from 'vue'

interface MediaListProps {
  items: ListItem[]
  label?: string
  editable?: boolean
}

const props = defineProps<MediaListProps>()

defineEmits<{ 'click:add': []; 'click:delete': [item: ListItem] }>()

const getName = (item: ListItem): string => {
  if (item.type === 'new') {
    return item.data.name
  }
  if (typeof item.data === 'string') {
    return item.data.split('/').pop() || ''
  }
  return item.data.title
}
const removeDashes = (input: string): string => input.replace(/-/g, ' ').replace(/\s+/g, ' ')

const downloadNewItem = (event: MouseEvent, item: ListItem) => {
  if (item.type !== 'new') {
    return
  }
  event.preventDefault()
  window.open(URL.createObjectURL(item.data))
}

const sortedItems = computed<ListItem[]>(() =>
  [...props.items].sort((a, b) => getName(a).localeCompare(getName(b)))
)
</script>

<style lang="scss" scoped>
.files {
  .list {
    .list-item {
      display: flex;
      align-items: stretch;

      button {
        opacity: 0;
        transition: opacity 0.3s;
      }

      .label {
        flex-grow: 1;
        display: flex;
        gap: 12px;
        background-color: map-get($theme-color-neutral, 'white');
        color: map-get($theme-color-primary, 'dark-blue');
        border: 1px solid map-get($theme-color-neutral, 'dark-blue');
        border-bottom: none;
        padding: 10px 12px;
        text-decoration: none;

        .text {
          flex-grow: 1;
        }
      }

      &:last-child .label {
        border-bottom: 1px solid map-get($theme-color-neutral, 'dark-blue');
      }

      &:hover {
        .label {
          color: white;
          background-color: map-get($theme-color-primary, 'dark-blue');
          cursor: pointer;

          :deep(path) {
            stroke: map-get($theme-color-neutral, 'white');
          }
        }
      }
      &:hover {
        button {
          opacity: 1;
        }
      }
    }
  }

  button {
    border: 0;
    background-color: transparent;
  }
}
</style>
