<template>
  <div>
    <h2 v-if="!isOnboarding" class="mb-4">
      {{ t('organisation.title') }}
      <button class="button-primary" :disabled="!v$.$anyDirty || v$.$invalid" @click="onSubmit">
        {{ t('app.button.save') }}
      </button>
    </h2>
    <skeleton v-if="loading" />
    <template v-else>
      <reactive-form v-model:form="organisationRecord" :config="companyFormConfig" />
      <reactive-form v-model:form="organisationRecord" :config="addressFormConfig" />
      <template v-if="!isOnboarding">
        <p v-safe-html="t('organisation.deleteAccount')" />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, watch, onBeforeMount, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import useOnboarding from '@/features/onboarding/compositions/useOnboarding'
import { useAlertStore } from '@/support/components/alert/store'
import { useOrganisationStore } from '@/features/organisation/store'
import { Status } from '@/support/enums/Status'
import { DEFAULT_ORGANISATION, type UpdateOrganisation } from '@/features/organisation/types'
import type { Alert } from '@/support/components/alert/types'

const { t } = useI18n()
const v$ = useVuelidate()
const emit = defineEmits(['update'])

const alertStore = useAlertStore()
const organisationStore = useOrganisationStore()
const { organisation } = storeToRefs(organisationStore)
const { isOnboarding } = useOnboarding()

const loading = ref(false)

const companyFormConfig = computed<ReactiveFormConfig>(() => ({
  buttons: null,
  legend: {
    id: 'companyLegend',
    text: t('organisation.companyForm.legend')
  },
  fields: [
    {
      id: 'name',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.companyForm.name'),
      validationRules: { required: true }
    },
    {
      id: 'postalPostCode',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.companyForm.postalPostCode'),
      validationRules: { required: true, zipCode: true }
    },
    {
      id: 'postalAddress',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.companyForm.postalAddress'),
      validationRules: { required: true }
    },
    {
      id: 'postalCity',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.companyForm.postalCity'),
      validationRules: { required: true }
    }
  ]
}))

const addressFormConfig = computed<ReactiveFormConfig>(() => ({
  buttons: null,
  legend: {
    id: 'invoiceLegend',
    text: t('organisation.invoiceForm.legend')
  },
  fields: [
    {
      id: 'invoiceEmail',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.invoiceEmail'),
      validationRules: { required: true, email: true }
    },
    {
      id: 'addressHeading',
      type: FieldType.HEADING,
      label: t('organisation.invoiceForm.addressHeading')
    },
    {
      id: 'invoiceAddress',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.invoiceAddress')
    },
    {
      id: 'invoicePostcode',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.invoicePostcode')
    },
    {
      id: 'invoiceCity',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.invoiceCity')
    },
    {
      id: 'contactPersonHeading',
      type: FieldType.HEADING,
      label: t('organisation.invoiceForm.contactPersonHeading')
    },
    {
      id: 'contactPersonInvoiceFirstName',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.contactPersonInvoiceFirstName'),
      validationRules: { required: true }
    },
    {
      id: 'contactPersonInvoiceNamePrefix',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.contactPersonInvoiceNamePrefix')
    },
    {
      id: 'contactPersonInvoiceLastName',
      type: FieldType.INPUT_TEXT,
      label: t('organisation.invoiceForm.contactPersonInvoiceLastName'),
      validationRules: { required: true }
    }
  ]
}))

const organisationRecord = reactive<UpdateOrganisation>({ ...DEFAULT_ORGANISATION })

watch(
  organisationRecord,
  (organisationRecord: UpdateOrganisation) => {
    emit('update', organisationRecord)
  },
  { deep: true }
)

const onSubmit = async () => {
  const response = await organisationStore.updateOrganisation(organisationRecord)
  const alert: Alert =
    response?.status !== 200
      ? { message: t('app.xhr.put.error', { status: response?.status }), type: Status.error }
      : { message: t('alert.organisation'), type: Status.success }

  alertStore.setMessage(alert)

  if (response?.status === 200) {
    v$.value.$reset()
  }
}

onBeforeMount(async () => {
  loading.value = true
  await organisationStore.getOrganisation()

  if (organisation.value) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isActive, countLocations, id, ...data } = organisation.value
    Object.assign(organisationRecord, data)
  }

  loading.value = false
})
</script>

<style lang="scss" scoped>
h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttons {
  display: flex;

  a[role='button'] {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: $theme-font-size-m;

    &:first-child {
      margin-left: 0;
    }

    .icon {
      margin-right: 5px;
    }
  }
}
:deep(.reactive-form) {
  fieldset.reactive-form__fieldset {
    .reactive-form__field {
      &.contact-person-invoice-first-name-field,
      &.contact-person-invoice-name-prefix-field,
      &.contact-person-invoice-last-name-field {
        min-width: 0;
        flex: 0 0 31.5%;
      }
      &.contact-person-invoice-first-name-field,
      &.contact-person-invoice-name-prefix-field {
        margin-right: 10px;
      }
    }
  }
}
</style>
