export enum FieldType {
  HEADING = 'heading',
  INPUT_CHECKBOX = 'input-checkbox',
  INPUT_TEXT = 'input-text',
  DATE_PICKER = 'date-picker',
  RADIO = 'radio',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  TOGGLE = 'toggle'
}
