import { ref } from 'vue'
import { defineStore } from 'pinia'

import { useApi } from '@/features/api/api'

import type { Permission, Role, RoleFormData } from '@/features/roles/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type { AxiosResponse } from 'axios'

export const useRolesStore = defineStore('roles', () => {
  const Api = useApi()
  const permissions = ref<Array<Permission>>([])
  const roles = ref<Array<Role>>([])
  const paginatedRoles = ref<PaginatedApiResponse<Role>>({
    items: [],
    pageIndex: 1,
    totalPages: 1
  })

  const getPermissions = async () => {
    const response: AxiosResponse<Permission[]> | undefined = await Api.roles.getPermissions()
    const { data = [] } = response

    permissions.value = data

    return response
  }

  const getPaginatedRoles = async () => {
    const { pageIndex } = paginatedRoles.value
    const response = await Api.roles.getRoles(pageIndex)
    if (response.status !== 200) {
      return
    }
    const { items = [], totalPages = 1, totalCount } = response?.data || {}

    Object.assign(paginatedRoles.value, {
      items,
      pageIndex,
      totalPages,
      totalCount
    })

    return response
  }

  const getAllRoles = async () => {
    const response = await Api.roles.getAllRoles()
    roles.value = response.data

    return response
  }

  const createRole = async (role: RoleFormData) => {
    const response: AxiosResponse<Role> = await Api.roles.createRole(role)
    return response
  }

  const updateRole = async (id: Guid, role: RoleFormData) => {
    const response: AxiosResponse<Role> = await Api.roles.updateRole(id, role)
    return response
  }

  const deleteRole = async (id: Guid) => {
    const response: AxiosResponse<any> = await Api.roles.deleteRole(id)
    return response
  }

  const setPaging = (pageIndex: number) => {
    Object.assign(paginatedRoles.value, {
      pageIndex
    })
  }

  return {
    permissions,
    roles,
    paginatedRoles,
    getPermissions,
    getPaginatedRoles,
    getAllRoles,
    createRole,
    updateRole,
    deleteRole,
    setPaging
  }
})
