<template>
  <modal-default
    :id="modalConfig.id"
    :title="modalConfigTitle"
    :button="modalConfig.button"
    :height="modalConfig.height"
    @confirm="onConfirm"
    @cancel="onClose"
    @close="onClose"
    @hidden="onHidden"
  >
    <template #default>
      <div>
        <reactive-form v-model:form="form" :config="formConfig" class-name="secondary mb-3" />

        <div class="mb-4">
          <div class="d-flex">
            <reactive-form-field-label field-id="search">
              {{ t('user.overview.listView.header.locations') }}
              <span v-if="locationsCount" class="count ms-1">{{ locationsCount }}</span>
            </reactive-form-field-label>
            <div class="ms-auto">
              <reactive-form-field-toggle
                v-model="form.hasAccessToOrganisation"
                :field="hasAccessToOrgField"
              />
            </div>
          </div>
          <search-input
            v-if="!form.hasAccessToOrganisation"
            :min-input-length="0"
            :items="locationsData"
            :item-projection="searchMapFn"
            @select-item="onSelectLocation"
          />
        </div>

        <list-view
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewButtons"
          :hide-header="true"
          @event="onListViewRowClick"
        />
      </div>
    </template>
  </modal-default>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, reactive, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import { useRolesStore } from '@/features/roles/store'
import { useLocationsStore } from '@/features/locations/store'
import { useUserStore } from '@/features/user/store'

import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'

import type { InviteUser, UpdateUser, User } from '@/features/user/types'
import type { Location } from '@/features/locations/types'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'
import type {
  ReactiveFormFieldInputText,
  ReactiveFormFieldToggle
} from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import type { Guid } from '@/support/types/Guid.dto'
import { createGuid } from '@/support/helpers/guid'
import type { ModalDefault } from '@/support/components/modal/default/types/ModalDefault.dto'

const { t } = useI18n()
const emit = defineEmits(['confirm', 'close', 'hidden'])

export type UserForm =
  | (UpdateUser & { type: 'update'; email?: string | null })
  | (InviteUser & { type: 'invite' })

interface Props {
  form: UserForm
  user?: User
}

const usersStore = useUserStore()
const rolesStore = useRolesStore()
const locationsStore = useLocationsStore()

const { roles } = storeToRefs(rolesStore)
const { locations } = storeToRefs(locationsStore)

const props = defineProps<Props>()
const form = reactive<UserForm>(props.form)

const modalId = createGuid()
const modalConfig = computed<ModalDefault>(() => ({
  id: modalId,
  title: t('user.overview.modal.user.title'),
  height: 690,
  button: {
    cancel: {
      text: t('app.button.cancel')
    },
    confirm: {
      text: props.form.type === 'invite' ? t('app.button.add') : t('app.button.save')
    }
  }
}))

const modalConfigTitle = computed(() => {
  return props.form.type === 'update'
    ? t('user.overview.modal.user.title')
    : t('user.overview.modal.userInvite.title')
})

const searchMapFn = (item: Location) => item.name

const onSelectLocation = (location: Location) => {
  if (!form.locationIds) {
    form.locationIds = []
  }

  form.locationIds.push(location.id)
}

const locationsSelected = computed(() => {
  return form.locationIds ?? []
})

const locationsCount = computed(() => {
  return locationsSelected.value.length >= 1 ? `(${locationsSelected.value.length})` : null
})

const locationsData = computed(() =>
  locations.value.filter((location) => !locationsSelected.value.includes(location.id))
)

const listViewHeader = [{ title: t('locations.listView.header.name'), property: 'name' }]

const listViewButtons = [ListViewButton.Delete]

const listViewRecords = computed(() => {
  return locations.value
    .filter((location) => locationsSelected.value.some((id) => id === location.id))
    .map((location) => ({
      id: location.id,
      name: location.name
    }))
})

const onListViewRowClick = (event: any) => {
  const { action, id } = event

  if (action === ListViewButton.Delete && id) {
    const index = locationsSelected.value.findIndex((locationId) => locationId === id)
    form.locationIds?.splice(index, 1)
  }
}

const onConfirm = () => {
  emit('confirm', form)
}

const onClose = () => {
  emit('close')
}

const onHidden = () => {
  emit('hidden')
}

const hasAccessToOrganisation = computed(() => form.hasAccessToOrganisation)
const formConfig = computed<ReactiveFormConfig>(() => ({
  fields: [
    {
      id: 'firstName',
      type: FieldType.INPUT_TEXT,
      label: t('app.defaults.firstName'),
      placeholder: '-',
      readonly: true,
      hidden: props.form.type === 'invite'
    },
    {
      id: 'namePrefix',
      type: FieldType.INPUT_TEXT,
      label: t('app.defaults.namePrefix'),
      placeholder: '-',
      readonly: true,
      hidden: props.form.type === 'invite'
    },
    {
      id: 'lastName',
      type: FieldType.INPUT_TEXT,
      label: t('app.defaults.lastName'),
      placeholder: '-',
      readonly: true,
      hidden: props.form.type === 'invite'
    },
    {
      id: 'email',
      type: FieldType.INPUT_TEXT,
      label: t('app.defaults.email'),
      readonly: props.form.type === 'update',
      validationRules: {
        required: true,
        email: true,
        custom: (value: string | undefined) => {
          if (props.form.type === 'update') {
            return true
          }
          if (
            value &&
            usersStore.users?.some(
              (user) => !user.isDeleted && user.email?.toLowerCase() === value!.trim().toLowerCase()
            )
          ) {
            return false
          }
          return true
        }
      },
      validationMessages: {
        custom: t('user.overview.modal.user.form.userExistsInOrganisationErrorMessage')
      }
    } satisfies ReactiveFormFieldInputText,
    {
      id: 'roleId',
      type: FieldType.SELECT,
      label: t('user.overview.modal.user.form.role'),
      options: rolesStore.roles.map((role) => ({ value: role.id, label: role.name })),
      validationRules: {
        required: true,
        custom: (value: Guid | undefined) => {
          if (isLastUserOfRoleThatMustRemain.value && value !== props.user?.role?.id) {
            return false
          }
          return true
        }
      },
      validationMessages: {
        custom: t('user.overview.modal.user.form.lastUserOfRoleError', {
          role: props.user?.role?.name
        })
      }
    }
  ]
}))
const hasAccessToOrgField = computed<ReactiveFormFieldToggle>(() => ({
  id: 'hasAccessToOrganisation',
  type: FieldType.TOGGLE,
  size: 'small',
  hideLabel: true,
  options: [{ value: true, label: t('user.hasAccessToOrganisationLabel') }]
}))
const isLastUserOfRoleThatMustRemain = computed<boolean>(() => {
  if (!props.user || !props.user.role?.mustBeAtLeastOne) {
    return false
  }
  const otherUsersWithSameRole = usersStore.users?.filter(
    (user) =>
      !user.isDeleted &&
      user.hasSsoId &&
      user.role?.id === props.user!.role?.id &&
      user.id !== props.user!.id
  )
  if (!otherUsersWithSameRole?.length) {
    return true
  }
  return false
})

watch(hasAccessToOrganisation, (value) => {
  if (value) {
    form.locationIds = []
  }
})

onBeforeMount(() => {
  const promises = []

  if (!roles.value.length) {
    promises.push(rolesStore.getAllRoles())
  }

  if (!locations.value.length) {
    promises.push(locationsStore.getAllLocations())
  }
})
</script>

<style lang="scss" scoped>
:deep(label[for='search']) {
  font-size: map-get($theme-heading-4, 'font-size');
  font-weight: map-get($theme-heading-4, 'font-weight');
  color: map-get($theme-heading-4, 'color');
  margin-bottom: 10px;

  span.count {
    color: map-get($theme-color-primary, 'light-blue-1');
  }
}
:deep(label[for='hasAccessToOrganisation']) {
  & + .custom-toggle input[type='checkbox'] ~ span {
    color: map-get($theme-color-neutral, 'grey-3');
  }
  & + .custom-toggle input[type='checkbox']:checked ~ span {
    color: map-get($theme-color-primary, 'dark-blue');
  }
}
:deep(.reactive-form) {
  fieldset.reactive-form__fieldset {
    .reactive-form__field {
      &.first-name-field,
      &.name-prefix-field,
      &.last-name-field {
        min-width: 0;
        flex: 0 0 33%;
      }
      &.first-name-field {
        padding-right: 5px;
      }
      &.name-prefix-field {
        padding: 0 5px;
      }
      &.last-name-field {
        padding-left: 5px;
      }
    }
  }
}
:deep(.list-view) {
  margin-right: 40px;
}
</style>
