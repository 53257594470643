import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/grote-risicos',
    name: 'new-cycle.big-risks',
    component: () => import('@/app/page/NewCycleBigRisks.vue'),
    meta: {
      title: "Grote Risico's - Nieuwe cyclus inrichten",
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/kleine-risicos',
    name: 'new-cycle.small-risks',
    component: () => import('@/app/page/NewCycleSmallRisks.vue'),
    meta: {
      title: "Grote Risico's - Nieuwe cyclus inrichten",
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/themas-inrichten',
    name: 'new-cycle.topics',
    component: () => import('@/app/page/NewCycleTopics.vue'),
    meta: {
      title: "Thema's - Nieuwe cyclus inrichten",
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/beleid',
    name: 'new-cycle.policy',
    component: () => import('@/app/page/NewCyclePolicy.vue'),
    meta: {
      title: "Thema's - Nieuwe cyclus inrichten",
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/checklist/:checklistId',
    name: 'new-cycle.checklist',
    component: () => import('@/app/page/NewCycleChecklist.vue'),
    meta: { requiresOrganisationAccess: true }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/checklist/:checklistId/subject/:subjectId',
    name: 'new-cycle.checklist-subject',
    component: () => import('@/app/page/NewCycleChecklist.vue'),
    meta: { requiresOrganisationAccess: true }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/planning-maken',
    name: 'new-cycle.planning',
    component: () => import('@/app/page/NewCyclePlanning.vue'),
    meta: {
      title: 'Planning - Nieuwe cyclus inrichten',
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/workform/:workformId',
    name: 'new-cycle.workform',
    component: () => import('@/app/page/NewCycleWorkform.vue'),
    meta: { requiresOrganisationAccess: true }
  },
  {
    path: '/dashboard/nieuwe-cyclus-inrichten/doorzetten-naar-locaties',
    name: 'new-cycle.push-to-locations',
    component: () => import('@/app/page/NewCyclePushToLocations.vue'),
    meta: {
      title: 'Doorzetten naar locaties - Nieuwe cyclus inrichten',
      requiresOrganisationAccess: true
    }
  }
] satisfies RouteRecordRaw[]
