import i18n from '@/support/core/localization.js'
import { computed } from 'vue'

const { t } = i18n.global

export default [
  {
    path: '/',
    name: 'index',
    meta: { requiresAuth: false },
    component: () => import('@/app/page/Index.vue')
  },
  {
    path: '/cookiebeleid',
    name: 'cookie-policy',
    meta: {
      requiresAuth: false,
      title: computed<string>(() => t('app.cookiePolicy.title')),
      content: computed<string>(() => t('app.cookiePolicy.content'))
    },
    component: () => import('@/app/page/CookiePolicy.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    meta: {
      title: computed<string>(() => t('app.pageNotFound.title')),
      content: computed<string>(() => t('app.pageNotFound.content')),
      requiresAuth: false
    },
    component: () => import('@/app/page/ContentPage.vue')
  }
]
