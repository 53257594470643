import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { AxiosResponse } from 'axios'
import type {
  PolicyChapter,
  CreateBigRiskForLocation,
  CreateBigRiskForOrganisation,
  UpdateChapterRisk,
  CreateChapter,
  Policy,
  PolicyVersion,
  PolicyTopic,
  UpdateChapterForLocation,
  UpdateChapterForOrganisation,
  PolicyFeedback,
  UpdateChapterFeedback,
  UpdatePolicyTopicForOrganisation,
  PolicyChapterFile,
  UpdatePolicyFile,
  UpdatePolicyTopicForLocation,
  ChapterRisk
} from '@/features/policy/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { OkResult } from '@/support/types/OkResult'

export const getPolicyDraft = async (locationPath: string) => {
  const request = async (method: 'GET' | 'POST') =>
    (await fetchWithAuth(`/policies/draft/${locationPath}`, {
      method
    })) as AxiosResponse<Policy>
  const response = await request('GET')
  if (response.status !== 404) {
    return response
  }
  return await request('POST')
}

export const getPolicyVersionList = async (locationPath: string) => {
  return (await fetchWithAuth(`/policies/versions/${locationPath}`)) as AxiosResponse<
    PolicyVersion[]
  >
}

export const getPolicy = async (locationPath: string, versionId?: Guid) => {
  return (await fetchWithAuth(
    `/policies/${versionId ? `versions/${versionId}/` : ''}${locationPath}`,
    {
      method: 'GET'
    }
  )) as AxiosResponse<Policy>
}

export const publishPolicy = async (options: {
  locationPath: string
  sendEmail: boolean
  emailMessage?: string | null
}) => {
  const { locationPath, ...data } = options
  return (await fetchWithAuth(`/policies/publish/${locationPath}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data
  })) as AxiosResponse<Policy>
}

export const deletePolicyDraft = async () => {
  return (await fetchWithAuth(`/policies/delete/draft`, {
    method: 'DELETE'
  })) as AxiosResponse<OkResult>
}

export const createChapter = async (locationPath: string, data: CreateChapter) => {
  return (await fetchWithAuth(`/policies/chapters/${locationPath}`, {
    method: 'POST',
    data
  })) as AxiosResponse<PolicyChapter>
}

export const updateChapter = async ({
  locationPath,
  chapterId,
  data
}: {
  locationPath: string
  chapterId: Guid
  data: Partial<UpdateChapterForLocation | UpdateChapterForOrganisation>
}) => {
  return (await fetchWithAuth(`/policies/chapters/${chapterId}/${locationPath}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<PolicyChapter>
}

export const deleteChapter = async (locationPath: string, chapterId: Guid) => {
  return (await fetchWithAuth(`/policies/chapters/${chapterId}/${locationPath}`, {
    method: 'DELETE'
  })) as AxiosResponse<PolicyChapter>
}

export const rollbackChapter = async (locationPath: string, chapterId: Guid) => {
  return (await fetchWithAuth(`/policies/chapters/${chapterId}/rollback/${locationPath}`, {
    method: 'PUT'
  })) as AxiosResponse<PolicyChapter>
}

export const updateTopic = async ({
  topicId,
  locationPath,
  data
}: {
  locationPath: string
  topicId: Guid
  data: Partial<UpdatePolicyTopicForOrganisation> | Partial<UpdatePolicyTopicForLocation>
}) => {
  return (await fetchWithAuth(`/policies/chapters/topics/${topicId}/${locationPath}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<PolicyTopic>
}

export const rollbackTopic = async (locationPath: string, topicId: Guid) => {
  return (await fetchWithAuth(`/policies/chapters/topics/${topicId}/rollback/${locationPath}`, {
    method: 'PUT'
  })) as AxiosResponse<PolicyTopic>
}

export const addChapterFile = async (options: {
  locationPath: string
  chapterId: Guid
  url: string
  title: string
}) => {
  return (await fetchWithAuth(
    `/policies/chapters/${options.chapterId}/files/${options.locationPath}`,
    {
      method: 'POST',
      data: { url: options.url, title: options.title }
    }
  )) as AxiosResponse<PolicyChapterFile>
}

export const updateChapterFile = async (fileId: Guid, data: UpdatePolicyFile) => {
  return await fetchWithAuth(`/policies/chapters/files/${fileId}`, {
    method: 'PATCH',
    data: data
  })
}

export const deleteChapterFile = async (fileId: Guid, locationPath: string) => {
  return await fetchWithAuth(`/policies/chapters/files/${fileId}/${locationPath}`, {
    method: 'DELETE'
  })
}

export const rollbackDeletedChapterFile = async (fileId: Guid, locationPath: string) => {
  return await fetchWithAuth(`/policies/chapters/files/${fileId}/rollback/delete/${locationPath}`, {
    method: 'PUT'
  })
}

export const addTopicFile = async (options: {
  locationPath: string
  topicId: Guid
  url: string
  title: string
}) => {
  const { topicId, locationPath, ...data } = options
  return (await fetchWithAuth(`/policies/chapters/topics/${topicId}/files/${locationPath}`, {
    method: 'POST',
    data
  })) as AxiosResponse<PolicyChapterFile>
}

export const updateTopicFile = async (fileId: Guid, data: UpdatePolicyFile) => {
  return await fetchWithAuth(`/policies/chapters/topics/files/${fileId}`, {
    method: 'PATCH',
    data: data
  })
}

export const deleteTopicFile = async (fileId: Guid, locationPath: string) => {
  return await fetchWithAuth(`/policies/chapters/topics/files/${fileId}/${locationPath}`, {
    method: 'DELETE'
  })
}

export const rollbackDeletedTopicFile = async (fileId: Guid, locationPath: string) => {
  return await fetchWithAuth(
    `/policies/chapters/topics/files/${fileId}/rollback/delete/${locationPath}`,
    {
      method: 'PUT'
    }
  )
}

export const createRisk = async (
  locationPath: string,
  chapterId: Guid,
  data: CreateBigRiskForOrganisation | CreateBigRiskForLocation
) => {
  return (await fetchWithAuth(`/policies/chapters/${chapterId}/risks/${locationPath}`, {
    method: 'POST',
    data
  })) as AxiosResponse<ChapterRisk>
}

export const updateRisk = async (
  locationPath: string,
  riskId: Guid,
  data: Partial<UpdateChapterRisk>
) => {
  return (await fetchWithAuth(`/policies/chapters/risks/${riskId}/${locationPath}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<ChapterRisk>
}

export const deleteRisk = async (locationPath: string, riskId: Guid) => {
  return (await fetchWithAuth(`/policies/chapters/risks/${riskId}/${locationPath}`, {
    method: 'DELETE'
  })) as AxiosResponse<PolicyTopic>
}

export const addChapterFeedback = async ({
  locationPath,
  chapterId,
  text
}: {
  locationPath: string
  chapterId: Guid
  text: string
}) => {
  return (await fetchWithAuth(`/policies/chapters/${chapterId}/feedbacks/${locationPath}`, {
    method: 'POST',
    data: { text }
  })) as AxiosResponse<PolicyFeedback>
}

export const addTopicFeedback = async ({
  locationPath,
  topicId,
  text
}: {
  locationPath: string
  topicId: Guid
  text: string
}) => {
  return (await fetchWithAuth(`/policies/chapters/topics/${topicId}/feedbacks/${locationPath}`, {
    method: 'POST',
    data: { text }
  })) as AxiosResponse<PolicyFeedback>
}

export const updateFeedback = async ({
  locationPath,
  feedbackId,
  data,
  type
}: {
  locationPath: string
  feedbackId: Guid
  data: UpdateChapterFeedback
  type: 'chapter' | 'topic'
}) => {
  if (type === 'topic') {
    return (await fetchWithAuth(
      `/policies/chapters/topics/feedbacks/${feedbackId}/${locationPath}`,
      {
        method: 'PUT',
        data
      }
    )) as AxiosResponse<PolicyFeedback>
  }
  return (await fetchWithAuth(`/policies/chapters/feedbacks/${feedbackId}/${locationPath}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<PolicyFeedback>
}

export const updatePartialChapterFeedback = async ({
  locationPath,
  feedbackId,
  data
}: {
  locationPath: string
  feedbackId: Guid
  data: Partial<UpdateChapterFeedback>
}) => {
  return (await fetchWithAuth(`/policies/chapters/feedbacks/${feedbackId}/${locationPath}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<PolicyFeedback>
}

export const updatePartialTopicFeedback = async ({
  locationPath,
  feedbackId,
  data
}: {
  locationPath: string
  feedbackId: Guid
  data: Partial<UpdateChapterFeedback>
}) => {
  return (await fetchWithAuth(`/policies/chapters/topics/feedbacks/${feedbackId}/${locationPath}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<PolicyFeedback>
}

export const deleteChapterFeedback = async ({
  locationPath,
  feedbackId
}: {
  locationPath: string
  feedbackId: Guid
}) => {
  return (await fetchWithAuth(`/policies/chapters/feedbacks/${feedbackId}/${locationPath}`, {
    method: 'DELETE'
  })) as AxiosResponse<OkResult>
}

export const deleteTopicFeedback = async ({
  locationPath,
  feedbackId
}: {
  locationPath: string
  feedbackId: Guid
}) => {
  return (await fetchWithAuth(`/policies/chapters/topics/feedbacks/${feedbackId}/${locationPath}`, {
    method: 'DELETE'
  })) as AxiosResponse<OkResult>
}
