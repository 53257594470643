import './app/scss/styles.scss'

import { createApp } from 'vue'
import Vue3Toastify from 'vue3-toastify'
import { cookieBot } from '@ambitiondev/vue-cookiebot'
import i18n from '@/support/core/localization'
import store from '@/support/core/store'
import { createGtmInstance } from '@/support/core/gtm'
import { HtmlPlugin } from '@/support/core/html-plugin'
import { init as initSentry } from '@sentry/vue'

import './app/localization'
import components from './app/components'
import App from './app/App.vue'
import router from './app/http/router'

const gtm = createGtmInstance(router)

const app = createApp(App)
Object.entries(components).forEach(([key, component]) => app.component(key, component))

initSentry({
  app,
  dsn: 'https://1cce02f18c2273763dcf40ccc3e6f764@o281606.ingest.us.sentry.io/4509128748302337'
})

const sentryWindow = window as any
sentryWindow.testSentry = () => {
  sentryWindow.undefinedSentryTestFunction()
}

app
  .use(router)
  .use(gtm)
  .use(i18n)
  .use(store)
  .use(HtmlPlugin)
  .use(Vue3Toastify, { clearOnUrlChange: false })
  .use(cookieBot, {
    cookieBotId: import.meta.env.VITE_APP_COOKIEBOT_ID ?? ''
  })

app.mount('#app')
