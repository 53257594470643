<template>
  <div>
    <div class="empty-state">
      <div class="empty-state__body">
        <div v-if="showTop" class="top">
          <slot name="top" />
        </div>
        <div v-if="iconProps" class="top">
          <icon :name="iconProps.name" :size="iconProps.size" />
        </div>
        <h4>{{ title }}</h4>
        <div v-if="descriptionArray.length" class="description">
          <slot name="description">
            <p
              v-for="(paragraph, index) in descriptionArray"
              :key="index"
              v-safe-html="paragraph"
            />
          </slot>
        </div>
      </div>
      <div v-if="videoId" class="empty-state__video">
        <a role="button" class="link-icon" @click="modalVideoIsOpen = true">
          <icon name="action-play" :size="{ width: 24, height: 24 }" />
          {{ t('emptyState.videoLink') }}
        </a>

        <video-dialog
          v-if="modalVideoIsOpen"
          :modal-props="{ backdrop: 'static', keyboard: false }"
          :title="$t('descriptivePage.video.title')"
          provider="youtube"
          :embed-id="videoId"
          @hidden="modalVideoIsOpen = false"
        />
      </div>
      <div class="empty-state__footer">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './localization'

import { useI18n } from 'vue-i18n'

import type { EmptyState } from './types/EmptyState.dto'
import { computed, ref, useSlots } from 'vue'
import { hasSlotContent } from '@/support/helpers/slots'

const { t } = useI18n()

const props = defineProps<EmptyState>()

const slots = useSlots()
const modalVideoIsOpen = ref(false)
const showTop = computed(() => hasSlotContent(slots, 'top'))

const descriptionArray = computed(() => {
  if (typeof props.description === 'string') {
    return [props.description]
  }

  if (Array.isArray(props.description)) {
    return props.description
  }

  return []
})
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  background-color: map-get($theme-color-secondary, 'beige');
  padding: 60px 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h4 {
      margin: 0;
    }

    .description {
      margin: 20px 0 0 0;
      max-width: 650px;
    }

    .top {
      margin-bottom: 20px;
    }
  }
  &__video {
    margin-top: 20px;
  }
  &__footer {
    margin-top: 20px;

    &:empty {
      display: none;
    }
  }
}
</style>
