<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <div v-for="(option, index) in field.options" :key="index" class="custom-radio">
      <input
        :id="`${field.id}-${index}`"
        v-model="model"
        type="radio"
        :name="field.id"
        :disabled="option.disabled"
        :value="option.value"
      />
      <label :for="`${field.id}-${index}`">
        {{ option.label }}
      </label>
    </div>

    <reactive-form-errors :errors="errors" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useFormField } from '../composables/useFormField'
import type { ReactiveFormFieldRadio } from '../types/ReactiveFormField.dto'
import type { ReactiveFormFieldValue } from '../types/ReactiveFormFieldValue.dto'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'
import type { Validation } from '@vuelidate/core'

interface Props {
  field: ReactiveFormFieldRadio
  vuelidate?: Validation | null
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null
})

const { errors, className, isRequired, modelSetter } = useFormField(props)

const model = defineModel<ReactiveFormFieldValue>({ default: '', set: modelSetter })

onMounted(() => {
  const hasValue = model.value && model.value.length

  if (hasValue) return

  model.value = props.field.options[0].value
})
</script>

<style lang="scss" scoped>
.custom-radio {
  display: inline-block;
  position: relative;

  & + & {
    margin-left: 10px;
  }

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    padding-left: 30px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      width: 24px;
      height: 24px;
      border-radius: 50%;

      background-color: map-get($form-field-background-color, 'idle');
      border: 1px solid map-get($form-field-border-color, 'idle');
    }
    &:after {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      top: 4px;
      left: 4px;
      visibility: hidden;
      background-color: $form-field-checkbox-checked;
    }
  }

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  input[type='radio']:checked + label:before {
    border-color: $form-field-checkbox-checked;
  }

  input[type='radio']:checked + label:after {
    visibility: visible;
  }

  input[type='radio']:disabled + label {
    background-color: map-get($form-field-background-color, 'disabled');
    border-color: map-get($form-field-border-color, 'disabled');

    cursor: not-allowed;
  }

  input[type='radio']:hover + label:before {
    background-color: map-get($form-field-background-color, 'hover');
    border-color: map-get($form-field-border-color, 'hover');
  }

  input[type='radio']:focus + label:before {
    background-color: map-get($form-field-background-color, 'focus');
    border-color: map-get($form-field-border-color, 'focus');

    box-shadow: 0 0 0 5px $form-field-outline-color;
    border-radius: 50%;
  }

  input[type='radio']:focus:checked + label:before {
    border-color: $form-field-checkbox-checked;
  }

  input[type='radio']:focus:checked + label:after {
    background-color: $form-field-checkbox-checked;
    border-color: $form-field-checkbox-checked;
  }
}
</style>
