import type { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/app/page/Onboarding.vue'),
    meta: { requiresOrganisationAccess: true },
    redirect: '/onboarding/start',
    children: [
      {
        path: 'start',
        name: 'onboarding.start',
        component: () => import('@/app/page/OnboardingIndex.vue'),
        meta: {
          requiresOrganisationAccess: true,
          title: 'Start - Onboarding'
        }
      },
      {
        path: 'organisatie',
        name: 'onboarding.organisation',
        component: () => import('@/app/page/OnboardingOrganisation.vue'),
        meta: {
          requiresOrganisationAccess: true,
          title: 'Organisatie - Onboarding'
        }
      },
      {
        path: 'locaties',
        name: 'onboarding.locations',
        component: () => import('@/app/page/OnboardingLocations.vue'),
        meta: {
          requiresOrganisationAccess: true,
          title: 'Locaties - Onboarding'
        }
      },
      {
        path: 'gebruikers',
        name: 'onboarding.users',
        component: () => import('@/app/page/OnboardingUsers.vue'),
        meta: {
          requiresOrganisationAccess: true,
          title: 'Gebruikers - Onboarding'
        }
      },
      {
        path: 'locatie-verantwoordelijken',
        name: 'onboarding.locationManagers',
        component: () => import('@/app/page/OnboardingLocationManagers.vue'),
        meta: {
          requiresOrganisationAccess: true,
          title: 'Locatieverantwoordelijken - Onboarding'
        }
      },
      {
        path: 'finish',
        name: 'onboarding.finish',
        component: () => import('@/app/page/OnboardingFinish.vue'),
        meta: {
          requiresOrganisationAccess: true,
          title: 'Afgerond - Onboarding'
        }
      }
    ]
  }
]

export default routes
