<template>
  <section class="d-print-none">
    <contextual-help @click="openHelpContact">
      <h4>{{ t('helpAndContact.section.title') }}</h4>
      <p>{{ t('helpAndContact.section.body') }}</p>
      <strong class="help-link">{{ t('helpAndContact.section.link') }}</strong>
    </contextual-help>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useHelpContactStore } from '@/features/helpAndContact/store'
import { useRoute } from 'vue-router'
import { pathToHelpPath } from '@/features/helpAndContact/helpers'

const { t } = useI18n()
const helpContactStore = useHelpContactStore()
const route = useRoute()

const openHelpContact = async () => {
  await helpContactStore.openHelpContact(pathToHelpPath(route.path))
}
</script>

<style lang="scss" scoped>
section {
  :deep(.contextual-help) {
    max-width: none;
  }
  .help-link:hover {
    text-decoration: underline;
  }
}
</style>
