<template>
  <modal-default
    :id="modalId"
    :title="title"
    :message="message"
    :modal-props="modalProps"
    :button="button"
    @confirm="onConfirm"
    @cancel="onClose"
    @close="onClose"
    @hidden="onHidden"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Modal } from 'bootstrap'
import { createGuid } from '@/support/helpers/guid'
import i18n from '@/support/core/localization'
import type { ModalDefaultButtons } from '@/support/components/modal/default/types/ModalDefaultButtons.dto'

const emit = defineEmits(['confirm', 'close', 'hidden'])

interface Props {
  title: string
  message?: string | null
  confirmButtonText?: string
  modalProps?: Partial<Modal.Options>
}

const props = defineProps<Props>()

const { t } = i18n.global

const modalId = createGuid()
const button = computed<ModalDefaultButtons>(() => ({
  cancel: { text: t('app.button.cancel') },
  confirm: {
    text: props.confirmButtonText || t('app.button.delete'),
    className: 'button-destructive'
  }
}))

const onConfirm = () => {
  emit('confirm')
}

const onClose = () => {
  emit('close')
}

const onHidden = () => {
  emit('hidden')
}
</script>

<style scoped></style>
