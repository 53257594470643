<template>
  <div class="video-player">
    <div
      ref="videoPlayerEmbedRef"
      class="video-player__embed"
      :data-plyr-provider="provider"
      :data-plyr-embed-id="embedId"
      :data-plyr-auto-play="autoPlay"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

interface VideoPlayerProps {
  provider: string
  embedId: string
  autoPlay?: boolean
}

withDefaults(defineProps<VideoPlayerProps>(), {
  autoPlay: false
})

const videoPlayerEmbedRef = ref<HTMLElement | null>(null)
const player = ref<Plyr | null>(null)

onMounted(() => {
  if (!videoPlayerEmbedRef.value) {
    return
  }

  player.value = new Plyr(videoPlayerEmbedRef.value, {
    controls: ['play', 'progress', 'current-time', 'mute', 'volume']
  })
})

onUnmounted(() => {
  if (player.value) {
    player.value.destroy()
  }
})
</script>

<style lang="scss">
body {
  --plyr-color-main: #f08d17;
}
</style>
