<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index" class="block">
      <button type="button" @click="onButtonOpenClick(block)">
        {{ block }}
      </button>
      <pre v-if="blockOpen === block" class="debug">{{ getComponentProperty(block) }}</pre>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface Props {
  vuelidate: any
  form: any
  config: any
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null,
  form: null,
  config: null
})

const blockOpen = ref<string | null>(null)

const blocks = ['vuelidate', 'form', 'config']

const getComponentProperty = (block: string) => {
  const properties = props as any
  return Object.defineProperty.hasOwnProperty.call(props, block) ? properties[block] : null
}

const onButtonOpenClick = (block: string) => {
  blockOpen.value = blockOpen.value === block ? null : block
}
</script>

<style lang="scss" scoped>
.block {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 0 5px 0;
  font-size: $theme-font-size-xs;

  button,
  pre {
    margin: 0;
    padding: 0;
  }
  button {
    flex-grow: 1;
    border: 0;
    background: map-get($theme-color-neutral, 'grey-4');
    padding: 10px;
  }
  pre {
    background: map-get($theme-color-neutral, 'grey-3');
  }
}
</style>
