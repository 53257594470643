import type { BaseOptions } from 'flatpickr/dist/types/options'

export enum DatePickerMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  RANGE = 'range'
}

export enum MonthSelectorType {
  STATIC = 'static',
  DROPDOWN = 'dropdown'
}

type AllowedDatePickerConfigKey =
  | 'allowInput'
  | 'locale'
  | 'disableMobile'
  | 'wrap'
  | 'dateFormat'
  | 'mode'
  | 'enableTime'
  | 'enableSeconds'
  | 'monthSelectorType'
  | 'minDate'
  | 'maxDate'

export type DatePickerConfig = Partial<Pick<BaseOptions, AllowedDatePickerConfigKey>>
