<template>
  <span
    class="badge"
    :class="[`badge--${props.type}`, { 'badge--icon': computedIcon, rounded: rounded }]"
  >
    <icon v-if="computedIcon" :name="computedIcon.name" :size="computedIcon.size" />
    <slot />
  </span>
</template>

<script setup lang="ts">
import { Status } from '@/support/enums/Status'
import { computed } from 'vue'

import type { Badge } from './types'

const props = withDefaults(defineProps<Badge>(), {
  type: Status.neutral,
  iconProps: null,
  rounded: false
})

const computedIcon = computed(() => {
  return props.iconProps
    ? {
        ...props.iconProps,
        size: { width: 24, height: 24 }
      }
    : null
})
</script>

<style lang="scss" scoped>
.badge {
  display: inline-flex;
  align-items: center;
  min-height: 24px;

  padding: 0 7px;
  border-radius: 5px;
  font-size: $theme-font-size-s;
  font-weight: 500;
  color: white;

  &--icon {
    padding: 0 7px 0 28px;
    position: relative;

    :deep(.icon) {
      position: absolute;
      margin: 0 0 0 4px;
      left: 0;
    }
  }

  &.rounded {
    min-height: 28px;
    padding: 0 14px;
    border-radius: 14px;
  }
  &--mintgreen {
    background-color: map-get($theme-color-secondary, 'mintgreen');
  }
  &--grey {
    background-color: map-get($theme-color-neutral, 'grey-2');
  }
  &--neutral {
    background-color: map-get($theme-color-primary, 'light-blue-2');
    color: map-get($theme-color-primary, 'dark-blue');
  }
  &--info {
    background-color: map-get($theme-color-status, 'info');
  }
  &--success {
    background-color: map-get($theme-color-status, 'success');
  }
  &--warning {
    background-color: map-get($theme-color-status, 'warning');
  }
  &--error {
    background-color: map-get($theme-color-status, 'error');
  }
}
</style>
