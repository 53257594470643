<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <div v-if="field.readonly" class="field-readonly" :class="{ 'no-value': !model }">
      {{ (model ? model : field.placeholder) || '-' }}
    </div>
    <reactive-form-field-save-wrapper
      v-else
      v-model="model"
      v-model:internal-model="internalModel"
      :field="field"
      class="field-input"
    >
      <input
        :id="field.id"
        v-model="internalModel"
        type="text"
        :name="field.id"
        :disabled="field.disabled"
        :placeholder="field.placeholder ?? ''"
        data-lpignore="true"
        @blur="vuelidate?.$touch()"
      />
      <icon v-if="field.icon && !model.length" :name="field.icon.name" :size="field.icon.size" />
      <icon v-if="field.clearable && model.length" name="action-close" @click="model = ''" />
      <reactive-form-errors :errors="errors" />
    </reactive-form-field-save-wrapper>
  </div>
</template>

<script setup lang="ts">
import { useFormField } from '../composables/useFormField'
import type { ReactiveFormFieldInputText } from '../types/ReactiveFormField.dto'
import type { ReactiveFormFieldValue } from '../types/ReactiveFormFieldValue.dto'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'
import type { Validation } from '@vuelidate/core'
import ReactiveFormFieldSaveWrapper from '@/support/components/reactiveForm/components/reactiveFormFieldSaveWrapper.vue'
import { ref } from 'vue'

interface Props {
  field: ReactiveFormFieldInputText
  vuelidate?: Validation | null
}

const props = defineProps<Props>()

const { errors, className, isRequired, modelSetter } = useFormField(props)

const model = defineModel<ReactiveFormFieldValue>({
  default: '',
  set: modelSetter
})
const internalModel = ref<ReactiveFormFieldValue>()
</script>

<style lang="scss" scoped>
.reactive-form__field {
  position: relative;

  .field-readonly {
    &.no-value {
      opacity: 0.3;
    }
  }

  .field-input {
    position: relative;

    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &.failed {
    .field-label {
      color: map-get($theme-color-status, 'error');
    }
    input[type='text'] {
      &,
      &:focus,
      &:focus:active {
        border-color: map-get($theme-color-status, 'error');
      }
    }
  }

  input[type='text'] {
    padding: 10px;
    border-radius: 4px;
    width: 100%;

    font-size: map-get($form-field-font, 'size');
    color: map-get($form-field-font, 'color');

    background-color: map-get($form-field-background-color, 'idle');
    border: 1px solid map-get($form-field-border-color, 'idle');
    transition: all 0.3s ease;

    &:hover {
      background-color: map-get($form-field-background-color, 'hover');
      border-color: map-get($form-field-border-color, 'hover');
    }

    &:focus,
    &:focus:active {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');

      box-shadow: 0 0 0 5px $form-field-outline-color;
      border-radius: 2px;
    }

    &:disabled {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');
      color: $form-field-font-color-disabled;
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $form-field-placeholder-color;
    }
  }
}
</style>
