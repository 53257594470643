import type { Guid } from '@/support/types/Guid.dto'

export enum PermissionName {
  ActionsChangeLocation = 'Actions.Change.Location', // Mag alle acties binnen de locatie wijzigen en acties aan andere gebruikers toewijzen
  ActionsChangeMine = 'Actions.Change.Mine', // Mag aan mij toegewezen actie bewerken
  ActionsCreate = 'Actions.Create', // Mag een nieuwe actie aanmaken, deze bekijken en bewerken
  ActionsDelete = 'Actions.Delete', // Mag een actie verwijderen
  ActionsViewLocation = 'Actions.View.Location', // Mag alle acties (van de locatie) bekijken
  CyclusActivitiesChangeLocation = 'Cyclus.Activities.Change.Location', // Mag alle activiteiten binnen de locatie wijzigen en activiteiten aan andere gebruikers toewijzen
  CyclusActivitiesChangeMine = 'Cyclus.Activities.Change.Mine', // Mag de aan mij toegekende activiteit bewerken
  CyclusActivitiesViewLocation = 'Cyclus.Activities.View.Location', // Mag alle activiteiten binnen de locatie zien
  CyclusCreateShare = 'Cyclus.Create.Share', // Mag een nieuwe cyclus inrichten en delen
  CyclusEditCurrent = 'Cyclus.Edit.Current', // Mag de huidige cyclus bewerken (bij alleen toegang tot locatie kan je hiermee werkvormen toevoegen en verwijderen)
  CyclusManagerChange = 'Cyclus.Manager.Change', // Mag de verantwoordelijke van de cyclus wijzigen
  NotificationCreateViewEditMine = 'Notification.Create.View.Edit.Mine', // Mag een melding aanmaken, deze bekijken en bewerken
  NotificationDelete = 'Notification.Delete', // Mag een melding verwijderen
  NotificationEdit = 'Notification.Edit', // Mag een melding bewerken
  NotificationViewLocation = 'Notification.View.Location', // Mag alle meldingen (van de locatie) bekijken
  PolicyEdit = 'Policy.Edit', // Mag beleid bewerken en wijzigingen delen
  PolicyFeedback = 'Policy.Feedback', // Mag feedback geven op het beleid
  PolicyPublish = 'Policy.Publish', // Mag beleid delen
  PolicyView = 'Policy.View', // Mag beleid bekijken
  SettingsLocationsManage = 'Settings.Locations.Manage', // Mag locaties beheren (wijzigen, toevoegen en verwijderen)
  SettingsOrganisationManage = 'Settings.Organisation.Manage',
  SettingsRolesManage = 'Settings.Roles.Manage', // Mag organisatiegegevens zien en bewerken
  SettingsUsersManage = 'Settings.Users.Manage' // Mag gebruikers beheren (wijzigen, toevoegen, verwijderen)
}

export enum PermissionCategoryName {
  Actions = 'Actions',
  Policy = 'Policy',
  Cyclus = 'Cyclus',
  Settings = 'Settings',
  Notification = 'Notification'
}

export interface Permission {
  id: Guid
  name: PermissionName
}

export interface Role {
  id: Guid
  name: string
  description: string
  mustBeAtLeastOne: boolean
  isLocked: boolean
  permissions: Array<Permission> | null
}

export interface RoleFormData {
  name: string
  description: string
  permissionIds: Array<Guid> | null
}
