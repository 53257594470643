import axios from 'axios'
import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { AxiosResponse } from 'axios'
import type { Action, UpdateAction, ActionsListFilter } from '@/features/actions/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import Console from '@/support/core/console'

let abortController: AbortController | null = null

export const getAction = async (id: Guid) => {
  return (await fetchWithAuth(`/actions/${id}`, { method: 'GET' })) as AxiosResponse<Action>
}

export const getActions = async (
  options: ActionsListFilter & {
    pageIndex?: number
    pageSize?: number
    locationPath: string
  }
) => {
  const { locationPath, ...params } = options

  return (await fetchWithAuth(`/actions/list/${locationPath}`, {
    method: 'GET',
    params
  })) as AxiosResponse<PaginatedApiResponse<Action>>
}

export const createAction = async (data: UpdateAction) => {
  return (await fetchWithAuth('/actions/create', { method: 'POST', data })) as AxiosResponse<Action>
}

export const updateAction = async (data: Partial<UpdateAction>) => {
  if (abortController) {
    abortController.abort()
    abortController = null
  }

  try {
    abortController = new AbortController()

    return (await fetchWithAuth(`/actions/${data.id}`, {
      method: 'PATCH',
      data,
      signal: abortController.signal
    })) as AxiosResponse<Action>
  } catch (error) {
    if (axios.isCancel(error)) {
      Console.log('Request canceled', error.message)
    } else {
      throw error
    }
  }
}

export const deleteAction = async (id: Guid) => {
  return (await fetchWithAuth(`/actions/${id}`, { method: 'DELETE' })) as AxiosResponse<{
    id: Guid
  }>
}

export const attachNotification = async (data: { actionId: Guid; notificationId: Guid }) => {
  return (await fetchWithAuth(`/actions/attach/notification`, {
    method: 'POST',
    data
  })) as AxiosResponse<Action>
}

export const attachWorkForm = async (data: { actionId: Guid; locationWorkformId: Guid }) => {
  return (await fetchWithAuth(`/actions/attach/workformresult`, {
    method: 'POST',
    data
  })) as AxiosResponse<Action>
}
