import { computed } from 'vue'
import { getVuelidateRules } from '../helpers/getVuelidateRules'
import { setCustomLocaleMessages } from '../helpers/setCustomLocaleMessages'
import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'

export function useReactiveForm(config: ReactiveFormConfig) {
  const { legend } = config
  const validators = computed(() => getVuelidateRules(config.fields))

  const createLocaleMessages = () => {
    const { fields } = config
    fields.forEach(setCustomLocaleMessages)
  }

  const buttons = computed(() => {
    const buttons: object = config.buttons ?? {}

    return Object.entries(buttons).map((button) => {
      return {
        type: button[0],
        ...button[1]
      }
    })
  })

  return {
    legend,
    buttons,
    validators,
    createLocaleMessages
  }
}
