<template>
  <main>
    <spinner size="large" />
  </main>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { type InviteParams, login } from '@/features/auth'
import { useRoute } from 'vue-router'

const route = useRoute()
const query = route.query as InviteParams

onMounted(() => {
  if (query.token) {
    login(query)
    return
  }
  login()
})
</script>

<style lang="css" scoped>
main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
