import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { AxiosResponse } from 'axios'

export const uploadMedia = async (file: File) => {
  const formData = new FormData()
  formData.append('media', file)

  return (await fetchWithAuth(`/media/upload`, {
    method: 'POST',
    data: formData
  })) as AxiosResponse<{ fileName: string }>
}

export const uploadNewMedia = async (newItems: File[] | undefined): Promise<string[]> => {
  const newMediaList: string[] = []
  for (const file of newItems || []) {
    const mediaItem = await uploadMedia(file)
    if (mediaItem.status !== 200) {
      throw new Error(
        `${mediaItem.status} ${mediaItem.statusText} POST media ${file.name} (${file.size})`
      )
    }
    newMediaList.push(mediaItem.data.fileName)
  }
  return newMediaList
}
