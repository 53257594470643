<template>
  <div class="carousel">
    <div class="carousel__heading">
      <button :disabled="currentIndex <= 0" @click="onClickBack">
        <icon name="action-chevron-left" />
      </button>
      <div>
        <strong>{{ currentIndex + 1 }}</strong
        >&nbsp;/&nbsp;{{ slides?.length || 0 }}
      </div>
      <button :disabled="currentIndex + 1 >= (slides?.length || 0)" @click="onClickNext">
        <icon name="action-chevron-right" />
      </button>
    </div>
    <div ref="viewport" class="carousel__viewport">
      <div class="carousel__track" :style="{ left: `${trackLeft}px` }">
        <div
          v-for="(slide, index) in slides"
          :key="slide.id"
          class="carousel__slide"
          @click="model = slide"
        >
          <slot name="slide" :index="index" :slide="slide" :active="index === currentIndex" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="Slide extends { id: Guid | number; [key: string]: unknown }">
import type { Guid } from '@/support/types/Guid.dto'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const model = defineModel<Slide>()

const SLIDE_WIDTH = 228

type Props = {
  slides?: Slide[]
  itemsToShow?: number
}
const props = withDefaults(defineProps<Props>(), { itemsToShow: 3.5 })
defineSlots<{ slide(props: { index: number; slide: Slide; active: boolean }): void }>()

const viewport = ref<HTMLDivElement>()
const viewportWidth = ref(0)

const currentIndex = computed<number>(() =>
  model.value && props.slides ? props.slides.findIndex((slide) => slide.id === model.value?.id) : -1
)

const trackLeft = computed<number>(() => {
  if (!props.slides?.length) {
    return 0
  }
  if (props.slides.length * SLIDE_WIDTH <= viewportWidth.value) {
    return viewportWidth.value / 2 - (props.slides.length * SLIDE_WIDTH) / 2
  }
  return viewportWidth.value / 2 - (currentIndex.value * SLIDE_WIDTH + SLIDE_WIDTH / 2)
})

const onClickBack = () => {
  if (!props.slides?.length || currentIndex.value <= 0) {
    return
  }
  model.value = props.slides[currentIndex.value - 1]
}

const onClickNext = () => {
  if (!props.slides?.length || currentIndex.value >= props.slides.length) {
    return
  }
  model.value = props.slides[currentIndex.value + 1]
}

const resizeObserver = ref<ResizeObserver>()
onMounted(() => {
  viewportWidth.value = viewport.value!.clientWidth
  resizeObserver.value = new ResizeObserver(() => {
    viewportWidth.value = viewport.value!.clientWidth
  })
  resizeObserver.value.observe(viewport.value!)
})

onUnmounted(() => {
  resizeObserver.value?.disconnect()
})

watch(
  () => props.slides,
  () => {
    if (!props.slides?.length && model.value) {
      model.value = undefined
    }
    if (props.slides?.length && !model.value) {
      model.value = props.slides[0]
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.carousel {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    margin-bottom: 30px;
    gap: 30px;

    button {
      appearance: none;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }
  }

  &__viewport {
    position: relative;
    overflow: hidden;
    height: 340px;
  }
  &__track {
    position: absolute;
    display: flex;
    gap: 15px;
    transition: all 0.3s;
  }
  &__slide {
    transition: all 0.3s;
    width: 228px;
    height: 329px;
    cursor: pointer;
  }
}
</style>
