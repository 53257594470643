import Accordion from '@/support/components/accordion/index'
import Alert from '@/support/components/alert/index.vue'
import Badge from '@/support/components/badge/index.vue'
import Banner from '@/support/components/banner/index'
import ButtonIcon from '@/support/components/buttonIcon/index.vue'
import ButtonIconFloating from '@/support/components/buttonIconFloating/index.vue'
import Card from '@/support/components/card/index.vue'
import Carousel from '@/support/components/carousel/index.vue'
import ContextualHelp from '@/support/components/contextualHelp/index.vue'
import CreatedAt from '@/support/components/createdAt/index.vue'
import Dialog from '@/support/components/dialog/index'
import Dropdown from '@/support/components/dropdownMenu/index'
import EditableContent from '@/support/components/editableContent/index.vue'
import EditableContentContainer from '@/support/components/editableContentContainer/index.vue'
import EmptyState from '@/support/components/emptyState/index.vue'
import FileSelector from '@/support/components/fileSelector/index.vue'
import HtmlEditor from '@/support/components/htmlEditor/index.vue'
import Icon from '@/support/components/icon/index.vue'
import ImageGrid from '@/support/components/imageGrid/index.vue'
import ListView from '@/support/components/listView/index'
import ListViewFilters from '@/support/components/listViewFilters/index.vue'
import ListLabel from '@/support/components/listLabel/index.vue'
import MediaImage from '@/features/media/components/mediaImage.vue'
import Modal from '@/support/components/modal/index'
import NestedDollMenu from '@/support/components/nestedDollMenu/index.vue'
import Notification from '@/support/components/notification/index'
import OffCanvas from '@/support/components/offCanvas/index.vue'
import PageHeader from '@/support/components/pageHeader/index.vue'
import Pagination from '@/support/components/pagination/index.vue'
import ParentLink from '@/support/components/parentLink/index.vue'
import ProgressBar from '@/support/components/progressBar/index.vue'
import ReactiveForm from '@/support/components/reactiveForm/index'
import SearchInput from '@/support/components/searchInput/index.vue'
import SectionGradient from './section/sectionGradient.vue'
import Skeleton from '@/support/components/skeleton/index.vue'
import Spinner from '@/support/components/spinner/index'
import StatusBar from '@/support/components/statusBar/index.vue'
import StepProgress from '@/support/components/stepProgress/index'
import SubMenu from '@/support/components/subMenu/index'
import TableView from '@/support/components/tableView/index.vue'
import Tabs from '@/support/components/tabs/index'
import ToggleButton from '@/support/components/toggleButton/index.vue'
import ToggleViewEdit from '@/support/components/toggleViewEdit/index.vue'
import Tooltip from '@/support/components/tooltip/index.vue'
import VideoPlayer from '@/support/components/videoPlayer/index.vue'
import VideoPoster from '@/support/components/videoPoster/index.vue'
import type { Component } from 'vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    Alert: typeof Alert
    Badge: typeof Badge
    ButtonIcon: typeof ButtonIcon
    ButtonIconFloating: typeof ButtonIconFloating
    Card: typeof Card
    Carousel: typeof Carousel
    ContextualHelp: typeof ContextualHelp
    CreatedAt: typeof CreatedAt
    EditableContent: typeof EditableContent
    EditableContentContainer: typeof EditableContentContainer
    EmptyState: typeof EmptyState
    FileSelector: typeof FileSelector
    HtmlEditor: typeof HtmlEditor
    Icon: typeof Icon
    ImageGrid: typeof ImageGrid
    ListViewFilters: typeof ListViewFilters
    ListLabel: typeof ListLabel
    Modal: typeof Modal
    MediaImage: typeof MediaImage
    NestedDollMenu: typeof NestedDollMenu
    OffCanvas: typeof OffCanvas
    PageHeader: typeof PageHeader
    Pagination: typeof Pagination
    ParentLink: typeof ParentLink
    ProgressBar: typeof ProgressBar
    SearchInput: typeof SearchInput
    SectionGradient: typeof SectionGradient
    Skeleton: typeof Skeleton
    StatusBar: typeof StatusBar
    TableView: typeof TableView
    ToggleButton: typeof ToggleButton
    ToggleViewEdit: typeof ToggleViewEdit
    Tooltip: typeof Tooltip
    VideoPlayer: typeof VideoPlayer
    VideoPoster: typeof VideoPoster
  }
}

const components: { [key: string]: Component } = {
  ...Accordion,
  Alert,
  Badge,
  ...Banner,
  ButtonIcon,
  ButtonIconFloating,
  Card,
  Carousel,
  ContextualHelp,
  CreatedAt,
  ...Dialog,
  ...Dropdown,
  EditableContent,
  EditableContentContainer,
  EmptyState,
  FileSelector,
  HtmlEditor,
  Icon,
  ImageGrid,
  ...ListView,
  ListViewFilters,
  ListLabel,
  ...Modal,
  MediaImage,
  NestedDollMenu,
  ...Notification,
  OffCanvas,
  PageHeader,
  Pagination,
  ParentLink,
  ProgressBar,
  ...ReactiveForm,
  SearchInput,
  SectionGradient,
  Skeleton,
  ...Spinner,
  StatusBar,
  ...StepProgress,
  ...SubMenu,
  TableView,
  ...Tabs,
  ToggleButton,
  ToggleViewEdit,
  Tooltip,
  VideoPlayer,
  VideoPoster
}

export default components
