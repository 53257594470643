import VideoDialog from './videoDialog/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    VideoDialog: typeof VideoDialog
  }
}

export default {
  VideoDialog
}
