import i18n from '@/support/core/localization.js'
import DesignSystem from '@/app/page/DesignSystem.vue'
import navigation from '@/features/designSystem/components/designSystemNavigation/constants/navigation'
import type { RouteRecordRaw } from 'vue-router'
import { computed } from 'vue'

const { t } = i18n.global

const children: RouteRecordRaw[] = navigation
  .filter((item) => item.to)
  .map(({ to }) => {
    const path = to?.replace('design-system.', '/design-system/') || ''
    return {
      path,
      name: to,
      component: DesignSystem
    } satisfies RouteRecordRaw
  })

export default [
  {
    path: '/design-system',
    name: 'design-system',
    component: DesignSystem,
    meta: {
      title: computed<string>(() => t('designSystem.title'))
    },
    redirect: children[0].path,
    children
  }
] satisfies RouteRecordRaw[]
