<template>
  <notification-select-type-dialog
    v-if="dialogIsOpen.selectType"
    v-model:form="notificationRecord"
    @confirm="onSelectType"
    @hidden="dialogIsOpen.selectType = false"
  />

  <notification-dialog
    v-if="dialogIsOpen.editNotification"
    ref="notificationDialogRef"
    :form="notificationRecord"
    :notification="selectedNotification"
    :new-files="newFiles"
    :modal-props="{ backdrop: 'static', focus: false, keyboard: false }"
    :saving="saving"
    @confirm="onConfirmEditNotification"
    @event="onEvent"
    @hidden="dialogIsOpen.editNotification = false"
  />

  <add-files-dialog
    v-if="dialogIsOpen.addImages"
    accept="image/*"
    @confirm="onAddImages"
    @hidden="dialogIsOpen.addImages = false"
  />

  <modal-delete
    v-if="dialogIsOpen.deleteImage"
    :title="$t('notifications.dialogs.edit.deleteImage.title')"
    @confirm="onConfirmDeleteImage"
    @hidden="dialogIsOpen.deleteImage = false"
  />

  <notification-add-workers-dialog
    v-if="dialogIsOpen.addWorkers"
    :settings="modalAddWorkersSettings"
    :modal-props="{ backdrop: false, focus: false }"
    :margin-top="200"
    @confirm="onAddWorker"
    @hidden="dialogIsOpen.addWorkers = false"
  />

  <modal-delete
    v-if="dialogIsOpen.removeWorker"
    :title="$t('notifications.dialogs.removeWorker.title')"
    :modal-props="{ backdrop: !dialogIsOpen.editNotification }"
    @confirm="onConfirmRemoveWorker"
    @hidden="dialogIsOpen.removeWorker = false"
  />

  <view-image-dialog
    v-if="dialogIsOpen.viewImage"
    :images="[
      ...(notificationRecord.mediaUrls?.map(
        (image) => ({ type: 'extended', data: image }) satisfies MediaListItem
      ) || []),
      ...(newFiles?.map((image) => ({ type: 'new', data: image }) satisfies MediaListItem) || [])
    ]"
    :current="modalViewImagesSettings.current"
    @hidden="dialogIsOpen.viewImage = false"
  />

  <modal-delete
    v-if="dialogIsOpen.deleteNotification"
    :title="t('notifications.overview.deleteNotification.title', { name: notificationRecord.name })"
    :modal-props="{ backdrop: !dialogIsOpen.editNotification }"
    :confirm-button-text="t('notifications.overview.deleteNotification.confirmButton')"
    @confirm="onConfirmModalDeleteNotification"
    @hidden="dialogIsOpen.deleteNotification = false"
  />
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'

import i18n from '@/support/core/localization'
import { delay } from '@/support/helpers/delay'

import ModalDelete from '@/features/modal/modalDelete/index.vue'

import NotificationDialog from '@/features/notifications/components/notificationDialog/index.vue'
import NotificationAddWorkersDialog from '@/features/notifications/components/notificationAddWorkersDialog/index.vue'
import NotificationSelectTypeDialog from '@/features/notifications/components/notificationSelectTypeDialog/index.vue'

import AddFilesDialog from '@/features/media/components/addFilesDialog.vue'
import ViewImageDialog from '@/features/media/components/viewImageDialog.vue'

import { useAlertStore } from '@/support/components/alert/store'
import { useNotificationsStore } from '@/features/notifications/store'
import { currentContextApiPath } from '@/features/auth'

import { Status } from '@/support/enums/Status'
import type { MediaListItem } from '@/features/media/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { Alert } from '@/support/components/alert/types'
import type { Notification, NotificationFormData } from '@/features/notifications/types'

import { useApi } from '@/features/api/api'
import { DateTime } from 'luxon'
import Console from '@/support/core/console'

const saving = defineModel<boolean>('saving')

const { t } = i18n.global

const alertStore = useAlertStore()
const notificationsStore = useNotificationsStore()
const api = useApi()

const notificationRecordDefaults: NotificationFormData = {
  id: null,
  notificationType: null,
  name: null,
  description: null,
  datetime: null,
  topicId: null,
  removeTopic: false,
  locationId: null,
  isInjured: null,
  parentsAlerted: false,
  workersInvolvedIds: [],
  preventiveSuggestion: null,
  mediaUrls: [],
  createdAt: null,
  createdBy: null
}

const notificationRecord = reactive<NotificationFormData>(
  JSON.parse(JSON.stringify(notificationRecordDefaults))
)
const selectedNotification = ref<Notification>()
const newFiles = reactive<File[]>([])

const notificationDialogRef = ref<InstanceType<typeof NotificationDialog>>()

const dialogIsOpen = reactive({
  editNotification: false,
  deleteNotification: false,
  selectType: false,
  addWorkers: false,
  removeWorker: false,
  addImages: false,
  deleteImage: false,
  viewImage: false
})

const emit = defineEmits(['event'])

const modalAddWorkersSettings = ref<{ users?: Guid[] | null; locationId: Guid }>()
const modalRemoveWorkerSettings = ref<{ id: Guid }>()
const modalDeleteImageSettings = ref<MediaListItem>()
const modalViewImagesSettings = ref<any>({
  files: [],
  current: 0
})

const showModal = (
  action: string,
  id: Guid | null,
  formData: NotificationFormData = { ...notificationRecordDefaults }
) => {
  const record = notificationsStore.paginatedNotifications?.items.find(
    (notification: Notification) => notification.id === id
  )
  selectedNotification.value = record

  const recordForm: NotificationFormData = !record
    ? { ...notificationRecordDefaults, ...formData }
    : {
        ...record,
        workersInvolvedIds: (record.workersInvolved || []).map((user) => user.id),
        topicId: record.topic?.id || null,
        locationId: record.location.id,
        mediaUrls: record.mediaUrls || []
      }

  Object.assign(notificationRecord, recordForm)

  if (action === 'create') {
    dialogIsOpen.selectType = true
    return
  }

  if (action === 'edit' || action === 'view') {
    dialogIsOpen.editNotification = true
    return
  }

  if (action === 'delete') {
    dialogIsOpen.deleteNotification = true
    return
  }
}

const onSelectType = (form: NotificationFormData) => {
  showModal('edit', null, form)
}

const onConfirmEditNotification = async (notification: NotificationFormData, newFiles: File[]) => {
  saving.value = true

  const newMediaUrls = await api.media.uploadNewMedia(newFiles)

  const data: Partial<NotificationFormData> = { id: notification.id }

  const mediaUrls: string[] = [...(notification.mediaUrls || []), ...newMediaUrls]

  const form: NotificationFormData = { ...notification, mediaUrls }

  const isEditing = Boolean(notification.id)
  const method = notification.id ? 'patch' : 'post'

  if (isEditing) {
    const orig: Notification | null =
      notificationsStore.paginatedNotifications?.items.find(
        (n: Notification) => n.id === notification.id
      ) ?? null

    if (!orig) {
      saving.value = false
      return
    }

    if (form.name != orig.name) {
      data.name = form.name
    }
    if (form.description != orig.description) {
      data.description = form.description || ''
    }
    if (form.preventiveSuggestion != orig.preventiveSuggestion) {
      data.preventiveSuggestion = form.preventiveSuggestion || ''
    }
    if (form.topicId && form.topicId !== orig.topic?.id) {
      data.topicId = form.topicId
    }
    if (!form.topicId && orig.topic) {
      data.removeTopic = true
    }
    if (form.isInjured !== orig.isInjured) {
      data.isInjured = form.isInjured
    }
    if (form.parentsAlerted !== orig.parentsAlerted) {
      data.parentsAlerted = form.parentsAlerted
    }
    if (
      JSON.stringify([...(form.workersInvolvedIds || [])].sort()) !==
      JSON.stringify([...(orig.workersInvolved?.map((worker) => worker.id) || [])].sort())
    ) {
      data.workersInvolvedIds = form.workersInvolvedIds || []
    }
    if (
      JSON.stringify([...(form.mediaUrls || [])].sort()) !==
      JSON.stringify([...(orig.mediaUrls || [])].sort())
    ) {
      data.mediaUrls = form.mediaUrls
      const removedMediaCount = (orig.mediaUrls?.length || 0) - data.mediaUrls.length
      if (removedMediaCount > 0) {
        alertStore.setMessage({
          message: t('notifications.overview.deleteFiles', { count: removedMediaCount }),
          type: Status.success
        })
      }
    }
    if (
      form.datetime &&
      DateTime.fromISO(form.datetime).toUnixInteger() !==
        DateTime.fromISO(orig.datetime).toUnixInteger()
    ) {
      data.datetime = form.datetime
    }

    if (Object.keys(data).length < 2) {
      saving.value = false
      return
    }

    data.notificationType = orig.notificationType
  }

  const response = isEditing
    ? await notificationsStore.updateNotification(data)
    : await notificationsStore.createNotification({ ...notification, mediaUrls: newMediaUrls })

  if (response?.status === 200) {
    if (method === 'post') {
      notificationsStore.setPaging(1)
    }
    await notificationsStore.getPaginatedNotifications(currentContextApiPath.value)
  }

  const alert: Alert =
    response?.status !== 200
      ? { message: t(`app.xhr.${method}.error`), type: Status.error }
      : {
          message: isEditing ? t('alert.notifications.updated') : t('alert.notifications.created'),
          type: Status.success
        }

  if (method === 'post' || response?.status !== 200) {
    alertStore.setMessage(alert)
  }

  if (response?.status !== 200) {
    return
  }

  Object.assign(notificationRecord, response.data)
  newFiles.splice(0, newFiles.length)

  if (method === 'patch') {
    await delay(400)
  }

  saving.value = false
}

const onConfirmModalDeleteNotification = async () => {
  Console.log('onConfirmModalDeleteNotification', notificationRecord.id)

  if (!notificationRecord.id) return

  const response = await notificationsStore.deleteNotification(notificationRecord.id)
  const alert: Alert =
    response.status !== 200
      ? { message: t('app.xhr.delete.error'), type: Status.error }
      : { message: t('alert.notifications.deleted'), type: Status.success }

  if (response.status === 200) {
    notificationsStore.setPaging(1)
    await notificationsStore.getPaginatedNotifications(currentContextApiPath.value)

    if (dialogIsOpen.editNotification) {
      notificationDialogRef.value?.close()
    }
  }

  alertStore.setMessage(alert)
}

const onAddWorker = (userIds: Guid[]) => {
  const workersInvolvedIds = notificationRecord.workersInvolvedIds || []
  const uniqueIds = new Set([...workersInvolvedIds, ...userIds])
  notificationRecord.workersInvolvedIds = [...uniqueIds]
}

const onConfirmRemoveWorker = () => {
  const id = modalRemoveWorkerSettings.value?.id
  const index = notificationRecord.workersInvolvedIds?.findIndex((userId) => userId === id) ?? -1
  if (index >= 0) {
    notificationRecord.workersInvolvedIds!.splice(index, 1)
  }
}

const onAddImages = (files: File[]) => {
  newFiles.push(...files)
}

const notificationDeleteHandler = (id: Guid) => {
  showModal('delete', id)
}

const onConfirmDeleteImage = () => {
  const deleteImage = modalDeleteImageSettings.value

  if (!deleteImage) {
    return
  }

  if (deleteImage.type === 'new') {
    newFiles.splice(newFiles.indexOf(deleteImage.data), 1)
    return
  }

  notificationRecord.mediaUrls =
    notificationRecord.mediaUrls?.filter((image) => image !== deleteImage.data) || []
}

type Event =
  | { type: 'add-workers' | 'add-images' }
  | { type: 'remove-worker'; id: Guid }
  | { type: 'view-image'; index: number; images: any[] }
  | { type: 'delete-image'; image: MediaListItem }
  | { type: 'edit-action'; id: Guid }
  | { type: 'create-action'; id: Guid }
  | { type: 'delete'; id: Guid }

const onEvent = (event: Event): any => {
  Console.log('onEvent', event)

  switch (event.type) {
    case 'delete': {
      notificationDeleteHandler(event.id)
      break
    }
    case 'view-image':
      dialogIsOpen.viewImage = true
      modalViewImagesSettings.value = {
        current: event.index
      }
      break
    case 'add-workers':
      if (notificationRecord.locationId) {
        dialogIsOpen.addWorkers = true
        modalAddWorkersSettings.value = {
          users: notificationRecord.workersInvolvedIds,
          locationId: notificationRecord.locationId
        }
      }
      break
    case 'remove-worker':
      dialogIsOpen.removeWorker = true
      modalRemoveWorkerSettings.value = {
        id: event.id
      }
      break
    case 'add-images':
      dialogIsOpen.addImages = true
      break
    case 'delete-image':
      dialogIsOpen.deleteImage = true
      modalDeleteImageSettings.value = event.image
      break
    case 'edit-action':
      emit('event', event)
      break
    case 'create-action':
      emit('event', event)
      break
    default:
      break
  }
}

defineExpose({
  openDialog: (event: string, id: Guid | null) => {
    showModal(event, id)
  }
})
</script>
