import { useApi } from '@/features/api/api'
import { computed, ref } from 'vue'
import type { CurrentUserContext, User, ContextOptions } from '@/features/user/types'
import type { Location } from '@/features/locations/types'
import type { Guid } from '@/support/types/Guid.dto'
import { useStorage } from '@vueuse/core'
import { User as OIDCUser, UserManager } from 'oidc-client-ts'
import { authConfig } from './config'
import { DateTime } from 'luxon'
import type { RouteLocation, RouteLocationNormalized, Router } from 'vue-router'
import { PermissionName } from '@/features/roles/types'
import type { RequiredPermissions } from '@/app/http/router'

const OIDUserStorageString = useStorage<string>('oidc-user', '')
export const storedOIDCUser = computed<OIDCUser | undefined>({
  get: () =>
    OIDUserStorageString.value ? OIDCUser.fromStorageString(OIDUserStorageString.value) : undefined,
  set(input) {
    OIDUserStorageString.value = input ? input.toStorageString() : ''
  }
})
export const tokenExpiresAt = computed<DateTime | undefined>(() =>
  storedOIDCUser.value?.expires_at
    ? DateTime.fromSeconds(storedOIDCUser.value.expires_at)
    : undefined
)

const Api = useApi()
export const loading = ref<boolean>(false)

export const userInfo = ref<User>()
const userContext = ref<ContextOptions>()
const userManager = new UserManager(authConfig)

const hasFreshAccessToken = (user?: OIDCUser): boolean => {
  if (!user) {
    return false
  }
  if (!user.expires_at) {
    throw new Error('missing expires_at value in OID user')
  }
  if (user.expires_at - DateTime.now().toUnixInteger() < 5) {
    return false
  }
  return true
}

export const isAuthenticated = (): boolean =>
  hasFreshAccessToken(storedOIDCUser.value) || Boolean(storedOIDCUser.value?.refresh_token)

const userPermissions = computed<PermissionName[] | undefined>(() =>
  userInfo.value?.role?.permissions?.reduce<PermissionName[]>(
    (result, permission) => [...result, permission.name],
    []
  )
)

export const hasPermission = (requiredPermission: RequiredPermissions): boolean | undefined => {
  if (!userPermissions.value) {
    return undefined
  }
  if (typeof requiredPermission === 'function') {
    return requiredPermission(userPermissions.value)
  }
  if (Array.isArray(requiredPermission)) {
    return requiredPermission.every((permission) => userPermissions.value!.includes(permission))
  }
  return userPermissions.value.includes(requiredPermission)
}

export const locations = computed<Location[] | undefined>(
  () => userContext.value?.locations || undefined
)

export const maxLocationCount = computed<number | undefined>(
  () => userContext.value?.maxLocationCount
)

export const isSingleLocationOrganisation = computed<boolean | undefined>(
  () => userInfo.value?.hasAccessToOrganisation && locations.value?.length === 1
)
export const singleLocation = computed<Location | undefined>(() =>
  isSingleLocationOrganisation.value ? (locations.value || [])[0] : undefined
)

type Context = 'organisation' | Guid
const storedContext = ref<Context>()
export const getContext = (route: RouteLocationNormalized): Context | undefined | null => {
  if (route.query.context) {
    storedContext.value = route.query.context as Context
    return route.query.context as 'organisation' | Guid
  }
  if (!userInfo.value || !locations.value) {
    throw new Error('no userInfo or locations loaded yet')
  }
  const context = userInfo.value.hasAccessToOrganisation
    ? 'organisation'
    : (locations.value || [])[0]?.id
  storedContext.value = context

  return context || null
}

export const switchContextDisabled = ref(false)
export const setContext = async (context: Context | undefined, router: Router): Promise<void> => {
  if (switchContextDisabled.value) {
    return
  }
  const route = router.currentRoute
  if ((route.value.query.context || undefined) === context) {
    return
  }
  if (!context && route.value.query.context) {
    await router.push({ query: { context: '' } })
    return
  }
  storedContext.value = context

  const from = router.currentRoute.value
  const fromName = String(from.name)
  const query = { context }

  if (from.meta.requiresOrganisationAccess && context !== 'organisation') {
    await router.push({ name: 'dashboard', query })
    return
  }

  if (Object.keys(from.params).length) {
    if (fromName.startsWith('policy.view')) {
      await router.push({ name: 'policy.view', query })
      return
    }
    if (fromName.startsWith('policy.edit')) {
      await router.push({ name: 'policy.edit', query })
      return
    }
    if (fromName.startsWith('current-cycle')) {
      await router.push({ name: 'current-cycle.index', query })
      return
    }
  }
  await router.push({ query: { context } })
}

export const currentContext = computed<Context | undefined>(() => {
  return storedContext.value
})

export const currentContextApiPath = computed<string>(() => {
  if (!currentContext.value) {
    throw new Error('currentContextApiPath was called before loading the context')
  }
  return currentContext.value === 'organisation'
    ? 'organisation'
    : `location/${currentContext.value}`
})

export const currentContextType = computed<CurrentUserContext['type'] | undefined>(() =>
  currentContext.value === 'organisation' ? 'organisation' : 'location'
)
export const currentLocation = computed<Location | undefined>(
  () => locations.value?.find((location) => location.id === currentContext.value) || undefined
)
export const currentLocationId = computed<Guid | undefined>(() => currentLocation.value?.id)

let getUserInfoPromise: Promise<User | null> | undefined
export const getUserInfo = async (): Promise<User | null> => {
  // return null
  if (getUserInfoPromise) {
    return await getUserInfoPromise
  }

  loading.value = true
  getUserInfoPromise = new Promise<User | null>((resolve, reject) => {
    userManager.getUser().then((user) => {
      if (!user) {
        reject('No OIDUser found')
        return
      }

      storedOIDCUser.value = user

      Api.user
        .getUser()
        .then((response) => {
          if (response.status === 404) {
            resolve(null)
            return
          }
          if (response?.status !== 200) {
            throw new Error('No user info found')
          }

          userInfo.value = response.data

          Api.user.getUserContext().then((response) => {
            if (response?.status !== 200) {
              throw new Error('No user context found')
            }
            userContext.value = response.data
            resolve(userInfo.value!)
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  })

  return getUserInfoPromise
}

export const reloadUserInfo = async (): Promise<User | null> => {
  getUserInfoPromise = undefined
  return getUserInfo()
}

export const hasAccessToRoute = (route: RouteLocation): boolean => {
  if (!currentContext.value && route.matched.some((m) => m.meta.needsContext)) {
    return false
  }
  const requiresOrganisationAccess = route.matched.some((m) => m.meta.requiresOrganisationAccess)
  if (requiresOrganisationAccess && !userInfo.value?.hasAccessToOrganisation) {
    return false
  }
  const requiresAuth: boolean = route.matched.some(
    (m) => m.meta.requiresAuth || m.meta.requiresAuth === undefined,
    []
  )
  if (requiresAuth && !isAuthenticated()) {
    return false
  }
  const requiredPermissions: RequiredPermissions[] = route.matched.reduce<RequiredPermissions[]>(
    (result, m) => (m.meta.requiredPermissions ? [...result, m.meta.requiredPermissions] : result),
    []
  )
  if (!requiredPermissions.length) {
    return true
  }

  return requiredPermissions.every(hasPermission)
}

export const isMe = (user?: User | null): boolean | undefined => {
  if (!userInfo.value || !user) {
    return undefined
  }
  return user.id === userInfo.value.id
}

export type InviteParams = {
  token?: string
}
export const login = (inviteParams?: InviteParams) =>
  userManager.signinRedirect({
    extraQueryParams: inviteParams
  })

export const signInCallback = async () => {
  const user = await userManager.signinCallback()
  if (!user) {
    throw new Error('No user found')
  }
  storedOIDCUser.value = user
}
export const logout = () => {
  storedOIDCUser.value = undefined
  userInfo.value = undefined
  userContext.value = undefined

  // todo: add this when available in SSO
  // return userManager.signoutRedirect()
}
