<template>
  <div ref="reference" class="tooltip" @mouseover="onMouseOver" @mouseout="onMouseLeave">
    <slot />
  </div>
  <div ref="floating" class="tooltip__item" :class="[floatingPlacement, color]" :style="styles">
    <slot name="tooltip" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import {
  useFloating,
  flip,
  shift,
  offset as offsetFn,
  computePosition as computePositionFn,
  type ReferenceElement
} from '@floating-ui/vue'

interface TooltipProps {
  placement?: 'top' | 'bottom' | 'left' | 'right'
  computePosition?: boolean
  offset?: number
  color?: 'blue' | 'orange'
  disableFlip?: boolean
}

const isVisible = ref(false)
const reference = ref<HTMLElement>()
const floating = ref<HTMLElement>()
const position = ref({ x: 0, y: 0 })

const props = withDefaults(defineProps<TooltipProps>(), {
  placement: 'bottom',
  color: 'blue',
  offset: 10,
  computePosition: false
})

const {
  update,
  floatingStyles,
  placement: floatingPlacement
} = useFloating(reference, floating, {
  placement: props.placement,
  middleware: [offsetFn(props.offset), ...(props.disableFlip ? [] : [flip()]), shift()]
})

const styles = computed(() => {
  const styles: any = {
    ...floatingStyles.value
  }

  if (position.value.x && position.value.y) {
    styles.top = `${position.value.y}px`
    styles.left = `${position.value.x}px`
  }

  return {
    ...styles,
    opacity: isVisible.value ? 1 : 0
  }
})

const onMouseOver = async () => {
  if (!reference.value || !floating.value) return
  update()
  if (props.computePosition) {
    computePositionFn(reference.value as ReferenceElement, floating.value).then(
      ({ x, y }: { x: number; y: number }) => {
        position.value = { x, y }
        isVisible.value = true
      }
    )
  } else {
    isVisible.value = true
  }
}

const onMouseLeave = () => {
  isVisible.value = false
}

onMounted(async () => {
  if (!reference.value || !floating.value) return
  document.body.appendChild(floating.value)
})

onBeforeUnmount(async () => {
  if (!reference.value || !floating.value) return
  document.body.removeChild(floating.value)
})
</script>

<style lang="scss" scoped>
$blue: map-get($theme-color-primary, 'dark-blue');
$orange: map-get($theme-color-primary, 'orange');

.tooltip {
  display: inline-block;

  &__item {
    width: max-content;
    max-width: 220px;
    position: absolute;
    top: 0;
    left: 0;
    background: $blue;
    color: white;
    padding: 6px;
    border-radius: 4px;
    font-size: $theme-font-size-s;
    z-index: 9999;
    transition: opacity 0.3s;
    text-align: center;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      border-width: 8px;
      border-style: solid;
      border-color: transparent;
    }

    &.top:before {
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: $blue;
    }
    &.bottom:before {
      top: -13px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: $blue;
    }
    &.left:before {
      right: -13px;
      top: 35%;
      border-left-color: $blue;
    }
    &.right:before {
      left: -13px;
      top: 35%;
      border-right-color: $blue;
    }

    &.orange {
      background: $orange;

      &.top:before {
        border-top-color: $orange;
      }
      &.bottom:before {
        border-bottom-color: $orange;
      }
      &.left:before {
        border-left-color: $orange;
      }
      &.right:before {
        border-right-color: $orange;
      }
    }
  }
}
</style>
