<template>
  <div class="file-selector">
    <div ref="dropZone" class="file-selector__dropzone" :class="{ hover: isOverDropZone }">
      <slot>
        <p>
          {{
            accept === 'image/*' ? $t('fileSelector.prefixImage') : $t('fileSelector.prefixFile')
          }}
        </p>
        <p>{{ $t('fileSelector.or') }}</p>
      </slot>
      <button class="button-secondary" @click="() => openFileDialog()">
        {{ buttonText || $t('fileSelector.browse') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useDropZone, useFileDialog } from '@vueuse/core'
import './localization'
import Console from '@/support/core/console'

interface Props {
  buttonText?: string
  accept?: '*/*' | 'image/*'
}

const model = defineModel<File[]>()

const props = withDefaults(defineProps<Props>(), { accept: '*/*' })

const dropZone = ref<HTMLElement>()

const onDrop = (files: File[] | null) => {
  if (!files) return
  addFiles(files)
}

const { isOverDropZone } = useDropZone(dropZone, onDrop)

const { open: openFileDialog, onChange } = useFileDialog({
  reset: true,
  accept: props.accept
})

const isUniqueFile = ({ name, size }: { name: string; size: number }) =>
  !model.value || !model.value.some((f) => f.name === name && f.size === size)

const addFiles = async (files: File[]) => {
  const uniqueFiles: File[] = files.filter(isUniqueFile)
  if (!uniqueFiles.length) {
    return
  }
  const acceptedFiles = uniqueFiles.filter((file) => {
    if (file.size < 1) {
      Console.warn(`Cannot use empty file ${file.name}`)
      return false
    }
    if (props.accept === 'image/*') {
      return file.type.startsWith('image/')
    }
    return true
  })

  if (!model.value) {
    model.value = []
  }

  model.value = [...model.value, ...acceptedFiles]
}

onChange((files) => {
  if (!files) return
  const filesArray = Array.from(files)
  addFiles(filesArray)
})
</script>

<style lang="scss" scoped>
.file-selector {
  &__dropzone {
    align-items: center;
    background-color: map-get($theme-color-secondary, 'beige');
    border: 2px dashed map-get($theme-color-primary, 'light-blue-2');
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 258px;
    justify-content: center;
    margin-bottom: 10px;

    &.hover {
      background-color: map-get($theme-color-primary, 'light-orange');
    }
  }
}
</style>
