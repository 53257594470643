<template>
  <div class="accordion__body">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.accordion__body {
  padding: 15px 10px;
  border-top: 1px solid map-get($theme-color-primary, 'light-blue-2');
  margin-left: 40px;
}
</style>
