import { createI18n } from 'vue-i18n'

const lang: string = document.documentElement.lang
const localizations: any = {
  nl: 'nl-NL'
}
const locale: string = localizations[lang] || localizations.nl

const i18n = createI18n({
  legacy: false,
  warnHtmlMessage: false,
  locale,
  datetimeFormats: {
    'nl-NL': {
      time: {
        hour: '2-digit',
        minute: '2-digit'
      },
      date: {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      },
      dateShort: {
        day: 'numeric',
        month: '2-digit',
        year: 'numeric'
      },
      dateTime: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      },
      dateShortMonthAsText: {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
    }
  }
})

export default i18n
