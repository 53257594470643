import { useTitle } from '@vueuse/core'
import { computed, type Ref, ref } from 'vue'
import i18n from '@/support/core/localization'

const appName = ref('')
const pageTitle = ref<string>('')

useTitle(
  computed<string>(() =>
    pageTitle.value ? `${pageTitle.value} - ${appName.value}` : appName.value
  )
)

export const usePageTitle = (setTitle?: string): Ref<string> => {
  const { t } = i18n.global
  appName.value = t('app.name')
  if (setTitle) {
    pageTitle.value = setTitle
  }

  return pageTitle
}
