<template>
  <div>
    <empty-state
      class="mb-4"
      :title="page.title"
      :icon-props="page.icon"
      :description="page.description"
    >
      <div>
        <button-icon icon-name="action-logout" class="button-secondary" @click="onClickLogout">
          {{ $t('app.header.logout') }}
        </button-icon>
      </div>
    </empty-state>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { logout, locations, userInfo } from '@/features/auth'
import Console from '@/support/core/console'

const router = useRouter()
const route = useRoute()

const { t } = useI18n()

const pages = {
  'auth.accountHasNoOrganisation': {
    title: t('auth.accountHasNoOrganisation.title'),
    description: t('auth.accountHasNoOrganisation.description'),
    icon: {
      name: 'illustration-empty-state-actions',
      size: { width: 180, height: 180 }
    }
  },
  'auth.organisationConfigurationNotCompleted': {
    title: t('auth.organisationConfigurationNotCompleted.title'),
    description: t('auth.organisationConfigurationNotCompleted.description'),
    icon: {
      name: 'illustration-empty-state-actions',
      size: { width: 180, height: 180 }
    }
  },
  'auth.unauthorized': {
    title: t('auth.unauthorized.title'),
    description: t('auth.unauthorized.description'),
    icon: {
      name: 'illustration-empty-state-actions',
      size: { width: 180, height: 180 }
    }
  },
  'auth.no-context': {
    title: t('auth.noContext.title'),
    description: t('auth.noContext.description'),
    icon: {
      name: 'illustration-empty-state-actions',
      size: { width: 180, height: 180 }
    }
  }
}

const page = computed(() => pages[route.name as keyof typeof pages])

const onClickLogout = () => {
  logout()
  window.location.href = `${import.meta.env.VITE_APP_OAUTH_AUTHORITY}/user/logout`
}

watch(
  () => [userInfo.value, route.name],
  (v) => {
    Console.log(v)
    if (
      route.name === 'auth.accountHasNoOrganisation' &&
      (userInfo.value?.hasAccessToOrganisation || locations.value)
    ) {
      router.replace({ name: 'dashboard' })
    }
  },
  { immediate: true }
)
</script>
