<template>
  <div
    :class="[`column column-${property} align-${align}`, { maxWidth, wrap }]"
    :style="{ maxWidth }"
  >
    <slot>
      <component
        :is="components[config.component]"
        :column-data="config.columnData"
        :property="property"
      />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue'
import ListViewColumnText from './listViewColumnText.vue'
import type { ListViewColumnData } from '@/support/components/listView/types'

interface Props {
  property: string
  width?: number
  align?: 'left' | 'right'
  wrap?: boolean
  item?: any
}

const props = withDefaults(defineProps<Props>(), { align: 'left' })

const components: any = {
  'column-text': ListViewColumnText
}

const config: ComputedRef<any> = computed(() => {
  const isObject = props.item && typeof props.item === 'object'
  const component: string =
    isObject && Object.prototype.hasOwnProperty.call(props.item, 'component')
      ? props.item.component
      : 'column-text'
  const columnData: ListViewColumnData = isObject ? props.item : { value: props.item }

  return {
    component,
    columnData
  }
})

const maxWidth = computed<string | undefined>(() => (props.width ? `${props.width}px` : undefined))
</script>

<style lang="scss" scoped>
.column {
  padding: 15px;
  flex: 1;

  &:not(.column-buttons) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.column-buttons {
    flex: 0 1 auto;
    padding: 0;
    position: relative;
    min-height: 60px;
    display: flex;
    align-items: center;
  }

  &.align-right {
    text-align: right;

    :deep(.date-value) {
      justify-content: end;
    }
    :deep(.user-data) {
      justify-content: end;
    }
  }

  &:not(.wrap) {
    :deep(.text) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
