<template>
  <img
    :src="img?.src || placeholder || placeholderPng"
    :alt="img?.title"
    :title="img?.title"
    @error="onError"
  />
</template>

<script lang="ts" setup>
import type { MediaImage } from '@/features/media/types'
import { computedAsync } from '@vueuse/core'
import placeholderPng from '@/app/img/placeholder.png'
import { computed, ref } from 'vue'
import { getAccessToken } from '@/features/api/fetchWithAuth'

const props = defineProps<MediaImage>()

const getFileData = async (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const result = reader.result as string
      resolve(result)
    }
  })

const computedMediaUrl = computed<string | undefined>(() => {
  if (props.mediaUrl) {
    return props.mediaUrl
  }
  if (props.file?.type === 'extended') {
    if (typeof props.file?.data === 'string') {
      return props.file.data
    }
    return props.file.data.url
  }
  return undefined
})

const token = ref<string>()
const tokenHash = computed<number | undefined>(() =>
  token.value?.split('').reduce<number>((result, char) => {
    const charCode = char.charCodeAt(0)
    const r = (result << 5) - result + charCode
    return r & charCode
  }, 0)
)

const img = computedAsync<{ src: string; title: string } | undefined>(async () => {
  if (props.file?.type === 'new') {
    return {
      src: await getFileData(props.file.data),
      title: props.file.data.name
    }
  }

  if (computedMediaUrl.value) {
    if (!tokenHash.value) {
      getAccessToken().then((v) => (token.value = v))
      return
    }
    const src = `/api/media/${encodeURIComponent(computedMediaUrl.value.replace(/^(\/media\/)?(.*)$/, '$2'))}`
    return {
      src: `${src}${src.includes('?') ? '&' : '?'}t=${tokenHash.value}`,
      title: computedMediaUrl.value.split('/').pop() || 'unknown file'
    }
  }
}, undefined)

const onError = async () => {
  if (!props.mediaUrl) {
    return
  }
  token.value = await getAccessToken()
}
</script>
