<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      v-if="field.label && field.label.length"
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
      :description="field.labelTooltip"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <div v-if="field.readonly" class="field-readonly">
      {{ readOnlyModel || '-' }}
    </div>
    <div v-else class="field-select" :class="selectedColor ? [selectedColor, 'with-color'] : null">
      <icon :name="iconChevronDown" class="icon-chevron-down" />
      <select
        :id="field.id"
        v-model="model"
        :name="field.id"
        :class="{ 'has-placeholder': field.placeholder, 'is-empty': !model }"
        :disabled="field.disabled"
        @change="handleOnChange"
        @blur="vuelidate?.$touch()"
      >
        <option
          v-if="field.placeholder && (modelValue === null || !isRequired)"
          :value="null"
          data-placeholder
        >
          {{ field.placeholder }}
        </option>
        <option
          v-for="(option, key) in field.options"
          :key="key"
          :value="typeof option === 'object' ? option.value : option"
          :disabled="option.disabled"
        >
          {{ typeof option === 'object' ? option.label : option }}
        </option>
      </select>
    </div>

    <reactive-form-errors :errors="errors" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useFormField } from '../composables/useFormField'
import type { ReactiveFormFieldSelect } from '../types/ReactiveFormField.dto'
import type { ReactiveFormFieldValue } from '../types/ReactiveFormFieldValue.dto'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'
import type { Validation } from '@vuelidate/core'

interface Props {
  field: ReactiveFormFieldSelect
  vuelidate?: Validation | null
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null
})

const { errors, className, isRequired, modelSetter } = useFormField(props)
const selectedColor = computed<'warning' | 'info' | 'success' | 'gray' | undefined>(
  () =>
    (props.field.options ?? []).find((option) =>
      typeof option === 'object' ? model.value === option.value : option === model.value
    )?.color
)

const emit = defineEmits(['change'])

const model = defineModel<ReactiveFormFieldValue>({ default: '', set: modelSetter })

const iconChevronDown = computed(() => {
  return props.field.disabled ? 'action-chevron-down-disabled' : 'action-chevron-down'
})

const readOnlyModel = computed(() => {
  const option = props.field.options.find((option) => option.value === model.value)
  return option && typeof option === 'object' ? option.label : props.field.placeholder
})

const handleOnChange = () => {
  emit('change', model.value)
}
</script>

<style lang="scss" scoped>
.field-select {
  position: relative;

  & > .icon-chevron-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 38px 10px 10px;
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    transition:
      border-color 0.3s,
      box-shadow 0.3s;

    background-color: map-get($form-field-background-color, 'idle');
    border: 1px solid map-get($form-field-border-color, 'idle');

    // Hack to make sure 'placeholder' has a different font color
    &.has-placeholder {
      option[data-placeholder] {
        color: $form-field-placeholder-color;
      }

      &:not(.is-empty) {
        color: map-get($form-field-font, 'color');
      }

      &.is-empty {
        color: $form-field-placeholder-color;

        option:not([data-placeholder]) {
          color: map-get($form-field-font, 'color');
        }
      }
    }

    &:hover {
      background-color: map-get($form-field-background-color, 'hover');
      border-color: map-get($form-field-border-color, 'hover');
    }

    &:focus,
    &:focus:active {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');

      box-shadow: 0 0 0 5px $form-field-outline-color;
      border-radius: 2px;
    }

    &:disabled {
      background-color: map-get($form-field-background-color, 'disabled');
      border-color: map-get($form-field-border-color, 'disabled');
      color: $form-field-font-color-disabled;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $form-field-placeholder-color;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &.info select {
    &,
    &:hover,
    &:focus,
    &:focus:active {
      background-color: map-get($theme-color-status, 'info');
    }
  }
  &.warning select {
    &,
    &:hover,
    &:focus,
    &:focus:active {
      background-color: map-get($theme-color-status, 'warning');
    }
  }
  &.success select {
    &,
    &:hover,
    &:focus,
    &:focus:active {
      background-color: map-get($theme-color-status, 'success');
    }
  }
  &.gray select {
    &,
    &:hover,
    &:focus,
    &:focus:active {
      background-color: map-get($theme-color-neutral, 'grey-2');
    }
  }
  &.with-color {
    select {
      color: white;

      option {
        background-color: white;
        color: map-get($theme-color-primary, 'dark-blue');
      }
    }
    :deep(.icon svg path) {
      stroke: white;
    }
  }
}
</style>
