import { useSlots } from 'vue'
import { type VNode, Comment, Text } from 'vue'

interface SlotCount {
  [key: string]: number
}

export const countSlots = (): SlotCount => {
  const slots = useSlots()
  const keys = Object.keys(slots)

  const countUniqueKeys = (arr: string[]): SlotCount =>
    arr.reduce((counts, item) => {
      const key = item.split('-')[0]
      counts[key] = (counts[key] || 0) + 1
      return counts
    }, {} as SlotCount)

  return countUniqueKeys(keys)
}

export const hasSlotContent = (slots: any, slotName: string): boolean => {
  const slot = slots[slotName]

  if (!slot) return false

  const content = slot()

  return content.some((node: VNode) => {
    if (node.type === Text) {
      return (node.children as string).trim() !== ''
    }

    return node.type !== Comment
  })
}
