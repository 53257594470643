import NotificationPrimary from './primary/index.vue'
import NotificationSecondary from './secondary/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    NotificationPrimary: typeof NotificationPrimary
    NotificationSecondary: typeof NotificationSecondary
  }
}

export default {
  NotificationPrimary,
  NotificationSecondary
}
