<template>
  <dropdown v-if="items && !disabled" :items="items" @click:item="onClickMenuItem">
    <button :class="variant" :disabled="disabled">
      <icon :name="iconName" :states="iconStates" />
    </button>
  </dropdown>
  <button v-else :class="variant" :disabled="disabled" @click="$emit('click:button', $event)">
    <icon :name="iconName" :states="iconStates" />
  </button>
</template>

<script lang="ts" setup>
import Dropdown from '@/support/components/dropdownMenu/components/Dropdown.vue'
import type { DropdownItemProps } from '@/support/components/dropdownMenu/dtos'

withDefaults(
  defineProps<{
    iconName?: string
    iconStates?: string[]
    items?: DropdownItemProps[]
    variant?: 'blue' | 'white' | 'on-white' | 'default'
    disabled?: boolean
  }>(),
  { iconName: 'action-more-options', variant: 'default' }
)

const emit = defineEmits<{
  'click:button': [event: Event]
  'click:item': [item: DropdownItemProps]
}>()

const onClickMenuItem = (item: DropdownItemProps) => {
  emit('click:item', item)
}
</script>

<style lang="scss" scoped>
button {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 40px;
  height: 40px;

  &.blue {
    background-color: map-get($theme-color-primary, 'light-blue-2');
  }
  &.white {
    background-color: map-get($theme-color-neutral, 'white');

    &:hover:not([disabled]) {
      border-color: map-get($theme-color-primary, 'dark-blue');
    }
  }
  &.on-white {
    background-color: transparent;

    &:hover:not([disabled]) {
      background-color: map-get($theme-color-primary, 'light-blue-2');
    }
  }
  &.default:hover:not([disabled]) {
    background-color: map-get($theme-color-neutral, 'white');
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }
}
</style>
