<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :role="to ? 'button' : undefined"
    :disabled="disabled || loading"
    class="button"
    :class="`button-${variant}`"
    @click="onClick"
  >
    <icon v-if="loading" name="action-spinner" :class="`icon--${iconPosition} icon--spinning`" />
    <icon
      v-else-if="computedIconProps"
      :name="computedIconProps.name"
      :class="`icon--${iconPosition}`"
      :size="computedIconProps.size"
      :states="computedIconProps.states"
    />
    <slot />
  </component>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import type { Icon } from '@/support/components/icon/types/Icon.dto'
import { computed } from 'vue'

type Props = {
  variant?: 'primary' | 'secondary'
  to?: RouteLocationRaw
  iconProps?: Icon
  iconName?: string
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), { iconPosition: 'left', variant: 'secondary' })
const emit = defineEmits<{ click: [ev: MouseEvent] }>()

const computedIconProps = computed<Icon | undefined>(() => {
  if (!props.iconProps && props.iconName) {
    return { name: props.iconName }
  }
  if (props.iconProps && props.iconName) {
    return { ...props.iconProps, name: props.iconName }
  }
  if (props.iconProps) {
    return props.iconProps
  }
  return undefined
})

const onClick = (ev: MouseEvent) => {
  if (props.disabled || props.loading) {
    ev.preventDefault()
    ev.stopPropagation()
    return
  }
  emit('click', ev)
}
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  float: left;

  text-decoration: none;

  .icon {
    display: inline-block;
    flex-shrink: 0;

    &--left {
      margin-right: 5px;
      margin-left: -5px;
    }

    &--right {
      order: 1;
      margin-right: -5px;
      margin-left: 5px;
    }

    &--spinning {
      animation: rotation 0.7s linear infinite;
    }
  }

  :deep(.icon__svg) {
    opacity: 0;

    &.idle {
      opacity: 1;
    }

    path {
      transition: all 0.3s ease-in-out;
    }
  }

  &:not([disabled]):has(.hover):hover :deep(.icon__svg) {
    &.idle,
    &.disabled {
      opacity: 0;
    }
    &.hover {
      opacity: 1;
    }
  }

  &:disabled:has(.disabled) :deep(.icon__svg) {
    &.idle,
    &.hover {
      opacity: 0;
    }
    &.disabled {
      opacity: 1;
    }
  }

  svg path {
    transition: all 0.3s ease;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
