import { defineStore } from 'pinia'
import type {
  AddWorkform,
  ActiveCycle,
  CyclePreparation,
  UpdateCycleChecklist,
  UpdateChecklistQuestion,
  UpdateChecklistSubject,
  UpdateChecklistSubSubject,
  UpdateCyclePreparation,
  UpdatePreparationStep,
  UpdateTopic,
  UpdateWorkform,
  CycleWorkFormForm,
  SaveReplyForLocation,
  UpdateCycleChecklistForActiveCycle,
  UpdateCycleStep,
  UpdateLocationCycleStep,
  UpdateActiveCycle,
  CreateCycleWorkform,
  UpdateActiveOrganisationCycle,
  CycleChecklist
} from '@/features/cycle/types'
import { ref } from 'vue'
import { useApi } from '@/features/api/api'
import type { Guid } from '@/support/types/Guid.dto'

const Api = useApi()

export const useCycleStore = defineStore('cycle', () => {
  const preparationCycle = ref<CyclePreparation>()
  const locationCycle = ref<ActiveCycle | null>()
  const organisationCycle = ref<ActiveCycle | null>()
  const activeOrganisationCycles = ref<ActiveCycle[] | null>()
  const loading = ref(false)

  const sortByOrder = (
    a: { order?: number; title?: string | null; question?: string | null },
    b: { order?: number; title?: string | null; question?: string | null }
  ) => {
    if (typeof a.order === 'number' && typeof b.order === 'number' && a.order !== b.order) {
      return a.order - b.order
    }
    return (a.title || a.question || '').localeCompare(b.title || b.question || '')
  }

  const sortChecklist = (checklist: CycleChecklist): CycleChecklist => ({
    ...checklist,
    subjects:
      checklist.subjects &&
      [...checklist.subjects].sort(sortByOrder).map((subject) => ({
        ...subject,
        subSubjects:
          subject.subSubjects &&
          [...subject.subSubjects].sort(sortByOrder).map((subSubject) => ({
            ...subSubject,
            questions: subSubject.questions && [...subSubject.questions].sort(sortByOrder)
          }))
      }))
  })
  const filterChecklistQuestions = (checklist: CycleChecklist): CycleChecklist => ({
    ...checklist,
    subjects:
      checklist.subjects &&
      checklist.subjects
        .filter((subject) => subject.enabled)
        .map((subject) => ({
          ...subject,
          subSubjects:
            subject.subSubjects &&
            subject.subSubjects
              .filter((subSubject) => subSubject.enabled)
              .map((subSubject) => ({
                ...subSubject,
                questions:
                  subSubject.questions &&
                  subSubject.questions.filter((question) => question.enabled)
              }))
        }))
  })

  const sortCyclePreparation = <CycleType extends ActiveCycle | CyclePreparation>(
    input: CycleType
  ): CycleType => ({
    ...input,
    cycleTopics:
      input.cycleTopics &&
      [...input.cycleTopics].sort(sortByOrder).map((topic) => ({
        ...topic,
        checklists: topic.checklists?.map((checklist) => sortChecklist(checklist)) || null,
        cycleWorkforms: topic.cycleWorkforms && [...topic.cycleWorkforms].sort(sortByOrder)
      })),
    preparationSteps: input.preparationSteps && [...input.preparationSteps].sort(sortByOrder)
  })
  const sortAndFilterActiveCycle = (input: ActiveCycle): ActiveCycle => ({
    ...input,
    cycleTopics:
      input.cycleTopics &&
      input.cycleTopics
        .filter((topic) => topic.enabled)
        .sort(sortByOrder)
        .map((topic) => ({
          ...topic,
          checklists:
            topic.checklists?.map((checklist) =>
              sortChecklist(filterChecklistQuestions(checklist))
            ) || null,
          cycleWorkforms: topic.cycleWorkforms && [...topic.cycleWorkforms].sort(sortByOrder)
        })),
    preparationSteps: input.preparationSteps && [...input.preparationSteps].sort(sortByOrder)
  })

  const getPreparationCycle = async () => {
    loading.value = true
    const response = await Api.cycle.getPreparationCycle()
    preparationCycle.value = sortCyclePreparation(response.data)
    loading.value = false
    return response
  }

  const updatePreparationCycle = async (data: UpdateCyclePreparation) => {
    await Api.cycle.updatePreparationCycle(data)
  }

  const updateActiveCycle = async (data: UpdateActiveOrganisationCycle) => {
    await Api.cycle.updateActiveCycle(data)
  }

  const removeWorkform = async (workformId: Guid) => {
    loading.value = true
    await Api.cycle.removeWorkform(workformId)
    const response = await Api.cycle.getPreparationCycle()
    preparationCycle.value = sortCyclePreparation(response.data)
    loading.value = false
  }

  const addWorkform = async (topicId: Guid, data: AddWorkform) => {
    loading.value = true
    await Api.cycle.addWorkform(topicId, data)
    const response = await Api.cycle.getPreparationCycle()
    preparationCycle.value = sortCyclePreparation(response.data)
    loading.value = false
  }

  const updateWorkform = async (workformId: Guid, data: UpdateWorkform) => {
    return await Api.cycle.updateWorkform(workformId, data)
  }

  const getAvailableWorkforms = async (topicId: Guid) => {
    return await Api.cycle.getAvailableWorkforms(topicId)
  }

  const updateTopic = async (topicId: Guid, data: UpdateTopic) => {
    return await Api.cycle.updateTopic(topicId, data)
  }

  const updateChecklist = async (checklistId: Guid, data: Partial<UpdateCycleChecklist>) => {
    return await Api.cycle.updateChecklist(checklistId, data)
  }

  const updateChecklistForActiveCycle = async (
    locationChecklistId: Guid,
    data: Partial<UpdateCycleChecklistForActiveCycle>
  ) => {
    return await Api.cycle.updateChecklistForActiveCycle(locationChecklistId, data)
  }

  const updateChecklistSubject = async (subjectId: Guid, data: UpdateChecklistSubject) => {
    return await Api.cycle.updateChecklistSubject(subjectId, data)
  }

  const updateChecklistSubSubject = async (subSubjectId: Guid, data: UpdateChecklistSubSubject) => {
    return await Api.cycle.updateChecklistSubSubject(subSubjectId, data)
  }

  const updateChecklistQuestion = async (questionId: Guid, data: UpdateChecklistQuestion) => {
    return await Api.cycle.updateChecklistQuestion(questionId, data)
  }

  const updatePreparationStep = async (stepId: Guid, data: Partial<UpdatePreparationStep>) => {
    return await Api.cycle.updatePreparationStep(stepId, data)
  }

  const updateCycleStep = async (stepId: Guid, data: UpdateCycleStep) => {
    return await Api.cycle.updateCycleStep(stepId, data)
  }

  const updateLocationCycleStep = async (
    cycleLocationStepId: Guid,
    data: Partial<UpdateLocationCycleStep>
  ) => {
    return await Api.cycle.updateLocationCycleStep(cycleLocationStepId, data)
  }

  const addActiveCycleWorkform = async (
    locationId: Guid,
    topicId: Guid,
    data: CreateCycleWorkform
  ) => {
    return await Api.cycle.addActiveCycleWorkform(locationId, topicId, data)
  }

  const updateActiveCycleWorkForm = async (
    cycleLocationWorkformId: Guid,
    data: Partial<CycleWorkFormForm>
  ) => {
    return await Api.cycle.updateActiveCycleWorkForm(cycleLocationWorkformId, data)
  }

  const getActiveLocationCycle = async (
    locationId: Guid
  ): Promise<ActiveCycle | undefined | null> => {
    loading.value = true

    if (locationCycle.value?.cycleLocation?.location.id !== locationId) {
      locationCycle.value = undefined
    }

    const response = await Api.cycle.getActiveLocationCycle(locationId)
    loading.value = false
    if (response.status === 200) {
      // Active cycle available
      locationCycle.value = sortAndFilterActiveCycle(response.data)
    }
    if (response.status === 404) {
      locationCycle.value = null
    }
    return locationCycle.value
  }

  const getActiveOrganisationCycle = async (): Promise<ActiveCycle | undefined | null> => {
    if (!organisationCycle.value) {
      loading.value = true
    }

    const response = await Api.cycle.getActiveOrganisationCycle()
    loading.value = false
    if (response.status === 200) {
      // Active cycle available
      organisationCycle.value = sortCyclePreparation(response.data)
    }
    if (response.status === 404) {
      organisationCycle.value = null
    }
    return organisationCycle.value
  }

  const reloadActiveCycle = async (): Promise<void> => {
    if (locationCycle.value?.cycleLocation?.location.id) {
      await getActiveLocationCycle(locationCycle.value?.cycleLocation?.location.id)
    }
    if (activeOrganisationCycles.value) {
      await getActiveOrganisationLocationCycles()
    }
  }

  const getActiveOrganisationLocationCycles = async (): Promise<
    ActiveCycle[] | undefined | null
  > => {
    loading.value = true
    const response = await Api.cycle.getActiveOrganisationLocationCycles()
    loading.value = false
    activeOrganisationCycles.value = response.status === 404 ? null : response.data
    return activeOrganisationCycles.value
  }

  const setQuestionReply = async (
    questionId: Guid,
    locationId: Guid,
    data: SaveReplyForLocation
  ) => {
    return await Api.cycle.setQuestionReply(questionId, locationId, data)
  }

  const pushPreparedCycleToLocations = async (workInstruction: string) => {
    const response = await Api.cycle.pushPreparedCycleToLocations(workInstruction)
    if (response.status === 200) {
      preparationCycle.value = undefined
      activeOrganisationCycles.value = undefined
      locationCycle.value = undefined
      await Api.cycle.getPreparationCycle()
      await Api.cycle.getActiveOrganisationCycle()
    }
    return response
  }

  const updateActiveLocationCycle = async (locationId: Guid, data: Partial<UpdateActiveCycle>) => {
    await Api.cycle.updateActiveLocationCycle(locationId, data)
  }

  return {
    preparationCycle,
    organisationCycle,
    loading,
    getPreparationCycle,
    updatePreparationCycle,
    updateActiveCycle,
    removeWorkform,
    addWorkform,
    updateWorkform,
    getAvailableWorkforms,
    updateTopic,
    updateChecklist,
    updateChecklistForActiveCycle,
    updateChecklistSubject,
    updateChecklistSubSubject,
    updateChecklistQuestion,
    updatePreparationStep,
    updateCycleStep,
    updateLocationCycleStep,
    addActiveCycleWorkform,
    updateActiveCycleWorkForm,
    getActiveLocationCycle,
    getActiveOrganisationCycle,
    reloadActiveCycle,
    locationCycle,
    activeOrganisationCycles,
    setQuestionReply,
    pushPreparedCycleToLocations,
    getActiveOrganisationLocationCycles,
    updateActiveLocationCycle
  }
})
