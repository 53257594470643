import { unref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
import { usePageTitle } from '@/support/composition/use-page-title'

export const setPageTitleRouteHook = (to: RouteLocationNormalized) => {
  if (!to.meta.title) {
    return
  }

  const pageTitle = usePageTitle()

  pageTitle.value = unref(to.meta.title)
}
