<template>
  <div class="reactive-form__field" :class="className">
    <reactive-form-field-label
      :field-id="field.id"
      :hide-label="field.hideLabel"
      :is-required="!field.readonly && isRequired"
    >
      {{ field.label }}
    </reactive-form-field-label>

    <div v-for="(option, index) in field.options" :key="index" class="custom-checkbox">
      <input
        :id="`${field.id}-${index}`"
        v-model="computedModel"
        type="checkbox"
        :name="field.id"
        :disabled="option.disabled"
        :value="option.value"
        :true-value="option.value"
        :false-value="optionValueFalse"
      />
      <label :for="`${field.id}-${index}`">
        {{ option.label }}
      </label>
    </div>

    <reactive-form-errors :errors="errors" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useFormField } from '../composables/useFormField'
import type { ReactiveFormFieldInputCheckbox } from '../types/ReactiveFormField.dto'
import type { ReactiveFormFieldValue } from '../types/ReactiveFormFieldValue.dto'
import ReactiveFormErrors from './reactiveFormFieldErrors.vue'
import type { Validation } from '@vuelidate/core'

interface Props {
  field: ReactiveFormFieldInputCheckbox
  vuelidate?: Validation | null
}

const props = withDefaults(defineProps<Props>(), {
  vuelidate: null
})

const { errors, className, isRequired, modelSetter } = useFormField(props)

const model = defineModel<ReactiveFormFieldValue>({ default: '', set: modelSetter })

const computedModel = computed({
  get: () => {
    if (props.field.options.length === 1) {
      return model.value
    }

    return Array.isArray(model.value) ? model.value : []
  },
  set: (value: any) => {
    model.value = value
  }
})

const optionValueFalse = computed(() => {
  const isBoolean = typeof props.field.options[0].value === 'boolean'
  const isNumber = typeof props.field.options[0].value === 'number'

  return props.field.options.length === 1 ? (isBoolean ? false : isNumber ? 0 : null) : null
})
</script>

<style lang="scss" scoped>
.reactive-form__field {
  margin-bottom: 10px !important;

  .custom-checkbox {
    margin-bottom: 10px;
    position: relative;

    label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      position: relative;
      padding-left: 30px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
      }

      &:before {
        border-radius: 2px;
        width: 24px;
        height: 24px;

        background-color: map-get($form-field-background-color, 'idle');
        border: 1px solid map-get($form-field-border-color, 'idle');
      }

      &:after {
        border-radius: 1px;
        width: 16px;
        height: 16px;
        top: 4px;
        left: 4px;
        visibility: hidden;

        background-color: $form-field-checkbox-checked;
      }
    }

    input[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    input[type='checkbox']:checked + label:before {
      border-color: $form-field-checkbox-checked;
    }
    input[type='checkbox']:checked + label:after {
      visibility: visible;
    }

    input[type='checkbox']:disabled + label {
      background-color: map-get($form-field-background-color, 'disabled');
      border-color: map-get($form-field-border-color, 'disabled');

      cursor: not-allowed;
    }

    input[type='checkbox']:hover + label:before {
      background-color: map-get($form-field-background-color, 'hover');
      border-color: map-get($form-field-border-color, 'hover');
    }

    input[type='checkbox']:focus + label:before {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');

      box-shadow: 0 0 0 5px $form-field-outline-color;
      border-radius: 2px;
    }

    input[type='checkbox']:focus:checked + label:before {
      border-color: $form-field-checkbox-checked;
    }

    input[type='checkbox']:focus:checked + label:after {
      background-color: $form-field-checkbox-checked;
      border-color: $form-field-checkbox-checked;
    }
  }
}
</style>
