<template>
  <action-dialog
    v-if="dialogIsOpen.editAction"
    ref="actionDialogRef"
    :input="actionReactive"
    :action="selectedAction"
    :location-id="actionContext?.locationId"
    :new-files="newFilesReactive"
    :saving="saving"
    :modal-props="{ focus: false }"
    @event="onEvent"
    @confirm="onConfirmEditAction"
    @hidden="onHideActionModal"
  />

  <add-files-dialog
    v-if="dialogIsOpen.addDocument"
    @confirm="onAddMediaFiles"
    @hidden="dialogIsOpen.addDocument = false"
  />

  <modal-delete
    v-if="dialogIsOpen.deleteDocument"
    :title="$t('actions.editAction.deleteDocument.title')"
    @confirm="onConfirmDeleteMediaFile"
    @hidden="dialogIsOpen.deleteDocument = false"
  />

  <modal-image-viewer
    v-if="dialogIsOpen.viewImage"
    :images="modalViewImagesSettings.images"
    :current="modalViewImagesSettings.current"
    :modal-props="{ backdrop: false, focus: false }"
    @hidden="dialogIsOpen.viewImage = false"
  />

  <modal-delete
    v-if="dialogIsOpen.deleteAction"
    :modal-props="{ backdrop: !dialogIsOpen.editAction }"
    :title="t('actions.deleteAction.title', { name: actionReactive?.name })"
    :confirm-button-text="t('actions.deleteAction.confirmButton')"
    @confirm="onConfirmModalDeleteAction"
    @hidden="dialogIsOpen.deleteAction = false"
  />

  <modal-alert
    v-if="dialogIsOpen.cannotDeleteAutomaticQuestion"
    :title="$t('actions.cannotDeleteAutomaticQuestion')"
    @hidden="dialogIsOpen.cannotDeleteAutomaticQuestion = false"
  />
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'

// support
import { useAlertStore } from '@/support/components/alert/store'
import { delay } from '@/support/helpers/delay'
import i18n from '@/support/core/localization'

// types
import type { Alert } from '@/support/components/alert/types'
import type { MediaListItem } from '@/features/media/types'
import type {
  Action,
  ActionContext,
  ActionEditDialogEvent,
  UpdateAction
} from '@/features/actions/types'

// features
import { useActionsStore } from '@/features/actions/store'
import { useActiveLocationCycle } from '@/features/cycle/composition/use-cycle'
import { currentContextApiPath, currentLocationId } from '@/features/auth'

import { useActionsPermissions } from '@/features/actions/compositions/useActionsPermissions'
import ActionDialog from '@/features/actions/components/actionDialog/index.vue'
import AddFilesDialog from '@/features/media/components/addFilesDialog.vue'
import ModalDelete from '@/features/modal/modalDelete/index.vue'
import ModalAlert from '@/features/modal/modalAlert/index.vue'
import { Status } from '@/support/enums/Status'
import type { Guid } from '@/support/types/Guid.dto'
import { useApi } from '@/features/api/api'
import Console from '@/support/core/console'

const props = defineProps<{ context?: ActionContext }>()
const saving = defineModel<boolean>('saving')
const emit = defineEmits<{ 'close-edit-dialog': [] }>()

const { t } = i18n.global

const actionToUpdateAction = (action?: Action): UpdateAction => ({
  id: action?.id,
  name: action?.name || null,
  description: action?.description || null,
  status: action?.status || 'todo',
  managerId: action?.manager?.id || null,
  locationId: action?.location?.id || null,
  topicId: action?.topic?.id || null,
  mediaUrls: [...(action?.mediaUrls || [])],
  deadlineAt: action?.deadlineAt || null
})

const alertStore = useAlertStore()
const actionsStore = useActionsStore()
const cycleStore = useActiveLocationCycle()
const api = useApi()

const actionDialogRef = ref<InstanceType<typeof ActionDialog>>()

const actionsPermissions = useActionsPermissions()
const { canDeleteActionsPermission } = actionsPermissions

const selectedAction = ref<Action>()
const actionReactive = reactive<UpdateAction>(actionToUpdateAction())
const actionOriginal = ref<Action>()
const newFilesReactive = reactive<File[]>([])

const actionContext = ref<ActionContext | undefined>(props.context)
const modalDeleteDocumentSettings = ref<MediaListItem<string>>()
const modalViewImagesSettings = ref<any>({
  files: [],
  current: 0
})

const dialogIsOpen = reactive({
  editAction: false,
  deleteAction: false,
  addDocument: false,
  deleteDocument: false,
  viewImage: false,
  cannotDeleteAutomaticQuestion: false
})

const onEvent = (event: ActionEditDialogEvent) => {
  Console.log('onEvent', event)

  switch (event.type) {
    case 'delete': {
      actionDeleteHandler(event.id)
      break
    }
    case 'add-documents':
      dialogIsOpen.addDocument = true
      break
    case 'delete-document': {
      dialogIsOpen.deleteDocument = true
      modalDeleteDocumentSettings.value = event.document
      break
    }
    default:
      break
  }
}

const actionDeleteHandler = async (id: Guid) => {
  const item: Action | null = (await actionsStore.getAction(id)) ?? null

  if (!item || !canDeleteActionsPermission.value) {
    return
  }

  selectedAction.value = item

  if (item.questionEnabled) {
    dialogIsOpen.cannotDeleteAutomaticQuestion = true
    return
  }

  dialogIsOpen.deleteAction = true
}

const onHideActionModal = () => {
  dialogIsOpen.editAction = false
  emit('close-edit-dialog')
}

const onConfirmEditAction = async (input: UpdateAction, newFiles: File[]) => {
  Console.log('onConfirmEditAction', input, newFiles)

  saving.value = true

  const method = input.id ? 'put' : 'post'

  const newMediaUrls = await api.media.uploadNewMedia(newFiles)
  const form: UpdateAction = {
    ...input,
    mediaUrls: [...input.mediaUrls, ...newMediaUrls]
  }

  const partialForm: Partial<UpdateAction> = {}

  const orig = actionOriginal.value
  if (form.id) {
    if (!orig) {
      throw new Error('could not find original action')
    }

    if (form.name !== orig.name) {
      partialForm.name = form.name || ''
    }
    if (form.description !== orig.description) {
      partialForm.description = form.description || ''
    }
    if (form.managerId && orig.manager?.id !== form.managerId) {
      partialForm.managerId = form.managerId
    }
    if (JSON.stringify(form.mediaUrls.sort()) !== JSON.stringify(orig.mediaUrls.sort())) {
      partialForm.mediaUrls = form.mediaUrls
      const removedMediaCount = orig.mediaUrls.length - form.mediaUrls.length
      if (removedMediaCount > 0) {
        alertStore.setMessage({
          message: t('actions.overview.deleteFiles', { count: removedMediaCount }),
          type: Status.success
        })
      }
    }
    if (form.topicId && form.topicId != orig.topic?.id) {
      partialForm.topicId = form.topicId
    }
    if (!form.topicId && orig.topic) {
      partialForm.removeTopic = true
    }
    if (form.status !== orig.status) {
      partialForm.status = form.status
    }
    if (form.deadlineAt !== orig.deadlineAt) {
      partialForm.deadlineAt = form.deadlineAt
    }
    if (!Object.keys(partialForm).length) {
      saving.value = false
      return
    }
  }

  const response = input.id
    ? await actionsStore.updateAction({ id: form.id, ...partialForm })
    : await actionsStore.createAction(form)

  if (!response?.data) {
    throw new Error('no action in response')
  }
  actionOriginal.value = response.data

  if (newMediaUrls.length) {
    input.mediaUrls.push(...newMediaUrls)
  }
  if (newFiles.length) {
    newFiles.splice(0, newFiles.length)
  }

  const alert: Alert =
    response?.status !== 200
      ? { message: t(`app.xhr.${method}.error`), type: Status.error }
      : {
          message: input.id ? t('alert.actions.updated') : t('alert.actions.created'),
          type: Status.success
        }

  if (response?.status === 200) {
    if (actionContext.value && response.data.id && method === 'post') {
      await actionsStore.attach(response.data.id, actionContext.value)
    }

    if (method === 'post') {
      actionsStore.setPaging(1)
    }
    await actionsStore.getPaginatedActions(currentContextApiPath.value)

    if (orig?.questionEnabled && cycleStore.locationCycle && currentLocationId.value) {
      await cycleStore.getActiveLocationCycle(currentLocationId.value)
    }
  }

  if (method === 'post' || response?.status !== 200) {
    alertStore.setMessage(alert)
  }

  if (method === 'put') {
    await delay(400)
  }

  saving.value = false
}

const onConfirmModalDeleteAction = async () => {
  Console.log('onConfirmModalDeleteAction', selectedAction.value?.id)

  if (!selectedAction.value?.id) {
    return
  }

  saving.value = true

  const response = await actionsStore.deleteAction(selectedAction.value.id)
  const alert: Alert =
    response.status !== 200
      ? { message: t('app.xhr.delete.error'), type: Status.error }
      : { message: t('alert.actions.deleted'), type: Status.success }

  if (response.status === 200) {
    actionsStore.setPaging(1)
    await actionsStore.getPaginatedActions(currentContextApiPath.value)

    if (dialogIsOpen.editAction) {
      actionDialogRef.value?.close()
    }
  }

  saving.value = false

  alertStore.setMessage(alert)
}

const onAddMediaFiles = (files: File[]) => {
  newFilesReactive.push(...files)
}

const onConfirmDeleteMediaFile = () => {
  if (!modalDeleteDocumentSettings.value) {
    return
  }
  if (modalDeleteDocumentSettings.value.type === 'new') {
    newFilesReactive.splice(newFilesReactive.indexOf(modalDeleteDocumentSettings.value.data), 1)
  }
  if (modalDeleteDocumentSettings.value.type === 'extended') {
    const index = actionReactive.mediaUrls.indexOf(modalDeleteDocumentSettings.value.data)
    if (index > -1) {
      actionReactive.mediaUrls.splice(index, 1)
    }
  }
}

defineExpose({
  openDialog: async (id: Guid | null, context?: ActionContext) => {
    actionContext.value = context
    newFilesReactive.splice(0, newFilesReactive.length)

    if (id) {
      selectedAction.value = await actionsStore.getAction(id)
      actionOriginal.value = selectedAction.value
      Object.assign(actionReactive, actionToUpdateAction(actionOriginal.value))
      dialogIsOpen.editAction = true
      return
    }

    Object.assign(actionReactive, actionToUpdateAction())
    dialogIsOpen.editAction = true
  }
})
</script>
