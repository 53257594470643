import Dropdown from '@/support/components/dropdownMenu/components/Dropdown.vue'
import DropdownSelector from '@/support/components/dropdownMenu/components/DropdownSelector.vue'
import DropdownMoreOptions from '@/support/components/dropdownMenu/components/DropdownMoreOptions.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    Dropdown: typeof Dropdown
    DropdownSelector: typeof DropdownSelector
    DropdownMoreOptions: typeof DropdownMoreOptions
  }
}

export default {
  Dropdown,
  DropdownSelector,
  DropdownMoreOptions
}
