<template>
  <div ref="container" class="modal modal-advanced fade" tabindex="-1">
    <div class="modal-dialog" :style="{ width: `960px` }">
      <div class="modal-content">
        <div class="modal-header">
          <h3>{{ title }}</h3>
          <button type="button" class="btn-close" @click="onCloseClick">
            <icon name="action-close" />
          </button>
        </div>
        <div class="modal-body">
          <video-player
            v-if="marketingConsent"
            :provider="provider"
            :embed-id="embedId"
            :auto-play="true"
          />
          <div v-else>
            <p>{{ $t('videoDialog.consent.text') }}</p>
            <button class="button-primary" @click="showCookieBanner = true">
              {{ $t('videoDialog.consent.button') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './localization'

import { onMounted, onUnmounted, computed, ref, watch } from 'vue'
import { Modal } from 'bootstrap'

interface VideoPlayerDialogProps {
  modalProps: any
  title: string
  provider: string
  embedId: string
  autoPlay?: boolean
}

const emit = defineEmits(['confirm', 'cancel', 'close', 'hidden'])
const container = ref<HTMLDivElement>()
const showCookieBanner = ref(false)
const Cookiebot = window.Cookiebot || null

const props = withDefaults(defineProps<VideoPlayerDialogProps>(), {
  modalProps: {} as any
})

let modal: any = null

const onHideModal = () => {
  emit('hidden')
}

const onCloseClick = () => {
  emit('close')
  modal.toggle()
}

const marketingConsent = computed(() => {
  if (!Cookiebot) return false
  return Cookiebot.consent.marketing
})

const enableVideoPlayer = () => {
  showCookieBanner.value = false
}

watch(
  showCookieBanner,
  (value) => {
    if (value && Cookiebot?.renew) {
      Cookiebot?.renew();
      modal.hide()
    }
  },
  { immediate: false }
)

onMounted(async () => {
  if (!container.value) {
    throw new Error('could not find container')
  }

  modal = new Modal(container.value, props.modalProps)
  container.value.addEventListener('hidden.bs.modal', onHideModal)
  container.value.addEventListener('shown.bs.modal', function (event) {
    event.preventDefault()
  })

  modal.show()

  if (Cookiebot) {
    window.addEventListener('CookieConsentUpdate', enableVideoPlayer)
  }
})

onUnmounted(() => {
  modal.dispose()

  if (Cookiebot) {
    window.removeEventListener('CookieConsentUpdate', enableVideoPlayer)
  }
})
</script>

<style lang="scss" scoped>
.modal.modal-advanced {
  --bs-modal-margin: 4rem;
  --bs-modal-header-padding: 40px;
  --bs-modal-header-border-width: 0;
  --bs-modal-padding: 0 40px 40px;
  --bs-modal-width: 960px;
  --bs-modal-border-width: 0;

  .modal-dialog {
    .modal-content {
      border-radius: 0;
      flex-grow: 1;
      border-top: 4px solid map-get($theme-color-primary, 'orange');
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      .modal-header {
        h3 {
          margin-bottom: 0;
        }
        button {
          margin-left: auto;
          border: 0;
          background: transparent;
        }
      }
    }
  }
}
</style>
