import Spinner from './index.vue'
import SpinnerContext from './components/spinnerContext.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    Spinner: typeof Spinner
    SpinnerContext: typeof SpinnerContext
  }
}

export default {
  Spinner,
  SpinnerContext
}
