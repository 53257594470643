import {
  type ActiveCycle,
  type CycleChecklist,
  type CycleLocationStep,
  type CycleStepSlug,
  cycleStepSlugList
} from '@/features/cycle/types'

type ProgressCount = { done: number; total: number }
export type CycleProgress = ProgressCount & { progress: number }

const DEFAULT_COUNT: ProgressCount = { total: 0, done: 0 }

const getProgress = (count: ProgressCount): CycleProgress => ({
  ...count,
  progress: count.total ? count.done / count.total : 1
})
export const getSumProgress = (...progressList: ProgressCount[]): CycleProgress =>
  getProgress({
    total: progressList.reduce<number>((result, item) => result + item.total, 0),
    done: progressList.reduce<number>((result, item) => result + item.done, 0)
  })

export const getChecklistProgress = (checkList: CycleChecklist | null): CycleProgress => {
  const result = (checkList?.subjects || []).reduce<ProgressCount>(
    (result, subject) =>
      ((subject.enabled && subject.subSubjects) || []).reduce<ProgressCount>(
        (result, subSubject) =>
          ((subSubject.enabled && subSubject.questions) || []).reduce<ProgressCount>(
            (result, question) => {
              if (!question.enabled) {
                return result
              }
              if (!question.reply) {
                return { ...result, total: result.total + 1 }
              }
              return { total: result.total + 1, done: result.done + 1 }
            },
            result
          ),
        result
      ),
    DEFAULT_COUNT
  )

  if (checkList?.locationChecklist?.isSkipped) {
    return getProgress({ total: 1, done: 1 })
  }

  return getProgress(result)
}

export const getCycleProgress = (
  cycle?: ActiveCycle | null
): CycleProgress & {
  topics: CycleProgress
  checklists: CycleProgress
  workforms: CycleProgress
  steps: CycleProgress
  step: Map<CycleStepSlug, CycleProgress>
} => {
  const topics = (cycle?.cycleTopics || []).filter((topic) => topic.enabled)
  const checklists: ProgressCount = topics.reduce<ProgressCount>((result, topic) => {
    return {
      total:
        result.total + (topic.checklists || []).filter((checklist) => checklist.enabled).length,
      done:
        result.done +
        (topic.checklists || [])
          .filter((checklist) => checklist.enabled)
          .reduce((result, checklist) => {
            if (checklist.locationChecklist?.isSkipped) {
              return result + 1
            }
            return result + getChecklistProgress(checklist).progress
          }, 0)
    }
  }, DEFAULT_COUNT)

  const workforms: ProgressCount = topics.reduce<ProgressCount>(
    (result, topic) => ({
      total: result.total + (topic.cycleWorkforms?.length || 0),
      done: (topic.cycleWorkforms || []).reduce((done, workform) => {
        if (
          workform.locationWorkform.status === 'completed' ||
          workform.locationWorkform.status === 'skipped'
        ) {
          return done + 1
        }
        return done
      }, result.done)
    }),
    DEFAULT_COUNT
  )

  const stepProgress: Map<CycleStepSlug, CycleProgress> = new Map()
  const getStepProgress = (step?: CycleLocationStep): ProgressCount => {
    if (!step) {
      return { total: 0, done: 0 }
    }
    if (step.status === 'toDo' || step.status === 'inProgress') {
      return { total: 1, done: 0 }
    }
    if (step.status === 'skipped') {
      return { total: 1, done: 1 }
    }
    return { total: 1, done: 1 }
  }
  cycleStepSlugList.forEach((slug) => {
    const step = cycle?.cycleSteps.find((step) => step.slug === slug)
    stepProgress.set(slug, getProgress(getStepProgress(step?.cycleLocationStep)))
  })

  const steps: ProgressCount = cycleStepSlugList.reduce<ProgressCount>(
    (result, slug) => getSumProgress(result, stepProgress.get(slug) || DEFAULT_COUNT),
    DEFAULT_COUNT
  )

  return {
    ...getProgress(getSumProgress(checklists, workforms, steps)),
    checklists: getProgress(checklists),
    workforms: getProgress(workforms),
    steps: getProgress(steps),
    topics: getProgress(getSumProgress(checklists, workforms)),
    step: stepProgress
  }
}
