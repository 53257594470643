import i18n from '@/support/core/localization'

const { tm, te, locale, mergeLocaleMessage } = i18n.global

export const setCustomLocaleMessages = (field: any) => {
  const validations = te('validations.custom') ? tm('validations.custom') : {}
  const { validationMessages = null } = field

  if (validationMessages) {
    mergeLocaleMessage(locale.value, {
      validations: {
        custom: {
          ...validations,
          [field.id]: validationMessages
        }
      }
    })
  }
}
