const getCurrentContentTreeFromPath = (path, contentTree) => {
  let currentContentTree = contentTree
  let currentLevel = 0
  let parent = null

  if (path.length) {
    for (const p in path) {
      for (const i in currentContentTree) {
        if (currentContentTree[i].slug === path[p]) {
          const { title, type, hidden, contentTree } = currentContentTree[i]

          parent = { title, type, hidden }
          currentContentTree = contentTree

          currentLevel++
          break
        }
      }
    }
  }

  return {
    currentLevel,
    currentContentTree,
    parent
  }
}

export default getCurrentContentTreeFromPath
