import { PermissionName } from '@/features/roles/types'

export default [
  {
    path: '/dashboard/bewerk-huidige-cyclus',
    name: 'edit-current-cycle.index',
    component: () => import('@/app/page/EditCurrentCycleIndex.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true,
      title: 'Bewerk huidige cyclus'
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/grote-risicos',
    name: 'edit-current-cycle.big-risks',
    component: () => import('@/app/page/NewCycleBigRisks.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true,
      title: "Bewerk huidige cyclus - Grote Risico's"
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/kleine-risicos',
    name: 'edit-current-cycle.small-risks',
    component: () => import('@/app/page/NewCycleSmallRisks.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true,
      title: "Kleine Risico's - Bewerk huidige cyclus"
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/themas-inrichten',
    name: 'edit-current-cycle.topics',
    component: () => import('@/app/page/NewCycleTopics.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true,
      title: "Thema's inrichten - Bewerk huidige cyclus"
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/beleid',
    name: 'edit-current-cycle.policy',
    component: () => import('@/app/page/NewCyclePolicy.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true,
      title: 'Beleid actualiseren - Bewerk huidige cyclus'
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/checklist/:checklistId',
    name: 'edit-current-cycle.checklist',
    component: () => import('@/app/page/NewCycleChecklist.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/checklist/:checklistId/subject/:subjectId',
    name: 'edit-current-cycle.checklist-subject',
    component: () => import('@/app/page/NewCycleChecklist.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/planning-maken',
    name: 'edit-current-cycle.planning',
    component: () => import('@/app/page/NewCyclePlanning.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true,
      title: 'Planning bewerken - Bewerk huidige cyclus'
    }
  },
  {
    path: '/dashboard/bewerk-huidige-cyclus/workform/:workformId',
    name: 'edit-current-cycle.workform',
    component: () => import('@/app/page/NewCycleWorkform.vue'),
    props: { editCurrentCycle: true },
    meta: {
      requiredPermissions: PermissionName.CyclusEditCurrent,
      requiresOrganisationAccess: true
    }
  }
]
