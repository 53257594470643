import { defineAsyncComponent } from 'vue'

interface Icons {
  [key: string]: any
}

const actions: Icons = {
  'action-add': defineAsyncComponent(() => import('@/app/img/icon/action-add.svg')),
  'action-add-disabled': defineAsyncComponent(
    () => import('@/app/img/icon/action-add-disabled.svg')
  ),
  'action-account': defineAsyncComponent(() => import('@/app/img/icon/action-account.svg')),
  'action-back': defineAsyncComponent(() => import('@/app/img/icon/action-back.svg')),
  'action-back-hover': defineAsyncComponent(() => import('@/app/img/icon/action-back-hover.svg')),
  'action-book': defineAsyncComponent(() => import('@/app/img/icon/action-book.svg')),
  'action-checkmark': defineAsyncComponent(() => import('@/app/img/icon/action-checkmark.svg')),
  'action-clock': defineAsyncComponent(() => import('@/app/img/icon/action-clock.svg')),
  'action-arrow-left-large': defineAsyncComponent(
    () => import('@/app/img/icon/action-arrow-left-large.svg')
  ),
  'action-arrow-right': defineAsyncComponent(() => import('@/app/img/icon/action-arrow-right.svg')),
  'action-arrow-right-hover': defineAsyncComponent(
    () => import('@/app/img/icon/action-arrow-right-hover.svg')
  ),
  'action-arrow-right-large': defineAsyncComponent(
    () => import('@/app/img/icon/action-arrow-right-large.svg')
  ),
  'action-check-white': defineAsyncComponent(() => import('@/app/img/icon/action-check-white.svg')),
  'action-chevron-down': defineAsyncComponent(
    () => import('@/app/img/icon/action-chevron-down.svg')
  ),
  'action-chevron-down-disabled': defineAsyncComponent(
    () => import('@/app/img/icon/action-chevron-down-disabled.svg')
  ),
  'action-chevron-left': defineAsyncComponent(
    () => import('@/app/img/icon/action-chevron-left.svg')
  ),
  'action-chevron-right': defineAsyncComponent(
    () => import('@/app/img/icon/action-chevron-right.svg')
  ),
  'action-chevron-up': defineAsyncComponent(() => import('@/app/img/icon/action-chevron-up.svg')),
  'action-drag-and-drop': defineAsyncComponent(
    () => import('@/app/img/icon/action-drag-and-drop.svg')
  ),
  'action-checklist': defineAsyncComponent(() => import('@/app/img/icon/action-checklist.svg')),
  'action-close': defineAsyncComponent(() => import('@/app/img/icon/action-close.svg')),
  'action-date': defineAsyncComponent(() => import('@/app/img/icon/action-date.svg')),
  'action-date-white': defineAsyncComponent(() => import('@/app/img/icon/action-date-white.svg')),
  'action-date-red': defineAsyncComponent(() => import('@/app/img/icon/action-date-red.svg')),
  'action-date-orange': defineAsyncComponent(() => import('@/app/img/icon/action-date-orange.svg')),
  'action-delete': defineAsyncComponent(() => import('@/app/img/icon/action-delete.svg')),
  'action-delete-red': defineAsyncComponent(() => import('@/app/img/icon/action-delete-red.svg')),
  'action-delete-white': defineAsyncComponent(
    () => import('@/app/img/icon/action-delete-white.svg')
  ),
  'action-check-circle': defineAsyncComponent(
    () => import('@/app/img/icon/action-check-circle.svg')
  ),
  'action-done': defineAsyncComponent(() => import('@/app/img/icon/action-done.svg')),
  'action-down': defineAsyncComponent(() => import('@/app/img/icon/action-down.svg')),
  'action-down-hover': defineAsyncComponent(() => import('@/app/img/icon/action-down-hover.svg')),
  'action-download': defineAsyncComponent(() => import('@/app/img/icon/action-download.svg')),
  'action-download-disabled': defineAsyncComponent(
    () => import('@/app/img/icon/action-download-disabled.svg')
  ),
  'action-edit': defineAsyncComponent(() => import('@/app/img/icon/action-edit.svg')),
  'action-edit-disabled': defineAsyncComponent(
    () => import('@/app/img/icon/action-edit-disabled.svg')
  ),
  'action-email': defineAsyncComponent(() => import('@/app/img/icon/action-email.svg')),
  'action-edit-white': defineAsyncComponent(() => import('@/app/img/icon/action-edit-white.svg')),
  'action-error': defineAsyncComponent(() => import('@/app/img/icon/action-error.svg')),
  'action-help-rounded': defineAsyncComponent(
    () => import('@/app/img/icon/action-help-rounded.svg')
  ),
  'action-help-rounded-hover': defineAsyncComponent(
    () => import('@/app/img/icon/action-help-rounded-hover.svg')
  ),
  'action-info': defineAsyncComponent(() => import('@/app/img/icon/action-info.svg')),
  'action-invisible': defineAsyncComponent(() => import('@/app/img/icon/action-invisible.svg')),
  'action-in-progress': defineAsyncComponent(() => import('@/app/img/icon/action-in-progress.svg')),
  'action-play': defineAsyncComponent(() => import('@/app/img/icon/action-play.svg')),
  'action-play-white': defineAsyncComponent(() => import('@/app/img/icon/action-play-white.svg')),
  'action-print': defineAsyncComponent(() => import('@/app/img/icon/action-print.svg')),
  'action-risk': defineAsyncComponent(() => import('@/app/img/icon/action-risk.svg')),
  'action-search': defineAsyncComponent(() => import('@/app/img/icon/action-search.svg')),
  'action-spinner': defineAsyncComponent(() => import('@/app/img/icon/action-spinner.svg')),
  'action-squares': defineAsyncComponent(() => import('@/app/img/icon/action-squares.svg')),
  'action-theme': defineAsyncComponent(() => import('@/app/img/icon/action-theme.svg')),
  'action-to-do': defineAsyncComponent(() => import('@/app/img/icon/action-to-do.svg')),
  'action-toggle-filter': defineAsyncComponent(
    () => import('@/app/img/icon/action-toggle-filter.svg')
  ),
  'action-toggle-filter-active': defineAsyncComponent(
    () => import('@/app/img/icon/action-toggle-filter-active.svg')
  ),
  'action-upload': defineAsyncComponent(() => import('@/app/img/icon/action-upload.svg')),
  'action-user': defineAsyncComponent(() => import('@/app/img/icon/action-user.svg')),
  'action-visible': defineAsyncComponent(() => import('@/app/img/icon/action-visible.svg')),
  'action-warning': defineAsyncComponent(() => import('@/app/img/icon/action-warning.svg')),
  'action-settings': defineAsyncComponent(() => import('@/app/img/icon/action-settings.svg')),
  'action-speech-bubble': defineAsyncComponent(
    () => import('@/app/img/icon/action-speech-bubble.svg')
  ),
  'action-logout': defineAsyncComponent(() => import('@/app/img/icon/action-logout.svg')),
  'action-more-options': defineAsyncComponent(
    () => import('@/app/img/icon/action-more-options.svg')
  ),
  'action-incident': defineAsyncComponent(() => import('@/app/img/icon/action-incident.svg')),
  'action-outbound-link': defineAsyncComponent(
    () => import('@/app/img/icon/action-outbound-link.svg')
  ),
  'action-outbound-link-hover': defineAsyncComponent(
    () => import('@/app/img/icon/action-outbound-link-hover.svg')
  ),
  'action-opportunity': defineAsyncComponent(() => import('@/app/img/icon/action-opportunity.svg')),
  'action-opportunity-disabled': defineAsyncComponent(
    () => import('@/app/img/icon/action-opportunity-disabled.svg')
  ),
  'action-location': defineAsyncComponent(() => import('@/app/img/icon/action-location.svg')),
  'action-locations': defineAsyncComponent(() => import('@/app/img/icon/action-locations.svg')),
  'action-up': defineAsyncComponent(() => import('@/app/img/icon/action-up.svg')),
  'action-up-hover': defineAsyncComponent(() => import('@/app/img/icon/action-up-hover.svg')),
  'action-view': defineAsyncComponent(() => import('@/app/img/icon/action-view.svg')),
  'action-view-white': defineAsyncComponent(() => import('@/app/img/icon/action-view-white.svg')),
  'action-workform': defineAsyncComponent(() => import('@/app/img/icon/action-workform.svg')),
  'action-workform-blue': defineAsyncComponent(() => import('@/app/img/icon/action-workform-blue.svg'))
}

const menuItems: Icons = {
  'menu-item-organisation': defineAsyncComponent(
    () => import('@/app/img/icon/menu-item-organisation.svg')
  ),
  'menu-item-organisation-hover': defineAsyncComponent(
    () => import('@/app/img/icon/menu-item-organisation-hover.svg')
  ),
  'menu-item-locations': defineAsyncComponent(
    () => import('@/app/img/icon/menu-item-locations.svg')
  ),
  'menu-item-locations-hover': defineAsyncComponent(
    () => import('@/app/img/icon/menu-item-locations-hover.svg')
  ),
  'menu-item-settings': defineAsyncComponent(() => import('@/app/img/icon/menu-item-settings.svg')),
  'menu-item-settings-hover': defineAsyncComponent(
    () => import('@/app/img/icon/menu-item-settings-hover.svg')
  ),
  'menu-item-users': defineAsyncComponent(() => import('@/app/img/icon/menu-item-users.svg')),
  'menu-item-users-hover': defineAsyncComponent(
    () => import('@/app/img/icon/menu-item-users-hover.svg')
  )
}

const theme: Icons = {
  'theme-1': defineAsyncComponent(() => import('@/app/img/icon/theme-1.svg')),
  'theme-1-white': defineAsyncComponent(() => import('@/app/img/icon/theme-1-white.svg')),
  'theme-2': defineAsyncComponent(() => import('@/app/img/icon/theme-2.svg')),
  'theme-2-white': defineAsyncComponent(() => import('@/app/img/icon/theme-2-white.svg')),
  'theme-3': defineAsyncComponent(() => import('@/app/img/icon/theme-3.svg')),
  'theme-3-white': defineAsyncComponent(() => import('@/app/img/icon/theme-3-white.svg')),
  'theme-4': defineAsyncComponent(() => import('@/app/img/icon/theme-4.svg')),
  'theme-4-white': defineAsyncComponent(() => import('@/app/img/icon/theme-4-white.svg')),
  'theme-5': defineAsyncComponent(() => import('@/app/img/icon/theme-5.svg')),
  'theme-5-white': defineAsyncComponent(() => import('@/app/img/icon/theme-5-white.svg')),
  'theme-6': defineAsyncComponent(() => import('@/app/img/icon/theme-6.svg')),
  'theme-6-white': defineAsyncComponent(() => import('@/app/img/icon/theme-6-white.svg')),
  'theme-7': defineAsyncComponent(() => import('@/app/img/icon/theme-7.svg')),
  'theme-7-white': defineAsyncComponent(() => import('@/app/img/icon/theme-7-white.svg')),
  'theme-8': defineAsyncComponent(() => import('@/app/img/icon/theme-8.svg')),
  'theme-8-white': defineAsyncComponent(() => import('@/app/img/icon/theme-8-white.svg'))
}

const illustrations = {
  'illustration-empty-state-default': defineAsyncComponent(
    () => import('@/app/img/icon/illustration-empty-state-default.svg')
  ),
  'illustration-empty-state-actions': defineAsyncComponent(
    () => import('@/app/img/icon/illustration-empty-state-actions.svg')
  ),
  'illustration-mobile-detect': defineAsyncComponent(
    () => import('@/app/img/icon/illustration-mobile-detect.svg')
  ),
  'illustration-cycle': defineAsyncComponent(() => import('@/app/img/icon/illustration-cycle.svg'))
}

export default {
  actions,
  menuItems,
  theme,
  illustrations
}
