import { computed } from 'vue'
import { toKebabCase } from '@/support/helpers/string'
import { FieldValidationType } from '../types/FieldValidationType.dto'
import type { ReactiveFormField } from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import type { Validation } from '@vuelidate/core'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import { ZIP_CODE } from '@/support/components/reactiveForm/constants/validationRegex'

interface ErrorItem {
  $message: string
  [key: string]: any
}

export function useFormField<ModelValue = unknown>(props: {
  vuelidate?: Validation | null | undefined
  field: ReactiveFormField
}) {
  const isRequired = computed(() => {
    if (props.vuelidate?.required) {
      return true
    }

    const { validationRules = null } = props.field

    if (!validationRules) return false

    const isRequired = validationRules[FieldValidationType.REQUIRED] ?? false
    const isSameAs = validationRules[FieldValidationType.SAME_AS] === 1

    return isRequired || isSameAs
  })

  const hidden = computed(() => {
    const { hidden = false } = props.field
    return hidden
  })

  const className = computed(() => {
    const defaultClassName = `${toKebabCase(props.field.id)}-field`

    const failed = props.vuelidate ? props.vuelidate.$error && props.vuelidate.$dirty : null
    const passed = props.vuelidate ? !props.vuelidate.$error && props.vuelidate.$dirty : null
    const errors = { failed, passed }

    return [
      defaultClassName,
      { ...errors, 'd-none': hidden.value, readonly: props.field.readonly },
      props.field.className
    ]
  })

  const errors = computed(() => {
    if (!props.vuelidate) return []

    return [
      ...new Map(props.vuelidate.$errors.map((item) => [item.$message, item])).values()
    ] as ErrorItem[]
  })

  const modelSetter = (input: ModelValue) => {
    if (
      props.field.type === FieldType.INPUT_TEXT &&
      props.field.validationRules?.zipCode &&
      typeof input === 'string'
    ) {
      // add space to zipcode and change letters to upper-case
      const match = input.match(ZIP_CODE)
      if (!match) {
        return input
      }
      return `${match[1]} ${match[3].toUpperCase()}`
    }

    return input
  }

  return { errors, className, isRequired, modelSetter }
}
