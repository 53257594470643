<template>
  <div class="navigation">
    <h3>{{ t('settings.title') }}</h3>
    <sub-menu-vertical :navigation="navigation" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { hasAccessToRoute } from '@/features/auth'
import { userInfo } from '@/features/auth'
import { getUserInitials } from '@/features/user/helpers'

const router = useRouter()

const navigation = computed(() => {
  const initials = getUserInitials(userInfo.value)
  return [
    {
      name: 'settings.account',
      to: { name: 'settings.account' },
      title: t('settings.subMenu.account'),
      useCredentials: initials
    },
    {
      name: 'settings.organisation',
      to: { name: 'settings.organisation' },
      title: t('settings.subMenu.organisation'),
      icon: {
        name: 'menu-item-organisation',
        size: { width: 36, height: 36 },
        states: ['hover']
      }
    },
    {
      name: 'settings.locations',
      to: { name: 'settings.locations' },
      title: t('settings.subMenu.locations'),
      icon: {
        name: 'menu-item-locations',
        size: { width: 36, height: 36 },
        states: ['hover']
      }
    },
    {
      name: 'settings.users',
      to: { name: 'settings.users' },
      title: t('settings.subMenu.users'),
      icon: {
        name: 'menu-item-users',
        size: { width: 36, height: 36 },
        states: ['hover']
      }
    },
    {
      name: 'settings.roles',
      to: { name: 'settings.roles' },
      title: t('settings.subMenu.roles'),
      icon: {
        name: 'menu-item-settings',
        size: { width: 36, height: 36 },
        states: ['hover']
      }
    }
  ].filter(({ to }) => hasAccessToRoute(router.resolve(to)))
})

const { t } = useI18n()
</script>

<style scoped>
.navigation {
  min-width: 261px;
}
</style>
