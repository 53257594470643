<template>
  <div id="image-viewer" ref="container" class="modal modal-image-viewer fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="image-viewer__count">
            <span>{{ currentIndex + 1 }}</span>
            <span>/</span>
            <span>{{ images?.length }}</span>
          </div>
          <button @click="onCloseClick">
            <icon name="action-close" />
          </button>
        </div>
        <div class="modal-body">
          <div class="image-viewer__pagination">
            <button @click="onPreviousClick">
              <icon name="action-arrow-left-large" :size="{ width: 60, height: 60 }" />
            </button>
            <button @click="onNextClick">
              <icon name="action-arrow-right-large" :size="{ width: 60, height: 60 }" />
            </button>
          </div>
          <div class="image-viewer__image">
            <media-image :file="images[currentIndex]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { Modal } from 'bootstrap'
import type { MediaListItem } from '@/features/media/types'

type Props = {
  images: MediaListItem[]
  modalProps?: Partial<Modal.Options>
  current?: number
}
const props = withDefaults(defineProps<Props>(), {
  current: 0,
  modalProps: {} as any
})

const container = ref<HTMLDivElement>()
let modal: any = null

const dialog = ref<HTMLElement | null>(null)
const emit = defineEmits(['close', 'hidden'])
const currentIndex = ref(props.current ?? 0)

const onPreviousClick = () => {
  currentIndex.value = currentIndex.value === 0 ? props.images.length - 1 : currentIndex.value - 1
}

const onNextClick = () => {
  currentIndex.value = currentIndex.value === props.images.length - 1 ? 0 : currentIndex.value + 1
}

const onHideModal = () => {
  emit('hidden')
}

const onCloseClick = () => {
  emit('close')
  modal.toggle()
}

onClickOutside(dialog, () => {
  modal.toggle()
})

onMounted(() => {
  if (!container.value) {
    throw new Error('could not find container')
  }

  modal = new Modal(container.value, props.modalProps)
  container.value.addEventListener('hidden.bs.modal', onHideModal)
  container.value.addEventListener('shown.bs.modal', function (event) {
    event.preventDefault()
  })

  modal.show()
})
</script>

<style lang="scss" scoped>
.modal.modal-image-viewer {
  --bs-modal-header-border-width: 0;

  .modal-dialog {
    display: flex;
    flex-direction: row;
    transition: all 0.3s ease-out !important;
    min-height: 700px;
    max-width: 1440px;

    button {
      all: unset;
      cursor: pointer;
    }

    .modal-content,
    .modal-header,
    .modal-body,
    .modal-footer {
      border-radius: 0;
    }

    .modal-header {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      position: relative;

      .image-viewer__count {
        display: flex;
        margin: 0 auto;
        gap: 4px;

        span {
          font-size: 18px;
          font-weight: 700;
          color: map-get($theme-color-primary, 'light-blue-1');

          &:first-child {
            color: map-get($theme-color-primary, 'dark-blue');
          }
        }
      }

      button {
        position: absolute;
        right: 0;
        margin: 0 20px 0 0;
      }
    }

    .modal-body {
      padding: 0 0 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .image-viewer__pagination {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 100px;
        display: flex;
        justify-content: space-between;
      }

      .image-viewer__image {
        img {
          max-width: 960px;
          max-height: 720px;
          object-fit: contain;
          user-select: none;
        }
      }
    }

    .modal-footer {
      display: none;
    }
  }
}
</style>
