import { NotificationType } from '@/features/notifications/types'

export const notificationTypeIcon = (type: NotificationType | null | undefined) => {
  switch (type) {
    case NotificationType.INCIDENT:
      return 'action-incident'
    case NotificationType.DANGEROUS_SITUATION:
      return 'action-warning'
    case NotificationType.OPPORTUNITY:
      return 'action-opportunity'
    default:
      return 'action-help-rounded'
  }
}
