<template>
  <div class="notification">
    <div class="notification__icon" :class="{ circle: iconCircle }">
      <icon :name="computedIconProps.name" :size="computedIconProps.size" />
    </div>
    <div class="notification__text">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Notification } from './types/Notification.dto'

const props = withDefaults(defineProps<Notification>(), {
  iconCircle: true
})

const computedIconProps = computed(() => {
  let { name = 'action-warning', size = { width: 50, height: 50 } } = props.iconProps ?? {}

  if (!props.iconCircle) {
    size.width = 24
    size.height = 24
  }

  return {
    name,
    size
  }
})
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-left: 2px solid map-get($theme-color-primary, 'orange');
  box-shadow:
    0px 8px 8px 0px rgba(38, 56, 75, 0.05),
    0px -4px 8px 0px rgba(38, 56, 75, 0.05);
  gap: 10px;

  &__text {
    :deep(strong) {
      color: map-get($theme-color-primary, 'orange');
    }
  }

  &__icon.circle {
    :deep(.icon) {
      background-color: rgba(250, 207, 156, 0.4);
      border-radius: 50%;

      svg.icon__svg {
        all: unset;
        width: 30px;
        height: 30px;
        margin: 10px;
      }
    }
  }
}
</style>
