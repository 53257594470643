import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import { useStorage } from '@vueuse/core'
import { computed } from 'vue'
import { User as OIDCUser, UserManager } from 'oidc-client-ts'
import { DateTime } from 'luxon'
import { authConfig } from '@/features/auth/config'
import Console from '@/support/core/console'

const userManager = new UserManager(authConfig)

const OIDUserStorageString = useStorage<string>('oidc-user', '')
const storedOIDCUser = computed<OIDCUser | undefined>({
  get: () =>
    OIDUserStorageString.value ? OIDCUser.fromStorageString(OIDUserStorageString.value) : undefined,
  set(input) {
    OIDUserStorageString.value = input ? input.toStorageString() : ''
  }
})

const hasFreshAccessToken = (user?: OIDCUser): boolean => {
  if (!user) {
    return false
  }
  if (!user.expires_at) {
    throw new Error('missing expires_at value in OID user')
  }
  if (user.expires_at - DateTime.now().toUnixInteger() < 5) {
    return false
  }
  return true
}

export const isAuthenticated = (): boolean =>
  hasFreshAccessToken(storedOIDCUser.value) || Boolean(storedOIDCUser.value?.refresh_token)

const setTokenCookie = (token: string | null) => {
  document.cookie = `bearerToken=${token || ''}; path=/api`
}

let getTokenPromise: Promise<string> | undefined
export const getAccessToken = async (): Promise<string> => {
  if (!isAuthenticated()) {
    setTokenCookie(null)
    return new Promise<string>(() => {
      userManager.signinRedirect()
    })
  }

  const user = storedOIDCUser.value
  if (user && hasFreshAccessToken(user)) {
    return user.access_token
  }
  if (getTokenPromise) {
    // token is already being refreshed
    return getTokenPromise
  }

  // token needs to be refreshed
  getTokenPromise = userManager.signinSilent().then((user) => {
    if (!user) {
      setTokenCookie(null)
      throw new Error('Cannot get user')
    }
    storedOIDCUser.value = user
    setTokenCookie(user.access_token)

    getTokenPromise = undefined
    return user.access_token
  })
  return getTokenPromise
}

export const fetchWithAuth = async (url: string, options: AxiosRequestConfig = {}) => {
  const fqUrl = isFullyQualifiedURL(url) ? url : `/api${url}`

  try {
    const token = await getAccessToken()

    const axiosConfig: AxiosRequestConfig = {
      ...options,
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        ...options.headers
      },
      params: {
        ...(options.params || {}),
        __t: !options.method || options.method === 'GET' ? Date.now() : undefined
      }
    }
    return await axios(fqUrl, axiosConfig)
  } catch (error) {
    if (error instanceof Error && error.message === 'The refresh token is invalid.') {
      return new Promise<AxiosResponse>(() => {
        storedOIDCUser.value = undefined
        document.location.replace('/auth/login')
      })
    }
    if (axios.isAxiosError(error)) {
      Console.error('Axios error status:', error.response?.status) // eslint-disable-line no-console
      return error.response
    }
  }
}

const isFullyQualifiedURL = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
  } catch (e) {
    return false
  }
}
