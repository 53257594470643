<template>
  <div class="banner-sticky">
    <h5>
      {{ title }}
      <a role="button" @click="() => emit('close')">
        <icon v-if="props.closable" name="action-close" />
      </a>
    </h5>
    <p>{{ text }}</p>
    <div class="banner__slot">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { StickyBanner } from './types/StickyBanner.dto'

const props = withDefaults(defineProps<StickyBanner>(), {
  closable: false
})

const emit = defineEmits(['close'])
</script>

<style lang="scss" scoped>
.banner-sticky {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: map-get($theme-color-primary, 'light-blue-2');
  border-left: 5px solid map-get($theme-color-primary, 'dark-blue');

  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  z-index: 1000;
  min-width: 849px;

  h5 {
    font-size: $theme-font-size-l;
    font-weight: 700;
    position: relative;

    a[role='button'] {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  p {
    margin-bottom: 24px;
  }
}
</style>
