<template>
  <div>
    <h2 class="mb-4">{{ t('user.details.title') }}</h2>
    <notification-primary v-if="profileChangeClicked" class="mb-3">
      <div v-safe-html="$t('user.details.profileChanged')" />
    </notification-primary>

    <reactive-form v-model:form="form" :config="formConfig">
      <div class="buttons">
        <a
          role="button"
          :href="changeProfileUrl"
          target="_blank"
          @click="profileChangeClicked = true"
        >
          <icon name="action-edit" />
          {{ t('user.details.editAccount') }}
        </a>
      </div>
    </reactive-form>
    <reactive-form-field-toggle v-model="receivesEmails" :field="notificationsField" />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/features/user/store'
import { useAlertStore } from '@/support/components/alert/store'

import type { ReactiveFormFieldToggle } from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import type { ReactiveFormConfig } from '@/support/components/reactiveForm/types/ReactiveFormConfig.dto'

import { Status } from '@/support/enums/Status'
import { reloadUserInfo, userInfo, tokenExpiresAt } from '@/features/auth'
import type { Alert } from '@/support/components/alert/types'
import type { UpdateUser } from '@/features/user/types'
import { useSessionStorage } from '@vueuse/core'

const changeProfileUrl = import.meta.env.VITE_APP_ACCOUNT_URL || 'https://www.veiligheid.nl/account'

const { t } = useI18n()

const notificationsField = computed<ReactiveFormFieldToggle>(() => ({
  id: 'notifications',
  type: FieldType.TOGGLE,
  hideLabel: true,
  options: [
    {
      label: t('user.details.form.notifications'),
      value: true
    }
  ]
}))

const formConfig = computed<ReactiveFormConfig>(() => ({
  legend: {
    id: 'MyAccount',
    text: t('user.details.form.legend')
  },
  buttons: null,
  fields: [
    {
      id: 'firstName',
      type: FieldType.INPUT_TEXT,
      label: t('user.details.form.firstName'),
      placeholder: t('app.defaults.unknown'),
      readonly: true
    },
    {
      id: 'namePrefix',
      type: FieldType.INPUT_TEXT,
      label: t('user.details.form.namePrefix'),
      placeholder: '-',
      readonly: true
    },
    {
      id: 'lastName',
      type: FieldType.INPUT_TEXT,
      label: t('user.details.form.lastName'),
      placeholder: t('app.defaults.unknown'),
      readonly: true
    },
    {
      id: 'email',
      type: FieldType.INPUT_TEXT,
      label: t('user.details.form.email'),
      placeholder: t('app.defaults.unknown'),
      readonly: true
    }
  ]
}))

const alertStore = useAlertStore()
const userStore = useUserStore()

const form = reactive<Partial<UpdateUser>>({})

const profileChangeClicked = useSessionStorage<boolean>(
  `profile-change-clicked-${tokenExpiresAt.value?.toUnixInteger() || '0'}`,
  false
)

const receivesEmails = ref<boolean | null>()

watch(
  userInfo,
  () => {
    Object.assign(form, userInfo.value)
    receivesEmails.value = userInfo.value?.receivesEmails
  },
  { immediate: true }
)

watch(receivesEmails, async () => {
  if (!userInfo.value?.id) {
    return
  }
  const response = await userStore.updateUser(userInfo.value.id, {
    receivesEmails: receivesEmails.value || false
  })
  const alert: Alert =
    response?.status !== 200
      ? { message: t('app.xhr.put.error', { status: response?.status }), type: Status.error }
      : { message: t('alert.users.updateSelf'), type: Status.success }

  await reloadUserInfo()

  alertStore.setMessage(alert)
})
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;

  a[role='button'] {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
}
:deep(.reactive-form) {
  fieldset.reactive-form__fieldset {
    .reactive-form__field {
      &.first-name-field,
      &.name-prefix-field,
      &.last-name-field {
        min-width: 0;
        flex: 0 0 33%;
      }
    }
  }
}
</style>
