import type { DesignSystemRoute } from '@/features/designSystem/types'

const navigation: Array<DesignSystemRoute> = [
  {
    title: 'Style foundations'
  },
  {
    to: 'design-system.logo',
    title: 'Logo'
  },
  {
    to: 'design-system.colors',
    title: 'Colors'
  },
  {
    to: 'design-system.typography',
    title: 'Typography'
  },
  {
    to: 'design-system.headings',
    title: 'Headings'
  },
  {
    to: 'design-system.hyperlink',
    title: 'Hyperlink'
  },
  {
    to: 'design-system.buttons',
    title: 'Buttons'
  },
  {
    title: 'Components'
  },
  {
    to: 'design-system.accordion',
    title: 'Accordion'
  },
  {
    to: 'design-system.alert',
    title: 'Alert'
  },
  {
    to: 'design-system.badge',
    title: 'Badge'
  },
  {
    to: 'design-system.banner',
    title: 'Banner'
  },
  {
    to: 'design-system.card',
    title: 'Card'
  },
  {
    to: 'design-system.carousel',
    title: 'Carousel'
  },
  {
    to: 'design-system.dropdown-menu',
    title: 'Dropdown Menu'
  },
  {
    to: 'design-system.editable-content',
    title: 'Editable Content'
  },
  {
    to: 'design-system.editable-content-container',
    title: 'Editable Content Container'
  },
  {
    to: 'design-system.empty-state',
    title: 'Empty State'
  },
  {
    to: 'design-system.file-selector',
    title: 'File Selector'
  },
  {
    to: 'design-system.html-editor',
    title: 'HTML-editor'
  },
  {
    to: 'design-system.icons',
    title: 'Icons'
  },
  {
    to: 'design-system.image-grid',
    title: 'Image Grid'
  },
  {
    to: 'design-system.list-label',
    title: 'List label'
  },
  {
    to: 'design-system.list-view',
    title: 'List View'
  },
  {
    to: 'design-system.modal',
    title: 'Modal'
  },
  {
    to: 'design-system.nested-doll-menu',
    title: 'Nested Doll Menu'
  },
  {
    to: 'design-system.notification',
    title: 'Notification'
  },
  {
    to: 'design-system.off-canvas',
    title: 'Off canvas'
  },
  {
    to: 'design-system.pagination',
    title: 'Pagination'
  },
  {
    to: 'design-system.parent-link',
    title: 'Parent link'
  },
  {
    to: 'design-system.reactive-form',
    title: 'Reactive Form'
  },
  {
    to: 'design-system.status-bar',
    title: 'Status Bar'
  },
  {
    to: 'design-system.spinner',
    title: 'Spinner'
  },
  {
    to: 'design-system.step-progress',
    title: 'Step Progress'
  },
  {
    to: 'design-system.sub-menu',
    title: 'Sub Menu'
  },
  {
    to: 'design-system.tabs',
    title: 'Tabs'
  },
  {
    to: 'design-system.toggle-button',
    title: 'Toggle button'
  },
  {
    to: 'design-system.toggle-view-edit',
    title: 'Toggle (view/edit)'
  },
  {
    to: 'design-system.tooltip',
    title: 'Tooltip'
  }
]

export default navigation
