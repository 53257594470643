<template>
  <Transition :css="false" @enter="onEnter" @before-leave="onBeforeLeave">
    <slot />
  </Transition>
</template>

<script setup lang="ts">
interface Props {
  modal: HTMLElement | null
  width?: number | null
}

const props = defineProps<Props>()

const onBeforeLeave = () => {
  const { modal = null } = props
  const dialog = (modal?.querySelector('.modal-dialog') as HTMLElement) ?? null

  if (!dialog) return

  const currentHeight = dialog.clientHeight

  dialog.style.height = `${currentHeight}px`
}

const onEnter = async (element: Element, done: () => void) => {
  const { modal = null } = props

  if (!modal) return

  // Clone modal
  const clone = modal.cloneNode(true) as HTMLElement
  clone.id = `${modal.id}-clone`

  // Set new width
  const dialog = clone.querySelector('.modal-dialog') as HTMLElement
  dialog.style.width = props.width ? `${props.width}px` : dialog.style.width
  dialog.style.height = 'auto'

  // Set height of modal body
  const body = clone.querySelector('.modal-body') as HTMLElement
  body.style.height = 'auto'

  // Hide cloned modal
  clone.style.visibility = 'hidden'

  // Replace content
  if (body.firstElementChild) {
    body.replaceChild(element.cloneNode(true), body.firstElementChild)
  } else {
    body.appendChild(element.cloneNode(true))
  }

  // Append cloned modal to document body
  document.body.appendChild(clone)

  // Set new height and width
  const height = dialog.clientHeight
  const updatedDialog = (modal.querySelector('.modal-dialog') as HTMLElement) ?? null

  if (!updatedDialog) {
    return
  }

  // Set transition and new size
  updatedDialog.style.transition = 'all 0.3s ease-in-out'
  updatedDialog.style.height = `${height}px`
  updatedDialog.style.width = props.width ? `${props.width}px` : dialog.style.width

  // Process done callback and remove clone
  updatedDialog.addEventListener('transitionend', () => {
    clone.remove()
    done()
  })
}
</script>
