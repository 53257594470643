import {
  getUserInfo,
  hasPermission,
  isAuthenticated,
  locations,
  signInCallback,
  singleLocation
} from '@/features/auth'
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNamedRaw,
  RouteRecordName,
  RouteParams,
  RouteQueryAndHash
} from 'vue-router'
import { PermissionName } from '@/features/roles/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { User } from '@/features/user/types'
import { useSessionStorage } from '@vueuse/core'
import { computed } from 'vue'
import Console from '@/support/core/console'

const redirectLocationStorage = useSessionStorage<string | undefined>(
  'redirect-location',
  undefined
)
const redirectLocation = computed<RouteLocationNamedRaw | undefined>({
  get: () =>
    redirectLocationStorage.value
      ? (JSON.parse(redirectLocationStorage.value) as RouteLocationNamedRaw)
      : undefined,
  set: (value: RouteLocationNamedRaw | undefined) => {
    redirectLocationStorage.value =
      value && value.name !== 'not-found' ? JSON.stringify(value) : undefined
  }
})

export const routeGuardFunction = async (
  to: RouteLocationNormalized,
  from?: RouteLocationNormalized
): Promise<RouteLocationNamedRaw | void> => {
  if (to.name === 'login.callback') {
    try {
      await signInCallback()
    } catch (e) {
      Console.info('Error while processing the sign in callback')
      Console.error(e)
    }
    const target = redirectLocation.value
    if (target) {
      redirectLocation.value = undefined
      return target
    }
    return { name: 'dashboard' }
  }

  const authRequired = to.matched.some(
    (record) =>
      record.meta.requiresAuth === true ||
      record.meta.requiresAuth === undefined ||
      record.meta.requiredPermissions?.length
  )
  if (!authRequired) {
    try {
      await getUserInfo()
    } catch (e) {
      // do nothing
    }
    return undefined
  }

  if (!isAuthenticated()) {
    redirectLocation.value = {
      name: to.name || '',
      params: to.params,
      query: to.query,
      hash: to.hash
    }
    return { name: 'login.index' }
  }

  let user: User | null | undefined = undefined
  try {
    user = await getUserInfo()
  } catch (e) {
    redirectLocation.value = {
      name: to.name || '',
      params: to.params,
      query: to.query,
      hash: to.hash
    }
    return { name: 'login.index' }
  }

  if (!user) {
    return { name: 'auth.accountHasNoOrganisation' }
  }

  if (!user?.hasAccessToOrganisation && !locations.value?.length) {
    return { name: 'auth.no-context' }
  }

  const isMissingPermission = to.matched.some(
    (record) => record.meta.requiredPermissions && !hasPermission(record.meta.requiredPermissions)
  )

  if (isMissingPermission) {
    return { name: 'auth.unauthorized' }
  }

  // Onboarding
  if (!user.isSuperUser) {
    // When the organisation configuration is not completed
    if (user.isOnboardingStarted && !user.isOnboardingComplete) {
      return { name: 'auth.organisationConfigurationNotCompleted' }
    }

    // When the user tries to access the onboarding pages
    if (to.path.startsWith('/onboarding')) {
      return { name: 'auth.unauthorized' }
    }
  }

  if (user.isSuperUser) {
    // When the (super) user has not started the onboarding
    if (!user.isOnboardingStarted && !String(to.name).startsWith('onboarding.')) {
      return { name: 'onboarding.start' }
    }

    // When the (super) user has not complete the onboarding force the user to complete it
    if (!user.isOnboardingComplete && !String(to.name).startsWith('onboarding.')) {
      return { name: 'onboarding.start' }
    }
  }

  let context: 'organisation' | Guid | undefined = String(
    to.query.context || from?.query.context || ''
  )

  if (to.meta.needsContext && !context) {
    if (user.hasAccessToOrganisation) {
      context = 'organisation'
    } else {
      context = (locations.value || [])[0]?.id
    }
  }

  if (to.name === 'dashboard') {
    if (context === 'organisation' && hasPermission(PermissionName.CyclusCreateShare)) {
      return { name: 'new-cycle.index' }
    }
    return { name: 'current-cycle.index', query: { context } }
  }

  if (context !== 'organisation' && !locations.value?.some((location) => location.id === context)) {
    context = undefined
  }
  if (to.meta.requiresOrganisationAccess) {
    if (!user.hasAccessToOrganisation) {
      return { name: 'auth.unauthorized' }
    }
    context = 'organisation'
  }

  if (
    singleLocation.value &&
    !to.meta.requiresOrganisationAccess &&
    context !== singleLocation.value.id
  ) {
    context = singleLocation.value.id
  }
  if (!context && locations.value?.length) {
    context = (locations.value || [])[0].id
  }

  if (to.query.context !== context) {
    if (!to.name) {
      throw new Error('no route name')
    }
    return { query: { ...to.query, context }, name: to.name, params: to.params, hash: to.hash }
  }
}

export const redirectEqualsTarget = (
  redirect: RouteLocationNamedRaw,
  to: { name?: RouteRecordName | null; params: RouteParams; query: RouteQueryAndHash }
) =>
  redirect.name === to.name &&
  JSON.stringify(redirect.params || {}) == JSON.stringify(to.params) &&
  JSON.stringify(redirect.query || {}) == JSON.stringify(to.query)

export const authRouteGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const redirect = await routeGuardFunction(to, from)
  if (!redirect || redirectEqualsTarget(redirect, to)) {
    next()
    return
  }
  next(redirect)
}
