import ListView from './index.vue'
import ListViewRow from './components/listViewRow.vue'
import ListViewColumn from './components/listViewColumn.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ListView: typeof ListView
    ListViewRow: typeof ListViewRow
    ListViewColumn: typeof ListViewColumn
  }
}

export default {
  ListView,
  ListViewRow,
  ListViewColumn
}
