import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { AxiosResponse } from 'axios'
import type { User, ContextOptions, UpdateUser, InviteUser, UserFilters } from '../types'
import type { Guid } from '@/support/types/Guid.dto'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'

export const getUser = async () => {
  return (await fetchWithAuth('/users/me', { method: 'POST' })) as AxiosResponse<User>
}

export const getUserContext = async () => {
  return (await fetchWithAuth('/contexts', { method: 'GET' })) as AxiosResponse<ContextOptions>
}

export const inviteUser = async (data: InviteUser) => {
  return (await fetchWithAuth(`/users/invite`, { method: 'POST', data })) as AxiosResponse
}

export const updateUser = async (id: Guid, data: Partial<UpdateUser>) => {
  return (await fetchWithAuth(`/users/${id}`, { method: 'PATCH', data })) as AxiosResponse
}

export const deleteUser = async (id: string) => {
  return (await fetchWithAuth(`/users/${id}`, { method: 'DELETE' })) as AxiosResponse
}

export const getUserFilters = async () => {
  return (await fetchWithAuth('/users/filters', {
    method: 'GET'
  })) as AxiosResponse<UserFilters>
}

export const getUsers = async (params: {
  roleId?: Guid | null
  locationId?: Guid | null
  pageIndex?: number
  pageSize?: number
}) => {
  return (await fetchWithAuth('/users', { method: 'GET', params })) as AxiosResponse<
    PaginatedApiResponse<User>
  >
}
