<template>
  <component
    :is="to ? 'router-link' : 'div'"
    class="dropdown-item"
    :class="{ selected }"
    :to="to"
    @click="onItemSelect"
  >
    <icon v-if="iconName" :name="iconName" :size="{ width: 24, height: 24 }" />
    <div class="label" :class="className">{{ name }}</div>
  </component>
</template>
<script setup lang="ts">
import { type DropdownItemProps } from '../dtos'

const props = defineProps<DropdownItemProps>()

const emit = defineEmits(['on-item-select'])
const onItemSelect = () => {
  if (props.onClick) {
    props.onClick()
  }
  emit('on-item-select', props.id)
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 6px;
  padding: 8px 8px 8px 4px;
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  color: map-get($theme-color-primary, 'dark-blue');
  text-decoration: none;

  &:hover {
    background-color: map-get($theme-color-primary, 'light-blue-2');
  }

  &.selected {
    background-color: map-get($theme-color-primary, 'light-blue-2');
  }

  .label {
    white-space: nowrap;
  }
}
</style>
