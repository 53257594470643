<template>
  <button
    v-for="(action, index) in buttons"
    :key="index"
    :disabled="action.disabled"
    :class="[`button-${action.name}`]"
    @click.stop="onButtonClick(action.name)"
  >
    <icon :name="`action-${action.name}`" />
  </button>
</template>

<script lang="ts" setup>
interface Action {
  name: string
  disabled: boolean
}

interface Props {
  buttons: Array<Action>
  item?: Record<string, any> | null
}

const props = withDefaults(defineProps<Props>(), {
  item: null
})
const emit = defineEmits(['click'])

const onButtonClick = (action: any) => {
  if (!props.item || props.item?.disableButtons?.includes(action)) return
  emit('click', { action, id: props.item.id })
}
</script>

<style lang="scss" scoped>
button {
  all: unset;
  opacity: 0;
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;

  &.button-delete {
    position: absolute;
    right: 0;
    margin-right: -40px;

    &:disabled {
      cursor: default;
    }
  }
}
</style>
