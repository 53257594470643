<template>
  <p class="placeholder-glow" aria-hidden="true">
    <span
      v-for="(v, index) in Array.from({ length: size })"
      :key="index"
      :class="`placeholder placeholder-lg mb-4 ${randomColClass()}`"
    />
  </p>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{ size?: number }>(), { size: 12 })

const randomColClass = (): string => {
  return `col-${Math.floor(Math.random() * (9 + 1) + 3)}`
}
</script>
