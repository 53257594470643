import Settings from '@/app/page/Settings.vue'
import SettingsAccount from '@/app/page/SettingsAccount.vue'
import SettingsOrganisation from '@/app/page/SettingsOrganisation.vue'
import SettingsLocations from '@/app/page/SettingsLocations.vue'
import SettingsUsers from '@/app/page/SettingsUsers.vue'
import SettingsRoles from '@/app/page/SettingsRoles.vue'
import { PermissionName } from '@/features/roles/types'

export default [
  {
    path: '/instellingen',
    component: Settings,
    children: [
      {
        path: 'mijn-account',
        name: 'settings.account',
        component: SettingsAccount,
        meta: { title: 'Mijn account - Instellingen' }
      },
      {
        path: 'mijn-organisatie',
        name: 'settings.organisation',
        component: SettingsOrganisation,
        meta: {
          requiredPermissions: PermissionName.SettingsOrganisationManage,
          title: 'Mijn Organisatie - Instellingen'
        }
      },
      {
        path: 'mijn-locaties',
        name: 'settings.locations',
        component: SettingsLocations,
        meta: {
          requiredPermissions: PermissionName.SettingsLocationsManage,
          title: 'Mijn Locaties - Instellingen'
        }
      },
      {
        path: 'gebruikers',
        name: 'settings.users',
        component: SettingsUsers,
        meta: {
          requiredPermissions: PermissionName.SettingsUsersManage,
          title: 'Gebruikers - Instellingen'
        }
      },
      {
        path: 'rollen',
        name: 'settings.roles',
        component: SettingsRoles,
        meta: {
          requiredPermissions: PermissionName.SettingsRolesManage,
          title: 'Rollen - Instellingen'
        }
      },
      { path: '', name: 'settings.index', redirect: '/instellingen/mijn-account' }
    ]
  }
]
