<template>
  <div class="progress-bar">
    <div class="progress-bar__indicator" :class="cssClass" :style="styles" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Status = 'success' | 'error' | 'default' | 'gray'

type Props = {
  value: number
  status?: Status
}

const props = withDefaults(defineProps<Props>(), {
  status: 'default'
})

const cssClass = computed(() => {
  if (props.value === 100) {
    return 'progress-bar__indicator--success'
  }

  return `progress-bar__indicator--${props.status}`
})

const styles = computed(() => {
  const width = `${props.value}%`

  return {
    width
  }
})
</script>

<style lang="scss" scoped>
.progress-bar,
.progress-bar__indicator {
  height: 20px;
}

.progress-bar {
  position: relative;
  background: map-get($theme-color-neutral, 'grey-5');
  flex-grow: 1;

  .progress-bar__indicator {
    -webkit-mask: linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        map-get($theme-color-neutral, 'grey-1') 0%,
        map-get($theme-color-neutral, 'grey-6') 100%
      );
    }
    &--default:before,
    &--info:before {
      background: linear-gradient(90deg, #499bde 0%, #00afff 37%, #66cdfc 100%) !important;
    }
    &--success:before {
      background: linear-gradient(90deg, #2fbd68 0%, #69d393 100%) !important;
    }
    &--error:before {
      background: linear-gradient(90deg, #bf4a4a 0%, #f05817 100%) !important;
    }
    &--gray:before {
      background: map-get($theme-color-neutral, 'grey-1') !important;
    }
  }
}
</style>
