<template>
  <modal-default
    :title="
      accept === 'image/*'
        ? $t('media.addFilesDialog.titleImages')
        : $t('media.addFilesDialog.title')
    "
    :button="{
      confirm: { text: $t('app.button.add') },
      cancel: { text: $t('app.button.cancel') }
    }"
    @confirm="$emit('confirm', newFiles)"
    @cancel="$emit('cancel')"
    @close="$emit('close')"
    @hidden="$emit('hidden')"
  >
    <div>
      <file-selector v-model="newFiles" :accept="accept" />
      <image-grid
        v-if="isOnlyImages"
        :images="newFiles.map((file) => ({ type: 'new', data: file }))"
        @delete="(file) => deleteFile(file.data)"
      />
      <list-view
        v-else
        class="new-files"
        :headers="[{ property: 'name' }]"
        :rows="newFiles?.map((file) => ({ id: `${file.name}:${file.size}`, name: file.name }))"
        hide-header
        disable-row-click
        :buttons="[ListViewButton.Delete]"
        @event="onListEvent"
      />
    </div>
  </modal-default>
</template>
<script setup lang="ts">
import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'
import { computed, ref } from 'vue'
import ImageGrid from '@/features/media/components/imageGrid.vue'

defineProps<{
  accept?: '*/*' | 'image/*'
}>()

defineEmits<{ confirm: [files: File[]]; cancel: []; hidden: []; close: [] }>()

const newFiles = ref<File[]>([])

const deleteFile = (file: File) => {
  newFiles.value = newFiles.value.filter((f) => f !== file)
}
const onListEvent = ({ action, id }: { action: string; id: string }) => {
  if (action === 'delete') {
    const file = newFiles.value.find((f) => id === `${f.name}:${f.size}`)
    if (file) {
      deleteFile(file)
    }
  }
}

const isOnlyImages = computed(() => !newFiles.value.some((file) => !file.type.startsWith('image/')))
</script>

<style lang="scss" scoped>
.new-files {
  margin-bottom: 24px;
  padding-right: 40px;
}
</style>
