<template>
  <dropdown v-if="!disabled" :items="itemsWithSelection" :on-item-select="onItemSelect">
    <button class="button-secondary">
      <icon
        v-if="selectedItem && selectedItem.iconName"
        :name="selectedItem.iconName"
        class="icon--left"
      />
      {{ selectedItem?.name || placeholder }}
      <icon name="action-chevron-down" class="icon--right" />
    </button>
  </dropdown>
  <button v-else class="button-secondary selector-disabled">
    <icon
      v-if="selectedItem && selectedItem.iconName"
      :name="selectedItem.iconName"
      class="icon--left"
    />
    {{ selectedItem?.name || placeholder }}
    <icon name="action-chevron-down-disabled" class="icon--right" />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Dropdown from '@/support/components/dropdownMenu/components/Dropdown.vue'
import type { DropdownItemProps } from '@/support/components/dropdownMenu/dtos'

const props = defineProps<{
  items: DropdownItemProps[]
  placeholder?: string
  disabled?: boolean
}>()

const modelValue = defineModel<string | null>()

const onItemSelect = (id: string) => {
  modelValue.value = id
}
const selectedItem = computed(() => props.items.find((item) => item.id === modelValue.value))

const itemsWithSelection = computed<DropdownItemProps[]>(() =>
  props.items.map((item) => ({ ...item, selected: item.id === modelValue.value }))
)
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;

  border: 1px solid map-get($theme-color-primary, 'light-blue-2');
  border-radius: 4px;

  &:not(.selector-disabled) {
    &:focus,
    &:focus:active {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');

      box-shadow: 0 0 0 5px $form-field-outline-color;
      outline: none;
    }
  }

  &.selector-disabled {
    cursor: default;
    &:hover,
    &:focus {
      background-color: white;
      border-color: map-get($theme-color-primary, 'light-blue-2');
      outline: none;
    }
  }

  .icon {
    display: inline-block;
    flex-shrink: 0;

    &--left {
      margin-right: 5px;
      margin-left: -17px;
    }

    &--right {
      margin-right: -17px;
      margin-left: 5px;
    }
  }
}
</style>
