<template>
  <ul class="nav nav-tabs">
    <slot />
  </ul>
</template>

<style lang="scss">
.nav-tabs {
  border-bottom: 0;
}
</style>
