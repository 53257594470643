import { useApi } from '@/features/api/api'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Topic } from '@/features/topics/types'

const sortTopic = (a: Topic, b: Topic) => {
  if (typeof a.order === 'number' && typeof b.order === 'number' && a.order !== b.order) {
    return a.order - b.order
  }
  return a.title.localeCompare(b.title)
}

export const useTopicsStore = defineStore('topics', () => {
  const Api = useApi()
  const topics = ref<Topic[]>()

  const getAllTopics = async () => {
    const response = await Api.topics.getTopics()

    if (response.status === 200) {
      topics.value = [...response.data].sort(sortTopic)
    }

    return response
  }

  return {
    topics,
    getAllTopics
  }
})
