<template>
  <div ref="container">
    <slot name="default" />
    <transition name="fade" mode="out-in">
      <div v-if="(field.withSaveButton && isFocussed) || isChanged" class="pt-3">
        <button type="button" class="button-primary me-4" @click.stop="model = internalModel">
          {{ $t('app.button.save') }}
        </button>
        <button type="button" class="button-cancel" @click.stop="internalModel = model">
          {{ $t('app.button.cancel') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup generic="ModelValue = any | undefined">
import { computed, onUnmounted, ref, watch } from 'vue'
import type {
  ReactiveFormFieldInputText,
  ReactiveFormFieldTextarea
} from '@/support/components/reactiveForm/types/ReactiveFormField.dto'

const props = defineProps<{ field: ReactiveFormFieldInputText | ReactiveFormFieldTextarea }>()

const model = defineModel<ModelValue>()
const internalModel = defineModel<ModelValue>('internalModel')

const container = ref<HTMLDivElement>()

const isFocussed = ref(false)
const onFocus = () => {
  isFocussed.value = true
}
const onBlur = () => {
  isFocussed.value = false
}
const inputElement = computed<HTMLInputElement | HTMLTextAreaElement | undefined>(
  () =>
    container.value?.querySelector<HTMLInputElement | HTMLTextAreaElement>('input,textarea') ||
    undefined
)
const isChanged = computed<boolean>(() => model.value !== internalModel.value)
watch(
  inputElement,
  () => {
    if (!inputElement.value) {
      return
    }
    inputElement.value.addEventListener('focus', onFocus)
    inputElement.value.addEventListener('blur', onBlur)
  },
  { immediate: true }
)
onUnmounted(() => {
  inputElement.value?.removeEventListener('focus', onFocus)
  inputElement.value?.removeEventListener('blur', onBlur)
})

watch(
  model,
  () => {
    if (model.value === internalModel.value) {
      return
    }
    internalModel.value = model.value
  },
  { immediate: true }
)
watch(internalModel, () => {
  if (internalModel.value === model.value || props.field.withSaveButton) {
    return
  }
  model.value = internalModel.value
})
</script>

<style lang="scss" scoped>
.button-cancel {
  all: unset;
  cursor: pointer;
  font-weight: 700;
  font-family: $theme-brand-font-headings;
}
</style>
