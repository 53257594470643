import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type { Permission, Role, RoleFormData } from '@/features/roles/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { AxiosResponse } from 'axios'

export const getPermissionsCategories = async () => {
  return (await fetchWithAuth(`/permissions/categories`, { method: 'GET' })) as AxiosResponse
}

export const getPermissions = async () => {
  return (await fetchWithAuth(`/permissions`, { method: 'GET' })) as AxiosResponse<Permission[]>
}

export const getRoles = async (pageIndex: number, pageSize: number = 10) => {
  return (await fetchWithAuth(`/roles`, {
    method: 'GET',
    params: { pageIndex, pageSize }
  })) as AxiosResponse<PaginatedApiResponse<Role>>
}

export const getAllRoles = async () => {
  return (await fetchWithAuth(`/roles`, {
    method: 'GET',
    params: { pageSize: 0 }
  })) as AxiosResponse<Role[]>
}

export const createRole = async (data: RoleFormData) => {
  return (await fetchWithAuth(`/roles/create`, { method: 'POST', data })) as AxiosResponse
}

export const updateRole = async (id: Guid, data: RoleFormData) => {
  return (await fetchWithAuth(`/roles/${id}`, { method: 'PUT', data })) as AxiosResponse
}

export const deleteRole = async (id: Guid) => {
  return (await fetchWithAuth(`/roles/${id}`, { method: 'DELETE' })) as AxiosResponse
}
