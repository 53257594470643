import i18n from '@/support/core/localization'
import type { ReactiveFormField } from '../types/ReactiveFormField.dto'
import type { FieldValidationType } from '../types/FieldValidationType.dto'

const { t, te } = i18n.global

export const getLocaleMessage = ({ id, label }: ReactiveFormField, rule: FieldValidationType) => {
  const customMessage = te(`validations.custom.${id}.${rule}`)
    ? t(`validations.custom.${id}.${rule}`, { fieldName: label })
    : null

  const defaultMessage = te(`validations.${rule}`)
    ? t(`validations.${rule}`, { fieldName: label })
    : null

  const unknownMessage = t('validations.unknown')

  return customMessage ?? defaultMessage ?? unknownMessage
}
