import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type { Location, UpdateCreateLocation } from '@/features/locations/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { AxiosResponse } from 'axios'

export const searchLocations = async (query: string) => {
  return (await fetchWithAuth(`/locations/search/${query}`, { method: 'GET' })) as AxiosResponse
}

export const getLocations = async (pageIndex: number, pageSize: number = 10) => {
  return (await fetchWithAuth('/locations', {
    method: 'GET',
    params: { pageIndex, pageSize }
  })) as AxiosResponse<PaginatedApiResponse<Location>>
}
export const getAllLocations = async () => {
  return (await fetchWithAuth('/locations', {
    method: 'GET',
    params: { pageSize: 0 }
  })) as AxiosResponse<Location[]>
}

export const getLocationTypes = async () => {
  return (await fetchWithAuth('/locations/types', { method: 'GET' })) as AxiosResponse
}

export const createLocation = async (data: UpdateCreateLocation) => {
  return (await fetchWithAuth('/locations/create', { method: 'POST', data })) as AxiosResponse
}

export const updateLocation = async (location: UpdateCreateLocation) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, typeId, ...data } = location
  if (!id) {
    throw new Error('id is required')
  }
  return (await fetchWithAuth(`/locations/${id}`, { method: 'PUT', data })) as AxiosResponse
}

export const deleteLocation = async (id: Guid) => {
  return (await fetchWithAuth(`/locations/${id}`, { method: 'DELETE' })) as AxiosResponse
}
