<template>
  <modal-advanced
    ref="modalAdvancedRef"
    v-model:title="form.name"
    :editable-content-props="modalConfig.editableContentProps"
    :modal-props="modalProps"
    @confirm="$emit('confirm', form, newFiles || [])"
    @cancel="$emit('close')"
    @close="$emit('close')"
    @hidden="$emit('hidden')"
  >
    <template #type>{{ modalConfig.type }}</template>
    <template #default>
      <div
        v-if="action?.questionEnabled"
        v-safe-html="
          $t('actions.editAction.questionComment', {
            topicTitle: action.topicTitle,
            question: action.questionQuestion,
            answer: $t(`cycle.reply.${action.questionReply}`)
          })
        "
        class="question-comment mb-3"
      />
      <reactive-form-field-textarea
        v-model="form.description"
        :field="fields.description"
        :vuelidate="v$.description"
      />
      <media-list
        :editable="!readonly"
        :items="combinedMediaList"
        @click:add="$emit('event', { type: 'add-documents' })"
        @click:delete="(document) => $emit('event', { type: 'delete-document', document })"
      />
    </template>

    <template #panel="{ confirm, cancel }">
      <div v-if="form.id" class="actions">
        <template v-if="canDeleteActionsPermission">
          <dropdown-more-options :items="moreOptions" />
        </template>
        <spinner v-if="saving" size="small" />
        <button-icon-floating v-else variant="on-white" icon-name="action-close" @click="cancel" />
      </div>
      <div v-else>
        <button class="button-cancel" @click="cancel">{{ modalConfig.button.cancel }}</button>
        <button :disabled="v$.$invalid" class="button-primary" @click="confirm">
          {{ input?.id ? modalConfig.button.edit : modalConfig.button.create }}
        </button>
      </div>
      <reactive-form-field-select v-if="input?.id" v-model="form.status" :field="fields.status" />
      <reactive-form-field-label
        :field-id="fields.manager.id"
        :is-required="fields.manager.validationRules.required"
      >
        {{ fields.manager.label }}
      </reactive-form-field-label>
      <div v-if="readonly" class="field-readonly readonly--icon">
        <icon :name="iconProps.name" />
        {{ searchUserProjection(form.managerId ?? '') }}
      </div>
      <search-input
        v-else
        :id="fields.manager.id"
        :placeholder="fields.manager.placeholder"
        :min-input-length="0"
        :clear-input="false"
        :default-item="form.managerId"
        :icon-props="iconProps"
        :items="searchUserOptions || []"
        :item-projection="searchUserProjection"
        :item-is-disabled="searchUserIsDeleted"
        @select-item="onSelectUser"
      />
      <reactive-form-field-date-picker
        v-model="form.deadlineAt"
        :field="fields.deadline"
        :vuelidate="v$.deadlineAt"
      />
      <reactive-form-field-select v-model="form.topicId" :field="fields.topic" />
      <reactive-form-field-label :field-id="fields.location.id">
        {{ fields.location.label }}
      </reactive-form-field-label>
      <div class="field-readonly">
        {{ locationsValue }}
      </div>
      <created-at
        v-if="action"
        :created-at="action?.createdAt || ''"
        :created-by="action?.createdBy"
      />
    </template>
  </modal-advanced>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import { computedAsync, watchDebounced } from '@vueuse/core'
import { useVuelidate } from '@vuelidate/core'
import { Modal } from 'bootstrap'

import { required } from '@/support/helpers/i18n-validators'
import i18n from '@/support/core/localization'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'
import { useActionsPermissions } from '@/features/actions/compositions/useActionsPermissions'
import { getFullNameOrEmail } from '@/features/user/helpers'
import { useUserStore } from '@/features/user/store'
import { useTopicsStore } from '@/features/topics/store'
import MediaList from '@/features/media/components/mediaList.vue'

import type {
  Action,
  ActionEditDialogEvent,
  UpdateAction,
  ActionStatus
} from '@/features/actions/types'

import { type DropdownItemProps } from '@/support/components/dropdownMenu/dtos'
import { storeToRefs } from 'pinia'
import type { User } from '@/features/user/types'
import { currentLocationId, locations, userInfo } from '@/features/auth'
import type { ExtendedFile, MediaListItem, NewFile } from '@/features/media/types'
import ModalAdvanced from '@/support/components/modal/advanced/index.vue'
import type { Guid } from '@/support/types/Guid.dto'
import type {
  ReactiveFormFieldDatePicker,
  ReactiveFormFieldSelect,
  ReactiveFormFieldTextarea
} from '@/support/components/reactiveForm/types/ReactiveFormField.dto'

type Events = {
  confirm: [data: UpdateAction, newFiles: File[]]
  close: []
  hidden: []
  event: [ActionEditDialogEvent]
}

interface Props {
  input: UpdateAction
  locationId?: Guid
  action?: Action
  newFiles?: File[]
  saving?: boolean
  modalProps?: Partial<Modal.Options>
}

const { t } = i18n.global

const iconProps = {
  name: 'action-user',
  position: 'left'
}

const props = defineProps<Props>()

const input = toRef(props, 'input')

const form = reactive<UpdateAction>(input.value)
if (!form.locationId && props.locationId) {
  form.locationId = props.locationId
}
if (!form.locationId && currentLocationId.value) {
  form.locationId = currentLocationId.value
}

const modalAdvancedRef = ref<InstanceType<typeof ModalAdvanced>>()
const managerIdCached = ref<string | null>(null)
const formCached = ref<string>(JSON.stringify(form))

const combinedMediaList = computed<MediaListItem<string>[]>(() => [
  ...(form.mediaUrls?.map((data) => ({ type: 'extended', data }) satisfies ExtendedFile<string>) ||
    []),
  ...(props.newFiles?.map((data) => ({ type: 'new', data }) satisfies NewFile) || [])
])

const userStore = useUserStore()
const topicsStore = useTopicsStore()

const { topics } = storeToRefs(topicsStore)

const actionsPermissions = useActionsPermissions()
const { canDeleteActionsPermission, canEditActionRecord } = actionsPermissions

const readonly = computed<boolean>(() => !canEditActionRecord(props.action || props.input).value)

const moreOptions: DropdownItemProps[] = [
  {
    id: 1,
    name: t('app.button.delete'),
    iconName: 'action-delete',
    onClick: () => onDeleteClick()
  }
]

const v$ = useVuelidate(
  {
    name: { required },
    description: {},
    managerId: { required },
    deadlineAt: { required },
    locationId: {}
  },
  form
)

const emit = defineEmits<Events>()

const modalConfig = computed(() => ({
  type: t('actions.editAction.type'),
  editableContentProps: {
    placeholder: t('actions.editAction.titlePlaceholder'),
    readonly: readonly.value
  },
  button: {
    cancel: t('actions.editAction.button.cancel'),
    create: t('actions.editAction.button.create'),
    edit: t('actions.editAction.button.edit')
  }
}))

const locationsValue = computed(() => {
  if (!form.locationId) {
    return t('app.header.organisationContext')
  }

  return locations.value?.find((option) => option.id === form.locationId)!.name
})

const statusColor: Record<ActionStatus, 'info' | 'warning' | 'success' | 'gray'> = {
  todo: 'warning',
  inprogress: 'info',
  done: 'success',
  skipped: 'gray'
}

const users = computedAsync<User[] | undefined>(async () =>
  form.locationId
    ? await userStore.getUsersForLocation(form.locationId, form.locationId)
    : await userStore.getUsersForOrganisation(form.locationId)
)

const searchUserOptions = computed(() => users.value?.map((user) => user.id))

const searchUserProjection = (id: string) =>
  getFullNameOrEmail(users.value?.find((user) => user.id === id))
const searchUserIsDeleted = (id: string): boolean => {
  const user = users.value?.find((user) => user.id === id)
  if (!user) {
    return false
  }
  if (user.isDeleted || !user.hasSsoId) {
    return true
  }
  if (
    !user.hasAccessToOrganisation &&
    !user.locations?.some((location) => location.id === form.locationId)
  ) {
    return true
  }
  return false
}

const onSelectUser = (id: Guid) => {
  form.managerId = id
}

const fields = computed(() => ({
  status: {
    id: 'status',
    type: FieldType.SELECT,
    label: t('actions.editAction.field.status'),
    options: (['todo', 'inprogress', 'done', 'skipped'] as ActionStatus[]).map((status) => ({
      label: t(`actions.status.${status}`),
      value: status,
      color: statusColor[status]
    })),
    className: form.status
  } satisfies ReactiveFormFieldSelect,
  description: {
    id: 'description',
    type: FieldType.TEXTAREA,
    label: t('actions.editAction.field.labelDescription'),
    readonly: readonly.value
  } satisfies ReactiveFormFieldTextarea,
  manager: {
    id: 'managerId',
    label: t('actions.editAction.field.labelManager'),
    placeholder: t('actions.editAction.field.placeholderManager'),
    validationRules: { required: true }
  },
  deadline: {
    id: 'deadline',
    type: FieldType.DATE_PICKER,
    label: t('actions.editAction.field.labelDeadline'),
    readonly: readonly.value,
    placeholder: t('actions.editAction.field.placeholderDeadline'),
    validationRules: {
      required: true
    }
  } satisfies ReactiveFormFieldDatePicker,
  topic: {
    id: 'topicId',
    type: FieldType.SELECT,
    readonly: readonly.value,
    label: t('actions.editAction.field.labelTopic'),
    placeholder: t('actions.editAction.field.placeholderTopic'),
    options: topics.value?.map((topic) => ({ label: topic.title, value: topic.id })) || []
  } satisfies ReactiveFormFieldSelect,
  location: {
    id: 'locationId',
    label: t('actions.editAction.field.labelLocation'),
    placeholder: t('actions.editAction.field.placeholderLocation')
  }
}))

const close = () => {
  if (!modalAdvancedRef.value) {
    return
  }

  modalAdvancedRef.value.close()
}

const onDeleteClick = () => {
  emit('event', { type: 'delete', id: form.id! })
}

onMounted(async () => {
  const promises = []

  if (!topics.value) {
    promises.push(topicsStore.getAllTopics())
  }

  await Promise.all(promises)

  if (!form.id && userInfo.value?.id) {
    form.managerId = userInfo.value?.id || null
  }
})

// Reset managerId when locationId changes
watch(
  () => form.locationId,
  () => {
    managerIdCached.value = form.managerId
    form.managerId = null
  }
)

// Set managerId to cached value when users are loaded
watch(users, () => {
  if (users.value?.find((user: User) => user.id === managerIdCached.value)) {
    form.managerId = managerIdCached.value
  }
})

watchDebounced(
  () => [form, props.newFiles],
  async () => {
    if (!form.id) {
      return
    }

    if (v$.value.$invalid) {
      v$.value.$touch()
      await nextTick()
      document.querySelector<HTMLDivElement>('div.reactive-form__errors')?.scrollIntoView()
      return
    }

    emit('confirm', form, props.newFiles || [])

    formCached.value = JSON.stringify(form)
  },
  { debounce: 500, deep: true }
)

defineExpose({
  close
})
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-self: end;
  align-items: center;
}
:deep(.search-input) {
  margin-bottom: 20px;
}
:deep(.created-at) {
  padding: 20px 0;
  max-width: 263px;
}
.field-readonly {
  margin-bottom: 20px;
  max-width: 319px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  &.placeholder {
    color: map-get($theme-color-neutral, 'grey-3');
  }

  &.readonly--icon {
    padding-left: 40px;

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
:deep(.location-selector) {
  .dropdown-holder {
    display: block;

    button {
      display: block;
      width: 263px;
      padding: 10px 38px 10px 40px;
      min-height: 45px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;

      .icon.icon--left,
      .icon.icon--right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        margin: 0;
      }
      .icon.icon--left {
        left: 10px;
      }
      .icon.icon--right {
        right: 10px;
      }
    }
    .dropdown-container {
      width: 263px;
      height: 300px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}
:deep(.field-readonly.placeholder) {
  color: map-get($theme-color-neutral, 'grey-3');
}
.question-comment {
  font-style: italic;
}
</style>
