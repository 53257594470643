<template>
  <div class="contextual-help">
    <div class="contextual-help__block" @click="emit('click')">
      <div class="contextual-help__icon mb-3">
        <icon name="action-help-rounded-hover" :size="{ width: 50, height: 50 }" />
      </div>
      <div class="contextual-help__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['click'])
</script>

<style lang="scss" scoped>
.contextual-help {
  display: flex;
  justify-content: center;
  border-top: 2px solid map-get($theme-color-primary, 'light-blue-2');
  padding-top: 50px;
  margin: 50px auto 25px;

  .contextual-help__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .contextual-help__content {
      text-align: center;
    }
  }
}
.green {
  .contextual-help {
    border-top: 2px solid white;
  }
}
</style>
