<template>
  <badge v-if="badgeProps && badgeValue" :rounded="badgeProps.rounded" :type="badgeProps.type">
    {{ badgeValue }}
  </badge>
  <div
    v-else-if="isDate && typeof columnData.value === 'string'"
    class="date-value"
    :class="deadlineClass"
  >
    <icon :name="!iconActionVariant ? 'action-date' : `action-date-${iconActionVariant}`" />
    <div class="date-text">
      {{ $d(DateTime.fromISO(columnData.value).toJSDate(), 'dateShortMonthAsText') }}
    </div>
  </div>
  <div
    v-else-if="isUser"
    class="user-data"
    :class="{ deleted: (columnData as unknown as User).isDeleted }"
  >
    <icon name="action-user" />
    <div class="text">{{ getFullNameOrEmail(columnData as unknown as User) }}</div>
  </div>
  <div v-else-if="columnValue" class="text">{{ columnValue }}</div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { ListViewColumnData } from '../types'
import { DateTime } from 'luxon'
import { getFullNameOrEmail } from '@/features/user/helpers'
import type { User } from '@/features/user/types'
import { useThisMinute } from '@/support/composition/use-this-minute'

interface Props {
  columnData: ListViewColumnData
  property?: string
}

const props = withDefaults(defineProps<Props>(), {
  columnData: () => ({
    value: '',
    badge: null
  })
})

const columnValue = computed(() => {
  return props.columnData?.value && props.columnData.value.length ? props.columnData.value : null
})

const isDate = computed<boolean>(() => {
  const value = props.columnData.value

  if (!value) {
    return false
  }
  if (value instanceof Date) {
    return true
  }

  // check if value is a ISO date
  if (
    typeof value === 'string' &&
    (value.match(/^\d{4}-\d{2}-\d{2}$/) ||
      value.match(
        /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)$/
      ))
  ) {
    return DateTime.fromISO(value).isValid
  }
  return false
})

const thisMinute = useThisMinute()
const deadlineClass = computed<'error' | 'warning' | undefined>(() => {
  const value = props.columnData.value

  if (!value || !props.property?.startsWith('deadline')) {
    return undefined
  }
  let date: DateTime | undefined = undefined
  if (value instanceof Date) {
    date = DateTime.fromJSDate(value)
  }
  if (typeof value === 'string') {
    date = DateTime.fromISO(value)
  }
  if (!date?.isValid) {
    return
  }
  if (date < thisMinute.value) {
    return 'error'
  }
  if (date < thisMinute.value.plus({ days: 3 })) {
    return 'warning'
  }
  return undefined
})

const iconActionVariant = computed<'red' | 'orange' | undefined>(() => {
  if (deadlineClass.value === 'error') {
    return 'red'
  }
  if (deadlineClass.value === 'warning') {
    return 'orange'
  }
  return undefined
})

const isUser = computed<boolean>(() => {
  const value = props.columnData as any
  if (!value) {
    return false
  }
  if (typeof value === 'object' && value.id && value.email) {
    return true
  }
  return false
})

const badgeValue = computed(() => {
  return props.columnData?.badge?.value && props.columnData.badge.value.length
    ? props.columnData.badge.value
    : null
})

const badgeProps = computed(() => {
  return props.columnData?.badge?.badgeProps || null
})
</script>

<style lang="scss" scoped>
.date-value,
.user-data {
  display: flex;
  gap: 4px;
  align-items: center;

  &.deleted {
    color: map-get($theme-color-neutral, 'grey-2');
    text-decoration: line-through;
  }
}
.date-value {
  &.error {
    color: map-get($theme-color-status, 'error');
  }
  &.warning {
    color: map-get($theme-color-status, 'warning');
  }

  .date-text {
    white-space: nowrap;
  }
}
</style>
