<template>
  <off-canvas
    id="offcanvas"
    :hide-title="hideTitle"
    :open="store.isOpen"
    :title="title"
    @hidden="onHidden"
  >
    <transition name="fade" mode="out-in">
      <component :is="component[currentSection]" @toggle-section="toggleSection" />
    </transition>
  </off-canvas>
</template>

<script setup lang="ts">
import { useHelpContactStore } from '@/features/helpAndContact/store'
import { computed, defineAsyncComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const component: any = {
  help: defineAsyncComponent(() => import('./help.vue')),
  'help-detail': defineAsyncComponent(() => import('./helpDetail.vue')),
  contact: defineAsyncComponent(() => import('./contact.vue'))
}

const store = useHelpContactStore()
const title = t('helpAndContact.sidePanel.title')
const currentSection = ref<string>('help')

const hideTitle = computed(() => currentSection.value !== 'help')

const toggleSection = ({ section, item = null, path = null }: any) => {
  currentSection.value = section

  if (item && path) {
    store.setHelpDetail(item, path)
  }
}

const onHidden = () => {
  currentSection.value = 'help'
  store.setHelpDetail(null, [])
  store.closeHelpContact()
}
</script>

<style scoped></style>
