import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { UpdateOrganisation } from '@/features/organisation/types'
import type { AxiosResponse } from 'axios'

export const getOrganisation = async () => {
  return (await fetchWithAuth(`/organisations`, { method: 'GET' })) as AxiosResponse
}

export const updateOrganisation = async (data: UpdateOrganisation) => {
  return (await fetchWithAuth(`/organisations`, { method: 'PUT', data })) as AxiosResponse
}
