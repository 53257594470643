<template>
  <div class="spinner-context" :class="{ 'spinner-fixed': isFixed }">
    <slot />
    <div class="spinner-context__inner">
      <slot name="context" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'
import { hasSlotContent } from '@/support/helpers/slots'

const slots = useSlots()
const isFixed = computed(() => hasSlotContent(slots, 'context'))
</script>

<style lang="scss" scoped>
.spinner-context {
  position: relative;

  &.spinner-fixed :deep(.spinner) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  :deep(.spinner) + &__inner {
    animation: blink 600ms infinite;
    pointer-events: none;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
