<script setup lang="ts">
import type { TableView } from './types/TableView.dto'

const props = defineProps<TableView>()
</script>

<template>
  <table class="table">
    <tbody>
      <tr v-for="(property, index) in props.rows" :key="index">
        <th scope="row">{{ property.key }}</th>
        <td>{{ property.value }}</td>
      </tr>
    </tbody>
  </table>
</template>
