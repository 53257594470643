<template>
  <li class="list-view__row" :class="classNames" @click="onRowClick">
    <slot />
    <list-view-column v-if="buttons.length" property="buttons">
      <list-view-column-buttons :buttons="buttonsNormalized" :item="item" @click="onButtonClick">
      </list-view-column-buttons>
    </list-view-column>
  </li>
</template>

<script setup lang="ts" generic="ListItem extends ListViewRecord">
import { computed } from 'vue'
import ListViewColumnButtons from './listViewColumnButtons.vue'
import type { ListViewRecord } from '@/support/components/listView/types'
import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'

interface Props {
  buttons: ListViewButton[]
  item?: ListItem | null
  disableRowClick?: boolean
  hoverable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  item: null
})

const emit = defineEmits(['event'])

const buttonsNormalized = computed(() => {
  return props.buttons
    .filter((button) => {
      if (button === ListViewButton.View) {
        if (!props.buttons.includes(ListViewButton.Edit)) {
          return true
        }
        if (!props.item) {
          // in header
          return false
        }
        return props.item.disableButtons?.includes(ListViewButton.Edit)
      }

      if (button === ListViewButton.Edit) {
        if (!props.buttons.includes(ListViewButton.View)) {
          return true
        }
        if (!props.item) {
          // in header
          return true
        }
        return !props.item.disableButtons?.includes(ListViewButton.Edit)
      }
      return true
    })
    .map((action) => {
      const disabled = props.item?.disableButtons?.includes(action) ?? false
      return {
        name: action,
        disabled
      }
    })
})

const classNames = computed(() => {
  const isHeader = !props.item

  if (isHeader) {
    return {
      header: true
    }
  }

  return {
    inactive: props.item?.inactive,
    'is-deleted': props.item?.isDeleted,
    'disable-row-click': props.disableRowClick,
    hoverable:
      buttonsNormalized.value.filter((item) => !item.disabled).length !== 0 || props.hoverable
  }
})

const onRowClick = () => {
  if (props.disableRowClick || !props.item) return

  const event = { id: props.item.id, item: props.item }

  if (!props.item?.disableButtons?.includes(ListViewButton.Edit)) {
    emit('event', { ...event, action: 'edit' })
    return
  }
  if (!props.item?.disableButtons?.includes(ListViewButton.View)) {
    emit('event', { ...event, action: 'view' })
    return
  }

  emit('event', { ...event, action: 'disabled' })
}

const onButtonClick = (event: string) => emit('event', event)
</script>

<style lang="scss" scoped>
.list-view__row {
  display: flex;
  align-items: center;
  min-height: 60px;
  font-size: $theme-font-size-s;
  position: relative;

  &.header {
    color: map-get($theme-color-primary, 'light-blue-1');
  }

  &:not(.header) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: map-get($theme-color-primary, 'light-blue-2');
    }
  }

  &.inactive :deep(.column) {
    color: map-get($theme-color-neutral, 'grey-1');

    .badge {
      background-color: map-get($theme-color-neutral, 'grey-6');
      color: map-get($theme-color-neutral, 'grey-1');
    }
  }

  &.is-deleted :deep(.column) {
    color: map-get($theme-color-neutral, 'grey-2');
    text-decoration: line-through;
  }

  &.hoverable {
    &:not(.disable-row-click) {
      cursor: pointer;
    }
  }

  &.hoverable {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: -44px;
      bottom: 0;
    }

    &:hover {
      background-color: map-get($theme-color-primary, 'light-blue-2');
      border-radius: 4px;

      :deep(.column.column-buttons button:not(:disabled)) {
        opacity: 1;
      }
    }

    &:hover,
    &:hover + li {
      &:after {
        display: none;
      }
    }
  }
}
</style>
