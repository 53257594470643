import { fetchWithAuth } from '@/features/api/fetchWithAuth'
import type { AxiosResponse } from 'axios'
import type {
  WorkformTemplate,
  CyclePreparation,
  CycleWorkform,
  UpdateCycleChecklist,
  UpdateChecklistQuestion,
  UpdateChecklistSubject,
  UpdateChecklistSubSubject,
  UpdateCyclePreparation,
  UpdatePreparationStep,
  UpdateTopic,
  UpdateWorkform,
  ChecklistSubject,
  ChecklistSubSubject,
  ChecklistQuestion,
  CycleChecklist,
  AddWorkform,
  ActiveCycle,
  CycleWorkFormForm,
  SaveReplyForLocation,
  PreparationStep,
  UpdateCycleChecklistForActiveCycle,
  CycleStep,
  UpdateCycleStep,
  UpdateLocationCycleStep,
  CycleLocationStep,
  CycleTopic,
  UpdateActiveCycle,
  CreateCycleWorkform,
  UpdateActiveOrganisationCycle
} from '@/features/cycle/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { OkResult } from '@/support/types/OkResult'

export const getPreparationCycle = async () => {
  const request = async (method: 'GET' | 'POST') =>
    (await fetchWithAuth('/cycles/preparation', {
      method
    })) as AxiosResponse<CyclePreparation>
  const response = await request('GET')
  if (response.status !== 404) {
    return response
  }
  return await request('POST')
}

export const updatePreparationCycle = async (data: UpdateCyclePreparation) => {
  return (await fetchWithAuth('/cycles/preparation', {
    method: 'PUT',
    data
  })) as AxiosResponse<CyclePreparation>
}

export const updateActiveCycle = async (data: UpdateActiveOrganisationCycle) => {
  return (await fetchWithAuth('/cycles/active/organisation', {
    method: 'PUT',
    data
  })) as AxiosResponse<ActiveCycle>
}

export const addWorkform = async (topicId: Guid, data: AddWorkform) => {
  return (await fetchWithAuth(`/cycles/topics/${topicId}/workforms`, {
    method: 'POST',
    data
  })) as AxiosResponse<CycleWorkform>
}

export const removeWorkform = async (workformId: Guid) => {
  return (await fetchWithAuth(`/cycles/topics/workforms/${workformId}`, {
    method: 'DELETE'
  })) as AxiosResponse<CycleWorkform>
}

export const updateWorkform = async (workformId: Guid, data: UpdateWorkform) => {
  return (await fetchWithAuth(`/cycles/topics/workforms/${workformId}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<CycleWorkform>
}

export const getAvailableWorkforms = async (topicId: Guid) => {
  return (await fetchWithAuth(`/topics/${topicId}/workformtemplates`)) as AxiosResponse<
    WorkformTemplate[]
  >
}

export const updateTopic = async (topicId: Guid, data: UpdateTopic) => {
  return (await fetchWithAuth(`/cycles/topics/${topicId}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<CycleTopic>
}

export const updateChecklist = async (checklistId: Guid, data: Partial<UpdateCycleChecklist>) => {
  return (await fetchWithAuth(`/cycles/topics/checklists/${checklistId}`, {
    method: 'PATCH',
    data: data
  })) as AxiosResponse<CycleChecklist>
}

export const updateActiveLocationCycle = async (
  locationId: Guid,
  data: Partial<UpdateActiveCycle>
) => {
  return (await fetchWithAuth(`/cycles/active/location/${locationId}`, {
    method: 'PATCH',
    data: data
  })) as AxiosResponse<OkResult>
}

export const updateChecklistForActiveCycle = async (
  locationChecklistId: Guid,
  data: Partial<UpdateCycleChecklistForActiveCycle>
) => {
  return (await fetchWithAuth(`/cycles/active/checklists/${locationChecklistId}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<CycleChecklist>
}

export const updateChecklistSubject = async (subjectId: Guid, data: UpdateChecklistSubject) => {
  return (await fetchWithAuth(`/cycles/topics/checklists/subjects/${subjectId}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<ChecklistSubject>
}

export const updateChecklistSubSubject = async (
  subSubjectId: Guid,
  data: UpdateChecklistSubSubject
) => {
  return (await fetchWithAuth(`/cycles/topics/checklists/subsubjects/${subSubjectId}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<ChecklistSubSubject>
}

export const updateChecklistQuestion = async (questionId: Guid, data: UpdateChecklistQuestion) => {
  return (await fetchWithAuth(`/cycles/topics/checklists/questions/${questionId}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<ChecklistQuestion>
}

export const updatePreparationStep = async (stepId: Guid, data: Partial<UpdatePreparationStep>) => {
  return (await fetchWithAuth(`/cycles/preparationsteps/${stepId}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<PreparationStep>
}

export const updateCycleStep = async (stepId: Guid, data: UpdateCycleStep) => {
  return (await fetchWithAuth(`/cycles/cyclesteps/${stepId}`, {
    method: 'PUT',
    data
  })) as AxiosResponse<CycleStep>
}

export const updateLocationCycleStep = async (
  cycleLocationStepId: Guid,
  data: Partial<UpdateLocationCycleStep>
) => {
  return (await fetchWithAuth(`/cycles/active/cyclelocationsteps/${cycleLocationStepId}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<CycleLocationStep>
}

export const getActiveLocationCycle = async (locationId: Guid) => {
  return (await fetchWithAuth(
    `/cycles/active/location/${locationId}`
  )) as AxiosResponse<ActiveCycle>
}

export const getActiveOrganisationLocationCycles = async () => {
  return (await fetchWithAuth(`/cycles/active/organisation`)) as AxiosResponse<ActiveCycle[]>
}

export const getActiveOrganisationCycle = async (): Promise<AxiosResponse<ActiveCycle>> => {
  return (await fetchWithAuth(`/cycles/active/editing`)) as AxiosResponse<ActiveCycle>
}

export const pushPreparedCycleToLocations = async (workInstruction: string) => {
  return (await fetchWithAuth('/cycles/preparation/push', {
    method: 'POST',
    data: { workInstruction }
  })) as AxiosResponse
}

export const addActiveCycleWorkform = async (
  locationId: Guid,
  topicId: Guid,
  data: CreateCycleWorkform
) => {
  return (await fetchWithAuth(`/cycles/topics/${topicId}/workforms/location/${locationId}`, {
    method: 'POST',
    data
  })) as AxiosResponse<CycleWorkform>
}

export const updateActiveCycleWorkForm = async (
  cycleLocationWorkformId: Guid,
  data: Partial<CycleWorkFormForm>
) => {
  return (await fetchWithAuth(`/cycles/active/workforms/${cycleLocationWorkformId}`, {
    method: 'PATCH',
    data
  })) as AxiosResponse<CycleWorkform>
}

export const setQuestionReply = async (
  questionId: Guid,
  locationId: Guid,
  data: SaveReplyForLocation
) => {
  return (await fetchWithAuth(
    `/cycles/active/questions/${questionId}/reply/location/${locationId}`,
    { method: 'PATCH', data }
  )) as AxiosResponse
}
