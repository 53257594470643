import StepProgress from './default/index.vue'
import StepProgressCyclus from './cyclus/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    StepProgress: typeof StepProgress
    StepProgressCyclus: typeof StepProgressCyclus
  }
}

export default {
  StepProgress,
  StepProgressCyclus
}
