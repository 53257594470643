<template>
  <li v-if="!item.hasParent" class="nested-doll-menu__title">
    {{ item.title }}
  </li>
  <li v-else class="nested-doll-menu__parent" @click="onParentClick">
    <icon name="action-chevron-left" />
    {{ item.title }}
  </li>
</template>

<script setup lang="ts">
interface Item {
  title: string
  hasParent: boolean
}

interface Props {
  item: Item
}

defineProps<Props>()

const emit = defineEmits(['click'])

const onParentClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
li.nested-doll-menu__title,
li.nested-doll-menu__parent {
  color: map-get($theme-color-primary, 'light-blue-1');
  font-family: $theme-brand-font-headings;
  justify-content: center;

  span {
    position: absolute;
    left: 10px;
  }
}
</style>
