import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useApi } from '@/features/api/api'

import type { Location, UpdateCreateLocation, LocationType } from '@/features/locations/types'
import type { Guid } from '@/support/types/Guid.dto'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type { AxiosResponse } from 'axios'

export const useLocationsStore = defineStore('locations', () => {
  const Api = useApi()
  const locations = ref<Array<Location>>([])
  const locationTypes = ref<Array<LocationType>>([])

  const paginatedLocations = ref<PaginatedApiResponse<Location>>({
    items: [],
    pageIndex: 1,
    totalPages: 1
  })

  const searchLocations = async (query: string) => {
    const response: AxiosResponse<Location[]> | undefined =
      await Api.locations.searchLocations(query)
    const { data = [] } = response || {}
    return data
  }

  const getPaginatedLocations = async () => {
    const { pageIndex } = paginatedLocations.value
    const response = await Api.locations.getLocations(pageIndex)
    const { items = [], totalPages = 1 } = response?.data || {}

    Object.assign(paginatedLocations.value, {
      items: items.map((location) => ({
        ...location,
        manager: { ...location.manager, id: location.manager.id.toLowerCase() } // todo: remove when id of manager is lower case guid
      })),
      pageIndex,
      totalPages
    })

    return response
  }

  const getAllLocations = async () => {
    const response = await Api.locations.getAllLocations()

    locations.value = response.data

    return response
  }

  const getLocationTypes = async () => {
    const response: AxiosResponse<LocationType[]> | undefined =
      await Api.locations.getLocationTypes()
    const { data = [] } = response

    locationTypes.value = data

    return response
  }

  const createLocation = async (location: UpdateCreateLocation) => {
    const response: AxiosResponse<Location[]> | undefined =
      await Api.locations.createLocation(location)
    return response
  }

  const updateLocation = async (location: UpdateCreateLocation) => {
    const response: AxiosResponse<Location[]> | undefined =
      await Api.locations.updateLocation(location)
    return response
  }

  const deleteLocation = async (id: Guid) => {
    const response: AxiosResponse<any> | undefined = await Api.locations.deleteLocation(id)
    return response
  }

  const setPaging = (pageIndex: number) => {
    Object.assign(paginatedLocations.value, {
      pageIndex
    })
  }

  return {
    locations,
    locationTypes,
    paginatedLocations,
    searchLocations,
    getPaginatedLocations,
    getAllLocations,
    getLocationTypes,
    createLocation,
    updateLocation,
    deleteLocation,
    setPaging
  }
})
