<template>
  <modal-default
    :id="modalId"
    :title="title"
    :message="message"
    :button="{ confirm: { text: t('app.button.ok') } }"
    @cancel="$emit('close')"
    @close="$emit('close')"
    @hidden="$emit('hidden')"
  />
</template>

<script setup lang="ts">
import { createGuid } from '@/support/helpers/guid'
import i18n from '@/support/core/localization'

defineEmits<{ close: []; hidden: [] }>()

interface Props {
  title: string
  message?: string | null
}
defineProps<Props>()

const { t } = i18n.global

const modalId = createGuid()
</script>
