import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useApi } from '@/features/api/api'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type {
  Action,
  ActionContext,
  UpdateAction,
  ActionsListFilter
} from '@/features/actions/types'
import type { Guid } from '@/support/types/Guid.dto'

export const useActionsStore = defineStore('actions', () => {
  const Api = useApi()

  const filter = ref<ActionsListFilter>({
    filterTopicId: undefined,
    filterState: undefined,
    filterOnlyOrganisation: false,
    filterNotificationId: undefined,
    filterLocationWorkformId: undefined
  })

  const paginatedActions = ref<PaginatedApiResponse<Action>>({
    items: [],
    pageIndex: 1,
    totalPages: 1,
    totalCount: 0
  })

  const openActionsCount = ref<number>()

  const action = ref<Action>()

  const getAction = async (id: Guid) => {
    const response = await Api.actions.getAction(id)
    if (response.status !== 200) {
      throw new Error('action not found')
    }
    const { data } = response

    action.value = data

    return data
  }

  const getPaginatedActions = async (locationPath: string) => {
    const { pageIndex } = paginatedActions.value
    const response = await Api.actions.getActions({
      ...filter.value,
      locationPath,
      pageIndex: pageIndex
    })
    const { items = [], totalPages = 1, totalCount = 0 } = response.data || {}

    Object.assign(paginatedActions.value, {
      items,
      pageIndex,
      totalPages,
      totalCount
    })

    if (openActionsCount.value !== undefined) {
      await getOpenActionCount(locationPath)
    }

    return response
  }

  const getOpenActionCount = async (locationPath: string) => {
    openActionsCount.value = undefined
    const response = await Api.actions.getActions({ locationPath, filterState: 'open' })
    openActionsCount.value = response.data.totalCount
  }

  const createAction = async (input: UpdateAction) => {
    return await Api.actions.createAction(input)
  }

  const updateAction = async (input: Partial<UpdateAction>) => {
    return await Api.actions.updateAction(input)
  }

  const deleteAction = async (actionId: Guid) => {
    return await Api.actions.deleteAction(actionId)
  }

  const setFilters = (newFilters: ActionsListFilter) => {
    Object.assign(filter.value, newFilters)
  }

  const setPaging = (pageIndex: number) => {
    paginatedActions.value = { ...paginatedActions.value, pageIndex }
  }

  const attach = async (actionId: Guid, { context, id }: ActionContext) => {
    if (context === 'notification') {
      await Api.actions.attachNotification({ actionId, notificationId: id })
    }
    if (context === 'workForm') {
      await Api.actions.attachWorkForm({ actionId, locationWorkformId: id })
    }
  }

  return {
    paginatedActions,
    action,
    getAction,
    getPaginatedActions,
    openActionsCount,
    getOpenActionCount,
    setPaging,
    setFilters,
    createAction,
    updateAction,
    deleteAction,
    attach
  }
})
