<template>
  <div class="editable-content">
    <div v-if="readonly" class="contenteditable readonly" :class="[...classNames]">
      {{ model }}
    </div>
    <div
      v-else
      ref="contenteditable"
      :class="[`contenteditable`, ...classNames, { failed: v$.model.$invalid && v$.model.$dirty }]"
      :data-placeholder="placeholder + (required ? '*' : '')"
      contenteditable="true"
      spellcheck="false"
      @keydown="handleKeydown"
      @paste="handlePaste"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { sanitizeInputWithDOMPurify } from '@/support/helpers/sanitize'

import type { EditableContent } from './types/EditableContent.dto'

const saveInput = (value: string) => {
  const newValue = sanitizeInputWithDOMPurify(value)
  return value.trim() === newValue
}

let observer: MutationObserver

const props = withDefaults(defineProps<EditableContent>(), {
  classNames: () => [] as string[],
  placeholder: '',
  readonly: false,
  enableEnter: false,
  required: false
})

const validations = {
  model: {
    required: requiredIf(() => props.required),
    saveInput
  }
}

const contenteditable = ref<HTMLElement | null>(null)
const model = defineModel<string | null | undefined>('title', { default: '' })
const v$ = useVuelidate(validations, { model })

const handleTextChange = (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'characterData') {
      model.value = contenteditable.value?.innerText ?? ''
      v$.value.$validate()
    }
  })
}

const handleKeydown = (event: KeyboardEvent) => {
  if (props.enableEnter) return

  if (event.key === 'Enter') {
    event.preventDefault()
  }
}

const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault()

  const text = event.clipboardData?.getData('text/plain')

  if (document.getSelection) {
    const selection = document.getSelection()

    if (selection?.rangeCount) {
      selection.deleteFromDocument()
      selection.getRangeAt(0).insertNode(document.createTextNode(text || ''))
    }
  }
  model.value = contenteditable.value?.textContent || ''
}

onMounted(() => {
  if (!contenteditable.value) return

  contenteditable.value.textContent = model.value || ''

  observer = new MutationObserver(handleTextChange)
  observer.observe(contenteditable.value, {
    characterData: true,
    subtree: true
  })
})

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<style lang="scss" scoped>
.editable-content {
  background-color: map-get($theme-color-secondary, 'light-green-2');

  .contenteditable {
    border-bottom: 2px solid white;
    padding: 0 10px;
    cursor: text;
    display: block;
    width: 100%;
    transition: border-color 0.15s ease-in-out;
    margin: 0;

    &.readonly {
      user-select: none;
      cursor: pointer;
    }

    &:empty:before {
      content: attr(data-placeholder);
      pointer-events: none;
      color: map-get($theme-color-primary, 'light-blue-1');
    }

    &.failed:empty:before,
    &.failed {
      color: map-get($theme-color-status, 'error');
    }

    &:hover {
      cursor: text;
    }

    &:focus {
      outline: none;
      border-color: map-get($theme-color-primary, 'light-blue-1');
    }
  }
}
</style>
