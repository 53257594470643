<template>
  <div class="status-bar" :class="currentStatus">
    <div class="status-bar__heading">
      <div class="heading__left column">
        <span>{{ subject }}</span>
        <h3 class="mb-0" :class="process.className">{{ processDetails }}</h3>
      </div>
      <div
        v-if="responsible || deadline || $slots.responsible || $slots.deadline"
        class="heading__right"
      >
        <div v-if="responsible || $slots.responsible" class="column">
          <span>{{ t('statusBar.responsible') }}</span>
          <slot name="responsible">
            <badge :type="Status.neutral" :icon-props="{ name: 'action-account' }">{{
              responsible
            }}</badge>
          </slot>
        </div>
        <div v-if="deadline || $slots.deadline" class="column">
          <span>{{ t('statusBar.deadline') }}</span>
          <slot name="deadline">
            <badge :type="badgeType" :icon-props="{ name: deadlineIcon }">{{ deadline }}</badge>
          </slot>
        </div>
      </div>
    </div>
    <div class="status-bar__bottom">
      <progress-bar :value="process.value" :status="status" />
      <button-icon
        v-if="buttonText"
        variant="primary"
        :disabled="buttonDisabled || process.value < 100"
        :icon-props="{ name: `action-arrow-right`, states: ['hover'] }"
        icon-position="right"
        :loading="buttonLoading"
        @click="$emit('click:button')"
      >
        {{ buttonText }}
      </button-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import './localization'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import { Status } from '@/support/enums/Status'
import type { StatusBar } from './types/StatusBar.dto'

const { t } = useI18n()

const props = defineProps<StatusBar>()

defineSlots<{ responsible(): void; deadline(): void }>()
defineEmits<{ 'click:button': [] }>()

const processDetails = computed(() => {
  return props.status === Status.neutral
    ? props.process.title
    : `${props.process.value}% ${(props.process.title || '').toLowerCase()}`
})

const currentStatus = computed(() => {
  return `status-bar--${props.status}`
})

const badgeType = computed(() => {
  return props.status === Status.error ? Status.error : Status.neutral
})

const deadlineIcon = computed(() => {
  return props.status === Status.error ? 'action-date-white' : 'action-date'
})
</script>

<style lang="scss" scoped>
.status-bar {
  .status-bar__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .column {
      span {
        padding-right: 10px;
        &:not(.badge) {
          display: block;
        }

        &.badge {
          width: 100%;
        }

        &:first-child {
          font-size: $theme-font-size-s;
          color: map-get($theme-color-primary, 'light-blue-1');
        }
      }
    }

    .column + .column {
      margin-left: 10px;
    }

    .heading__right {
      display: flex;

      .column span:first-child {
        margin-bottom: 4px;
      }
    }
  }

  .status-bar__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;

    :deep(.progress-bar) + button {
      margin-left: 20px;
    }
  }

  &--info {
    h3 {
      color: map-get($theme-color-status, 'info');
    }
  }
  &--success {
    h3 {
      color: map-get($theme-color-status, 'success');
    }
  }
  &--error {
    h3 {
      color: map-get($theme-color-status, 'error');
    }
  }
  &--neutral {
    h3 {
      color: map-get($theme-color-neutral, 'grey-2');
    }
  }
}
</style>
