import type { RouteRecordRaw } from 'vue-router'
import { PermissionName } from '@/features/roles/types'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/app/page/Dashboard.vue'),
    children: [
      {
        path: 'nieuwe-cyclus-inrichten',
        name: 'new-cycle.index',
        component: () => import('@/app/page/DashboardNewCycle.vue'),
        meta: {
          requiredPermissions: PermissionName.CyclusCreateShare,
          requiresOrganisationAccess: true,
          title: 'Nieuwe cyclus inrichten'
        }
      },
      {
        path: 'huidige-cyclus',
        name: 'current-cycle.index',
        component: () => import('@/app/page/DashboardCurrentCycle.vue'),
        meta: { needsContext: true }
      },
      {
        path: 'acties/:actionId?',
        name: 'dashboard.actions',
        component: () => import('@/app/page/DashboardActions.vue'),
        meta: { needsContext: true }
      }
    ]
  }
] satisfies RouteRecordRaw[]
