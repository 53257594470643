<template>
  <div :id="`dropdown-${uniqueId}`" ref="dropdown" class="dropdown">
    <div class="dropdown-holder" @click="toggleDropdown">
      <slot></slot>
      <div v-if="open" class="dropdown-container">
        <dropdown-item
          v-for="item in items"
          v-bind="item"
          :key="item.id"
          @on-item-select="onItemClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DropdownItem from './DropdownItem.vue'
import { createGuid } from '@/support/helpers/guid'

import { type DropdownItemProps, type DropdownProps } from '../dtos'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps<DropdownProps>()

const emit = defineEmits<{ 'click:item': [item: DropdownItemProps] }>()

const open = ref(false)

const toggleDropdown = () => {
  open.value = !open.value
}

const closeDropdown = () => {
  if (open.value) {
    toggleDropdown()
  }
}

const uniqueId = createGuid()
const dropdown = ref<HTMLElement | null>(null)

const onItemClick = (id: string) => {
  if (props.onItemSelect) {
    props.onItemSelect(id)
  }
  const item = props.items.find((item) => item.id === id)
  if (item) {
    emit('click:item', item)
  }
  closeDropdown()
}

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
    closeDropdown()
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style lang="scss" scoped>
.dropdown-holder {
  position: relative;
  display: inline-block;
}

.dropdown-container {
  position: absolute;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(38, 56, 75, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-color: map-get($theme-color-primary, 'light-blue-2');
  margin-top: 8px;
  right: 0px;
  z-index: 10;
}
</style>
