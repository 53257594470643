import type { DirectiveBinding, FunctionPlugin, ObjectDirective } from 'vue'
import DOMPurify from 'dompurify'
import router from '@/app/http/router'

const relativeLinkMatcher = new RegExp(
  `^((https?:)?\\/\\/${window.location.host.replace(/\./g, '\\.')})?\\/([^\\/][^\\s"']+)$`,
  'i'
)

DOMPurify.addHook('uponSanitizeElement', (node) => {
  if (node.tagName === 'A') {
    const relativeMatch = node.getAttribute('href')?.match(relativeLinkMatcher)

    if (relativeMatch) {
      node.setAttribute('data-use-router', '')
      node.setAttribute('href', `/${relativeMatch[3]}`)
      node.removeAttribute('target')
    }

    if (!relativeMatch) {
      node.removeAttribute('data-use-router')
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer')
    }
  }
})

const mediaUrlMatcher = /<(img|a)([^>]*)\s(src|href)=['"]\/media\/([^'"]*)['"]/gi

type DirectiveValue = string | undefined | null
const setHtml = async (el: HTMLElement, binding: DirectiveBinding<DirectiveValue>) => {
  let html = DOMPurify.sanitize(String(binding.value || ''), {
    ADD_ATTR: ['target']
  }).trim()
  if (!html) {
    el.innerHTML = ''
    return
  }

  if (!html.match(/<\w+/i)) {
    // Use newlines for non-HTML
    html = html.replace(/\n/g, '<br>\n')
  }

  if (html.match(mediaUrlMatcher)) {
    // media images
    html = html.replace(mediaUrlMatcher, '<$1$2 $3="/api/media/$4"')
  }

  el.classList.add('safe-html')
  el.innerHTML = html
}

const onClick = (ev: MouseEvent) => {
  const element = ev.target as HTMLElement | undefined

  if (element?.tagName === 'A' && element.hasAttribute('data-use-router')) {
    const href = element.getAttribute('href')
    if (!href) {
      return
    }
    ev.preventDefault()
    ev.stopPropagation()
    router.push(href)
  }
}

const vSafeHtml: ObjectDirective<HTMLHtmlElement, DirectiveValue> = {
  mounted: (el, binding) => {
    setHtml(el, binding)
    el.addEventListener('click', onClick)
  },
  updated: setHtml,
  beforeUnmount: (el) => {
    el.innerHTML = ''
    el.classList.remove('safe-html')
    el.removeEventListener('click', onClick)
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    vSafeHtml: typeof vSafeHtml
  }
}

export const HtmlPlugin: FunctionPlugin = (app) => {
  app.directive('safe-html', vSafeHtml)
}
