<template>
  <div :id="id" ref="offcanvas" class="offcanvas offcanvas-end" tabindex="-1">
    <div class="offcanvas-header">
      <transition name="fade" mode="out-in">
        <h2 v-if="!hideTitle" class="mb-0">{{ title }}</h2>
      </transition>
      <a role="button" @click="onClose">
        <icon name="action-close" />
      </a>
    </div>
    <div class="offcanvas-body">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { Offcanvas } from 'bootstrap'

interface Props {
  id: string
  title: string
  hideTitle?: boolean
  open: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hideTitle: false,
  open: false
})

let offcanvasElement: HTMLElement | null = null

const offcanvasInstance = ref<any>()
const emit = defineEmits(['hidden'])

const onClose = () => {
  offcanvasInstance.value.hide()
}

const onHidden = () => {
  emit('hidden', false)
}

const isOpen = computed(() => props.open)

onMounted(() => {
  offcanvasElement = document.getElementById(props.id)

  if (!offcanvasElement) return

  offcanvasInstance.value = new Offcanvas(offcanvasElement, {
    backdrop: true,
    keyboard: true
  })

  offcanvasElement.addEventListener('hidden.bs.offcanvas', onHidden)
})

watch(isOpen, (value) => {
  if (!offcanvasInstance.value) return

  if (value) {
    offcanvasInstance.value.show()
  } else {
    offcanvasInstance.value.hide()
  }
})
</script>

<style lang="scss">
.offcanvas-backdrop.show {
  opacity: 0.2;
}
</style>

<style lang="scss" scoped>
.offcanvas {
  --bs-offcanvas-width: 450px;
  --bs-offcanvas-padding-x: 30px;
  --bs-offcanvas-padding-y: 30px;
  --bs-offcanvas-border-width: 0;

  &-header {
    padding-bottom: 0;
    min-height: 80px;

    :deep(a[role='button']) {
      margin-left: auto;
    }
  }
  &-body {
    padding-top: 15px;
  }
}
</style>
