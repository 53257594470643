<template>
  <div class="edit-toggle-container">
    <span v-if="label" class="label">{{ label }}</span>
    <button
      :class="{
        'edit-state': modelValue === 'edit',
        'view-state': modelValue === 'view' || !modelValue
      }"
      @click="modelValue = modelValue !== 'edit' ? 'edit' : 'view'"
    >
      <div class="slider" />
      <icon
        class="icon__view"
        name="action-view"
        :states="['white']"
        :size="{ width: 32, height: 32 }"
      />
      <icon
        class="icon__edit"
        name="action-edit"
        :states="['white']"
        :size="{ width: 32, height: 32 }"
      />
      <span v-if="badge" class="badge">{{ badge }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'

defineProps<{ label?: string; badge?: string | number }>()

const modelValue = defineModel<'edit' | 'view'>()
const emit = defineEmits<{ (e: 'switch-to-edit' | 'switch-to-view'): void }>()

watch(modelValue, (v) => {
  if (!v) {
    return
  }
  emit(v === 'edit' ? 'switch-to-edit' : 'switch-to-view')
})
</script>

<style lang="scss" scoped>
.edit-toggle-container {
  display: flex;
  align-items: center;

  .label {
    padding-right: 18px;
    font-weight: 700;
  }

  button {
    display: flex;
    background-color: map-get($theme-color-primary, 'dark-blue');
    border: 0;
    padding: 4px;
    border-radius: 10px;
    position: relative;

    .slider {
      position: absolute;
      top: 0;
      background-color: white;
      width: 41px;
      height: 100%;
      border: 5px solid map-get($theme-color-primary, 'dark-blue');
      border-radius: 8px;

      transition: left 0.3s ease-in-out;
    }

    :deep(.icon__svg) {
      transition: opacity 0.3s ease-in-out;
    }

    &.view-state {
      .slider {
        left: 0;
      }

      .icon__view {
        :deep(.icon__svg.idle) {
          opacity: 1;
        }
        :deep(.icon__svg.white) {
          opacity: 0;
        }
      }

      .icon__edit {
        :deep(.icon__svg.idle) {
          opacity: 0;
        }
        :deep(.icon__svg.white) {
          opacity: 1;
        }
      }
    }

    &.edit-state {
      .slider {
        left: 31px;
      }

      .icon__view {
        :deep(.icon__svg.idle) {
          opacity: 0;
        }
        :deep(.icon__svg.white) {
          opacity: 1;
        }
      }

      .icon__edit {
        :deep(.icon__svg.idle) {
          opacity: 1;
        }
        :deep(.icon__svg.white) {
          opacity: 0;
        }
      }
    }
  }

  .badge {
    position: absolute;
    right: -12px;
    top: -10px;

    font-size: 12px;
    line-height: 14.5px;
    color: map-get($theme-color-neutral, 'white');
    font-weight: 700;
    min-width: 18px;
    padding: 2px 6px;
    background-color: map-get($theme-color-status, 'warning');
    border-radius: 1000px;
  }
}
</style>
