import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useApi } from '@/features/api/api'

import type { AxiosResponse } from 'axios'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type {
  User,
  UpdateUser,
  UsersListFilter,
  UserListFilterOptions,
  InviteUser,
  UserFilters
} from '@/features/user/types'
import type { Guid } from '@/support/types/Guid.dto'

export const useUserStore = defineStore('user', () => {
  const Api = useApi()
  const user = ref<User | null>(null)
  const users = ref<User[] | undefined>()
  const filterData = ref<UserFilters>({ locations: [], roles: [] })
  const filter = ref<UsersListFilter>({
    locationId: null,
    roleId: null
  })
  const paginatedUsers = ref<PaginatedApiResponse<User>>({
    items: [],
    pageIndex: 1,
    totalPages: 1
  })

  const filterOptions = computed<UserListFilterOptions>(() => {
    const { locations = [], roles = [] } = filterData.value || {}
    return {
      locations: locations.map((location) => ({
        value: location.id,
        label: location.name || ''
      })),
      roles: roles.map((role) => ({
        value: role.id,
        label: role.name
      }))
    }
  })

  const getPaginatedUsers = async () => {
    const { pageIndex } = paginatedUsers.value
    const response = await Api.user.getUsers({
      ...filter.value,
      pageIndex
    })
    const { items = [], totalPages = 0 } = response?.data || {}

    Object.assign(paginatedUsers.value, {
      items,
      pageIndex,
      totalPages
    })

    return response
  }

  let getAllUsersPromise: Promise<User[]> | undefined = undefined
  const getAllUsers = async (): Promise<User[]> => {
    if (getAllUsersPromise) {
      return getAllUsersPromise
    }
    getAllUsersPromise = new Promise((resolve, reject) => {
      Api.user.getUsers({ pageSize: 0 }).then((result) => {
        if (!result.data) {
          reject()
          return
        }
        users.value = result.data as unknown as User[]
        resolve(users.value)
      })
    })
    return getAllUsersPromise
  }
  // always get all users
  getAllUsers()

  const reloadAllUsers = async () => {
    getAllUsersPromise = undefined
    return getAllUsers()
  }

  const isSelectedUser = (user: User, selectedUserId?: Guid | Guid[] | null): boolean => {
    if (!selectedUserId) {
      return false
    }
    return (
      (Array.isArray(selectedUserId) && selectedUserId.some((id) => id === user.id)) ||
      user.id === selectedUserId
    )
  }
  const getUsersForLocation = async (locationId: Guid, selectedUserId?: Guid | Guid[] | null) =>
    (await getAllUsers())
      .map((user) => ({
        ...user,
        isDeleted:
          user.isDeleted ||
          (!user.hasAccessToOrganisation &&
            !user.locations?.some((location) => location.id === locationId))
      }))
      .filter((user) => (!user.isDeleted && user.hasSsoId) || isSelectedUser(user, selectedUserId))

  const getUsersForOrganisation = async (selectedUserId?: Guid | Guid[] | null) =>
    (await getAllUsers()).filter(
      (user) =>
        (!user.isDeleted && user.hasSsoId && user.hasAccessToOrganisation) ||
        isSelectedUser(user, selectedUserId)
    )

  const getUserFilters = async () => {
    const response = await Api.user.getUserFilters()
    filterData.value = response.data
    return response
  }

  const inviteUser = async (user: InviteUser) => {
    const response: AxiosResponse<User> = await Api.user.inviteUser(user)
    return response
  }

  const updateUser = async (id: Guid, user: Partial<UpdateUser>) => {
    const response: AxiosResponse<User> | undefined = await Api.user.updateUser(id, user)
    return response
  }

  const deleteUser = async (id: string) => {
    const response: AxiosResponse<any> = await Api.user.deleteUser(id)
    return response
  }

  const setFilters = (newFilters: UsersListFilter) => {
    Object.assign(filter.value, newFilters)
  }

  const setPaging = (pageIndex: number) => {
    Object.assign(paginatedUsers.value, {
      pageIndex
    })
  }

  return {
    user,
    users,
    paginatedUsers,
    filterOptions,
    getUsersForLocation,
    getUsersForOrganisation,
    inviteUser,
    updateUser,
    deleteUser,
    getPaginatedUsers,
    getAllUsers,
    reloadAllUsers,
    getUserFilters,
    setFilters,
    setPaging
  }
})
