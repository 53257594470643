<template>
  <ul :class="{ 'nested-doll-menu': true, 'search-active': searchActive }">
    <nested-doll-menu-parent
      v-if="!searchActive"
      :item="computedParentTitle"
      @click="onParentClick"
    />
    <nested-doll-menu-item
      v-for="(item, index) in currentContentTree"
      :key="index"
      :item="item"
      @click="onClick"
    />
  </ul>
</template>

<script setup lang="ts">
import './localization'

import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import NestedDollMenuItem from './components/NestedDollMenuItem.vue'
import NestedDollMenuParent from './components/NestedDollMenuParent.vue'

import getCurrentContentTreeFromPath from './helpers/getCurrentContentTreeFromPath'
import type { HelpItem } from '@/features/helpAndContact/types'

interface Props {
  contentTree: HelpItem[]
  searchActive: boolean
}

const { t } = useI18n()

const props = defineProps<Props>()

const currentContentTree = ref([])
const parent = ref<any>(null)
const currentLevel = ref(0)
const emit = defineEmits(['open'])
const path = defineModel<Array<string>>('path', {
  default: []
})

const onClick = ({ action, item }: any) => {
  if (action === 'next') {
    path.value = [...path.value, item.slug]
  } else {
    emit('open', item)
  }
}

const onParentClick = () => {
  const pathCopy = [...path.value]
  pathCopy.pop()
  path.value = pathCopy
}

const computedParentTitle = computed(() => {
  const hasParent = parent.value && parent.value.title ? true : false

  return {
    title: parent.value?.title || t('nestedDollMenu.root'),
    hasParent
  }
})

const computedCurrentContentTree = computed(() => {
  const { currentLevel, currentContentTree, parent } = getCurrentContentTreeFromPath(
    path.value,
    props.contentTree
  )

  return {
    currentLevel,
    currentContentTree,
    parent
  }
})

watch(
  () => computedCurrentContentTree,
  () => {
    parent.value = computedCurrentContentTree.value.parent
    currentLevel.value = computedCurrentContentTree.value.currentLevel
    currentContentTree.value = computedCurrentContentTree.value.currentContentTree
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss" scoped>
ul {
  &,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 20px;
    position: relative;
    user-select: none;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 1px;
      background-color: map-get($theme-color-primary, 'light-blue-2');
    }

    &:not(.nested-doll-menu__title):hover {
      background-color: map-get($theme-color-primary, 'light-blue-2');
      border-radius: 4px;
      cursor: pointer;
    }
    &:first-child,
    &:not(.nested-doll-menu__title):hover,
    &:not(.nested-doll-menu__title):hover + li {
      &:after {
        display: none;
      }
    }
  }

  &.search-active {
    li:first-child {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 1px;
        background-color: map-get($theme-color-primary, 'light-blue-2');
      }
    }
  }
}
</style>
