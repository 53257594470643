<template>
  <div :class="['search-input', ...classNames]">
    <simple-typeahead
      :id="id"
      :key="index"
      ref="searchInput"
      :placeholder="placeholder || $t('app.search')"
      :items="items"
      :default-item="defaultItem"
      :min-input-length="minInputLength"
      :item-projection="itemProjection"
      @select-item="selectItemEventHandler"
      @on-input="onInputEventHandler"
      @on-focus="onFocusEventHandler"
      @on-blur="onBlurEventHandler"
    >
      <template #list-item-text="{ item }"
        ><div
          class="simple-typeahead-list-item__text"
          :class="{ disabled: itemIsDisabled ? itemIsDisabled(item) : false }"
        >
          {{ itemProjection ? itemProjection(item) || item : item }}
        </div></template
      >
    </simple-typeahead>
    <template v-if="iconProps">
      <icon :name="iconProps.name" :class="`icon--` + iconProps.position" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, computed, nextTick, watch } from 'vue'
import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

import type { SearchInputProps, SearchInputEvent } from './types'

const props = withDefaults(defineProps<SearchInputProps>(), {
  minInputLength: 3,
  defaultItem: null,
  id: 'search',
  clearInput: true,
  variant: 'default',
  iconProps: () => ({
    name: 'action-search',
    position: 'right'
  })
})

const emit = defineEmits(['selectItem', 'onInput', 'onFocus', 'onBlur'])
const searchInput = ref<any>(null)
const index = ref(0)
const { items, defaultItem, minInputLength, id, iconProps, placeholder } = toRefs(props)

const classNames = computed(() => [
  `search-input--${props.variant}`,
  `search-input--icon-${props.iconProps.position}`,
  props.itemIsDisabled && props.itemIsDisabled(props.defaultItem)
    ? 'search-input__disabled'
    : undefined
])

const selectItemEventHandler = (item: string) => {
  emit('selectItem', item)

  if (props.clearInput) {
    searchInput.value?.clearInput()
  }
}

const onInputEventHandler = (event: SearchInputEvent) => {
  const { input } = event

  if (input.length < props.minInputLength) return

  emit('onInput', event)
}

const onFocusEventHandler = () => {
  emit('onFocus')

  // When the default item is set, trigger clearInput() on focus...
  if (props.defaultItem) {
    searchInput.value?.clearInput()
  }
}

const onBlurEventHandler = async () => {
  emit('onBlur')

  // Wait for the next tick to ensure the input is blurred and value has been set...
  await nextTick()

  // When the default item is set, trigger re-render on blur...
  if (props.defaultItem) {
    index.value += 1
  }
}

watch(
  () => props.items,
  () => {
    index.value += 1
  }
)

watch(defaultItem, () => {
  if (defaultItem.value === searchInput.value?.currentSelection) {
    return
  }
  searchInput.value?.selectItem(defaultItem.value)
})
</script>

<style lang="scss" scoped>
:deep(.simple-typeahead) {
  .simple-typeahead-list {
    min-width: 220px;
    left: 0;
    margin-top: 2px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid map-get($theme-color-primary, 'light-blue-2');

    .simple-typeahead-list-header,
    .simple-typeahead-list-footer,
    .simple-typeahead-list-item {
      padding: 0;
      display: flex;
      justify-content: stretch;

      background-color: map-get($theme-color-neutral, 'white');
      border: 0;

      &.simple-typeahead-list-item-active {
        background-color: map-get($theme-color-primary, 'light-blue-2');
      }

      &__text {
        padding: 12px;
        color: map-get($theme-color-primary, 'dark-blue');

        &.disabled {
          color: map-get($theme-color-neutral, 'grey-2');
          text-decoration: line-through;
        }
      }

      &:hover {
        cursor: default;
      }
    }
  }
}

.search-input {
  position: relative;

  &.search-input__disabled {
    :deep(input[type='text']) {
      color: map-get($theme-color-neutral, 'grey-2');
      text-decoration: line-through;
    }
  }

  :deep(input[type='text']) {
    padding: 10px;
    border-radius: 4px;
    width: 100%;

    font-size: map-get($form-field-font, 'size');
    color: map-get($form-field-font, 'color');

    background-color: map-get($form-field-background-color, 'idle');
    border: 1px solid map-get($form-field-border-color, 'idle');
    transition: all 0.3s ease;

    &:hover {
      background-color: map-get($form-field-background-color, 'hover');
      border-color: map-get($form-field-border-color, 'hover');
    }

    &:focus,
    &:focus:active {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');

      box-shadow: 0 0 0 5px $form-field-outline-color;
      border-radius: 2px;
    }

    &:disabled {
      background-color: map-get($form-field-background-color, 'focus');
      border-color: map-get($form-field-border-color, 'focus');
      color: $form-field-font-color-disabled;
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $form-field-placeholder-color;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &--left {
      left: 10px;
    }

    &--right {
      right: 10px;
    }
  }

  &--tiny {
    :deep(input[type='text']) {
      padding: 7px;
    }

    &.search-input--icon-left {
      :deep(input[type='text']) {
        padding-left: 30px;
      }
      .icon--left {
        left: 5px;
      }
    }
    &.search-input--icon-right {
      :deep(input[type='text']) {
        padding-right: 30px;
      }
      .icon--right {
        right: 5px;
      }
    }
  }

  &--default {
    &.search-input--icon-left {
      :deep(input[type='text']) {
        padding-left: 40px;
      }
    }
    &.search-input--icon-right {
      :deep(input[type='text']) {
        padding-right: 40px;
      }
    }
  }
}
</style>
