import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/dashboard/huidige-cyclus/stap/:cycleStepId',
    name: 'current-cycle.step',
    component: () => import('@/app/page/CurrentCycleStep.vue')
  },
  {
    path: '/dashboard/huidige-cyclus/checklist/:checklistId',
    name: 'current-cycle.checklist',
    component: () => import('@/app/page/CurrentCycleChecklist.vue'),
    meta: { print: { hideTitle: true } }
  },
  {
    path: '/dashboard/huidige-cyclus/checklist/:checklistId/subject/:subjectId',
    name: 'current-cycle.checklist-subject',
    component: () => import('@/app/page/CurrentCycleChecklist.vue')
  },
  {
    path: '/dashboard/huidige-cyclus/checklist/:checklistId/uitkomsten',
    name: 'current-cycle.checklist-results',
    component: () => import('@/app/page/CurrentCycleChecklist.vue')
  },
  {
    path: '/dashboard/huidige-cyclus/werkvorm/:workformId',
    name: 'current-cycle.workform',
    component: () => import('@/app/page/CurrentCycleWorkform.vue')
  }
] satisfies RouteRecordRaw[]
