import { ref } from 'vue'

import { defineStore } from 'pinia'
import { createGuid } from '@/support/helpers/guid'

import type { Alert } from '@/support/components/alert/types'

export const useAlertStore = defineStore('alert', () => {
  const messages = ref<Array<Alert>>([])

  const setMessage = ({ message, type }: Alert) => {
    const guid = createGuid()
    const alert: Alert = {
      message,
      type,
      guid
    }

    messages.value.push(alert)
  }

  const removeMessage = (guid: string) => {
    messages.value = messages.value.filter((message: Alert) => message.guid !== guid)
  }

  return {
    messages,
    setMessage,
    removeMessage
  }
})
