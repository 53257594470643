import type { Guid } from '@/support/types/Guid.dto'
import type { User } from '@/features/user/types'
import type { Location } from '@/features/locations/types'
import type { Action } from '@/features/actions/types'

export type PreparationCycleItemStatus = 'toDo' | 'inProgress' | 'completed'
export type CycleItemStatus = 'toDo' | 'inProgress' | 'completed' | 'skipped'

export type ChecklistReplySlug = 'ja' | 'nee' | 'nvt' | 'later'
export type ChecklistReply = {
  reply: ChecklistReplySlug
  action: Action | null
}
export type ChecklistQuestion = {
  id: Guid
  order?: number
  question: string | null
  description: string | null
  enabled: boolean
  number?: number
  reply: ChecklistReply | null
}
export type SaveReplyForLocation = {
  reply: ChecklistReplySlug
}

export type ChecklistSubSubject = {
  id: Guid
  order?: number
  title: string | null
  description: string | null
  enabled: boolean
  questions: ChecklistQuestion[] | null
}
export type ChecklistSubject = {
  id: Guid
  order?: number
  title: string | null
  description: string | null
  enabled: boolean
  subSubjects: ChecklistSubSubject[] | null
}
export type CycleLocationChecklist = {
  id: Guid
  deadlineAt: string | null
  isSkipped: boolean
  manager: User
  location: Location
}
export type CycleChecklist = {
  id: Guid
  bodyText: string | null
  introText: string | null
  iconUrl: string | null
  enabled: boolean
  manager: User | null
  status: PreparationCycleItemStatus
  deadlineAt: string | null
  subjects: ChecklistSubject[] | null
  locationChecklist: CycleLocationChecklist | null
}
export type WorkformResult = {
  conclusion: string | null
  mediaUrls: string[] | null
  actions: Action[] | null
}

export type CycleWorkFormForm = {
  id?: Guid
  deadlineAt: string | null
  managerId: Guid | null
  status: CycleItemStatus
  conclusion: string | null
  mediaUrls: string[]
}

export type CycleLocationWorkform = {
  id: Guid
  deadlineAt: string
  location: Location
  status: CycleItemStatus
  manager: User | null
  locationResult: WorkformResult | null
}

export type CycleWorkform = {
  id: Guid
  workformTemplateId: Guid
  type: string | null
  title: string | null
  text: string | null
  imageUrl: string | null
  mediaUrls: string[] | null
  preparationDuration: string | null
  sessionDuration: string | null
  introText: string | null
  deadlineAt: string | null
  isAddedByLocation: boolean
  locationWorkform: CycleLocationWorkform
}

export type CycleTopic = {
  id: Guid
  topicTemplateId: Guid
  title: string | null
  text: string | null
  order: number
  enabled: boolean
  checklists: CycleChecklist[] | null
  cycleWorkforms: CycleWorkform[] | null
  iconUrl: string | null
}

export type CyclePreparationStepSlug =
  | 'topics'
  | 'planning'
  | 'big-risks'
  | 'small-risks'
  | 'policy'

export const POLICY_CYCLE_PREPARATION_STEPS: CyclePreparationStepSlug[] = [
  'policy',
  'big-risks',
  'small-risks'
]

export type PreparationStep = {
  id: Guid
  title: string | null
  slug: CyclePreparationStepSlug
  order: number
  status: PreparationCycleItemStatus
}
export type CycleDuration = {
  days: number
}
export type CyclePreparation = {
  id: Guid
  startAt: string | null
  duration: CycleDuration | null
  cycleTopics: CycleTopic[] | null
  preparationSteps: PreparationStep[] | null
  cycleSteps: CycleStep[] | null
}

export type WorkformTemplate = {
  id: Guid
  topicTemplateId: Guid
  type: string | null
  title: string | null
  introText: string | null
  mediaUrl: string | null
  name: string | null
  text: string | null
  preparationDuration: string | null
  sessionDuration: string | null
  files: string[] | null
}

export type UpdateCyclePreparation = {
  startAt: string
  duration: CycleDuration
}
export type UpdateActiveOrganisationCycle = UpdateCyclePreparation

type UpdateEnabled = {
  enabled: boolean
}
export type UpdateTopic = UpdateEnabled
export type UpdateCycleChecklist = {
  enabled: boolean | null
  status: CycleItemStatus | null
  deadlineAt: string | null
}
export type UpdateCycleChecklistForActiveCycle = {
  managerId: Guid | null
  deadlineAt: string
  isSkipped: boolean
}
export type UpdateChecklistSubject = UpdateEnabled
export type UpdateChecklistSubSubject = UpdateEnabled
export type UpdateChecklistQuestion = UpdateEnabled

export type UpdatePreparationStep = {
  status: CycleItemStatus | null
}

export type UpdateCycleStep = {
  deadline: string
}

export type UpdateLocationCycleStep = {
  deadline: string
  managerId: Guid
  status: CycleItemStatus
}

export type AddWorkform = {
  workformTemplateId: Guid
  deadlineAt?: string | null
}

export type UpdateWorkform = {
  deadlineAt: string | null
}

export type CreateCycleWorkform = {
  workformTemplateId: Guid
  deadlineAt?: string | null
}

export type CycleStepSlug = 'bigRisk' | 'smallRisk' | 'policy'

export const cycleStepSlugList: CycleStepSlug[] = ['bigRisk', 'smallRisk', 'policy']

export type CycleLocationStep = {
  id: Guid
  location: Location
  manager: User
  status: CycleItemStatus
  deadline: string
}

export type CycleStep = {
  id: Guid
  slug: CycleStepSlug
  manager: User
  status: CycleItemStatus
  deadline: string
  cycleLocationStep: CycleLocationStep
}
export type CycleLocation = {
  id: Guid
  cycleId: Guid
  location: Location
  manager: User | null
  deadline: string | null
}
export type ActiveCycle = {
  id: Guid
  startAt: string | null
  duration: CycleDuration | null
  cycleLocation: CycleLocation | null
  isDraft: boolean
  isActive: boolean
  cycleSteps: CycleStep[]
  cycleTopics: CycleTopic[]
  preparationSteps: PreparationStep[] | null
  workInstruction: string | null
}
export type UpdateActiveCycle = {
  deadline: string | null
  managerId: Guid | null
}
