<template>
  <div class="action-overview">
    <div v-if="!hideTitle" class="title d-flex align-items-center">
      <list-label
        :hide-add-button="!canCreateActionsPermission"
        :label="$t('actions.title')"
        @click:add="onClickNewAction"
      />
    </div>

    <list-view-filters v-if="!hideFilters">
      <reactive-form-field-select v-model="filters.filterState" :field="{ ...filterFieldState }" />
      <reactive-form-field-select
        v-model="filters.filterTopicId"
        :field="{
          ...filterFieldTopic,
          disabled: Boolean(loading && !filterFieldTopic.options.length)
        }"
      />
      <toggle-button
        v-if="currentContextType === 'organisation'"
        v-model="filters.filterOnlyOrganisation"
        >{{ $t('actions.overview.filter.onlyOrganisation') }}</toggle-button
      >
      <toggle-button v-if="currentContextType === 'location'" v-model="filters.filterOnlyMine">{{
        $t('actions.overview.filter.onlyMine')
      }}</toggle-button>

      <template #right>
        <div class="buttons">
          <a
            role="button"
            :href="listViewRecords.length ? exportUrl : '#'"
            :target="listViewRecords.length ? '_blank' : undefined"
          >
            <icon name="action-download" />
            {{ $t('app.button.export') }}
          </a>
          <button-icon
            v-if="canCreateActionsPermission"
            variant="secondary"
            :icon-props="{ name: `action-add`, states: ['disabled'] }"
            :loading="saving"
            icon-position="left"
            @click="onClickNewAction"
          >
            {{ $t('app.button.new') }}
          </button-icon>
        </div>
      </template>
    </list-view-filters>

    <div v-if="actionsStore.paginatedActions?.totalCount" class="font-size-s mb-3">
      <strong>{{
        $t('actions.overview.listCount', { count: actionsStore.paginatedActions.totalCount })
      }}</strong>
    </div>

    <skeleton v-if="loading && !listViewRecords.length" />
    <spinner-context>
      <spinner v-if="(saving || loading) && listViewRecords.length" />
      <template #context>
        <list-view
          v-if="listViewRecords.length"
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewButtons"
          class="list-view-actions mb-5"
          @event="onRowClickHandler"
        >
          <template #location="{ item }">{{ item.location?.name ?? 'Organisatie' }}</template>
          <template #footer>
            <pagination
              :page-index="actionsStore.paginatedActions.pageIndex"
              :total-pages="actionsStore.paginatedActions.totalPages"
              @change="onPageChange"
            />
          </template>
        </list-view>

        <empty-state
          v-if="!loading && !listViewRecords.length"
          class="mb-4"
          :title="emptyStateProps.title"
          :description="emptyStateProps.description"
          :icon-props="emptyStateProps.iconProps"
        />
      </template>
    </spinner-context>

    <action-detail
      ref="actionDetailRef"
      v-model:saving="saving"
      @close-edit-dialog="onCloseEditDialog"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed, onMounted, watch } from 'vue'

import i18n from '@/support/core/localization'
import { Status } from '@/support/enums/Status'
import { FieldType } from '@/support/components/reactiveForm/enums/FieldType'

import ActionDetail from '@/features/actions/components/actionDetail/index.vue'

import { currentContextApiPath, currentContextType } from '@/features/auth'
import { useActionsStore } from '@/features/actions/store'
import { useTopicsStore } from '@/features/topics/store'
import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'
import { useActionsPermissions } from '@/features/actions/compositions/useActionsPermissions'

import type { Guid } from '@/support/types/Guid.dto'
import type { ReactiveFormFieldSelect } from '@/support/components/reactiveForm/types/ReactiveFormField.dto'
import type { Action, ActionContext, ActionsListFilter } from '@/features/actions/types'
import { useRoute, useRouter } from 'vue-router'
import type { ListviewHeader } from '@/support/components/listView/types'

const { t } = i18n.global

const router = useRouter()
const route = useRoute()

type ActionsOverviewProps = {
  hideFilters?: boolean
  hideTitle?: boolean
  context?: ActionContext
  rowClick?: ({ action, id }: { id: Guid; action: 'edit' | 'delete' }) => void
  addClick?: () => void
}

const props = defineProps<ActionsOverviewProps>()

const actionsStore = useActionsStore()
const topicStore = useTopicsStore()
const actionsPermissions = useActionsPermissions()
const { canCreateActionsPermission } = actionsPermissions

const loading = ref<boolean | number>(1)
const saving = ref<boolean>(false)
const actionDetailRef = ref<InstanceType<typeof ActionDetail>>()

const isLocationOrContextNotification = computed(
  () => currentContextType.value === 'location' || props.context?.context === 'notification'
)

const emptyStateProps = computed(() => {
  if (props.context) {
    return {
      title: t('actions.overview.emptyState.context.title'),
      description: canCreateActionsPermission.value
        ? t('actions.overview.emptyState.context.description')
        : undefined,
      iconProps: null
    }
  }

  return {
    title: t('actions.overview.emptyState.default.title'),
    description: canCreateActionsPermission.value
      ? t('actions.overview.emptyState.default.description')
      : undefined,
    iconProps: {
      name: 'illustration-empty-state-actions',
      size: { width: 180, height: 180 }
    }
  }
})

const getStatus = (item: Action): Status => {
  if (item.status === 'todo') {
    return Status.warning
  }
  if (item.status === 'done') {
    return Status.success
  }
  if (item.status === 'inprogress') {
    return Status.info
  }
  if (item.status === 'skipped') {
    return Status.grey
  }
  return Status.neutral
}

const listViewButtons = [ListViewButton.Edit, ListViewButton.Delete]
const listViewHeader = computed<ListviewHeader<keyof Action>[]>(() => {
  if (isLocationOrContextNotification.value) {
    return [
      { value: t('actions.overview.header.name'), property: 'name' },
      {
        value: t('actions.overview.header.responsible'),
        property: 'manager',
        align: 'right',
        width: 210
      },
      {
        value: t('actions.overview.header.deadline'),
        property: 'deadlineAt',
        align: 'right',
        width: 140
      },
      {
        value: t('actions.overview.header.status'),
        property: 'status',
        align: 'right',
        width: 140
      }
    ]
  }

  return [
    { value: t('actions.overview.header.name'), property: 'name' },
    { value: t('actions.overview.header.location'), property: 'location', width: 140 },
    {
      value: t('actions.overview.header.responsible'),
      property: 'manager',
      align: 'right',
      width: 210
    },
    {
      value: t('actions.overview.header.deadline'),
      property: 'deadlineAt',
      align: 'right',
      width: 140
    },
    { value: t('actions.overview.header.status'), property: 'status', align: 'right', width: 140 }
  ]
})

const listViewRecords = computed(() => {
  if (loading.value === 1) {
    return Array.from({ length: 10 }, (_, index) => ({
      id: index,
      name: t('app.loading.records', { entity: t('actions.title') }),
      ...(!isLocationOrContextNotification.value ? { location: '' } : {}),
      manager: '',
      deadlineAt: null,
      status: '',
      disableButtons: [ListViewButton.Edit, ListViewButton.Delete]
    }))
  }

  return actionsStore.paginatedActions.items.map((item: Action) => {
    const disableButtons: ListViewButton[] = [ListViewButton.Delete]

    return {
      ...item,
      manager: item.manager,
      status: {
        badge: {
          value: t(`actions.status.${item.status}`),
          badgeProps: { type: getStatus(item), rounded: false }
        }
      },
      disableButtons
    }
  })
})

const filters = reactive<ActionsListFilter>({
  filterState: 'open',
  filterNotificationId: props.context?.context === 'notification' ? props.context.id : undefined,
  filterLocationWorkformId: props.context?.context === 'workForm' ? props.context.id : undefined,
  filterOnlyOrganisation: false,
  filterOnlyMine: false,
  filterTopicId: null
})

const onPageChange = async (pageIndex: number) => {
  loading.value = true
  actionsStore.setPaging(pageIndex)
  await actionsStore.getPaginatedActions(currentContextApiPath.value)
  loading.value = false
}

const filterFieldTopic = computed<ReactiveFormFieldSelect>(() => ({
  id: 'topic',
  type: FieldType.SELECT,
  label: t('actions.overview.filter.topic'),
  placeholder: t('app.filterOn', {
    entity: t('actions.overview.filter.topic').toLowerCase()
  }),
  hideLabel: true,
  options: (topicStore.topics || []).map((topic) => ({ label: topic.title, value: topic.id }))
}))

const filterFieldState = computed<ReactiveFormFieldSelect>(() => ({
  id: 'state',
  type: FieldType.SELECT,
  label: t('actions.overview.filter.state.label'),
  placeholder: t('app.filterOn', {
    entity: t('actions.overview.filter.state.label').toLowerCase()
  }),
  hideLabel: true,
  options: [
    { label: t('actions.overview.filter.state.open'), value: 'open' },
    { label: t('actions.overview.filter.state.closed'), value: 'closed' }
  ]
}))

const exportUrl = computed<string>(
  () =>
    `/api/actions/export/${currentContextApiPath.value}?${(
      Object.keys(filters) as (keyof ActionsListFilter)[]
    )
      .filter((key) => filters[key])
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(String(filters[key]))}`)
      .join('&')}`
)

const onClickNewAction = () => {
  const { addClick } = props

  if (addClick) {
    addClick()
    return
  }

  actionDetailRef.value?.openDialog(null, props.context)
}

const onRowClickHandler = ({ action, id }: { id: Guid; action: 'edit' }) => {
  if (action === 'edit') {
    if (props.rowClick) {
      props.rowClick({ action, id })
      return
    }
    actionDetailRef.value?.openDialog(id)
  }
}

watch(
  () => [filters, currentContextApiPath.value],
  async () => {
    loading.value = true
    actionsStore.setFilters(filters)
    actionsStore.setPaging(1)
    await actionsStore.getPaginatedActions(currentContextApiPath.value)
    loading.value = false
  },
  { deep: true, immediate: true }
)

onMounted(async () => {
  if (route.params.actionId) {
    actionDetailRef.value?.openDialog(route.params.actionId as Guid)
  }

  await actionsStore.getPaginatedActions(currentContextApiPath.value)
})

const onCloseEditDialog = () => {
  if (route.params.actionId) {
    router.replace({ name: 'dashboard.actions', params: { actionId: undefined } })
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
:deep(.column-buttons) {
  display: none !important;
}

.buttons {
  display: flex;

  a[role='button'] {
    margin-right: 15px;
  }
}
</style>
