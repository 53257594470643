import SubMenuDefault from './default/index.vue'
import SubMenuVertical from './vertical/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    SubMenuDefault: typeof SubMenuDefault
    SubMenuVertical: typeof SubMenuVertical
  }
}

export default {
  SubMenuDefault,
  SubMenuVertical
}
