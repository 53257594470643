<template>
  <div class="video-poster" @click="onClick">
    <img :src="poster" alt="Video poster" />
    <icon name="action-play-white" :size="{ width: 60, height: 60 }" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  poster: string
}

defineProps<Props>()

const emit = defineEmits(['click'])

const onClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.video-poster {
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
