import ReactiveForm from './index.vue'
import ReactiveFormFieldDatePicker from './components/reactiveFormFieldDatePicker.vue'
import ReactiveFormFieldInputCheckbox from './components/reactiveFormFieldInputCheckbox.vue'
import ReactiveFormFieldInputText from './components/reactiveFormFieldInputText.vue'
import ReactiveFormFieldLabel from './components/reactiveFormFieldLabel.vue'
import ReactiveFormFieldRadio from './components/reactiveFormFieldRadio.vue'
import ReactiveFormFieldSelect from './components/reactiveFormFieldSelect.vue'
import ReactiveFormFieldToggle from './components/reactiveFormFieldToggle.vue'
import ReactiveFormFieldTextarea from './components/reactiveFormFieldTextarea.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ReactiveForm: typeof ReactiveForm
    ReactiveFormFieldDatePicker: typeof ReactiveFormFieldDatePicker
    ReactiveFormFieldInputCheckbox: typeof ReactiveFormFieldInputCheckbox
    ReactiveFormFieldInputText: typeof ReactiveFormFieldInputText
    ReactiveFormFieldLabel: typeof ReactiveFormFieldLabel
    ReactiveFormFieldRadio: typeof ReactiveFormFieldRadio
    ReactiveFormFieldSelect: typeof ReactiveFormFieldSelect
    ReactiveFormFieldToggle: typeof ReactiveFormFieldToggle
    ReactiveFormFieldTextarea: typeof ReactiveFormFieldTextarea
  }
}

export default {
  ReactiveForm,
  ReactiveFormFieldDatePicker,
  ReactiveFormFieldInputCheckbox,
  ReactiveFormFieldInputText,
  ReactiveFormFieldLabel,
  ReactiveFormFieldRadio,
  ReactiveFormFieldSelect,
  ReactiveFormFieldToggle,
  ReactiveFormFieldTextarea
}
