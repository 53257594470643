<template>
  <div class="list-view">
    <ul :class="className">
      <list-view-row v-if="!hideHeader" :buttons="buttons">
        <list-view-column
          v-for="header in headers"
          :key="header.property"
          :item="header"
          :property="header.property"
          :width="header.width"
          :align="header.align"
          :wrap="header.wrap"
        >
          <slot :name="`header-${header.property}`" :item="header" />
        </list-view-column>
      </list-view-row>
      <list-view-row
        v-for="(row, index) in rows"
        :key="row.id || index"
        :item="row"
        :buttons="buttons"
        :disable-row-click="disableRowClick"
        :hoverable="hoverable"
        @event="onEventHandler"
      >
        <list-view-column
          v-for="header in headers"
          :key="header.property"
          :item="row[header.property]"
          :property="header.property"
          :width="header.width"
          :align="header.align"
          :wrap="header.wrap"
        >
          <slot
            :key="header.property"
            :name="header.property"
            :item="row"
            :value="row[header.property]"
          />
        </list-view-column>
      </list-view-row>
    </ul>
    <div class="list-view__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts" setup generic="ListItem extends ListViewRecord">
import type { ListView, ListviewHeader, ListViewRecord } from './types'

const emit = defineEmits(['event'])

withDefaults(defineProps<ListView<ListItem>>(), {
  hideHeader: false,
  className: null,
  buttons: () => [],
  disableRowClick: false
})

defineSlots<{
  [K: ListviewHeader['property']]: (props: {
    key: keyof ListItem
    item: ListItem
    value: unknown
  }) => void
  footer(): void
}>()

const onEventHandler = (event: { action: string; record: Record<string, any> }) => {
  emit('event', event)
}
</script>

<style lang="scss" scoped>
.list-view {
  .list-view__row:not(.header) .column:first-child {
    font-family: $theme-brand-font-headings;
    font-weight: 700;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0 0;
  }
}
</style>
