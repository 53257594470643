const MODE = import.meta.env.VITE_APP_MODE

const log = (type: 'log' | 'info' | 'warn' | 'error', ...content: any[]): void => {
  if (type === 'log' && MODE !== 'development') {
    return
  }

  // eslint-disable-next-line no-console
  console[type](...content)
}

type LogMethod = (...content: any[]) => void
const Console: { log: LogMethod; info: LogMethod; warn: LogMethod; error: LogMethod } = {
  log: (...content: any[]): void => log('log', ...content),
  info: (...content: any[]): void => log('info', ...content),
  warn: (...content: any[]): void => log('warn', ...content),
  error: (...content: any[]): void => log('error', ...content)
}

export default Console
