import type { RouteRecordRaw } from 'vue-router'
import { PermissionName } from '@/features/roles/types'

const routes: RouteRecordRaw[] = [
  {
    path: '/beleid',
    name: 'policy',
    component: () => import('@/app/page/Policy.vue'),
    meta: {
      needsContext: true,
      requiredPermissions: [PermissionName.PolicyView]
    },
    children: [
      {
        path: 'bekijken',
        name: 'policy.view',
        component: () => import('@/app/page/PolicyView.vue'),
        meta: { needsContext: true, requiredPermissions: [PermissionName.PolicyView] }
      },
      {
        path: 'bekijken/versie/:policyVersionId',
        name: 'policy.view.version',
        component: () => import('@/app/page/PolicyView.vue'),
        meta: { needsContext: true, requiredPermissions: [PermissionName.PolicyView] }
      },
      {
        path: 'bekijken/hoofdstuk/:chapterId',
        name: 'policy.view.chapter',
        component: () => import('@/app/page/PolicyViewChapter.vue'),
        meta: { needsContext: true, requiredPermissions: [PermissionName.PolicyView] }
      },
      {
        path: 'bekijken/versie/:policyVersionId/hoofdstuk/:chapterId',
        name: 'policy.view.version.chapter',
        component: () => import('@/app/page/PolicyViewChapter.vue'),
        meta: { needsContext: true, requiredPermissions: [PermissionName.PolicyView] }
      },
      {
        path: 'bekijken/hoofdstuk/:chapterId/thema/:topicId',
        name: 'policy.view.topic',
        component: () => import('@/app/page/PolicyViewChapter.vue'),
        meta: { requiredPermissions: PermissionName.PolicyView }
      },
      {
        path: 'bekijken/versie/:policyVersionId/hoofdstuk/:chapterId/thema/:topicId',
        name: 'policy.view.version.topic',
        component: () => import('@/app/page/PolicyViewChapter.vue'),
        meta: { requiredPermissions: PermissionName.PolicyView }
      },
      {
        path: 'bewerken',
        name: 'policy.edit',
        component: () => import('@/app/page/PolicyEdit.vue'),
        meta: { requiredPermissions: PermissionName.PolicyEdit }
      },
      {
        path: 'bewerken/hoofdstuk/:chapterId/:tab?',
        name: 'policy.edit.chapter',
        component: () => import('@/app/page/PolicyEditChapter.vue'),
        meta: { requiredPermissions: PermissionName.PolicyEdit }
      },
      {
        path: 'bewerken/hoofdstuk/:chapterId/thema/:topicId/:tab?',
        name: 'policy.edit.topic',
        component: () => import('@/app/page/PolicyEditChapter.vue'),
        meta: { requiredPermissions: PermissionName.PolicyEdit }
      },
      { path: '', name: 'policy.index', redirect: '/beleid/bekijken' }
    ]
  }
]

export default routes
