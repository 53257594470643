import Tabs from './index.vue'
import TabItem from './components/tabItem.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    Tabs: typeof Tabs
    TabItem: typeof TabItem
  }
}

export default {
  Tabs,
  TabItem
}
