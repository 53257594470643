import Accordion from './index.vue'
import AccordionItem from './components/accordionItem.vue'
import AccordionItemBody from './components/accordionItemBody.vue'
import AccordionItemHeading from './components/accordionItemHeading.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    Accordion: typeof Accordion
    AccordionItem: typeof AccordionItem
    AccordionItemBody: typeof AccordionItemBody
    AccordionItemHeading: typeof AccordionItemHeading
  }
}
export default {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeading
}
