import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { AxiosResponse } from 'axios'
import type { Organisation, UpdateOrganisation } from '@/features/organisation/types'

import { useApi } from '@/features/api/api'

export const useOrganisationStore = defineStore('organisation', () => {
  const Api = useApi()
  const organisation = ref<Organisation | null>(null)

  const getOrganisation = async () => {
    const response: AxiosResponse<Organisation> | undefined =
      await Api.organisation.getOrganisation()
    const { data = null } = response || {}
    organisation.value = data
    return response
  }

  const updateOrganisation = async (organisation: UpdateOrganisation) => {
    const response: AxiosResponse<Organisation> | undefined =
      await Api.organisation.updateOrganisation(organisation)
    return response
  }

  return {
    organisation,
    getOrganisation,
    updateOrganisation
  }
})
