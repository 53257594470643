import { PermissionCategoryName, PermissionName } from '@/features/roles/types'

const permissionCategories: { name: PermissionCategoryName; permissions: PermissionName[] }[] = [
  {
    name: PermissionCategoryName.Actions,
    permissions: [
      PermissionName.ActionsCreate,
      PermissionName.ActionsChangeMine,
      PermissionName.ActionsDelete,
      PermissionName.ActionsViewLocation,
      PermissionName.ActionsChangeLocation
    ]
  },
  {
    name: PermissionCategoryName.Policy,
    permissions: [
      PermissionName.PolicyView,
      PermissionName.PolicyFeedback,
      PermissionName.PolicyEdit,
      PermissionName.PolicyPublish
    ]
  },
  {
    name: PermissionCategoryName.Cyclus,
    permissions: [
      PermissionName.CyclusCreateShare,
      PermissionName.CyclusEditCurrent,
      PermissionName.CyclusActivitiesChangeMine,
      PermissionName.CyclusActivitiesViewLocation,
      PermissionName.CyclusActivitiesChangeLocation,
      PermissionName.CyclusManagerChange
    ]
  },
  {
    name: PermissionCategoryName.Settings,
    permissions: [
      PermissionName.SettingsOrganisationManage,
      PermissionName.SettingsLocationsManage,
      PermissionName.SettingsUsersManage,
      PermissionName.SettingsRolesManage
    ]
  },
  {
    name: PermissionCategoryName.Notification,
    permissions: [
      PermissionName.NotificationCreateViewEditMine,
      PermissionName.NotificationViewLocation,
      PermissionName.NotificationEdit,
      PermissionName.NotificationDelete
    ]
  }
]

export default permissionCategories
