<template>
  <div v-if="text" class="reactive-form__heading" :class="className">
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { toKebabCase } from '@/support/helpers/string'

interface Props {
  id: string
  text: string | undefined
  hidden?: boolean
}

const props = defineProps<Props>()

const className = computed(() => {
  const defaultClassName = toKebabCase(props.id)
  return [defaultClassName, { 'd-none': props.hidden }]
})
</script>

<style lang="scss" scoped>
.reactive-form__heading {
  font-size: $theme-font-size-l;
  font-family: $theme-brand-font-headings;
  font-weight: 700;
  margin-bottom: 15px;
}
.reactive-form__field + .reactive-form__heading {
  margin-top: 15px;
}
</style>
