import type { User } from '@/features/user/types'

export const getFullNameOrEmail = (
  user?: Partial<{
    firstName: string | null
    namePrefix: string | null
    lastName: string | null
    email: string | null
  }> | null
): string => {
  if (!user) {
    return ''
  }

  const { firstName, namePrefix, lastName, email } = user

  if (!firstName && !lastName) {
    return email || 'unknown user'
  }

  return [firstName, namePrefix, lastName]
    .map((item) => item?.trim())
    .filter(Boolean)
    .join(' ')
}

export const getUserInitials = (user?: User | null): string => {
  if (!user) {
    return 'RM'
  }

  const firstName = user.firstName || ''
  const lastNamePieces = (user.lastName || '').trim().split(' ')
  const lastName = lastNamePieces[lastNamePieces.length - 1]

  const email = user.email || ''

  if (!firstName || !lastName) {
    return email.substring(0, 2).toUpperCase()
  }

  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
}

export const getFirstNameOrEmail = (
  user?: Partial<{
    firstName: string | null
    namePrefix: string | null
    lastName: string | null
    email: string | null
  }> | null
): string => {
  if (!user) {
    return ''
  }

  const { firstName, namePrefix, lastName, email } = user

  if (firstName) {
    return firstName
  }
  if (lastName) {
    ;[namePrefix, lastName]
      .map((item) => item?.trim)
      .filter(Boolean)
      .join(' ')
  }
  return email || 'unknown user'
}
