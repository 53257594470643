<template>
  <div class="page-header d-print-none" :class="color">
    <div class="container">
      <slot name="body">
        <div class="page-header__left">
          <div class="column">
            <slot name="default">
              <h1>{{ header.title }}</h1>
              <p v-if="header.intro" class="intro">{{ header.intro }}</p>
            </slot>
          </div>
          <div class="column">
            <slot name="bottom" />
          </div>
        </div>
        <div v-if="$slots.right" class="page-header__right">
          <slot name="right" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, unref } from 'vue'

type Props = {
  title?: string
  intro?: string
  color?: 'green' | 'beige' | 'white'
}

const route = useRoute()
const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  intro: undefined,
  color: 'green'
})

defineSlots<{ default(): void; bottom(): void; right(): void; body(): void }>()

const header = computed(() => {
  const meta = [...route.matched].reverse().find((route) => route.meta && route.meta.title)?.meta

  return {
    title: props.title || unref(meta?.title) || route.path,
    intro: props.intro || unref(meta?.intro)
  }
})
</script>

<style lang="scss" scoped>
.page-header {
  position: relative;
  padding-top: 15px;

  &:before {
    content: '';
    background: linear-gradient(to bottom, rgb(38, 56, 75, 9%), rgba(0, 0, 0, 0));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px 0 0 0;
    pointer-events: none;
  }

  &.green {
    background-color: map-get($theme-color-secondary, 'light-green-2');
  }
  &.beige {
    background-color: map-get($theme-color-secondary, 'beige');
  }
  &.white {
    background-color: map-get($theme-color-neutral, 'white');
  }

  .container {
    display: flex;
    justify-content: space-between;

    .page-header__left {
      display: flex;
      flex-direction: column;
      height: 168px;

      & > div {
        margin-top: auto;
      }
    }

    .page-header__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
