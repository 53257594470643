export const toKebabCase = (input?: string): string | undefined =>
  input?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()

export const camelCaseToReadable = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, ' $1$2')
    .replace(/^./, (match) => match.toUpperCase())
}

export const dashToCamelCase = (str: string): string =>
  str
    .split('-')
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('')
