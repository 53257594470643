<template>
  <aside>
    <ul>
      <li
        v-for="(page, index) in navItems"
        :key="index"
        :class="[!page.to ? 'h5 heading' : '', activeRoute === page.to ? 'active' : '']"
      >
        <router-link v-if="page.to" :to="{ name: page.to }">
          {{ page.title }}
        </router-link>
        <span v-else>
          {{ page.title }}
        </span>
      </li>
    </ul>
  </aside>
</template>

<script setup lang="ts">
import { useRouter, type RouteRecordName } from 'vue-router'
import { ref } from 'vue'
import navigation from './constants/navigation'

const navItems = ref(navigation)
const activeRoute = ref<RouteRecordName | null>(null)

const router = useRouter()

router.afterEach((to) => {
  activeRoute.value = to.name ?? null
})
</script>

<style scoped lang="scss">
aside {
  ul,
  ul li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li + li.heading {
    margin-top: 1rem;
  }

  ul li span,
  ul li a {
    font-size: $theme-font-size-s;
  }
  ul li span {
    color: map-get($theme-color-primary, 'orange');
  }
}
</style>
