import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useApi } from '@/features/api/api'
import type {
  Notification,
  NotificationsListFilter,
  NotificationFormData
} from '@/features/notifications/types'
import type { PaginatedApiResponse } from '@/support/types/PaginatedApiResponse.dto'
import type { AxiosResponse } from 'axios'
import type { Guid } from '@/support/types/Guid.dto'

export const useNotificationsStore = defineStore('notifications', () => {
  const Api = useApi()
  const filter = ref<NotificationsListFilter>({})
  const paginatedNotifications = ref<PaginatedApiResponse<Notification>>()
  const notification = ref<Notification>()

  const getPaginatedNotifications = async (locationPath: string) => {
    const pageIndex = paginatedNotifications.value?.pageIndex || 1
    const response = await Api.notifications.getNotifications({
      ...filter.value,
      locationPath,
      pageIndex
    })
    const { items = [], totalPages = 1, totalCount } = response?.data || {}

    paginatedNotifications.value = {
      items,
      pageIndex,
      totalPages,
      totalCount
    }

    return response
  }

  const createNotification = async (data: NotificationFormData) => {
    return await Api.notifications.createNotification(data)
  }

  const updateNotification = async (data: Partial<NotificationFormData>) => {
    return await Api.notifications.updateNotification(data)
  }

  const getNotification = async (id: Guid) => {
    notification.value = undefined
    const response: AxiosResponse<Notification> = await Api.notifications.getNotification(id)
    notification.value = response.data
    return response
  }

  const deleteNotification = async (id: Guid) => {
    notification.value = undefined
    const response: AxiosResponse<Notification> = await Api.notifications.deleteNotification(id)
    notification.value = response.data
    return response
  }

  const setFilters = (newFilters: NotificationsListFilter) => {
    Object.assign(filter.value, newFilters)
  }

  const setPaging = (pageIndex: number) => {
    if (paginatedNotifications.value) {
      paginatedNotifications.value = { ...paginatedNotifications.value, pageIndex }
    }
  }

  return {
    paginatedNotifications,
    notification,
    getPaginatedNotifications,
    deleteNotification,
    createNotification,
    updateNotification,
    getNotification,
    setPaging,
    setFilters,
    filter
  }
})
