<template>
  <div>
    <h2>
      {{ t('roles.title') }}
      <button-icon
        variant="secondary"
        :icon-props="{ name: `action-add`, states: ['disabled'] }"
        icon-position="left"
        @click="showModal('create')"
      >
        {{ t('app.button.new') }}
      </button-icon>
    </h2>

    <div v-if="pageRecords.length" class="font-size-s mb-3">
      <strong>{{ t('roles.listView.count', { count: paginatedRoles?.totalCount }) }}</strong>
    </div>

    <spinner-context>
      <spinner v-if="loading" />
      <template #context>
        <list-view
          v-if="listViewRecords.length"
          :headers="listViewHeader"
          :rows="listViewRecords"
          :buttons="listViewButtons"
          @event="handleRowClick"
        >
          <template #footer>
            <pagination
              :page-index="paginatedRoles.pageIndex"
              :total-pages="paginatedRoles.totalPages"
              @change="onPageChange"
            />
          </template>
        </list-view>
      </template>
    </spinner-context>

    <role-dialog
      v-if="modalRecordOpen"
      v-model="roleRecord"
      :role="selectedRecord"
      @confirm="onConfirmModalRecord"
      @hidden="modalRecordOpen = false"
    />

    <modal-delete
      v-if="modalDeleteRecordOpen"
      :title="t('roles.modal.deleteRole.title', { name: roleRecord.name })"
      :confirm-button-text="t('roles.modal.deleteRole.confirmButton')"
      @confirm="onConfirmModalDeleteRecord"
      @hidden="modalDeleteRecordOpen = false"
    />

    <modal-default
      v-if="modalDeleteDeleteRoleError"
      :id="modalDeleteDeleteRoleErrorData.id"
      :title="modalDeleteDeleteRoleErrorData.title"
      :message="modalDeleteDeleteRoleErrorData.message"
      :button="{ confirm: { text: modalDeleteDeleteRoleErrorData.button } }"
      @hidden="modalDeleteDeleteRoleError = undefined"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, reactive, type ComputedRef } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import Console from '@/support/core/console'

import { ListViewButton } from '@/support/components/listView/enums/ListViewButton'

import RoleDialog from '@/features/roles/components/roleDialog/index.vue'
import ModalDelete from '@/features/modal/modalDelete/index.vue'

import { useRolesStore } from '@/features/roles/store'
import type { Permission, Role, RoleFormData } from '@/features/roles/types'
import type { Guid } from '@/support/types/Guid.dto'

import { useAlertStore } from '@/support/components/alert/store'

import { Status } from '@/support/enums/Status'
import type { Alert } from '@/support/components/alert/types'
import type { ListviewHeader, ListViewRecord } from '@/support/components/listView/types'
import { useUserStore } from '@/features/user/store'

const { t } = useI18n()

const roleRecordDefaults: RoleFormData = {
  name: '',
  description: '',
  permissionIds: null
}

const roleRecord = reactive<RoleFormData>({ ...roleRecordDefaults })
const selectedRecord = ref<Role>()

const modalRecordOpen = ref<boolean>(false)
const modalDeleteRecordOpen = ref<boolean>(false)
const modalDeleteDeleteRoleError = ref<'locked' | 'in-use'>()

const loading = ref(false)

const alertStore = useAlertStore()
const rolesStore = useRolesStore()
const usersStore = useUserStore()
const { permissions, paginatedRoles } = storeToRefs(rolesStore)

const pageRecords: ComputedRef<Role[]> = computed(() => paginatedRoles.value.items)

const listViewHeader: Array<ListviewHeader> = [
  { value: t('roles.listView.header.name'), property: 'name', width: 240 },
  { value: t('roles.listView.header.description'), property: 'description' }
]

const listViewButtons: Array<ListViewButton> = [ListViewButton.Edit, ListViewButton.Delete]

const listViewRecords: ComputedRef<Array<ListViewRecord>> = computed(() => {
  if (loading.value) {
    const { pageSize = 10 } = paginatedRoles.value

    return Array.from({ length: pageSize }, (_, index) => ({
      id: index,
      name: t('app.loading.records', { entity: t('roles.title') }),
      description: '',
      disableButtons: [ListViewButton.Edit, ListViewButton.Delete]
    }))
  }

  return pageRecords.value.map((role: Role) => ({
    id: role.id,
    name: {
      badge: {
        badgeProps: {
          type: Status.mintgreen,
          rounded: true
        },
        value: role.name
      }
    },
    description: role.description
  }))
})

const modalDeleteDeleteRoleErrorData = computed(() => {
  const name = roleRecord.name?.toLowerCase()
  const error = modalDeleteDeleteRoleError.value

  return {
    id: `roles-error-dialog-${error}`,
    title: error ? t(`roles.modal.${error}.title`, { name }) : undefined,
    message:
      error && modalDeleteDeleteRoleError.value !== 'locked'
        ? t(`roles.modal.${error}.message`, { name })
        : undefined,
    button: t('app.button.ok')
  }
})

const showModal = (action: 'create' | 'edit' | 'delete', id?: Guid) => {
  const record = pageRecords.value.find((role: Role) => role.id === id)

  const recordForm: RoleFormData = !record
    ? { ...roleRecordDefaults }
    : {
        name: record.name ?? null,
        description: record.description ?? null,
        permissionIds: record.permissions?.map((permission: Permission) => permission.id) ?? []
      }

  Console.log('showModal', recordForm)

  Object.assign(roleRecord, recordForm)
  selectedRecord.value = record

  if (action === 'delete' && id) {
    if (record?.isLocked) {
      modalDeleteDeleteRoleError.value = 'locked'
      return
    }
    if (usersStore.users?.find((user) => !user.isDeleted && user.role?.id === id)) {
      modalDeleteDeleteRoleError.value = 'in-use'
      return
    }

    modalDeleteRecordOpen.value = true
    return
  }

  if (action === 'edit' && selectedRecord.value) {
    modalRecordOpen.value = true
  }
  if (action === 'create' && !selectedRecord.value) {
    modalRecordOpen.value = true
  }
}

const onConfirmModalRecord = async (role: RoleFormData) => {
  Console.log('onConfirmModalRecord', role)

  loading.value = true

  const response = selectedRecord.value
    ? await rolesStore.updateRole(selectedRecord.value.id, role)
    : await rolesStore.createRole(role)

  const method = selectedRecord.value ? 'put' : 'post'
  const alert: Alert =
    response?.status !== 200
      ? { message: t(`app.xhr.${method}.error`), type: Status.error }
      : {
          message: method === 'put' ? t('alert.roles.updated') : t('alert.roles.created'),
          type: Status.success
        }

  if (response?.status === 200) {
    rolesStore.setPaging(1)
    await rolesStore.getPaginatedRoles()
    await rolesStore.getAllRoles()
  }
  loading.value = false

  alertStore.setMessage(alert)
}

const onConfirmModalDeleteRecord = async () => {
  Console.log('onConfirmModalDeleteRecord', selectedRecord.value?.id)

  if (!selectedRecord.value?.id) {
    return
  }

  loading.value = true
  const response = await rolesStore.deleteRole(selectedRecord.value.id)
  const alert: Alert =
    response?.status !== 200
      ? { message: t('app.xhr.delete.error'), type: Status.error }
      : { message: t('alert.roles.deleted'), type: Status.success }

  if (response?.status === 409) {
    modalDeleteDeleteRoleError.value = 'in-use'
    loading.value = false
    return
  }

  if (response?.status === 200) {
    rolesStore.setPaging(1)
    await rolesStore.getPaginatedRoles()
    await rolesStore.getAllRoles()
  }
  loading.value = false

  alertStore.setMessage(alert)
}

const handleRowClick = (event: { action: 'create' | 'edit' | 'delete'; id?: Guid }) => {
  if (loading.value) {
    return
  }

  Console.log('handleRowClick', event)

  const { action, id } = event

  if (action === 'edit') {
    showModal('edit', id)
  }
  if (action === 'delete') {
    showModal('delete', id)
  }
}

const onPageChange = async (pageIndex: number) => {
  rolesStore.setPaging(pageIndex)
  loading.value = true
  await rolesStore.getPaginatedRoles()
  loading.value = false
}

onMounted(async () => {
  const promises = []
  loading.value = true

  if (!permissions.value.length) {
    promises.push(rolesStore.getPermissions())
  }

  rolesStore.setPaging(1)
  promises.push(rolesStore.getPaginatedRoles())

  await Promise.all(promises)
  loading.value = false
})
</script>

<style lang="scss" scoped>
h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
