<template>
  <div class="accordion-heading">
    <div class="left">
      <media-image
        v-if="mediaImageProps?.mediaUrl"
        :media-url="mediaImageProps.mediaUrl"
        :style="mediaImageSize"
      />
      <icon v-if="iconProps?.name" :name="iconProps.name" :size="iconProps.size" />
      <span class="title d-block h4 mb-0"
        >{{ title }} <span class="counter">({{ counter || '0' }})</span>
      </span>
      <a v-if="showInfo" role="button" @click.stop="$emit('click:info')">
        <icon name="action-info" />
      </a>
    </div>
    <div class="right">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { AccordionItemHeading } from '@/support/components/accordion/types/AccordionItemHeading.dto'

const props = withDefaults(defineProps<AccordionItemHeading>(), {
  iconProps: undefined,
  mediaImageProps: undefined,
  info: undefined,
  open: false
})
defineEmits<{ 'click:info': [] }>()

const mediaImageSize = computed(() => {
  return `width:${props?.mediaImageProps?.size?.width ?? 40}px; height:${props?.mediaImageProps?.size?.height ?? 40}px;`
})
</script>

<style lang="scss" scoped>
.accordion-heading {
  display: flex;
  flex-grow: 1;

  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .left .title {
    margin-left: 10px;

    &:last-child {
      margin-left: 20px;
    }

    .counter {
      font-weight: 500;
    }
  }
  .right {
    display: flex;
    margin-left: auto;

    :deep(.heading-property) {
      font-size: $theme-font-size-s;
      margin-right: 25px;
      display: flex;
      align-items: center;
      justify-content: end;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
