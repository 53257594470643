<template>
  <div class="image-grid">
    <h5 v-if="enableTitle && images.length">{{ t(`imageGrid.title`, images.length) }}:</h5>
    <div
      class="image-grid__list"
      :class="{ scrollable: enableScroll, deletion: !disableDeletion, 'enable-click': enableClick }"
      :style="enableScroll ? { maxHeight: `${props.maxHeight}px` } : {}"
    >
      <ul>
        <li v-for="(file, index) in images" :key="index">
          <div class="image" @click="onImageClick($event, index)">
            <img :src="file.base64" />
          </div>
          <a v-if="!disableDeletion" role="button">
            <icon name="action-delete-white" @click="onDeleteImage($event, index)" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import './localization'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { FileData } from '@/support/types/FileData.dto'

interface Props {
  images: FileData[]
  disableDeletion?: boolean
  enableClick?: boolean
  enableTitle?: boolean
  maxHeight?: number | null
}

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  disableDeletion: false,
  enableClick: true,
  enableTitle: true,
  maxHeight: null
})

const emit = defineEmits(['delete', 'click'])

const onImageClick = ($event: MouseEvent, index: number) => {
  if (!props.enableClick) return
  emit('click', { $event, index, images: props.images })
}

const onDeleteImage = ($event: PointerEvent, index: number) => {
  emit('delete', { $event, index })
}

const enableScroll = computed(() => {
  return props.maxHeight !== null
})
</script>

<style lang="scss" scoped>
.image-grid {
  overflow: hidden;

  h5 {
    font-size: $theme-font-size-s;
  }

  &__list {
    &.enable-click {
      ul li {
        .image {
          cursor: pointer;
        }
      }
    }
    &.deletion {
      ul li {
        a[role='button'] {
          opacity: 0;
          position: absolute;
          bottom: 7px;
          right: 5px;
          transition: opacity 0.3s;
          z-index: 2;
        }

        &:after {
          background: linear-gradient(
            135.9deg,
            rgba(38, 56, 75, 0) 47.07%,
            rgba(38, 56, 75, 0.6) 100%
          );
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: opacity 0.3s;
          z-index: 1;
          pointer-events: none;
        }
        &:hover {
          &:after,
          a[role='button'] {
            opacity: 1;
          }
        }
      }
    }

    &.scrollable {
      overflow-y: auto;
      overflow-x: hidden;
      white-space: nowrap;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    ul,
    ul li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 13px;

      &:empty {
        display: none;
      }

      li {
        position: relative;

        .image {
          width: 120px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
