<template>
  <button
    type="button"
    :class="button.className"
    :disabled="button.disabled"
    @click="onClickHandler"
  >
    {{ button.text }}
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Specs {
  type: string
  text?: string
  className?: string
}

interface Props {
  disabled: boolean
  specs: Specs
}

interface DefaultProps {
  [key: string]: any
}

const { t } = useI18n()

const defaults: DefaultProps = {
  submit: {
    text: t('button.submit'),
    className: 'button-primary'
  },
  cancel: {
    text: t('button.cancel'),
    className: 'button-primary'
  }
}

const props = defineProps<Props>()
const emit = defineEmits(['click'])

const button = computed(() => {
  const { type } = props.specs
  const buttonDefaults = defaults[type]
    ? defaults[props.specs.type]
    : {
        text: '',
        className: ''
      }

  return {
    disabled: props.specs.type === 'submit' && props.disabled,
    ...buttonDefaults,
    ...props.specs
  }
})

const onClickHandler = () => {
  emit('click', props.specs.type)
}
</script>

<style scoped></style>
