import ModalAdvanced from './advanced/index.vue'
import ModalDefault from './default/index.vue'
import ModalImageViewer from './imageViewer/index.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ModalAdvanced: typeof ModalAdvanced
    ModalDefault: typeof ModalDefault
    ModalImageViewer: typeof ModalImageViewer
  }
}

export default {
  ModalAdvanced,
  ModalDefault,
  ModalImageViewer
}
