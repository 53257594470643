<template>
  <button
    class="button-secondary"
    :class="{ active: modelValue }"
    @click="modelValue = !modelValue"
  >
    <slot />
    <icon
      :name="modelValue ? 'action-toggle-filter-active' : 'action-toggle-filter'"
      class="icon"
    />
  </button>
</template>

<script setup lang="ts">
const modelValue = defineModel<boolean>()
</script>
<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  font-weight: inherit;
  color: map-get($theme-color-primary, 'dark-blue');

  border: 1px solid map-get($theme-color-primary, 'light-blue-2');
  border-radius: 4px;

  padding: 10px;
  line-height: 1.5;

  &:hover {
    border-color: map-get($theme-color-primary, 'dark-blue');
    background-color: inherit;
    outline: none;
  }

  &:focus,
  &:focus:active {
    background-color: map-get($form-field-background-color, 'focus');
    border-color: map-get($form-field-border-color, 'focus');

    box-shadow: 0 0 0 5px $form-field-outline-color;
    outline: none;
  }

  .icon {
    display: inline-block;
    flex-shrink: 0;

    //margin-right: -10px;
    margin-left: 5px;
  }

  &.active {
    color: white;
    background-color: map-get($theme-color-primary, 'dark-blue');
  }
}
</style>
