import type { UserManagerSettings } from 'oidc-client-ts'

export const authConfig: UserManagerSettings = {
  client_id: import.meta.env.VITE_APP_OAUTH_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_APP_OAUTH_REDIRECT_URI,
  authority: import.meta.env.VITE_APP_OAUTH_AUTHORITY,
  scope: 'openid profile email',
  automaticSilentRenew: false,

  metadata: {
    issuer: import.meta.env.VITE_APP_OAUTH_AUTHORITY,
    authorization_endpoint: `${import.meta.env.VITE_APP_OAUTH_AUTHORITY}/oauth/authorize`,
    token_endpoint: `${import.meta.env.VITE_APP_OAUTH_AUTHORITY}/oauth/token`,
    userinfo_endpoint: `${import.meta.env.VITE_APP_OAUTH_AUTHORITY}/oauth/userinfo`,
    jwks_uri: `${import.meta.env.VITE_APP_OAUTH_AUTHORITY}/oauth/jwks`,
    response_types_supported: ['code', 'id_token'],
    subject_types_supported: ['pairwise', 'public'],
    scopes_supported: ['openid', 'profile', 'email']
  }
}
