<template>
  <ul class="step-progress">
    <li
      v-for="(step, index) in extendedSteps"
      :key="step.id"
      class="step-progress__item"
      :class="{ 'step-progress__item--active': step.active, 'step-progress__item--completed': step.completed }"
    >
      <icon name="action-check-white" :size="{ width: 32, height: 32 }" />
      <span>{{ index + 1 }}</span>
      <span>{{ step.title }}</span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Step = { title: string; id: string | number }
const props = defineProps<{ steps: Step[]; currentStepId?: string | number }>()

const extendedSteps = computed<(Step & { active: boolean; completed: boolean })[]>(() => {
  let completed = true;
  return props.steps.map((step) => {
    let active = false;
    if (step.id === props.currentStepId) {
      completed = false; // steps after this should not be completed
      active = true;
    }
    return {
      ...step,
      active,
      completed,
    };
  });
});

</script>

<style lang="scss" scoped>
.step-progress {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 0;

  .step-progress__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0;
    width: 0;
    position: relative;
    font-size: 12px;

    &::after {
      content: '';
      left: 50%;
      right: 0;
      top: 0;
      width: 100%;
      margin-top: 16px;
      height: 1px;
      background: map-get($theme-color-primary, 'dark-blue');
      opacity: 0.25;
      position: absolute;
      z-index: -2;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    span {
      &:first-child,
      &.icon + span {
        display: none;
      }
      &:not(:last-child) {
        display: inline-flex;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: white;
        font-family: $theme-brand-font-headings;
        font-weight: 700;
        background: map-get($theme-color-primary, 'dark-blue');
        border: 2px solid map-get($theme-color-primary, 'dark-blue');
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
      }
    }

    &--completed {
  
      &::after {
        height:4px;
        top:-2px;
        opacity: 1;
      }
    }
    
    &--active {
      span {
        &.icon {
          display: none;
        }
        &.icon + span {
          display: inline-flex;
        }
      }
      & ~ .step-progress__item span.icon {
        display: none;
      }
      & ~ .step-progress__item span.icon + span {
        display: inline-flex;
      }
      & ~ .step-progress__item {
        span:not(:last-child) {
          background: map-get($theme-color-secondary, 'light-green-1');
          color: map-get($theme-color-primary, 'dark-blue');
        }
      }
    }
  }
}
</style>
