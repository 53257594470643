import DOMPurify from 'dompurify'

export const sanitizeInputWithDOMPurify = (input: string): string => {
  // Use DOMPurify to sanitize the input string
  const sanitizedHTML = DOMPurify.sanitize(input)

  // Create a new DOMParser instance
  const parser = new DOMParser()

  // Parse the sanitized HTML string
  const doc = parser.parseFromString(sanitizedHTML, 'text/html')

  // Extract the text content from the parsed document
  const sanitizedText = doc.body.textContent || ''

  // Return the sanitized text
  return sanitizedText.trim()
}
