import i18n from '@/support/core/localization.js'

import { app } from '@/features/app/localization'
import { auth } from '@/features/auth/localization'
import { contentPage } from '@/features/contentPage/localization'
import { dashboard } from '@/features/dashboard/localization'
import { descriptivePage } from '@/features/descriptivePage/localization'
import { designSystem } from '@/features/designSystem/localization'
import { helpAndContact } from '@/features/helpAndContact/localization'
import { locations } from '@/features/locations/localization'
import { media } from '@/features/media/localization'
import { notifications } from '@/features/notifications/localization'
import { onboarding } from '@/features/onboarding/localization'
import { organisation } from '@/features/organisation/localization'
import { policy } from '@/features/policy/localization'
import { roles } from '@/features/roles/localization'
import { settings } from '@/features/settings/localization'
import { user } from '@/features/user/localization'
import { workForm } from '@/features/workForm/localization'
import { actions } from '@/features/actions/localization'
import { cycle } from '@/features/cycle/localization'

const { mergeLocaleMessage, locale } = i18n.global

mergeLocaleMessage(locale.value, {
  app,
  auth,
  contentPage,
  dashboard,
  designSystem,
  helpAndContact,
  descriptivePage,
  locations,
  media,
  notifications,
  onboarding,
  organisation,
  policy,
  roles,
  settings,
  user,
  workForm,
  actions,
  cycle
})
