<template>
  <router-view
    v-if="
      routeName.startsWith('login.') ||
      routeName.startsWith('auth.') ||
      routeName === 'index' ||
      !routeName
    "
  />
  <master-page v-else-if="context !== undefined || routeName === 'not-found'" @click="onClickAny">
    <alert />

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <side-panel />
  </master-page>
  <div v-else-if="loadingTakesMoreTime" class="spinner-wrapper">
    <spinner size="large" />
  </div>
</template>

<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import SidePanel from '@/features/helpAndContact/components/sidePanel/index.vue'
import { computed, onMounted, ref } from 'vue'
import { getContext, locations, userInfo } from '@/features/auth'
import type { Guid } from '@/support/types/Guid.dto'
import { delay } from '@/support/helpers/delay'
import { getAccessToken } from '@/features/api/fetchWithAuth'

const route = useRoute()

const routeName = computed(() => String(route.name))

const context = computed<'organisation' | Guid | null | undefined>(() => {
  if (!userInfo.value || !locations.value) {
    return
  }
  return getContext(route)
})

const loadingTakesMoreTime = ref(false)

onMounted(async () => {
  await delay(1200)
  loadingTakesMoreTime.value = true
})

const onClickAny = async (ev: MouseEvent) => {
  const el = ev.target as HTMLElement
  const a = el.closest('a')
  const href = a?.getAttribute('href')
  if (a && href?.startsWith('/api')) {
    ev.preventDefault()
    await getAccessToken()

    const target = a.getAttribute('target')
    if (target || ev.ctrlKey) {
      window.open(href, target || '_blank')
      return
    }
    document.location = href
  }
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
