<template>
  <div class="accordion-item">
    <div class="accordion-header">
      <button class="accordion-button" :class="isOpen ? '' : 'collapsed'" @click.stop="onClick">
        <icon :name="open === index ? 'action-chevron-up' : 'action-chevron-down'" />
        <slot name="heading" />
      </button>
    </div>
    <div class="accordion-collapse collapse" :class="isOpen ? 'show' : ''">
      <div class="accordion-body">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { AccordionItem } from '../types/AccordionItem.dto'

const props = defineProps<AccordionItem>()
const emit = defineEmits<{ update: [index: number] }>()

const isOpen = computed<boolean>(() => props.open === props.index)

const onClick = () => {
  emit('update', isOpen.value ? 0 : props.index)
}
</script>

<style lang="scss" scoped>
.accordion-item {
  border-top: 1px solid map-get($theme-color-primary, 'light-blue-2');

  .accordion-header {
    display: flex;

    button.accordion-button {
      background: none;
      border: none;
      padding: 10px;

      display: flex;
      flex-grow: 1;
      align-items: center;
      text-align: right;
      gap: 10px;

      &:focus {
        background: map-get($theme-color-secondary, 'light-beige');
        outline: none;
      }
    }
  }
}
</style>
