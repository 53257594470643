import Notifications from '@/app/page/Notifications.vue'
import { PermissionName } from '@/features/roles/types'
import type { RouteRecordRaw } from 'vue-router'
export default [
  {
    path: '/meldingen/:notificationId?',
    name: 'notifications',
    component: Notifications,
    meta: {
      needsContext: true,
      requiredPermissions: (permissions) =>
        [
          PermissionName.NotificationCreateViewEditMine,
          PermissionName.NotificationViewLocation
        ].some((permission) => permissions.includes(permission))
    }
  }
] satisfies RouteRecordRaw[]
