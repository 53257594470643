<template>
  <li class="nav-item">
    <component
      :is="to ? RouterLink : 'div'"
      :to="to"
      class="nav-link"
      :class="{ active: computedActive }"
      :aria-disabled="disabled || undefined"
      :aria-current="computedActive || undefined"
      @click="$emit('click', value)"
    >
      {{ title }}
      <badge-component
        v-if="badge && badge.content"
        :type="badge.status"
        rounded
        class="nav-item__badge"
        >{{ badge.content }}</badge-component
      >
    </component>
  </li>
</template>

<script lang="ts" setup generic="Value = string | number | null">
import { type RouteLocationRaw, RouterLink, useRouter } from 'vue-router'
import { computed } from 'vue'
import type { Status } from '@/support/enums/Status'
import BadgeComponent from '@/support/components/badge/index.vue'

const router = useRouter()

const props = withDefaults(
  defineProps<{
    active?: boolean | undefined
    title: string
    disabled?: boolean
    value?: Value
    to?: RouteLocationRaw
    badge?: { status: Status; content: string | number }
  }>(),
  { active: undefined }
)

const computedActive = computed<boolean>(() => {
  if (props.active !== undefined) {
    return props.active
  }
  if (props.to) {
    const to = router.resolve(props.to)
    return router.currentRoute.value.fullPath.startsWith(to.fullPath)
  }
  return false
})
defineEmits<{ click: [v: Value | void] }>()
</script>

<style lang="scss" scoped>
.nav-item {
  > * {
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: $theme-brand-font-headings;
    font-size: $theme-font-size-m;
    font-weight: 700;
    color: map-get($theme-color-primary, 'light-blue-1');

    padding: 16px;
    border-radius: 0;
    border: 0;
    cursor: pointer;

    &.active {
      color: map-get($theme-color-primary, 'dark-blue');
      background-color: map-get($theme-color-neutral, 'white');
      cursor: default;
    }

    &:hover {
      text-decoration: none;
      color: map-get($theme-color-primary, 'dark-blue');
    }

    > .nav-item__badge {
      font-size: 12px;
      min-width: 16px;
      min-height: 22px;
      padding: 0 8px;
      font-weight: 700;
    }
  }
}
</style>
