<template>
  <ul v-if="totalPages > 1" class="pagination">
    <li v-if="config.firstPageHandler">
      <button :disabled="pageIndex === 1" @click="firstPageHandler">
        <icon name="action-chevron-left" />
      </button>
    </li>
    <li>
      <button :disabled="pageIndex === 1" @click="prevPageHandler">
        <icon name="action-chevron-left" />
      </button>
    </li>
    <li class="page-numbers">
      <span>{{ pageIndex }}</span>
      <span>/</span>
      <span>{{ totalPages }}</span>
    </li>
    <li>
      <button :disabled="pageIndex === totalPages" @click="nextPageHandler">
        <icon name="action-chevron-right" />
      </button>
    </li>
    <li v-if="config.lastPageHandler">
      <button :disabled="pageIndex === totalPages" @click="lastPageHandler">
        <icon name="action-chevron-right" />
      </button>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue'
import { useRoute } from 'vue-router'

interface Config {
  firstPageHandler: boolean
  lastPageHandler: boolean
}

interface Props {
  pageIndex: number
  totalPages: number
  config?: Config
}

const props = withDefaults(defineProps<Props>(), {
  config: () => {
    return {
      firstPageHandler: false,
      lastPageHandler: false
    }
  }
})
const emit = defineEmits(['change'])

const route = useRoute()

const { pageIndex, totalPages } = toRefs(props)

const isSamePage = (to: number) => {
  return route.query.page === String(to)
}

const changeHandler = (pageIndex: number) => {
  emit('change', pageIndex)
}

const prevPageHandler = () => {
  const toPageNumber = pageIndex.value > 1 ? pageIndex.value - 1 : null

  if (toPageNumber && !isSamePage(toPageNumber)) {
    changeHandler(toPageNumber)
  }
}

const nextPageHandler = () => {
  const toPageNumber = pageIndex.value < totalPages.value ? pageIndex.value + 1 : null

  if (toPageNumber && !isSamePage(toPageNumber)) {
    changeHandler(toPageNumber)
  }
}

const firstPageHandler = () => {
  const toPageNumber = 1

  if (toPageNumber && !isSamePage(toPageNumber)) {
    changeHandler(toPageNumber)
  }
}

const lastPageHandler = () => {
  const toPageNumber = totalPages.value

  if (toPageNumber && !isSamePage(toPageNumber)) {
    changeHandler(toPageNumber)
  }
}
</script>

<style lang="scss" scoped>
ul,
ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.pagination {
  display: flex;
  gap: 10px;
  align-items: center;

  li {
    display: flex;
  }

  li.page-numbers {
    display: flex;
    align-items: center;
    gap: 5px;

    span:not(:first-child) {
      color: map-get($theme-color-primary, 'light-blue-1');
    }
  }

  button {
    display: flex;
    all: unset;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;

    :deep(.icon) {
      margin: 8px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      background-color: map-get($theme-color-primary, 'light-blue-2');
    }
  }
}
</style>
