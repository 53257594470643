<template>
  <div class="reactive-form" :class="[`reactive-form--${className}`]">
    <form autocomplete="off">
      <reactive-form-debug v-if="debug" :vuelidate="v$" :form="model" :config="config" />

      <fieldset class="reactive-form__fieldset">
        <reactive-form-legend v-if="legend" :id="legend.id" :text="legend.text">
          <slot />
        </reactive-form-legend>

        <section class="reactive-form__section">
          <template v-for="(field, index) in config.fields" :key="`${index.id || index}_field`">
            <reactive-form-field-heading
              v-if="field.type === FieldType.HEADING"
              :id="field.id"
              :text="field.label"
              :hidden="field.hidden"
            />
            <component
              :is="`reactive-form-field-${field.type}`"
              v-else
              v-model="model[field.id]"
              :vuelidate="v$.form[field.id]"
              :field="field"
            />
          </template>
        </section>
      </fieldset>

      <div v-if="buttons?.length" class="reactive-form__footer">
        <template v-for="(button, index) in buttons" :key="index">
          <reactive-form-button
            :specs="button"
            :disabled="v$.form.$invalid || (button.disabled ?? false)"
            @click="onClickHandler"
          />
        </template>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import './localization'

import { computed, reactive, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'

// Composables
import { useReactiveForm } from './composables/useReactiveForm'

// Components
import ReactiveFormButton from './components/reactiveFormButton.vue'
import ReactiveFormDebug from './components/reactiveFormDebug.vue'
import reactiveFormLegend from './components/reactiveFormLegend.vue'
import reactiveFormFieldHeading from './components/reactiveFormFieldHeading.vue'

// Types
import type { KeyValue } from '@/support/types/KeyValue.dto'
import { ButtonType } from './types/ButtonType.dto'
import type { ReactiveFormConfig } from './types/ReactiveFormConfig.dto'
import { ReactiveFormTypes } from './types/ReactiveFormTypes.dto'
import { FieldType } from './enums/FieldType'

interface Props {
  config: ReactiveFormConfig
  form: KeyValue
  debug?: boolean
  className?: ReactiveFormTypes | string
}

// Props
const props = withDefaults(defineProps<Props>(), { className: ReactiveFormTypes.PRIMARY })

// Form models
const model = reactive(props.form)

// Emit definition
const emit = defineEmits([ButtonType.SUBMIT, ButtonType.CANCEL, 'update:form'])

// Composables
const { legend, buttons, validators, createLocaleMessages } = useReactiveForm(props.config)

// Create custom locale messages
createLocaleMessages()

// Vuelidate instance
const v$ = useVuelidate(validators, { form: model })

// Watch
const watchOptions = { deep: true }

// Fn onClickHandler
const onClickHandler = (type: any) => {
  emit(type, model)

  if (type === ButtonType.SUBMIT) return

  const { $reset } = v$.value

  $reset()
}

const config = computed(() => props.config)

// Watch form changes
watch(
  model,
  () => {
    emit('update:form', model)
  },
  watchOptions
)

// Watch config changes
watch(
  config,
  () => {
    v$.value.$reset()
  },
  watchOptions
)
</script>

<style>
[data-lastpass-icon-root],
[data-lastpass-root] {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.reactive-form {
  fieldset {
    section {
      display: flex;
      flex-wrap: wrap;

      .reactive-form__field,
      .reactive-form__heading {
        flex: 1 1 auto;
        min-width: 100%;
      }
    }
  }

  .reactive-form__footer {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
  }

  &--primary {
    fieldset {
      margin-bottom: 30px;

      section {
        box-shadow:
          0px 8px 8px 0px rgba(38, 56, 75, 0.05),
          0px -4px 8px 0px rgba(38, 56, 75, 0.05);
        border-radius: 5px;
        padding: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
.reactive-form__field {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 10px;
  }
}
</style>
