import { useCycleStore } from '@/features/cycle/store'
import { currentLocationId } from '@/features/auth'
import type {
  ActiveCycle,
  ChecklistSubject,
  ChecklistSubSubject,
  CycleDuration,
  CyclePreparation,
  CycleTopic
} from '@/features/cycle/types'
import { computed, type ComputedRef, type Ref, unref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { getCycleProgress } from '@/features/cycle/composition/use-progress'
import { useAlertStore } from '@/support/components/alert/store'
import { Status } from '@/support/enums/Status'
import i18n from '@/support/core/localization'
import { useDebounceFn } from '@vueuse/core'
import { DateTime, Interval } from 'luxon'

const { t } = i18n.global

export const useEditCycle = (useCurrentCycle: Ref<boolean> | boolean) => {
  const cycleStore = useCycleStore()
  const editableCycle = computed<CyclePreparation | ActiveCycle | undefined | null>(() =>
    unref<boolean>(useCurrentCycle) ? cycleStore.organisationCycle : cycleStore.preparationCycle
  )
  const { loading } = storeToRefs(cycleStore)

  watch(
    () => unref<boolean>(useCurrentCycle),
    (currentCycle) => {
      if (currentCycle && !cycleStore.organisationCycle) {
        cycleStore.getActiveOrganisationCycle()
      }
      if (!currentCycle && !cycleStore.preparationCycle) {
        cycleStore.getPreparationCycle()
      }
    },
    { immediate: true }
  )

  const refresh = async (useCurrentCycle: Ref<boolean> | boolean) => {
    if (unref<boolean>(useCurrentCycle)) {
      await cycleStore.getActiveOrganisationCycle()
      return
    }
    await cycleStore.getPreparationCycle()
  }

  return { cycleStore, editableCycle, loading, refresh }
}

const alertCycleFinished = useDebounceFn((setMessage: (message: string) => void) => {
  setMessage(t('cycle.current.cycleFinishedMessage'))
}, 500)

export const useActiveLocationCycle = () => {
  const cycleStore = useCycleStore()
  const alertStore = useAlertStore()

  watch(
    currentLocationId,
    () => {
      if (!currentLocationId.value) {
        return
      }
      cycleStore.getActiveLocationCycle(currentLocationId.value)
    },
    { immediate: true }
  )

  const progress = computed<number | undefined>(() =>
    cycleStore.locationCycle ? getCycleProgress(cycleStore.locationCycle).progress : undefined
  )

  watch(progress, (newProgress, oldProgress) => {
    if (oldProgress === undefined || newProgress === undefined) {
      return
    }
    if (newProgress >= 1 && oldProgress < 1) {
      alertCycleFinished((message: string) => {
        alertStore.setMessage({ message, type: Status.success })
      })
    }
  })

  return cycleStore
}

export const useActiveOrganisationLocationsCycle = () => {
  const cycleStore = useCycleStore()

  if (!cycleStore.activeOrganisationCycles) {
    cycleStore.getActiveOrganisationLocationCycles()
  }

  return cycleStore
}

export const getEnabledActiveCycle = (cycle: ActiveCycle): ActiveCycle => ({
  ...cycle,
  cycleTopics: cycle.cycleTopics
    .filter((topic) => topic.enabled)
    .map<CycleTopic>((topic) => ({
      ...topic,
      // filter checklist
      checklists: (topic.checklists || [])
        .filter((checklist) => checklist.enabled)
        .map((checklist) => ({
          ...checklist,
          // filter subjects
          subjects:
            checklist.subjects
              ?.filter((subject) => subject.enabled)
              .map<ChecklistSubject>((subject) => ({
                ...subject,
                // filter sub-subjects
                subSubjects:
                  subject.subSubjects
                    ?.filter((subSubject) => subSubject.enabled)
                    .map<ChecklistSubSubject>((subSubject) => ({
                      ...subSubject,
                      // filter questions
                      questions:
                        subSubject.questions?.filter((question) => question.enabled) || null
                    })) || null
              })) || null
        }))
    }))
})

type CycleIntervalInput = { startAt?: string | null; duration?: CycleDuration | null }
export const getCycleInterval = (
  cycle: CycleIntervalInput | null | undefined
): Interval<true> | undefined => {
  if (!cycle || !cycle?.startAt || !cycle.duration) {
    return undefined
  }
  const startAt = DateTime.fromISO(cycle.startAt).startOf('day')
  if (!startAt.isValid) {
    return undefined
  }
  const interval = Interval.fromDateTimes(
    startAt,
    startAt.plus({ days: cycle.duration.days }).minus({ day: 1 })
  )
  return interval.isValid ? interval : undefined
}

export const useCycleInterval = (
  cycle: Ref<CycleIntervalInput | null | undefined>
): ComputedRef<Interval<true> | undefined> =>
  computed<Interval<true> | undefined>(() => getCycleInterval(cycle.value))
